import { IEntityInfo } from 'app/state/entityFetchModule/types';
import { App } from '../constants/enums';

// export const isValidGroupLink = (url: string) => {
//   if (url && url.trim().length > 0) {
//     if (url.includes('https://vk.com/') || url.includes('vk.com/')) {
//       const arr = url.split('vk.com/');
//       return arr[arr.length - 1].length > 0;
//     }
//     return false;
//   }
//   return false;
// };

export const sanitizeEntityStr = (application: App, str = '') => {
  switch (application) {
    case App.vk: {
      const arr = str.split('vk.com/');
      const word = arr[arr.length - 1];
      const containsPublic = word.startsWith('public');
      const containsClub = word.startsWith('club');
      const containsEvent = word.startsWith('event');
      const wordToSplit =
        (containsPublic && 'public') ||
        (containsClub && 'club') ||
        (containsEvent && 'event');

      if (wordToSplit && /\d/.test(word)) {
        const values = word.split(wordToSplit);
        return values[values.length - 1];
      }
      return word;
    }
    case App.ok: {
      if (str.includes('https://ok.ru/')) {
        return str;
      }
      return `https://ok.ru/${str}`;
    }

    default:
      return str;
  }
};

export const generateSocialLink = entity => {
  return {
    0: `https://vk.com/public${entity.id}`,
    1: `https://ok.ru/group/${entity.id}`,
    2: `https://t.me/${
      entity.channelId ? entity?.channelId?.replace('@', '') : entity.id
    }`,
    3: `https://${entity.name ? entity.name.toLowerCase() : ''}`,
  };
};
