import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './index';
import { RootState } from '../../../types';

const selectSlice = (state: RootState) => state.entities || initialState;

export const getDownloadingStatus = createSelector(
  [selectSlice],
  state => state.downloadStatus,
);

export const getEntityInfo = createSelector(
  [selectSlice],
  state => state.entityInfo,
);

export const getEntityLoad = createSelector([selectSlice], state => state.load);

export const getExcelGenerateStatus = createSelector(
  [selectSlice],
  state => state.excelGenerateStatus,
);

export const getExportPercent = createSelector(
  [selectSlice],
  state => state.exportPercent,
);

export const getCollectLoad = createSelector(
  [selectSlice],
  state => state.collectLoad,
);
