import { call, delay, put, takeEvery } from 'redux-saga/effects';
import { siteStatisticsSliceActions } from './index';
import SitesApiServices from '../../services/client/site-analysis/api';

export function* fetchSiteStatisticsWorker(action) {
  let currentDomain;
  const domainsArray: string[] = action.payload.list.entities.map(
    item => item.name,
  );
  const { monthLimit } = action.payload;

  try {
    let domainSteps = [
      ...domainsArray.map(domain => ({
        title: `Идёт сбор ${domain}`,
        description: '',
      })),
      { title: 'Отрисовка графиков...', description: '' },
    ];

    yield put(siteStatisticsSliceActions.setSiteStatisticsSteps(domainSteps));

    const graphicData: any[] = [];

    for (const domain of domainsArray) {
      currentDomain = domain;

      yield put(
        siteStatisticsSliceActions.setSiteStatisticsStepStatus({
          current: domainsArray.indexOf(domain),
          status: 'process',
        }),
      );

      const before = Date.now();
      const siteData = yield call(
        SitesApiServices.getVisits,
        domain,
        monthLimit,
      );
      const after = Date.now();

      graphicData.push(...siteData);

      yield put(
        siteStatisticsSliceActions.setSiteStatisticsStepStatus({
          current: domainsArray.indexOf(domain),
          status: 'finish',
          description: `Собрали за ${((after - before) / 1000).toFixed(
            2,
          )} сек.`,
        }),
      );
    }

    yield put(
      siteStatisticsSliceActions.setSiteStatisticsStepStatus({
        current: domainsArray.length,
        status: 'process',
      }),
    );

    yield delay(500);

    yield put(
      siteStatisticsSliceActions.setSiteStatisticsGraphicsData(graphicData),
    );
    yield put(siteStatisticsSliceActions.setSiteStatisticsSuccess(graphicData));

    yield put(
      siteStatisticsSliceActions.setSiteStatisticsStepStatus({
        current: domainsArray.length,
        status: 'finish',
      }),
    );
  } catch (e) {
    yield put(
      siteStatisticsSliceActions.setSiteStatisticsStepStatus({
        current: domainsArray.indexOf(currentDomain),
        status: 'error',
      }),
    );
    yield put(
      siteStatisticsSliceActions.setSiteStatisticsFailure(
        'Произошла ошибка в сборе',
      ),
    );
  }
}

export function* siteStatisticsSaga() {
  yield takeEvery(
    siteStatisticsSliceActions.setSiteStatisticsRequest,
    fetchSiteStatisticsWorker,
  );
}
