import React, { FC, memo } from 'react';

import { useSelector } from 'react-redux';

import styled from 'styled-components';
import { getSiteStatisticsGraphicData } from '../../../state/siteStatisticsModule/selectors';
import Graphic from '../../../components/app/Graphic';
import { ExportCSV } from '../exports';
import * as SiteSelectors from '../../../state/siteStatisticsModule/selectors';

interface SiteGraphicsProps {
  selectedList: {};
}

const SiteStatisticsGraphics: FC<SiteGraphicsProps> = ({ selectedList }) => {
  const siteStatisticsGraphicData = useSelector(getSiteStatisticsGraphicData);
  const filteredGraphicsData = useSelector(
    SiteSelectors.getSiteStatisticsFilteredGraphicData,
  );

  const seriesNamesNew = Array.from(
    new Set([...siteStatisticsGraphicData.map(site => Object.keys(site)[0])]),
  );

  const types = ['viewsCount'];

  return (
    <>
      {siteStatisticsGraphicData.length > 0 && (
        <Container>
          <StyledGraphicsHeader>
            <span>Графики</span>
            <ExportCSV
              selectedList={selectedList}
              csvData={
                filteredGraphicsData.length > 0
                  ? filteredGraphicsData
                  : siteStatisticsGraphicData
              }
            />
          </StyledGraphicsHeader>
          {types.map(type => (
            <Graphic
              seriesNames={seriesNamesNew}
              id={'idHitalamaSiteStatistics'}
              key={type}
              type={type}
              graphicData={
                filteredGraphicsData.length > 0
                  ? [...filteredGraphicsData]
                  : [...siteStatisticsGraphicData]
              }
            />
          ))}
        </Container>
      )}
    </>
  );
};

const StyledGraphicsHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Container = styled.div`
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
`;

export default memo(SiteStatisticsGraphics);
