export const totalSeriesNames = {
  reachTotal: 'полный',
  reachSubscribers: 'подписчиков',
  subscribedTotal: 'подписалось',
  unsubscribedTotal: 'отписалось',
  copies: 'репосты',
  reachLikes: 'лайки',
  reachCopies: 'репосты',
  reachComments: 'коммент.',
  reachHidden: 'скрытия',
  subscribedDifference: 'прирост',
};

export const typesName = {
  reach: 'Охват',
  activity: 'Подписки',
  visitors: 'Активность',
};
