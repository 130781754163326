import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './index';
import { RootState } from '../../../types';

const selectSlice = (state: RootState) => state.dates || initialState;

export const getDefaultDateRange = createSelector(
  [selectSlice],
  state => state.defaultDateRange,
);

export const getDateRange = createSelector(
  [selectSlice],
  state => state.dateRange,
);

export const getGroupBy = createSelector([selectSlice], state => state.groupBy);
