// eslint-disable-next-line import/prefer-default-export
import { IOK_ENTITY } from '../../types/type.ok';
import { IEntityInfo } from '../state/entityFetchModule/types';
import { getOwnerIdFromEntity } from './auth.helper';
import { Object } from '@amcharts/amcharts4/.internal/fabric/fabric-impl';

export const buildEntityObject = (entity): IOK_ENTITY => ({
  admin_level:
    // eslint-disable-next-line no-nested-ternary
    entity.admin_level || entity.status === 'ADMIN'
      ? 3
      : entity.status === 'MODERATOR'
      ? 1
      : null,
  photo_100: entity.photo_100 || entity.picAvatar,
  followers_count: entity.followers_count,
  members_count: entity.members_count,
  type: entity.type ? entity.type.toLowerCase() : '',
  first_name: entity.first_name,
  last_name: entity.last_name,
  id: entity.id || entity.uid,
  name: entity.name,
});

export function findEntity(
  entities: IEntityInfo[],
  entityId: number,
): undefined | IEntityInfo {
  return entities.find(e => getOwnerIdFromEntity(e, e.app) === entityId);
}

export function getDataFromVkResponse(isExecuteError, results) {
  if (isExecuteError) {
    return [[], [], [], [], []];
  }

  return [
    [
      ...results[0].response[0],
      ...results[1].response[0],
      ...results[2].response[0],
    ],
    [
      ...results[0].response[1],
      ...results[1].response[1],
      ...results[2].response[1],
    ],
    [
      ...results[0].response[2],
      ...results[1].response[2],
      ...results[2].response[2],
    ],
    [
      ...results[0].response[3],
      ...results[1].response[3],
      ...results[2].response[3],
    ],
    [
      ...results[0].response[4],
      ...results[1].response[4],
      ...results[2].response[4],
    ],
    [
      ...results[0].response[5],
      ...results[1].response[5],
      ...results[2].response[5],
    ],
  ];
}

export const sortedTableColumns = values =>
  values
    ? [
        'postsCount',
        'reachTotal',
        'viewsCount',
        'likesCount',
        'repostsCount',
        'commentsCount',
        'erv',
        'erp',
        'err',
      ]
    : [];
