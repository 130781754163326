interface IRoutes {
  _app: string;
  app: {
    auth: string;
    collectAndAnalyze: string;
    _groupLists: string;
    groupLists: {
      covers: string;
      edit: string;
      create: string;
      stats: string;
    };
    siteStatistics: {
      path: string;
      name: string;
    };
  };
  auth: string;
  login: string;
  recoverPassword: string;
  signUp: string;
  resetPassword: string;
  loadingComponents: string;
}

const routes: IRoutes = {
  _app: '/',
  app: {
    auth: '/add-source',
    collectAndAnalyze: '/collect-and-analyze',
    _groupLists: '/lists',
    groupLists: {
      covers: '/lists/:id/covers',
      edit: '/lists/edit/:id',
      create: '/lists/create',
      stats: '/lists/:id/stats',
    },
    siteStatistics: {
      path: '/site-statistics',
      name: 'Анализ сайтов',
    },
  },
  auth: '/',
  login: '/login',
  recoverPassword: '/recover-password',
  signUp: '/sign-up',
  resetPassword: '/reset-password',
  loadingComponents: '/loading-components',
};

export default routes;
