import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { GlobalStyle } from 'styles/global-styles';
import './index.css';

import routes from '../configs/app.routes';
import { useDispatch, useSelector } from 'react-redux';
import { getIsLoading } from './state/initModule/selectors';
import { getCredentialsFromAuthUrl } from './helpers/auth.helper';
import { getUser } from './state/userAuthModule/selectors';
import { Login } from './pages/Login';
import { SignUp } from './pages/SignUp';
import { RecoverPassword } from './pages/RecoverPassword';
import CollectAndAnalyze from './pages/CollectAndAnalyze';
import Lists from './components/app/Lists';
import GroupCovers from './pages/GroupCovers';
import ListPageContainer from './pages/GroupLists/container';
import ListPage from './pages/GroupLists';
import { LandingContainer } from './components/app/LandingContainer';
import { AppAuth } from './components/app/AppAuth';
import AppLayout from './pages/AppLayout';
import SiteStatistics from './pages/SiteStatistics';
import HomePage from './pages/HomePage';
import { getNormalizeAccounts } from './helpers/getAppIcon.helper';
import { socialAccountsModuleActions } from './state/socialAccountsModule';
import ListStatistics from './pages/ListStatistics';

export function App() {
  const dispatch = useDispatch();
  const isAppLoading = useSelector(getIsLoading);
  const { isLoggedIn } = useSelector(getUser);
  const user = useSelector(getUser);

  const location = useLocation();

  function RequireAuth({ children }: { children: JSX.Element }) {
    const { isLoggedIn } = useSelector(getUser);

    if (!isLoggedIn) {
      return <Navigate to={routes.login} state={{ from: location }} />;
    }

    return children;
  }

  function RequireAccount({ children }: { children: JSX.Element }) {
    const accounts = getNormalizeAccounts();

    const siteAccount =
      location.pathname === routes.app.siteStatistics.path
        ? accounts.find(acc => acc.userId === 'hitalamaSiteStatistics')
        : accounts.find(acc => acc.userId === location.state);

    if (siteAccount) {
      dispatch(
        socialAccountsModuleActions.changeUser({
          user: siteAccount,
        }),
      );
    }

    return children;
  }

  useEffect(() => {
    const url = location ? location.search || location.hash : '';
    const isPermissionAuth = location.search && location.hash;

    if (url) {
      getCredentialsFromAuthUrl(
        isPermissionAuth ? `${location.search}${location.hash}` : url,
        dispatch,
      );
    }

    if (user) {
      // @ts-ignore
      window.dataLayer = window.dataLayer || [];
      // @ts-ignore
      window.dataLayer.push({
        userId: user.userId,
        authorizationType: user.isAnonymous ? 'anonim' : 'authorization',
        tariff: user.isAnonymous ? 'anonim' : user.tariffPlan,
      });
    }

    ((w, d, s, l, i) => {
      w[l] = w[l] || [];
      w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      });
      let f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l !== 'dataLayer' ? '&l=' + l : '';
      // @ts-ignore
      j.async = true;
      // @ts-ignore
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      // @ts-ignore
      f.parentNode.insertBefore(j, f);
      w.localStorage.setItem('checkGtm', 'enable');
    })(window, document, 'script', 'dataLayer', 'GTM-K5RLK3');
  }, [isLoggedIn, dispatch, location]);

  return (
    <>
      <Helmet titleTemplate="%s - Хиталама" defaultTitle="Хиталама">
        <meta name="description" content="Хиталама" />
      </Helmet>
      <Routes>
        <Route
          element={
            <RequireAuth>
              <AppLayout />
            </RequireAuth>
          }
        >
          <Route path={routes._app} element={<HomePage />} />
          <Route path={routes.app.auth} element={<AppAuth />} />
          <Route
            path={routes.app.collectAndAnalyze}
            element={
              <RequireAccount>
                <CollectAndAnalyze />
              </RequireAccount>
            }
          />
          <Route path={routes.app._groupLists} element={<ListPageContainer />}>
            <Route index element={<Lists />} />
            <Route path={routes.app.groupLists.create} element={<ListPage />} />
            <Route path={routes.app.groupLists.edit} element={<ListPage />} />
            <Route
              path={routes.app.groupLists.covers}
              element={<GroupCovers />}
            />
          </Route>
          <Route
            path={routes.app.groupLists.stats}
            element={<ListStatistics />}
          />
          <Route
            path={routes.app.siteStatistics.path}
            element={
              <RequireAccount>
                <SiteStatistics />
              </RequireAccount>
            }
          />
          <Route path="*" element={<span>404</span>} />
        </Route>
        <Route element={<LandingContainer isAppLoading={isAppLoading} />}>
          <Route path={routes.login} element={<Login />} />
          <Route path={routes.signUp} element={<SignUp />} />
          <Route path={routes.recoverPassword} element={<RecoverPassword />} />
          <Route path="*" element={<span>404</span>} />
        </Route>
        <Route path="*" element={<span>404</span>} />
      </Routes>
      <GlobalStyle />
    </>
  );
}
