import { PayloadAction } from '@reduxjs/toolkit';
import { IGroupCoversSliceState, IVKGroup } from './types';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { IList } from '../listsModule/types';

export const initialState: IGroupCoversSliceState = {
  groupCovers: [],
  selectedListsId: '',
  selectedListTitle: '',
  isLoad: false,
  failMessage: '',
  selectedList: {} as IList,
};

const slice = createSlice({
  name: 'groupCoversData',
  initialState,
  reducers: {
    setGroupCoversRequest(
      state,
      action: PayloadAction<{ id: string; selectedList: IList }>,
    ) {
      state.isLoad = true;
      state.selectedListsId = action.payload.id;
    },
    setGroupCoversSuccess(
      state,
      action: PayloadAction<{ data: IVKGroup[]; title: string }>,
    ) {
      state.groupCovers = action.payload.data;
      state.selectedListTitle = action.payload.title;
      state.isLoad = false;
    },
    setGroupCoversFailure(state, action: PayloadAction<string>) {
      state.failMessage = action.payload;
      state.isLoad = false;
    },
    setSelectedList(state, action: PayloadAction<IList>) {
      state.selectedList = action.payload;
    },
  },
});

export const {
  actions: groupCoversSliceActions,
  reducer: groupCoversDataModuleReducers,
} = slice;
