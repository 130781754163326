import moment from 'moment';

async function addHitalamaSiteStatisticsService(app: number, userId: string) {
  const usersMap = await JSON.parse(localStorage.getItem('usersMap') || '{}');

  if (!usersMap && usersMap[userId].application === app) {
    return 'Анализ сайтов уже добавлен.';
  } else {
    localStorage.setItem(
      'usersMap',
      JSON.stringify({
        ...usersMap,
        hitalamaSiteStatistics: {
          application: app,
          lastVisit: Date.now(),
          userId: userId,
          userInfo: {
            accessRestricted: false,
            avatar: '',
            firstName: 'Анализ',
            lastName: 'сайтов',
            social: app,
            userId: userId,
          },
        },
      }),
    );
    localStorage.setItem('lastAddedDate', `${moment().unix()}`);
    return 'Анализ сайтов добавлен.';
  }
}

export default addHitalamaSiteStatisticsService;
