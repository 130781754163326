import React from 'react';
import { Card, Col, Row } from 'antd';

const StatSkeleton = ({ statLoad }) => {
  return (
    <Row gutter={[0, 16]}>
      <Col span={24}>
        <Card loading={statLoad} />
      </Col>
      <Col span={24}>
        <Card loading={statLoad} />
      </Col>
      <Col span={24}>
        <Card loading={statLoad} />
      </Col>
      <Col span={24}>
        <Card loading={statLoad} />
      </Col>
    </Row>
  );
};

export default StatSkeleton;
