import PageHeader from '../../components/common/PageHeader/PageHeader';
import React, { useState } from 'react';
import * as Antd from 'antd';
import ContentContainer from '../../components/common/ContentContainer/StyledContent';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { getAppIcon } from '../../helpers/getAppIcon.helper';
import SitePreview from '../../components/common/SitePreview';
import { siteStatisticsSliceActions } from '../../state/siteStatisticsModule';
import { useNavigate } from 'react-router-dom';
import * as SiteSelectors from '../../state/siteStatisticsModule/selectors';
import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { SiteStatisticsGraphics, SiteStatisticsMenu } from './exports';
import SiteRangePicker from './Components/SiteRangePicker';
import { getUser } from '../../state/userAuthModule/selectors';
import routes from '../../../configs/app.routes';
import { App } from '../../constants/enums';

const SiteStatistics = () => {
  const dispatch = useDispatch();
  const load = useSelector(SiteSelectors.getSiteStatisticsLoad);
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState<string>('');
  const [monthLimit, setMonthLimit] = useState<string | number>('6');
  const selectedList = useSelector(SiteSelectors.getSiteStatisticsSelectedList);
  const steps = useSelector(SiteSelectors.getSiteStatisticsSteps);
  const graphicsData = useSelector(SiteSelectors.getSiteStatisticsGraphicData);
  const { fail } = useSelector(SiteSelectors.getSiteStatisticsMessage);
  const user = useSelector(getUser);

  const handleCollect = type => {
    dispatch(siteStatisticsSliceActions.setSiteStatisticsGraphicsData([]));
    dispatch(
      siteStatisticsSliceActions.setSiteStatisticsRequest({
        list:
          type === 'list'
            ? selectedList
            : {
                name: inputValue,
                application: App.sites,
                type: 'site',
                entities: [
                  {
                    name: inputValue,
                    photo_100: `https://www.google.com/s2/favicons?sz=32&domain_url=${inputValue}`,
                  },
                ],
              },
        monthLimit: monthLimit,
      }),
    );
  };

  const calculateDataByRange = calcData => {
    dispatch(
      siteStatisticsSliceActions.setSiteStatisticsFilteredGraphicsData(
        calcData,
      ),
    );
  };

  return (
    <>
      <PageHeader title={'Анализ сайтов'} />
      <ContentContainer>
        <StyledContent>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Antd.Space style={{ minWidth: '500px' }} direction="vertical">
              <Antd.Space direction={'horizontal'}>
                <span>Введите адрес сайта или</span>
                <SiteStatisticsMenu setInputValue={setInputValue} />
              </Antd.Space>
              <Antd.Space>
                <Antd.Input.Search
                  style={{ minWidth: '500px' }}
                  placeholder="example.com"
                  enterButton={
                    <Antd.Button
                      disabled={inputValue.length === 0 || load}
                      children="Поиск"
                      type={'primary'}
                    />
                  }
                  onSearch={handleCollect}
                  onChange={e => setInputValue(e.target.value)}
                  value={inputValue}
                  disabled={load}
                />
                {user.tariffPlan === 'beta' && (
                  <Antd.InputNumber
                    min={1}
                    max={48}
                    value={monthLimit}
                    onChange={setMonthLimit}
                    disabled={inputValue.length === 0 && !selectedList}
                  />
                )}
              </Antd.Space>
              {inputValue.length > 0 && (
                <StyledCard
                  style={{ cursor: `${load ? 'not-allowed' : ''}` }}
                  onClick={
                    load
                      ? () => Antd.message.warn('Идёт сбор...')
                      : handleCollect
                  }
                >
                  <Antd.Space>
                    <SitePreview inputValue={inputValue} />
                    <span>{inputValue}</span>
                  </Antd.Space>
                </StyledCard>
              )}
              <Antd.Space
                style={{ width: '500px' }}
                size={'large'}
                direction={'vertical'}
              >
                {selectedList.entities.length > 1 && inputValue.length === 0 && (
                  <StyledCard list={true}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                      }}
                    >
                      <Antd.Space>
                        <img
                          style={{ height: '20px' }}
                          src={getAppIcon()[selectedList.application]}
                          alt=""
                        />
                        <span>{selectedList.name}</span>
                        <Antd.Popover
                          content={
                            <div
                              style={{ maxHeight: '200px', overflow: 'auto' }}
                            >
                              {selectedList.entities.map(site => (
                                <li key={site.id}>
                                  <img
                                    style={{
                                      height: '15px',
                                      marginRight: '5px',
                                    }}
                                    src={site.photo_100}
                                    alt=""
                                  />
                                  <span>{site.name}</span>
                                </li>
                              ))}
                              <Antd.Button
                                size={'small'}
                                style={{ marginTop: '10px' }}
                                children="Редактировать"
                                onClick={() =>
                                  navigate(
                                    `${routes.app.groupLists.edit.replace(
                                      ':id',
                                      `${selectedList.id}`,
                                    )}`,
                                  )
                                }
                              />
                            </div>
                          }
                        >
                          <InfoCircleOutlined />
                        </Antd.Popover>
                      </Antd.Space>
                      <Antd.Button
                        onClick={() => handleCollect('list')}
                        disabled={load}
                        type="primary"
                      >
                        Собрать сайты
                      </Antd.Button>
                    </div>
                  </StyledCard>
                )}
                {fail && (
                  <Antd.Alert closable={true} message={fail} type="error" />
                )}
                {load && (
                  <Antd.Steps
                    direction="vertical"
                    size="small"
                    status={steps.status}
                    current={steps.current}
                  >
                    {steps.all.map((step, index) => (
                      <Antd.Steps.Step
                        key={step.title}
                        title={step.title}
                        description={step.description}
                        icon={steps.current === index && <LoadingOutlined />}
                      />
                    ))}
                  </Antd.Steps>
                )}
              </Antd.Space>
            </Antd.Space>
            {graphicsData.length > 0 && (
              <Antd.Space direction={'vertical'}>
                <span>Изменить отчётный период</span>
                <SiteRangePicker
                  graphicsData={graphicsData}
                  calculateDataByRange={calculateDataByRange}
                />
              </Antd.Space>
            )}
          </div>
          <SiteStatisticsGraphics selectedList={selectedList} />
        </StyledContent>
      </ContentContainer>
    </>
  );
};

const StyledContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  grid-gap: 55px;
`;

const StyledCard = styled(Antd.Card)<{ list?: boolean }>`
  .ant-card-body {
    display: flex;
    align-items: center;
    padding: 5px 10px 5px 10px;
  }

  cursor: ${p => (p.list ? '' : 'pointer')};
`;

export default SiteStatistics;
