import * as React from 'react';
import styled from 'styled-components/macro';
import { Outlet } from 'react-router-dom';
import { Spin } from 'antd';

export function LandingContainer({ isAppLoading }: { isAppLoading: boolean }) {
  return (
    <Container>
      <PageContainer isAppLoading={isAppLoading}>
        {isAppLoading ? <Spin /> : <Outlet />}
      </PageContainer>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PageContainer = styled.div<{ isAppLoading: boolean }>`
  background: #ffffff;
  box-shadow: ${props =>
    props.isAppLoading
      ? 'none'
      : '0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14), 0 1px 5px rgba(0, 0, 0, 0.12)'};
  border-radius: 4px;
  padding: 30px;
`;
