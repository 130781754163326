import { ICredentialsCache } from './types';
import { IUser } from '../state/socialAccountsModule/types';
import { App } from '../constants/enums';
import moment from 'moment';
import { getNormalizeAccounts } from '../helpers/getAppIcon.helper';

const credentialsCache: ICredentialsCache = {} as ICredentialsCache;

export const setUserCredentials = (
  application: App,
  userId?: string,
  sessionSecretKey?: string,
  accessToken?: string,
) => {
  // This function in ok case called with accessToken, sessionSecretKey and without userId.
  // When userId not exists we keep it in credentialsCache and then when userId exists we add userId to localStorage.
  if (application === App.vk && App.tg) {
    if (userId) {
      const usersMap = JSON.parse(localStorage.getItem('usersMap') || '{}');
      localStorage.setItem(
        'usersMap',
        JSON.stringify({
          ...usersMap,
          [userId]: { application, lastVisit: Date.now(), userId },
        }),
      );
      if (accessToken != null) {
        localStorage.setItem(`${userId}-accessToken`, accessToken);
      }
      localStorage.setItem('userId', userId);
      localStorage.setItem('lastAddedDate', `${moment().unix()}`);

      return true;
    }
  }

  if (application === App.ok) {
    if (!userId && sessionSecretKey && accessToken) {
      // when userId not exists accessToken, sessionSecretKey must be exists
      credentialsCache[application] = credentialsCache[application] || {};
      credentialsCache[application].sessionSecretKey = sessionSecretKey;
      credentialsCache[application].accessToken = accessToken;
      return false;
    }
    if (userId) {
      const usersMap = JSON.parse(localStorage.getItem('usersMap') || '{}');

      localStorage.setItem(
        'usersMap',
        JSON.stringify({
          ...usersMap,
          [userId]: { application, lastVisit: Date.now(), userId },
        }),
      );
      localStorage.setItem('userId', userId);
      localStorage.setItem(
        `${userId}-sessionSecretKey`,
        sessionSecretKey || credentialsCache[application].sessionSecretKey,
      );
      localStorage.setItem(
        `${userId}-accessToken`,
        accessToken || credentialsCache[application].accessToken,
      );
      localStorage.setItem('lastAddedDate', `${moment().unix()}`);

      if (credentialsCache[application]) {
        delete credentialsCache[application].sessionSecretKey;
        delete credentialsCache[application].accessToken;
      }

      return true;
    }
  }

  return false;
};

export const setUserInfo = (userInfo: IUser) => {
  const { userId } = userInfo;
  const usersMap = JSON.parse(localStorage.getItem('usersMap') || '{}');

  if (userId && usersMap[userId]) {
    usersMap[userId] = {
      ...usersMap[userId],
      userInfo,
    };

    localStorage.setItem('usersMap', JSON.stringify(usersMap));

    return true;
  }
  return false;
};

export const getCredentialFromCache = (
  application: App,
  key: 'accessToken' | 'sessionSecretKey',
  _userId = null,
) => {
  const userId = _userId || localStorage.getItem('userId');
  return (
    (credentialsCache[application] && credentialsCache[application][key]) ||
    localStorage.getItem(`${userId}-${key}`)
  );
};

export const getUserMeta = userId =>
  JSON.parse(localStorage.getItem('usersMap') || '{}')[userId];

export const getSignInStatus = async () => {
  let localStaySignIn = await localStorage.getItem('staySignIn');

  if (localStaySignIn) {
    return localStaySignIn;
  } else {
    localStorage.setItem('staySignIn', 'false');
    return localStaySignIn;
  }
};

export const setSignInStatus = async status => {
  localStorage.setItem('staySignIn', status);
  return localStorage.getItem('staySignIn');
};

export const addUserSocialInfo = firebaseSocialData => {
  const usersMap = {};

  firebaseSocialData.forEach(account => {
    usersMap[account.userId] = {
      application: account.social,
      lastVisit: account.lastVisit,
      userId: account.userId,
      userInfo: {
        accessRestricted: false,
        avatar: account.avatar,
        firstName: account.firstName,
        lastName: account.lastName,
        social: account.social,
        userId: account.userId,
      },
    };
  });

  localStorage.setItem('usersMap', JSON.stringify(usersMap));

  firebaseSocialData.forEach(account => {
    localStorage.setItem(
      `${account.userId}-accessToken`,
      account[`accessToken`],
    );
    localStorage.setItem(
      `${account.userId}-sessionSecretKey`,
      account[`sessionSecretKey`],
    );
  });
};

export const getLinkedCred = () => {
  const normalizeAccounts = getNormalizeAccounts();

  if (normalizeAccounts.length === 0) {
    return [];
  }

  return normalizeAccounts.map(account => {
    return {
      ...account,
      accessToken: localStorage.getItem(`${account.userId}-accessToken`),
      sessionSecretKey: localStorage.getItem(
        `${account.userId}-sessionSecretKey`,
      ),
    };
  });
};
