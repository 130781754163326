import { takeLatest, put, select, all } from 'redux-saga/effects';
import { searchDataModuleActions } from './searchModule';
import { initModuleActions } from './initModule';
import { entityModuleActions } from './entityFetchModule';
import { listsModuleActions } from './listsModule';
import { graphicDataModuleActions } from './graphicDataModule';
import { dateModuleActions } from './dateModule';
import { rateDataModuleActions } from './ratesModule';
import { groupDataModuleActions } from './groupDataModule';
import { loadingStepsModuleActions } from './loadingStepsModule';
import { socialAccountsModuleActions } from './socialAccountsModule';
import { DownloadStatus, SearchStatus } from '../constants/enums';
import { getSearchStatus } from './searchModule/selectors';
import { IList } from './listsModule/types';

export const RESET_ALL = 'RESET_ALL';

export function resetAll(action: any) {
  return {
    type: RESET_ALL,
    payload: { action },
  };
}

function* resetSaga({ payload }) {
  const entity =
    payload &&
    payload.action &&
    payload.action.payload &&
    payload.action.payload.entity;
  const isListExist =
    payload &&
    payload.action &&
    payload.action.payload &&
    payload.action.payload.list &&
    Object.keys(payload.action.payload.list).length > 0;
  const isEntityExist = entity && Object.keys(entity).length > 0;
  let searchState;
  searchState = yield select(getSearchStatus);
  if (!isEntityExist) {
    if (searchState.state !== SearchStatus.BLOCKED || isListExist) {
      searchState = {
        state: SearchStatus.INITIAL,
      };
    }
  }
  const effects: any = [];
  if (payload.action.type === entityModuleActions.setEntityInfo.type) {
    effects.push(
      put(listsModuleActions.setSelectedList({ list: {} as IList })),
    );
  }
  if (payload && payload.action && payload.action.type === 'SIGN_OUT') {
    effects.push(put(initModuleActions.signOut()));
    effects.push(put(socialAccountsModuleActions.signOut()));
    effects.push(put(listsModuleActions.reset({})));
  }
  yield all([
    ...effects,
    put(searchDataModuleActions.setSearchStatus({ state: searchState.state })),
    put(
      entityModuleActions.setDownloadingStatus({
        downloadState: DownloadStatus.NOT_STARTED,
        downloadProgress: 0,
      }),
    ),
    put(graphicDataModuleActions.reset({})),
    put(dateModuleActions.reset({})),
    put(rateDataModuleActions.reset({})),
    put(groupDataModuleActions.reset({})),
    put(loadingStepsModuleActions.reset({})),
  ]);
}

export function* resetAllSaga() {
  // @ts-ignore
  yield takeLatest(RESET_ALL, resetSaga);
}
