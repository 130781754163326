import React, { useEffect } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons/lib';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { getSelectedApplication } from '../../../state/socialAccountsModule/selector';
import { logEvent } from '../../../libs/lib.amplitude';
import { LOG_EVENTS } from '../../../constants/lib.constants';
import { getPathName, getSocialName } from '../../../helpers/auth.helper';
import routes from '../../../../configs/app.routes';
import EntitiesList from '../EntitiesList';
import { getLists } from '../../../state/listsModule/selectors';
import { listsModuleActions } from '../../../state/listsModule';
import styled from 'styled-components';
import { IList } from '../../../state/listsModule/types';
import { names } from '../../../helpers/names';
import PageHeader from '../../common/PageHeader/PageHeader';
import ContentContainer from '../../common/ContentContainer/StyledContent';
import AnonWarningModal from '../../common/Modals/AnonWarningModal';
import { getUser } from '../../../state/userAuthModule/selectors';

export default function Lists() {
  const dispatch = useDispatch();
  const application = useSelector(getSelectedApplication);
  const listsOfEntities: IList[] = useSelector(getLists);
  const user = useSelector(getUser);
  const listsExists = listsOfEntities.length > 0;
  const navigate = useNavigate();
  const location = useLocation();

  const handleListsExport = () => {
    const fileName = `Список Листов - ${new Date().toLocaleDateString(
      'ru-RU',
    )}.json`;
    const data = JSON.stringify(listsOfEntities, null, 2);
    const element = document.createElement('a');
    element.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(data),
    );
    element.setAttribute('download', fileName);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  const handleOnEntityRemove = (ownerId: number, id?: number) => {
    dispatch(listsModuleActions.removeEntityFromUserList({ ownerId, id }));
  };

  const handleOnListRemove = (id: number, title) => {
    dispatch(listsModuleActions.removeUserList({ id, title }));
  };

  const handleOnCreateList = () => {
    if (user.isAnonymous && listsOfEntities.length === 1) {
      AnonWarningModal(navigate);
      return;
    }
    navigate(routes.app.groupLists.create);
  };

  return (
    <>
      <PageHeader title={names[location.pathname]} />
      <ContentContainer>
        <Container>
          <StyledContent>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {!listsExists && <p>У вас еще нет созданных списков</p>}
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  className="create-list-button"
                  onClick={handleOnCreateList}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '160px',
                  }}
                  block
                  type="primary"
                  icon={<PlusOutlined />}
                >
                  Создать список
                </Button>
                <div>
                  {listsExists && (
                    <Button type="primary" onClick={handleListsExport}>
                      Скачать Списки
                      <DownloadOutlined />
                    </Button>
                  )}
                </div>
              </div>
            </div>
            {listsExists && (
              <div style={{ marginTop: '50px' }}>
                <p id="group-list">Списки сообществ</p>
                {listsOfEntities.map(list => {
                  return (
                    <EntitiesList
                      indexes={list.indexes}
                      showActions
                      id={list.id}
                      key={list.id}
                      entities={list.entities}
                      handleOnEntityRemove={handleOnEntityRemove}
                      handleOnListRemove={handleOnListRemove}
                      title={list.name}
                      application={list.application}
                      roles={list.roles}
                      linkedAccount={list.linkedAccount}
                      list={list}
                    />
                  );
                })}
              </div>
            )}
          </StyledContent>
        </Container>
      </ContentContainer>
    </>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
