/**
 *
 * RecoverPassword
 *
 */
import * as React from 'react';
import {
  Alert,
  Button,
  Form,
  Input,
  PageHeader,
  Space,
  Typography,
} from 'antd';
import FirebaseAccountService from '../../firebase/firebase.user.service';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import routes from '../../../configs/app.routes';
import { useSelector } from 'react-redux';
import { getUser } from '../../state/userAuthModule/selectors';

const { Item } = Form;

export function RecoverPassword() {
  const [disabled, setDisabled] = useState<boolean>(false);
  const navigate = useNavigate();
  const [result, setResult] = useState<{ success: boolean; error?: string }>({
    success: false,
    error: '',
  });
  const onFinish = async (values: any) => {
    setDisabled(true);
    const result = await FirebaseAccountService.forgot(values.email);

    if (result && !result.isError) {
      setResult({ success: true });
      setTimeout(() => {
        navigate(routes.login);
      }, 5000);
    } else {
      setResult({ success: false, error: result.error || 'Error' });
    }
    setDisabled(false);
  };

  const { isLoggedIn } = useSelector(getUser);

  useEffect(() => {
    isLoggedIn ? navigate(routes._app) : navigate(routes.recoverPassword);
  }, [isLoggedIn, navigate]);

  return (
    <>
      {result.success ? (
        <Space>
          <span>Письмо успешно отправлено, проверьте почту</span>
          <Alert
            message={`Вы будете автоматически перенаправлены на страницу входа через 5 сек.`}
            type="info"
            showIcon
          />
        </Space>
      ) : (
        <>
          <PageHeader
            className="site-page-header"
            title="Восстановление пароля"
          />
          <Form
            name="recover"
            onFinish={onFinish}
            scrollToFirstError
            layout="vertical"
          >
            <Item
              name="email"
              label="Адрес эл. почты"
              rules={[
                {
                  type: 'email',
                  message: 'Неправильный адрес эл. почты.',
                },
                {
                  required: true,
                  message: 'Введите адрес эл. почты.',
                },
              ]}
            >
              <Input placeholder="Введите адрес эл. почты" />
            </Item>
            {result.error && <span>Something is wrong with email address</span>}
            <Item>
              <Space size={'large'}>
                <Button disabled={disabled} type="primary" htmlType="submit">
                  Отправить письмо
                </Button>
                <Typography.Link onClick={() => navigate(routes.login)}>
                  Войти
                </Typography.Link>
              </Space>
            </Item>
          </Form>
        </>
      )}
    </>
  );
}
