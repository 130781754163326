import moment from 'moment';
import { initModuleActions } from '../../../state/initModule';

async function addTelegramService(app: number, userId: string) {
  const usersMap = JSON.parse(localStorage.getItem('usersMap') || '{}');

  if (!usersMap && usersMap[userId].application === app) {
    return 'Telegram-аналитика уже добавлен.';
  } else {
    localStorage.setItem(
      'usersMap',
      JSON.stringify({
        ...usersMap,
        [userId]: {
          application: app,
          lastVisit: Date.now(),
          userId: userId,
          userInfo: {
            accessRestricted: false,
            avatar: '',
            firstName: 'Telegram',
            lastName: 'аналитика',
            social: app,
            userId: userId,
          },
        },
      }),
    );
    localStorage.setItem('lastAddedDate', `${moment().unix()}`);
    return 'Telegram-аналитика добавлен.';
  }
}

export default addTelegramService;
