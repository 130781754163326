import VkSvg from '../../styles/assets/svg/0.svg';
import OkSvg from '../../styles/assets/svg/1.svg';
import TgSvg from '../../styles/assets/svg/2.svg';
import HitalamaSiteStatistics from '../../styles/assets/svg/3.svg';
import { IUser } from '../state/socialAccountsModule/types';
import { store } from '../../index';
import { socialAccountsModuleActions as socialActions } from '../state/socialAccountsModule';
import routes from '../../configs/app.routes';

export const getAppIcon = () => {
  return {
    0: VkSvg,
    1: OkSvg,
    2: TgSvg,
    3: HitalamaSiteStatistics,
  };
};

export function changeSocialSvg(socialType) {
  switch (socialType) {
    case 0:
      return VkSvg;
    case 1:
      return OkSvg;
    case 2:
      return TgSvg;
    case 3:
      return HitalamaSiteStatistics;
  }
}

export const getNormalizeAccounts = () => {
  const accounts = JSON.parse(localStorage.getItem('usersMap') || '{}');
  if (accounts.length === 0) {
    return [];
  }

  return Object.keys(accounts).map(item => {
    return {
      social: accounts[item].application,
      accessRestricted: accounts[item].userInfo?.accessRestricted || false,
      userId: accounts[item].userId || null,
      avatar: accounts[item].userInfo?.avatar || null,
      firstName: accounts[item].userInfo?.firstName || null,
      lastName: accounts[item].userInfo?.lastName || null,
      allowedRequestsCountInNum: null,
      lastVisit: accounts[item].lastVisit || null,
    };
  });
};

export const selectAccount = (
  appNum: number | null,
  account: {} | null,
): any => {
  if (account !== null) {
    store.dispatch(
      socialActions.changeUser({
        user: account,
      }),
    );
  } else {
    const normalizeAccounts = getNormalizeAccounts();

    const requestAccount = normalizeAccounts.find(
      item => item.social === appNum,
    );

    store.dispatch(
      socialActions.changeUser({
        user: requestAccount,
      }),
    );
  }
};

export const disableAccountByUrl = (url: string) => {
  const accounts = JSON.parse(localStorage.getItem('usersMap') || '{}');
  if (accounts.length === 3) {
    return;
  }

  const normalizeAccounts = getNormalizeAccounts();

  const generateObj = (appNums: number[]) => {
    const disabledObj = {};

    Object.keys(accounts).forEach(item => {
      disabledObj[item] = appNums.includes(accounts[item].application);
    });

    return disabledObj;
  };

  switch (url) {
    case routes.app.collectAndAnalyze:
      store.dispatch(socialActions.setDisabledAccounts(generateObj([])));
      const noSiteSocial = normalizeAccounts.find(acc => acc.social !== 3);
      noSiteSocial && selectAccount(noSiteSocial.social, null);
      break;
    case routes.app.siteStatistics.path:
      store.dispatch(socialActions.setDisabledAccounts(generateObj([])));
      const siteSocial = normalizeAccounts.find(acc => acc.social === 3);
      siteSocial && selectAccount(siteSocial.social, null);
      break;
    default:
      store.dispatch(
        socialActions.setDisabledAccounts(generateObj([0, 1, 2, 3])),
      );
      break;
  }
};

export const disableMenuByAccount = (path: string) => {
  const accounts = JSON.parse(localStorage.getItem('usersMap') || '{}');
  if (accounts == null) {
    return;
  }
};

export const getAccountByListId = (
  id: any,
  entities: any,
  accountsMap,
): any => {
  const accounts: IUser[] = Object.values(accountsMap);

  return accounts.find(item => (item.social === entities[0].social ? item : 0));
};
