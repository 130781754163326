import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './index';
import { RootState } from '../../../types';

const selectSlice = (state: RootState) => state.groupData || initialState;

export const getPosts = createSelector([selectSlice], state => state.posts);

export const getCollectOwnerIdGroup = createSelector([selectSlice], state => {
  return state.collectIdOwnerId;
});
