import React from 'react';

import { UserOutlined, DatabaseOutlined } from '@ant-design/icons';

import ActionBlock from '../ActionBlock';
import { calculateInfoData } from '../../../helpers/auth.helper';
import { IEntityInfo } from '../../../state/entityFetchModule/types';
import { IList } from '../../../state/listsModule/types';
import styled from 'styled-components';

type Props = {
  setSelectedCountExecute?: (countExecute: number) => void;
  handleAddToList: (entityInfo: IEntityInfo) => void;
  downloadInProgress: boolean;
  entityInfo: IEntityInfo;
  variant: string | null;
  selectedList?: IList;
  postsCount?: number;
  isBlockedUser?: boolean;
  entitiesArr?: IEntityInfo[];
  setInputValue: (input: string) => void;
};

export default function InfoBlock({
  setSelectedCountExecute,
  downloadInProgress,
  postsCount,
  handleAddToList,
  selectedList,
  entityInfo,
  variant,
  isBlockedUser,
  entitiesArr,
  setInputValue,
}: Props) {
  const selectedListExists =
    selectedList && Object.keys(selectedList).length > 0;
  const { label, avatar, membersCount } = calculateInfoData(
    selectedList,
    entityInfo,
    variant,
  );
  const showStatisticsBlock = !!(membersCount || postsCount !== null);

  return (
    <EntityInfo>
      {avatar && <img src={avatar} alt="" />}
      <EntityInfoContent>
        {label && <span>{label}</span>}
        {showStatisticsBlock && (
          <EntityInfoStatistic>
            {membersCount && (
              <Statistic>
                <UserOutlined />
                <span
                  style={{
                    marginLeft: '10px',
                  }}
                >
                  {membersCount}
                </span>
              </Statistic>
            )}
            {postsCount !== null && (
              <Statistic
                style={{
                  marginLeft: '15px',
                }}
              >
                <DatabaseOutlined />
                <span
                  style={{
                    marginLeft: '10px',
                  }}
                >
                  {postsCount}
                </span>
              </Statistic>
            )}
          </EntityInfoStatistic>
        )}
      </EntityInfoContent>
      <ActionBlock
        variant={variant}
        setSelectedCountExecute={setSelectedCountExecute}
        downloadInProgress={downloadInProgress}
        handleAddToList={handleAddToList}
        entityInfo={entityInfo}
        selectedListExists={selectedListExists || false}
        isBlockedUser={isBlockedUser}
        entitiesArr={entitiesArr}
        setInputValue={setInputValue}
      />
    </EntityInfo>
  );
}

const EntityInfo = styled.div`
  display: flex;
  width: 100%;
  height: 71px;
  flex-direction: row;
  align-items: flex-start;
  color: #000;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  margin-top: 20px;
  justify-content: space-between;

  & > img {
    width: 70px;
    height: 70px;
  }
`;

const EntityInfoContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  height: 90%;
  margin-left: 10px;
`;

const EntityInfoStatistic = styled.div`
  display: flex;
  flex-direction: row;
`;

const Statistic = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

InfoBlock.defaultProps = {
  postsCount: null,
  handleAddToList: null,
  selectedList: {} as IList,
};
