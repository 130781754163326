import { all, call, fork, put, spawn } from 'redux-saga/effects';
import { userAuthModuleSaga } from './userAuthModule/saga';
import { initModuleSaga } from './initModule/saga';
import { socialAccountsModuleSaga } from './socialAccountsModule/saga';
import { listModuleSaga } from './listsModule/saga';
import { resetAllSaga } from './resetModule';
import { groupDataModuleSaga } from './groupDataModule/groupDataModule.saga';
import { ratesModuleSaga } from './ratesModule/saga';
import { entityModuleSaga } from './entityFetchModule/saga';
import { graphicDataModuleSaga } from './graphicDataModule/saga';
import { dateModuleSaga } from './dateModule/saga';
import { groupCoversSliceSaga } from './groupCoversDataModule/saga';
import { siteStatisticsSaga } from './siteStatisticsModule/saga';
import { listStatisticsSliceSaga } from './listStatistics/saga';

export default function* rootSaga() {
  yield spawn(socialAccountsModuleSaga);
  yield spawn(initModuleSaga);
  yield spawn(userAuthModuleSaga);
  yield spawn(listModuleSaga);
  yield spawn(resetAllSaga);
  yield spawn(entityModuleSaga);
  yield spawn(ratesModuleSaga);
  yield spawn(graphicDataModuleSaga);
  yield spawn(groupDataModuleSaga);
  yield spawn(dateModuleSaga);
  yield spawn(groupCoversSliceSaga);
  yield spawn(siteStatisticsSaga);
  yield spawn(listStatisticsSliceSaga);
}
