import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { initModuleActions, initModuleActions as actions } from './index';
import { authModuleActions } from '../userAuthModule';
import { listsModuleActions } from '../listsModule';
import history from '../../../configs/app.history';
import routes from '../../../configs/app.routes';
import FirebaseAccountService from '../../firebase/firebase.user.service';
import firebase from 'firebase';
import { ISocialAccountStorage } from '../../localStorage/types';
import { logEvent } from '../../libs/lib.amplitude';
import { LOG_EVENTS } from '../../constants/lib.constants';
import { AUTH_TYPE_VK } from '../../constants/auth.constants';
import { store } from '../../../index';
import FirestoreListService from '../../firebase/firestore.list.service';
import { IList } from '../listsModule/types';
import { UserTypeEnum } from '../../constants/enums';
import { IAdditionalUserInfo, IFirebaseUser } from '../userAuthModule/types';
import { addUserSocialInfo, getLinkedCred } from '../../localStorage';
import { socialAccountsModuleActions } from '../socialAccountsModule';
import { customLogger } from '../../helpers/log.helper';
import { AuthLogs } from 'app/services/amplitude/amplitude';

function initSaga(action) {
  firebase.auth().onAuthStateChanged(async (user: any) => {
    const currentUser: IFirebaseUser | null = !user
      ? null
      : {
          email: user.email,
          emailVerified: user.emailVerified,
          displayName: user.displayName,
          lastSignInTime: user.metadata.lastSignInTime,
          userId: user.uid,
          isLoggedIn: true,
          isAnonymous: user.isAnonymous,
          photoURL: user.photoURL,
        };

    const additionalUserInfo = !user
      ? null
      : await FirebaseAccountService.getUserAdditionalInfo();

    if (!currentUser) {
      store.dispatch(actions.fail({}));
    } else {
      store.dispatch(
        actions.initialize({
          user: { ...currentUser, ...additionalUserInfo },
        }),
      );
    }
  });
}

function* initialize({
  payload: { user },
}: {
  payload: { user: IAdditionalUserInfo };
}) {
  try {
    if (user.storeTokens) {
      const localAccounts = yield call(getLinkedCred);
      const accounts = yield call(FirebaseAccountService.getLinkedUserAccount);

      if (localAccounts.length > accounts.length) {
        FirebaseAccountService.unlinkUserSocialAccount().then(async () => {
          await FirebaseAccountService.linkUserSocialAccount(localAccounts);
        });
      }
    }

    const firebaseSocialData = yield call(
      FirebaseAccountService.getLinkedUserAccount,
    );

    if (firebaseSocialData.length > 0) {
      yield call(addUserSocialInfo, firebaseSocialData);
    }

    const socialAccountsMap: ISocialAccountStorage = JSON.parse(
      localStorage.getItem('usersMap') || '{}',
    );

    const socialAccountsExists = Object.keys(socialAccountsMap).length > 0;

    const normalizeAccounts = Object.keys(socialAccountsMap).map(item => {
      return {
        social: socialAccountsMap[item].application,
        accessRestricted: socialAccountsMap[item].userInfo.accessRestricted,
        userId: socialAccountsMap[item].userId,
        avatar: socialAccountsMap[item].avatar,
        firstName: socialAccountsMap[item].userInfo.firstName,
        lastName: socialAccountsMap[item].userInfo.lastName,
        allowedRequestsCountInNum: null,
      };
    });

    yield put(authModuleActions.setUser({ user }));

    if (!socialAccountsExists) {
      yield put(actions.success({}));
      logEvent(LOG_EVENTS.START_APP);
    } else {
      logEvent(LOG_EVENTS.START_APP, {
        socialAccountsMap,
      });
      let applicationToSet = null;
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const user of Object.values(socialAccountsMap)) {
        const { userId, userInfo, application } = user;
        if (userId && userId !== 'null') {
          // if user already have this key in localStorage. Should be skipped!
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          applicationToSet = application;
          // const accessToken = localStorage.getItem(`${userId}-accessToken`);
          if (application === AUTH_TYPE_VK) {
            // TODO set vk token check if need
          }

          yield put(
            authModuleActions.commonAuth({
              _userId: userId,
              _application: application,
              _userInfo: userInfo,
            }),
          );
        }
      }

      yield put(initModuleActions.success({}));
      // выбирается первый аккаунт
      yield all([
        put(
          socialAccountsModuleActions.setApplication({
            application: normalizeAccounts[0].social,
          }),
        ),
        put(
          socialAccountsModuleActions.setSelectedAccount({
            userId: normalizeAccounts[0].userId,
          }),
        ),
      ]);
      localStorage.setItem('userId', `${normalizeAccounts[0].userId}`);
      localStorage.setItem('application', `${normalizeAccounts[0].social}`);

      // тут начинается запрос за листами
      yield put(listsModuleActions.requestUserListsOfEntities(true));
      let arrayOfUserLists = yield call(
        FirestoreListService.getAllUserDocuments,
      );
      arrayOfUserLists = arrayOfUserLists.map(list => {
        if (typeof list.entities === 'string') {
          list.entities = JSON.parse(list.entities);
        }
        return list;
      });
      yield call(FirestoreListService.setAllDocuments, arrayOfUserLists);
      const arrayOfListsState: IList[] = arrayOfUserLists.map(list => {
        const newList = { ...list };
        newList.id = list.id || list.listId;
        newList.name = list.name || list.listName;
        delete newList.listId;
        delete newList.listName;
        return newList;
      });
      yield put(
        listsModuleActions.setUserListsOfEntities({
          userListsOfEntities: arrayOfListsState,
        }),
      );
      yield put(listsModuleActions.requestUserListsOfEntities(false));
    }
  } catch (e) {
    customLogger('Error occurred in initializeApplication: ', e);
  }
}

function* authStateSaga({ payload: { user } }) {
  yield fork(initialize, user);
}

function* loginSaga({ payload: { username, password, loginType, setResult } }) {
  try {
    const result =
      loginType === UserTypeEnum.Login
        ? yield call(FirebaseAccountService.login, username, password)
        : yield call(FirebaseAccountService.anonymousLogin);

    if (UserTypeEnum.Anonymous) {
      // @ts-ignore
      window.dataLayer = window.dataLayer || [];
      // @ts-ignore
      window.dataLayer.push({
        userId: result.uid,
        authorizationType: 'anonim',
        tariff: 'anonim',
      });

      // @ts-ignore
      window.dataLayer.push({
        event: 'autoEvent',
        eventCategory: 'profile',
        eventAction: 'registration',
        eventLabel: 'anonim',
        eventValue: undefined,
      });
    }

    if (result && !result.isError) {
      const user = {
        email: result.email,
        emailVerified: result.emailVerified,
        displayName: result.displayName,
        lastSignInTime: result.metadata.lastSignInTime,
        userId: result.uid,
        isLoggedIn: true,
        isAnonymous: result.isAnonymous,
        creationTime: result.metadata.creationTime,
        tariffPlan: result.tariffPlan,
        photoURL: result.photoURL,
        storeTokens: result.storeTokens,
        role: result.role,
      };
      yield put(authModuleActions.setUser({ user }));
      yield put(actions.initialize({ user }));

      const userLog = {
        id: result.uid,
        tariff: result.tariffPlan,
        storeTokens: result.storeTokens,
        isAnonymous: result.isAnonymous,
      };

      AuthLogs.loginIdentity(userLog);
      AuthLogs.loginEvent('click login', userLog);
    } else {
      if (process.env.REACT_APP_DEV === 'true') {
        console.log('Ошибка', result);
      }
      yield put(actions.fail(result));
    }
  } catch (e: any) {
    customLogger(e, 'e');
  }
}

export function* initModuleSaga() {
  yield takeLatest(actions.login, loginSaga);
  yield takeLatest(actions.authStateChanged, authStateSaga);
  yield takeLatest(actions.init, initSaga);
  yield takeLatest(actions.initialize, initialize);
}
