import React, { useState } from 'react';
import { DatePicker, Space, ConfigProvider, Button, message } from 'antd';
import moment from 'moment';
import 'moment/locale/ru';
import ruRU from 'antd/lib/locale/ru_RU';

const SiteRangePicker = ({ graphicsData, calculateDataByRange }) => {
  const [dateRange, setDateRange] = useState<string[]>([
    graphicsData[0].date,
    graphicsData[graphicsData.length - 1].date,
  ]);

  function calculateData() {
    const calcData = graphicsData.filter(item => {
      const itemDate = moment(item.date).unix();
      return (
        itemDate >= moment(dateRange[0]).unix() &&
        itemDate <= moment(dateRange[1]).unix()
      );
    });
    if (calcData.length === 0) {
      message.warn('Нет данных по выбранному периоду').then(() => {
        return;
      });
    }
    calculateDataByRange(calcData);
  }

  function resetFilter() {
    calculateDataByRange([]);
    setDateRange([
      graphicsData[0].date,
      graphicsData[graphicsData.length - 1].date,
    ]);
  }

  function getQuarterRange(quarter) {
    const start = moment().quarter(quarter).startOf('quarter');
    const end = moment().quarter(quarter).endOf('quarter');
    return { start, end };
  }

  return (
    <Space direction="vertical">
      <ConfigProvider locale={ruRU}>
        <Space>
          <DatePicker.RangePicker
            onChange={(date, dateString) => setDateRange(dateString)}
            value={[moment(dateRange[0]), moment(dateRange[1])]}
            ranges={{
              Сегодня: [moment(), moment()],
              Квартал: [
                getQuarterRange(moment().quarter()).start,
                getQuarterRange(moment().quarter()).end,
              ],
              'Предыдуший месяц': [
                moment().startOf('month').subtract(1, 'months'),
                moment().endOf('month').subtract(1, 'months'),
              ],
            }}
          />
          <Button
            disabled={dateRange.length === 0}
            type={'primary'}
            children="Обновить"
            onClick={calculateData}
          />
          <Button
            disabled={dateRange.length === 0}
            children="Сбросить"
            onClick={resetFilter}
          />
        </Space>
      </ConfigProvider>
    </Space>
  );
};

export default SiteRangePicker;
