import React from 'react';
import { Button, notification, Space } from 'antd';
import routes from '../../../../configs/app.routes';

const linkNotification = (list, navigate) => {
  const btn = (
    <Space>
      <Button
        type="primary"
        size="small"
        onClick={() => {
          navigate(routes.app.auth);
          notification.close('key');
        }}
      >
        Добавить аккаунт
      </Button>
      {list.hasOwnProperty('linkedAccount') && (
        <Button
          type="primary"
          size="small"
          onClick={() => {
            navigate(routes.app.groupLists.edit.replace(':id', list?.id));
            notification.close('key');
          }}
        >
          Сменить привязку
        </Button>
      )}
    </Space>
  );

  notification.open({
    message: `Ошибка привязки`,
    description: list.hasOwnProperty('linkedAccount') ? (
      <p>
        Список <b>{list?.name}</b> привязан к аккаунту{' '}
        <b>
          {list?.linkedAccount.firstName} {list?.linkedAccount.lastName}.
        </b>{' '}
        Добавьте этот аккаунт или смените привязку на нужный аккаунт.
      </p>
    ) : (
      <p>
        К списку <b>{list?.name}</b> не привязан аккаунт. Добавьте аккаунт,
        потом привяжите его к списку.
      </p>
    ),
    type: 'error',
    btn,
    duration: 15,
    key: 'key',
  });
};

export default linkNotification;
