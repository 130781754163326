import { call, put, select, takeLatest } from 'redux-saga/effects';
import { listsModuleActions } from './index';
import {
  getSelectedAccount,
  getSelectedApplication,
} from '../socialAccountsModule/selector';
import { App, AppMaps } from '../../constants/enums';
import FirestoreListService from '../../firebase/firestore.list.service';
import routes from '../../../configs/app.routes';
import { getSelectedList } from './selectors';
import { IList } from './types';
import { message } from 'antd';
import { getOwnerIdFromEntity } from '../../helpers/auth.helper';
import { resetAll } from '../resetModule';
import { getGroups } from '../../services/client/vk/handlers';
import {
  getEntityInfoOk,
  getUserGroupsByIdsOk,
} from '../../helpers/ok.requests';
import { buildEntityObject } from '../../helpers/data.helpers';
import { IOK_ENTITY } from '../../../types/type.ok';
import TgApiServices from '../../services/client/tg/api';
import { customLogger } from '../../helpers/log.helper';
import { IEntityInfo } from '../entityFetchModule/types';
import { ListsLogs } from '../../services/amplitude/amplitude';

export function* requestActualEntities(entities, application, key) {
  const entitiesIds = entities.map(entity => entity.id);
  const entitiesChannelIDs = entities.map(entity => entity.channelId);

  if (application === App.vk) {
    return yield call(getGroups, entitiesIds.join(), key);
  }

  if (application === App.tg) {
    return yield call(TgApiServices.getChannelsInfo, entitiesChannelIDs, key);
  }

  const result: IOK_ENTITY[] = [];
  const selectedAccount = yield select(getSelectedAccount);
  const userId = selectedAccount.userId;
  const entityIds: string[] = [];
  const groupedUserGroupResponse: any[] = [];

  for (let i = 0; i < entities.length; i++) {
    const entity = entities[i];
    entityIds.push(entity.id);
    let userGroupsResponse = yield call(
      getUserGroupsByIdsOk,
      entity.id,
      [userId],
      key,
    );
    groupedUserGroupResponse.push({
      index: i,
      response: userGroupsResponse[0] ? { ...userGroupsResponse[0] } : null,
    });
  }

  const entityInfoOk = yield call(getEntityInfoOk, entityIds.join(','));
  for (let index = 0; index <= entities.length - 1; index += 1) {
    const entity = entities[index];
    const combinedEntity = {
      ...entityInfoOk[index],
      type: entity.type,
      status:
        groupedUserGroupResponse[index] &&
        groupedUserGroupResponse[index].response &&
        groupedUserGroupResponse[index].response.status,
    };
    const combinedResponse = buildEntityObject(combinedEntity);
    result.push({ ...combinedResponse, app: application });
  }

  return {
    response: result,
  };
}

function* setSelectedListWithCheck({ payload: { list } }: any) {
  const key = 'entities-request';
  const application = yield select(getSelectedApplication);
  if (application !== list.application) {
    message.warn(`Выберите соответствующий социальный аккаунт`);
    return null;
  }

  if (application === 3) {
    yield put(listsModuleActions.setSelectedList({ list: list }));
    return null;
  }

  message.loading({
    content: `Запрашиваю актуальные данные для списка ${list.name}`,
    key,
    duration: 2,
  });

  let listToSet = { ...list };
  const result = yield call(
    requestActualEntities,
    list.entities,
    application,
    key,
  );

  const arrayOfUserLists = yield call(FirestoreListService.getAllUserDocuments);

  const listOfEntities: IEntityInfo[] = arrayOfUserLists.find(
    userList => `${userList.id}` === `${list.id}`,
  );

  if (listOfEntities) {
    listToSet = { ...listOfEntities };
    listToSet.entities =
      typeof listToSet.entities === 'string'
        ? JSON.parse(listToSet.entities)
        : listToSet.entities;

    listToSet.entities = result.response.map(entity => ({
      ...entity,
      tags: [...list.entities.find(e => e.id === entity.id).tags],
    }));
    listToSet.lastUpdate = new Date().getTime();
    yield put(
      listsModuleActions.updateList({
        id: listToSet.id,
        updatedList: listToSet,
      }),
    );
  } else {
    listToSet.entities = result.response;
  }

  yield put(listsModuleActions.setSelectedList({ list: listToSet }));
}

function* createListOfEntities({
  payload: { name, entities, linkedAccount, navigate },
}: any) {
  const key = 'updatable';
  message.loading({ content: `Создаю лист ${name}...`, key });
  try {
    const { id, lastUpdate } = yield call(FirestoreListService.addDocument, {
      name,
      entities: JSON.stringify(entities),
      application: linkedAccount.social,
      linkedAccount,
    });

    yield put(
      listsModuleActions.addListOfEntities({
        id,
        name,
        entities,
        lastUpdate,
        application: linkedAccount.social,
        linkedAccount,
      }),
    );

    navigate(routes.app._groupLists);

    message.success({
      content: `Лист "${name}" успешно создан`,
      key,
      duration: 2,
    });

    const listData = {
      social: AppMaps[linkedAccount.social],
      numberOfObjects: entities.length,
      listId: id,
    };

    ListsLogs.createList('click create list', listData);
  } catch (e) {
    customLogger('Error Occurred in createListOfEntities: ', e);
    message.error({ content: `Не удалось создать лист "${name}"`, key });
  }
}

function* removeEntityFromUserList({ payload: { ownerId, id } }: any) {
  try {
    const selectedList = yield select(getSelectedList);

    const arrayOfUserLists = yield call(
      FirestoreListService.getAllUserDocuments,
    );

    const listOfEntities = arrayOfUserLists.find(list => list.id === id);
    listOfEntities.entities =
      typeof listOfEntities.entities === 'string'
        ? JSON.parse(listOfEntities.entities)
        : listOfEntities.entities;
    const index = listOfEntities.entities.findIndex(
      entity => getOwnerIdFromEntity(entity, entity.app) === ownerId,
    );
    listOfEntities.entities.splice(index, 1);
    yield call(FirestoreListService.removeDocument, `${id}`);
    if (selectedList.id === listOfEntities.id) {
      yield put(listsModuleActions.setSelectedList({ list: listOfEntities }));
    }

    yield put(listsModuleActions.removeEntityFromList({ ownerId, id }));
  } catch (e) {
    customLogger('Error Occurred in removeEntityFromUserList: ', e);
  }
}

function* removeUserList({ payload: { id, title } }: any) {
  const key = 'updatable';
  message.loading({ content: `Удаляю список ${title}...`, key });

  const selectedList = yield select(getSelectedList);

  const arrayOfUserLists = yield call(FirestoreListService.getAllUserDocuments);

  const index =
    arrayOfUserLists &&
    arrayOfUserLists.findIndex(list => `${list.id}` === `${id}`);
  if (index) {
    arrayOfUserLists.splice(index, 1);
  }

  try {
    yield call(FirestoreListService.removeDocument, `${id}`);

    if (`${selectedList.id}` === `${id}`) {
      yield put(listsModuleActions.setSelectedList({ list: {} as IList }));
    }

    yield put(listsModuleActions.removeList({ id }));

    message.success({ content: `Cписок ${title}, удалён!`, key, duration: 2 });

    ListsLogs.deleteList('click delete list', { listId: id });
  } catch (e: any) {
    customLogger('Error: ', e.code);
    message.error(e.message);
  }
}

function* updateUserList(action) {
  const { id, name, entities, linkedAccount, indexes }: IList = action.payload;

  try {
    const key = 'updatable';
    message.loading({ content: `Обновляю список ${name}...`, key });

    const selectedList = yield select(getSelectedList);

    const arrayOfUserLists = yield call(
      FirestoreListService.getAllUserDocuments,
    );

    const listOfEntities = arrayOfUserLists.find(
      list => `${list.id}` === `${id}`,
    );
    listOfEntities.name = name;
    listOfEntities.entities = JSON.stringify(entities);
    listOfEntities.lastUpdate = new Date().getTime();
    listOfEntities.linkedAccount = linkedAccount;
    listOfEntities.indexes = indexes;

    yield call(FirestoreListService.updateDocument, listOfEntities);
    listOfEntities.entities = JSON.parse(listOfEntities.entities);
    if (selectedList.id === listOfEntities.id) {
      yield put(listsModuleActions.setSelectedList({ list: listOfEntities }));
    }

    yield put(
      listsModuleActions.updateList({ id, updatedList: listOfEntities }),
    );
    message.success({
      content: `Cписок ${name} успешно обновлён`,
      key,
      duration: 2,
    });

    ListsLogs.editList('click edit list', { listId: id });
  } catch (e: any) {
    message.error(`${e.message}`);
  }
}

export function* setSelectedList(action) {
  yield put(resetAll(action));
}

export function* listModuleSaga() {
  yield takeLatest(
    listsModuleActions.setSelectedListWithCheck,
    setSelectedListWithCheck,
  );
  yield takeLatest(
    listsModuleActions.createListOfEntities,
    createListOfEntities,
  );
  yield takeLatest(
    listsModuleActions.removeEntityFromUserList,
    removeEntityFromUserList,
  );
  yield takeLatest(listsModuleActions.removeUserList, removeUserList);
  yield takeLatest(listsModuleActions.updateUserList, updateUserList);
  yield takeLatest(listsModuleActions.setSelectedList, setSelectedList);
}
