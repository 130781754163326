import { calculateTotalRatesWorker } from '../ratesModule/saga';
import { fetchGraphicData } from '../graphicDataModule/saga';
import { put, select } from 'redux-saga/effects';
import { getEntityInfo } from '../entityFetchModule/selector';
import { groupDataModuleActions } from '../groupDataModule';
import { getSelectedList } from '../listsModule/selectors';
import { IList } from '../listsModule/types';

export function* collectFlowSaga() {
  const entityInfo = yield select(getEntityInfo);

  const selectedList: IList = yield select(getSelectedList);
  const selectedListExists = Object.keys(selectedList).length > 0;

  yield calculateTotalRatesWorker();

  if (selectedListExists) {
    if (selectedList.entities.length <= 10) {
      yield fetchGraphicData();
    }
  } else {
    yield fetchGraphicData();
  }

  if (entityInfo && Object.keys(entityInfo).length > 0) {
    yield put(groupDataModuleActions.fetchPosts({}));
  }
}
