import amplitude from 'amplitude-js';
import {
  AUTH_EVENTS,
  ICreateListData,
  IPostsAnalyzeData,
  IPostsCollectData,
  ISourceData,
  IUserData,
  LISTS_EVENTS,
  POSTS_ANALYZE_EVENTS,
  POSTS_COLLECT_EVENTS,
  SOURCE_EVENTS,
  TOTAL_RATES_EVENTS,
} from './types';
import { GroupBy, pjVersion } from '../../constants/app.constants';

amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_KEY || '');

export const AuthLogs = {
  registerEvent(
    event: AUTH_EVENTS,
    data: Pick<IUserData, 'id' | 'isAnonymous'>,
  ) {
    amplitude.getInstance().logEvent(event, data);
  },
  loginEvent(event: AUTH_EVENTS, data: IUserData) {
    amplitude.getInstance().logEvent(event, data);
    amplitude.getInstance().setVersionName(pjVersion);
  },
  loginIdentity(user: IUserData) {
    const identify = new amplitude.Identify()
      .setOnce('id', user.id)
      .setOnce('tariff', user.tariff)
      .setOnce('storeTokens', user.storeTokens)
      .setOnce('isAnonymous', user.isAnonymous);

    amplitude.getInstance().setUserId(user.id);
    amplitude.getInstance().identify(identify);
  },
};

export const SourceLogs = {
  sourceEvent(event: SOURCE_EVENTS, data: ISourceData) {
    amplitude.getInstance().logEvent(event, data);
  },
};

export const PostsAnalyzeLogs = {
  searchGroup(event: POSTS_ANALYZE_EVENTS, data: IPostsAnalyzeData) {
    amplitude.getInstance().logEvent(event, data);
  },
  postsCollect(event: POSTS_COLLECT_EVENTS, data: IPostsCollectData) {
    amplitude.getInstance().logEvent(event, data);
  },
  downloadExcel(event: POSTS_COLLECT_EVENTS) {
    amplitude.getInstance().logEvent(event);
  },
  groupData(event: POSTS_COLLECT_EVENTS, data: { groupBy: GroupBy }) {
    amplitude.getInstance().logEvent(event, data);
  },
  dataFiltering(
    event: POSTS_COLLECT_EVENTS,
    data: Pick<IPostsCollectData, 'period'>,
  ) {
    amplitude.getInstance().logEvent(event, data);
  },
};

export const TotalRatesLogs = {
  downloadExcel(event: TOTAL_RATES_EVENTS) {
    amplitude.getInstance().logEvent(event);
  },
  changeView(event: TOTAL_RATES_EVENTS, data: { type: 'grid' | 'table' }) {
    amplitude.getInstance().logEvent(event, data);
  },
};

export const ListsLogs = {
  createList(event: LISTS_EVENTS, data: ICreateListData) {
    amplitude.getInstance().logEvent(event, data);
  },
  editList(event: LISTS_EVENTS, data: Pick<ICreateListData, 'listId'>) {
    amplitude.getInstance().logEvent(event, data);
  },
  deleteList(event: LISTS_EVENTS, data: Pick<ICreateListData, 'listId'>) {
    amplitude.getInstance().logEvent(event, data);
  },
  searchGroup(event: LISTS_EVENTS) {
    amplitude.getInstance().logEvent(event);
  },
  searchGroupBySearch(event: LISTS_EVENTS, data: { query: string }) {
    amplitude.getInstance().logEvent(event, data);
  },
  addGroup(event: LISTS_EVENTS, data: { type: 'form' | 'search' }) {
    amplitude.getInstance().logEvent(event, data);
  },
  showCover(event: LISTS_EVENTS) {
    amplitude.getInstance().logEvent(event);
  },
};
