import React from 'react';

import { Steps } from 'antd';

import {
  getNameFromEntity,
  getOwnerIdFromEntity,
} from '../../../helpers/auth.helper';
import { useSelector } from 'react-redux';
import { getLoadingSteps } from '../../../state/loadingStepsModule/selectors';

type Props = {
  selectedList;
  entityInfo;
  selectedCountExecute;
};
const { Step } = Steps;
export default function LoadingSteps({
  selectedList,
  entityInfo,
  selectedCountExecute,
}: Props) {
  const loadingSteps = useSelector(getLoadingSteps);
  const selectedListExists =
    selectedList && Object.keys(selectedList).length > 0;
  const entitiesArray = selectedListExists
    ? selectedList.entities
    : [entityInfo];

  // TODO current and description as total time for request
  const { current: currentLoadingStep, spentTime } = loadingSteps;
  return (
    <Steps direction="vertical" size="small" current={currentLoadingStep}>
      {entitiesArray.map((entity, index) => (
        <Step
          key={getOwnerIdFromEntity(entity, entity.app)}
          title={`Идет сбор постов из ${getNameFromEntity(entity)}`}
          description={
            spentTime[index]
              ? spentTime[index] === '-1' // changed to string TODO check if this even need ?
                ? 'Нету постов'
                : `Данные собрали за ${spentTime[index]} сек, количество постов: ${loadingSteps.totalPosts[index]} постов.`
              : null
          }
        />
      ))}
      <Step key="graphics" title="Отрисовка графиков и сводных показателей" />
    </Steps>
  );
}
