import { ConfigProvider, Table, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { FC } from 'react';

import ruRU from 'antd/lib/locale/ru_RU';
import { generateSocialLink } from '../../../helpers/link.helpers';
import { COLUMN_NAMES, IRate, RATE_TOTAL_KEYS, TableRatesProps } from './types';

const TableRates: FC<TableRatesProps> = ({ totalRates }) => {
  const tableData: IRate[] = Object.keys(totalRates).map((key, index) => {
    const formatCount = (count: number | string): number => {
      return `${count}` === '------'
        ? 0
        : parseInt(`${count}`.replaceAll(' ', ''));
    };

    const formatErCount = (count: string): number => {
      return `${count}` === '------'
        ? 0
        : parseFloat(`${count}`.replaceAll(' ', ''));
    };

    return {
      id: totalRates[key].id,
      name: totalRates[key].name,
      membersCount: totalRates[key].membersCount,
      postsCount: formatCount(totalRates[key].postsCount),
      reachTotal: formatCount(totalRates[key].reachTotal),
      viewsCount: formatCount(totalRates[key].viewsCount),
      likesCount: formatCount(totalRates[key].likesCount),
      repostsCount: formatCount(totalRates[key].repostsCount),
      commentsCount: formatCount(totalRates[key].commentsCount),
      erv: formatErCount(totalRates[key].erv),
      erp: formatErCount(totalRates[key].erp),
      err: formatErCount(totalRates[key].err),
      joinGroup: formatCount(totalRates[key].joinGroup) || 0,
      unsubscribe: totalRates[key].unsubscribe || 0,
      reachSubscribers: totalRates[key].reachSubscribers || 0,
      reachAds: totalRates[key].reachAds || 0,
      reachViral: totalRates[key].reachViral || 0,
      toGroup: totalRates[key].toGroup || 0,
      links: totalRates[key].links || 0,
      report: totalRates[key].report || 0,
      hide: totalRates[key].hide || 0,
      app: totalRates[key].app || 0,
    };
  });

  const replaceDot = (value: number) => value.toString().replace('.', ',');

  const newColumns: ColumnsType<IRate> = COLUMN_NAMES.map(name => {
    const defaultColumn = {
      title: name.render,
      dataIndex: name.key,
      width: 150,
    };

    switch (name.key) {
      case 'name':
        return {
          ...defaultColumn,
          filters: tableData.map(rate => ({
            text: rate.name,
            value: rate.name,
          })),
          onFilter: (value, record) =>
            record.name.indexOf(value as string) === 0,
          render: (text: string, rate) => {
            return (
              <Tooltip placement="topLeft" title={text}>
                <a
                  href={generateSocialLink(rate)[rate.app]}
                  target="_blank"
                  rel="noreferrer"
                >
                  {text}
                </a>
              </Tooltip>
            );
          },
          ellipsis: {
            showTitle: false,
          },
          fixed: 'left',
          width: 250,
        };
      case 'erv':
      case 'erp':
      case 'err':
        return {
          ...defaultColumn,
          render: text => replaceDot(text),
          align: 'right',
          sorter: (a, b) => b[name.key] - a[name.key],
        };
      default:
        return {
          ...defaultColumn,
          align: 'right',
          sorter: (a, b) => b[name.key] - a[name.key],
        };
    }
  });

  return (
    <ConfigProvider locale={ruRU}>
      <Table<IRate>
        scroll={{ x: 1500, y: 500 }}
        pagination={false}
        style={{ marginTop: '30px' }}
        columns={newColumns}
        dataSource={tableData}
        summary={pageData => {
          const arrayOfTotalSum = RATE_TOTAL_KEYS.map(key => ({
            total: tableData
              .map(rate => rate[key])
              .reduce((p, c) => (p as number) + (c as number)) as number,
            key: key,
          }));

          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>Итого</Table.Summary.Cell>
                {arrayOfTotalSum.map((totalSum, index) => (
                  <Table.Summary.Cell align={'right'} index={index + 1}>
                    <Typography.Text strong>
                      {['erv', 'erp', 'err'].includes(totalSum.key)
                        ? (totalSum.total / pageData.length).toFixed(2)
                        : totalSum.total}
                    </Typography.Text>
                  </Table.Summary.Cell>
                ))}
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </ConfigProvider>
  );
};

export default TableRates;
