import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { Button } from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getSelectedList } from '../../../state/listsModule/selectors';
import { getTotalRates } from '../../../state/ratesModule/selectors';
import { getEntityInfo } from '../../../state/entityFetchModule/selector';
import { changeColumnTypeToPercent } from '../../../helpers/xlsx.helper';
import {
  getDateRange,
  getDefaultDateRange,
} from '../../../state/dateModule/selectors';
import { generateSocialLink } from '../../../helpers/link.helpers';
import { TotalRatesLogs } from '../../../services/amplitude/amplitude';
import { getSelectedApplication } from '../../../state/socialAccountsModule/selector';
import { nanoid } from 'nanoid';
import { generateUID } from '../../../helpers/auth.helper';

const ExportTotalRatesExcel = () => {
  const fileExtension = '.xlsx';
  const [load, setLoad] = useState(false);
  const dateToExport: any = [];
  const entityInfo = useSelector(getEntityInfo);
  const selectedList = useSelector(getSelectedList);
  const totalRates = useSelector(getTotalRates);
  const defaultDateRange = useSelector(getDefaultDateRange);
  const dateRange = useSelector(getDateRange);
  const application = useSelector(getSelectedApplication);

  const checkAndTransformEr = er => {
    return `${er}` === '------' ? '' : parseFloat(er.replace('%', ''));
  };

  const checkAndTransformRate = rate => {
    return rate === '------'
      ? ''
      : parseInt(
          rate
            ? typeof rate !== 'string'
              ? rate
              : rate.replaceAll(' ', '')
            : '',
        );
  };

  const generateLink = index => {
    return Object.keys(selectedList).length > 0
      ? generateSocialLink(selectedList.entities[index])[
          `${selectedList.application}`
        ]
      : generateSocialLink(entityInfo)[`${entityInfo.app}`];
  };

  const unloadingPeriod = `${moment(
    dateRange.from || defaultDateRange.from || 0,
  ).format('DD-MM-YY')}_${moment(
    dateRange.to || defaultDateRange.to || 0,
  ).format('DD-MM-YY')}`;

  const exportToCSV = async (dateToExport, fileName) => {
    Object.keys(totalRates).forEach((rateKey, index) => {
      const tags =
        Object.keys(selectedList).length > 0
          ? selectedList.entities
              .find(entity => entity.id === totalRates[rateKey].id)
              ?.tags.map(tag => tag.text)
              .join('|')
          : '';

      dateToExport.push({
        [`Название`]: totalRates[rateKey].name,
        [`Ссылка`]: generateLink(index),
        [`Период выгрузки`]: unloadingPeriod,
        [`Подписчики`]: totalRates[rateKey].membersCount || 0,
        [`Посты`]: checkAndTransformRate(totalRates[rateKey].postsCount) || 0,
        [`Охват`]: checkAndTransformRate(totalRates[rateKey].reachTotal) || 0,
        [`Просмотры`]:
          checkAndTransformRate(totalRates[rateKey].viewsCount) || 0,
        [`Лайки`]: checkAndTransformRate(totalRates[rateKey].likesCount) || 0,
        [`Репосты`]:
          checkAndTransformRate(totalRates[rateKey].repostsCount) || 0,
        [`Комментарии`]:
          checkAndTransformRate(totalRates[rateKey].commentsCount) || 0,
        'ER.v': checkAndTransformEr(totalRates[rateKey].erv) || 0,
        'ER.p': checkAndTransformEr(totalRates[rateKey].erp) || 0,
        'ER.r': checkAndTransformEr(totalRates[rateKey].err) || 0,
        [`Вступления в сообщество`]:
          checkAndTransformRate(totalRates[rateKey].joinGroup) || 0,
        [`Отписавшихся участников`]: totalRates[rateKey].unsubscribe || 0,
        [`Охват подписчиков`]: totalRates[rateKey].reachSubscribers || 0,
        [`Рекламный охват`]: totalRates[rateKey].reachAds || 0,
        [`Виральный охват`]: totalRates[rateKey].reachViral || 0,
        [`Переходы в сообщество`]: totalRates[rateKey].toGroup || 0,
        [`Переходы по ссылке`]: totalRates[rateKey].links || 0,
        [`Жалоб на запись`]: totalRates[rateKey].report || 0,
        [`Скрывших запись`]: totalRates[rateKey].hide || 0,
        [`Теги`]: tags,
      });
    });

    const postsWS = XLSX.utils.json_to_sheet(dateToExport);
    const wb = XLSX.utils.book_new();

    changeColumnTypeToPercent(postsWS, 'K');
    changeColumnTypeToPercent(postsWS, 'L');
    changeColumnTypeToPercent(postsWS, 'M');

    XLSX.utils.book_append_sheet(wb, postsWS, 'Сводные показатели');
    XLSX.writeFile(wb, fileName + fileExtension);
    setLoad(false);

    // Лог клика
    TotalRatesLogs.downloadExcel('click download total rates excel');
  };

  const appMap = {
    0: 'vk',
    1: 'ok',
    2: 'tg',
  };

  const appName = appMap[application || 0];
  const hash = generateUID();

  const handleExportToCSV = () => {
    const fileName = `${appName}_Сводные_показатели_${
      selectedList.name || entityInfo.name
    }_${unloadingPeriod}_${hash}`;
    return exportToCSV(dateToExport, fileName);
  };

  return (
    <Button loading={load} type="link" onClick={handleExportToCSV}>
      Скачать
    </Button>
  );
};

export default ExportTotalRatesExcel;
