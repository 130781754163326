import React, { FC, useState } from 'react';
import { Tag, Input } from 'antd';
import { CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { IEntityInfo, ITag } from '../../../state/entityFetchModule/types';
import { nanoid } from 'nanoid';

interface TagsProps {
  entity: IEntityInfo;
  setEntitiesArr: (entitiesArr: IEntityInfo[]) => void;
  entities: IEntityInfo[];
}

const Tags: FC<TagsProps> = ({ entity, entities, setEntitiesArr }) => {
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleUpdate = (tag: ITag, actionName: string) => {
    return () => {
      const newEntities = entities.map(ent =>
        ent.id === entity.id
          ? {
              ...ent,
              tags:
                actionName === 'remove'
                  ? ent.tags.filter(e => e.id !== tag.id)
                  : [...ent.tags, tag],
            }
          : ent,
      );
      setEntitiesArr(newEntities);
      setInputValue('');
    };
  };

  return (
    <StyledTagsContainer>
      {entity.tags &&
        entity.tags.map(tag => (
          <Tag
            key={tag.id}
            closable={true}
            onClose={handleUpdate(tag, 'remove')}
          >
            {tag.text}
          </Tag>
        ))}
      {inputVisible && (
        <Input.Search
          type="text"
          size="small"
          placeholder={'Введите название тега'}
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
          enterButton={
            inputValue.length > 0 ? <CheckOutlined /> : <CloseOutlined />
          }
          onSearch={
            inputValue.length > 0
              ? handleUpdate({ id: nanoid(), text: inputValue }, 'add')
              : () => setInputVisible(false)
          }
          autoFocus={inputVisible}
          style={{ height: '22px' }}
        />
      )}
      {!inputVisible && (
        <StyledAddTag onClick={() => setInputVisible(true)}>
          <PlusOutlined /> Новый тег
        </StyledAddTag>
      )}
    </StyledTagsContainer>
  );
};

const StyledTagsContainer = styled.div`
  display: flex;
  align-items: center;

  span {
    display: flex;
    align-items: center;
  }
`;

const StyledAddTag = styled(Tag)`
  .anticon-plus {
    margin-right: 5px;
  }
`;

export default Tags;
