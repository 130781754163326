import { takeLatest, call, put, all } from 'redux-saga/effects';
import { socialAccountsModuleActions as actions } from './index';
import { authModuleActions } from '../userAuthModule';
import { setUserCredentials } from '../../localStorage';
import { logEvent } from '../../libs/lib.amplitude';
import { LOG_EVENTS } from '../../constants/lib.constants';
import moment from 'moment';

function setUserApplicationSaga({ payload: { application } }) {
  localStorage.setItem('application', application);
}

function* addUserAccountSaga(action) {
  const {
    payload: { accessToken, userId, isPermissionRequest, sessionSecretKey },
  } = action;
  localStorage.setItem('lastAddedDate', `${moment().unix()}`);
  const app = localStorage.getItem('application');

  const appInNum = app ? +app : null;
  if (appInNum || appInNum === 0) {
    yield call(
      setUserCredentials,
      appInNum,
      userId,
      sessionSecretKey,
      accessToken,
    );
    yield put(authModuleActions.commonAuth({ userId, app }));
    if (isPermissionRequest) {
      logEvent(LOG_EVENTS.SUCCESS_REQUEST_PERMISSIONS_VK, {
        userId,
      });
    } else {
      // history.replace(routes.app.collectAndAnalyze);
      logEvent(
        sessionSecretKey
          ? LOG_EVENTS.SUCCESS_AUTH_OK
          : LOG_EVENTS.SUCCESS_AUTH_VK,
        {
          userId,
        },
      );
    }
  }
}

function* changeUserSaga({ payload: { user } }) {
  const usersMap = JSON.parse(localStorage.getItem('usersMap') || '{}');
  const { userId } = user;

  const { application } = usersMap[userId];

  usersMap[userId].lastVisit = Date.now();

  localStorage.setItem('usersMap', JSON.stringify(usersMap));
  localStorage.setItem('userId', userId.toString());
  localStorage.setItem('application', application.toString());

  yield all([
    put(actions.setApplication({ application })),
    put(actions.setSelectedAccount({ userId: userId })),
  ]);
}

export function* socialAccountsModuleSaga() {
  yield takeLatest(actions.addAccount, addUserAccountSaga);
  yield takeLatest(actions.setApplication, setUserApplicationSaga);
  yield takeLatest(actions.changeUser, changeUserSaga);
}
