import React, { useState } from 'react';
import styled from 'styled-components';

import { DownloadOutlined, DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Modal, Space } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import { entityModuleActions } from '../../../state/entityFetchModule';
import { App, DownloadStatus } from '../../../constants/enums';
import { getSelectedApplication } from '../../../state/socialAccountsModule/selector';
import DateRange from '../DateRange';
import GetPostsReach from '../GetPostsReach';
import GroupBy from '../GroupBy';
import { getDefaultDateRange } from '../../../state/dateModule/selectors';
import {
  getDownloadingStatus,
  getExcelGenerateStatus,
  getExportPercent,
} from '../../../state/entityFetchModule/selector';
import { getUser } from '../../../state/userAuthModule/selectors';
import { useNavigate } from 'react-router-dom';
import AnonWarningModal from '../../common/Modals/AnonWarningModal';
import Post from '../../../services/client/database/classes/post';
import { nanoid } from 'nanoid';
import { PostsAnalyzeLogs } from '../../../services/amplitude/amplitude';

moment.locale('en', {
  week: {
    dow: 1,
  },
});

type Props = {
  selectedCountExecute: number;
};

function PostsActions({ selectedCountExecute }: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const defaultDateRange = useSelector(getDefaultDateRange);
  const downloadingStatus = useSelector(getDownloadingStatus);
  const application = useSelector(getSelectedApplication);
  const excelGenerateStatus = useSelector(getExcelGenerateStatus);

  const postsExists = defaultDateRange.from && defaultDateRange.to;
  const actionDisabled = downloadingStatus.state === DownloadStatus.PROGRESS;
  const triggerWarning = title => {
    return Modal.warning({
      title,
    });
  };

  const user = useSelector(getUser);

  const handleExportEntityPosts = () => {
    if (user.isAnonymous) {
      AnonWarningModal(navigate);
    } else {
      !postsExists
        ? triggerWarning('Нету постов')
        : dispatch(entityModuleActions.exportEntityPosts({}));

      PostsAnalyzeLogs.downloadExcel('click download posts collect excel');
    }
  };

  const exportPercent = useSelector(getExportPercent);

  const [jsonLoad, setJsonLoad] = useState<boolean>(false);

  const saveJson = async () => {
    const startOperationBefore = Date.now();
    if (process.env.REACT_APP_DEV === 'true') {
      console.log('JSON | Старт операций по выгрузке...');
    }

    setJsonLoad(true);
    const fileName = `id_${nanoid()}_posts_with_reach_${new Date().toLocaleDateString(
      'ru-RU',
    )}.json`;

    const posts = await Post.getAllPostsWithReach();

    const data = JSON.stringify(posts, null, 2);
    const element = document.createElement('a');
    element.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(data),
    );
    element.setAttribute('download', fileName);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();
    document.body.removeChild(element);
    setJsonLoad(false);

    if (process.env.REACT_APP_DEV === 'true') {
      console.log(
        `JSON | Затраченное время: ${
          (Date.now() - startOperationBefore) / 1000
        } сек.`,
      );
    }
  };

  return (
    <Container>
      <Space>
        {user.tariffPlan === 'beta' && (
          <Button
            type={'primary'}
            loading={jsonLoad}
            icon={<DownloadOutlined />}
            onClick={saveJson}
            children={'Скачать JSON'}
          />
        )}
        <Button
          type="primary"
          disabled={actionDisabled}
          onClick={handleExportEntityPosts}
          loading={excelGenerateStatus}
          icon={<DownloadOutlined />}
        >
          Скачать Excel
        </Button>
      </Space>
      {exportPercent.value !== 0 && (
        <>
          <progress value={exportPercent.value} max={exportPercent.max} />
          <span>{`Отформатировано: ${exportPercent.value} постов`}</span>
        </>
      )}
      <DateRange triggerWarning={triggerWarning} />
      <GroupBy />
      {application === App.vk && (
        <GetPostsReach
          disabled={actionDisabled}
          selectedCountExecute={selectedCountExecute}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 37px;
  align-items: flex-end;
`;

export default PostsActions;
