import * as lf from 'lovefield';

import DatabaseClient from '../index';
import {
  getEndOfDay,
  getEndOfMonth,
  getEndOfWeek,
  getStartOfDay,
} from '../../../../helpers/date.helper';
import {
  calculatePostErp,
  calculatePostErv,
  calculatePostErr,
  calculateTgPostErv,
} from '../../../../helpers/auth.helper';
import { IFetchParams } from '../dbInterface';
import { IIndexes } from '../../../../state/listsModule/types';
import { postReachTypes } from '../../../../constants/app.constants';

export default class Post {
  static async insertOrReplace(
    postsResult: number[][],
    ownerId: number,
    membersCount: number,
  ) {
    if (process.env.REACT_APP_DEV === 'true') {
      console.log('Начался процесс создания полученный даты в базе данных');
    }

    const databaseClient: DatabaseClient = await DatabaseClient.getInstance();
    const table: lf.schema.Table = databaseClient.tables.posts;
    const rows: lf.Row[] = [];

    const link = '';
    const postsMap: any = {};
    const postTimestamps = postsResult[0];
    const postIds = postsResult[1];
    const postLikesCount = postsResult[2];
    const postRepostsCount = postsResult[3];
    const postCommentsCount = postsResult[4];
    const postViewsCount = postsResult[5];

    postIds.forEach((postId: number, index: number) => {
      const timestamp = new Date(postTimestamps[index] * 1000);
      const timestampStartOfDay = getStartOfDay(timestamp);
      const timestampEndOfDay = getEndOfDay(timestamp);
      const timestampWeek = getEndOfWeek(timestampStartOfDay);
      const timestampMonth = getEndOfMonth(timestampStartOfDay);
      postsMap[postId] = {
        id: `${ownerId}_${postId}`,
        viewsCount: +postViewsCount[index],
        likesCount: +postLikesCount[index],
        repostsCount: +postRepostsCount[index],
        commentsCount: +postCommentsCount[index],
        timestamp,
        timestampStartOfDay,
        timestampEndOfDay,
        timestampWeek,
        timestampMonth,
        postId,
        ownerId,
        link,
      };
      postsMap[postId].erp = calculatePostErp(postsMap[postId], membersCount);
      postsMap[postId].erv = calculatePostErv(postsMap[postId]);
      postsMap[postId].err = calculatePostErr(postsMap[postId], {
        reachTotal: 0,
      });

      rows.push(table.createRow(postsMap[postId]));
    });

    if (rows.length > 0) {
      if (process.env.REACT_APP_DEV === 'true') {
        console.log(
          'Колличество постов, готовых к созданию в базе данных: ',
          rows.length,
        );
      }

      return databaseClient.db
        .insertOrReplace()
        .into(table)
        .values(rows)
        .exec()
        .catch((e: Error) =>
          console.log('Post insertOrReplace error occurred: ', e),
        );
    }
    return [];
  }

  static async insertOrReplaceOk(
    posts: any,
    ownerId: number,
    membersCount: number,
    indexes: IIndexes,
  ) {
    const databaseClient: DatabaseClient = await DatabaseClient.getInstance();
    const table: lf.schema.Table = databaseClient.tables.posts;
    const rows: lf.Row[] = [];

    const postsMap: any = {};
    // eslint-disable-next-line array-callback-return
    posts.map(post => {
      const postId = post.id;
      const link = '';
      const timestamp = new Date(post.created_ms);
      const timestampStartOfDay = getStartOfDay(timestamp);
      const timestampEndOfDay = getEndOfDay(timestamp);
      const timestampWeek = getEndOfWeek(timestampStartOfDay);
      const timestampMonth = getEndOfMonth(timestampStartOfDay);
      postsMap[post.id] = {
        viewsCount: Object.prototype.hasOwnProperty.call(post, 'views')
          ? post.views
          : post.views_count || 0,
        likesCount: Object.prototype.hasOwnProperty.call(post, 'likes')
          ? post.likes
          : post.like_summary.count,
        repostsCount: Object.prototype.hasOwnProperty.call(post, 'reshares')
          ? post.reshares
          : post.reshare_summary.count,
        commentsCount: Object.prototype.hasOwnProperty.call(post, 'comments')
          ? post.comments
          : post.discussion_summary.comments_count,
        timestamp,
        timestampStartOfDay,
        timestampEndOfDay,
        timestampWeek,
        timestampMonth,
        postId,
        ownerId,
        id: `${ownerId}_${postId}`,
        link,
        reachTotal: post.reach || 0,
      };
      postsMap[postId].erp = calculatePostErp(
        postsMap[postId],
        membersCount,
        indexes,
      );
      postsMap[postId].erv = calculatePostErv(postsMap[postId], indexes);
      postsMap[postId].err = calculatePostErr(
        postsMap[postId],
        {
          reachTotal: postsMap[postId].reachTotal,
        },
        indexes,
      );

      rows.push(table.createRow(postsMap[postId]));
    });

    if (rows.length > 0) {
      if (process.env.REACT_APP_DEV === 'true') {
        console.log(
          'Колличество постов, готовых к созданию в базе данных: ',
          rows.length,
        );
      }
      return databaseClient.db
        .insertOrReplace()
        .into(table)
        .values(rows)
        .exec()
        .catch((e: Error) =>
          console.log('Post insertOrReplace error occurred: ', e),
        );
    }
    return [];
  }

  static async insertOrReplaceTg(
    posts: any,
    ownerId: number,
    membersCount: number,
  ) {
    const databaseClient: DatabaseClient = await DatabaseClient.getInstance();
    const table: lf.schema.Table = databaseClient.tables.posts;
    const rows: lf.Row[] = [];

    const postsMap: any = {};

    const checkNull = (item: null | number) => {
      return item === null ? 0 : item;
    };

    posts.forEach(post => {
      const postId = post.id;
      const link = post.link;
      const timestamp = new Date(post.date * 1000);
      const timestampStartOfDay = getStartOfDay(timestamp);
      const timestampEndOfDay = getEndOfDay(timestamp);
      const timestampWeek = getEndOfWeek(timestampStartOfDay);
      const timestampMonth = getEndOfMonth(timestampStartOfDay);

      postsMap[post.id] = {
        viewsCount: Object.prototype.hasOwnProperty.call(post, 'views')
          ? checkNull(post.views)
          : 0,
        likesCount: Object.prototype.hasOwnProperty.call(post, 'likes')
          ? checkNull(post.likes)
          : 0,
        repostsCount: Object.prototype.hasOwnProperty.call(post, 'reshares')
          ? checkNull(post.reshares)
          : 0,
        commentsCount: Object.prototype.hasOwnProperty.call(post, 'comments')
          ? checkNull(post.comments)
          : 0,
        timestamp,
        timestampStartOfDay,
        timestampEndOfDay,
        timestampWeek,
        timestampMonth,
        postId,
        ownerId,
        id: `${ownerId}_${postId}`,
        link,
      };
      postsMap[postId].erp = calculatePostErp(postsMap[postId], membersCount);
      postsMap[postId].erv = calculateTgPostErv(postsMap[postId], membersCount);
      postsMap[postId].err = calculatePostErr(postsMap[postId], {
        reachTotal: 0,
      });

      rows.push(table.createRow(postsMap[postId]));
    });

    if (rows.length > 0) {
      if (process.env.REACT_APP_DEV === 'true') {
        console.log(
          'Колличество постов, готовых к созданию в базе данных: ',
          rows.length,
        );
      }
      return databaseClient.db
        .insertOrReplace()
        .into(table)
        .values(rows)
        .exec()
        .catch((e: Error) =>
          console.log('Post insertOrReplace error occurred: ', e),
        );
    }
    return [];
  }

  static async getAllById(ids: number | number[]) {
    const databaseClient: DatabaseClient = await DatabaseClient.getInstance();
    const table: lf.schema.Table = databaseClient.tables.posts;

    return databaseClient.db
      .select()
      .from(table)
      .where(table.ownerId.in(Array.isArray(ids) ? ids : [ids]))
      .orderBy(table.timestamp, lf.Order.DESC)
      .exec()
      .catch((e: Error) => console.log('Post getAll error occurred: ', e));
  }

  static async getAllByIdWithReach(ids: number | number[]) {
    const databaseClient: DatabaseClient = await DatabaseClient.getInstance();
    const postsTable: lf.schema.Table = databaseClient.tables.posts;
    const postsReachTable: lf.schema.Table = databaseClient.tables.postsReach;

    const result: any = await databaseClient.db
      .select()
      .from(postsTable)
      .leftOuterJoin(postsReachTable, postsReachTable.id.eq(postsTable.id))
      .where(postsTable.ownerId.in(Array.isArray(ids) ? ids : [ids]))
      .orderBy(postsTable.timestamp, lf.Order.DESC)
      .exec()
      .catch((e: Error) => console.log('Post getAll error occurred: ', e));

    if (result.length > 0) {
      return result.map(post => ({
        ...post.posts_reach,
        ...post.posts,
      }));
    }

    return [];
  }

  static async getAllByIdWithReachDateRange(
    ids: number | number[],
    d1: number,
    d2: number,
    indexes?: IIndexes,
  ) {
    const date1 = new Date(d1);
    const date2 = new Date(d2);
    const databaseClient: DatabaseClient = await DatabaseClient.getInstance();
    const postsTable: lf.schema.Table = databaseClient.tables.posts;
    const postsReachTable: lf.schema.Table = databaseClient.tables.postsReach;

    const result = await databaseClient.db
      .select()
      .from(postsTable)
      .leftOuterJoin(postsReachTable, postsReachTable.id.eq(postsTable.id))
      .where(
        lf.op.and(
          postsTable.ownerId.in(Array.isArray(ids) ? ids : [ids]),
          postsTable.timestamp.between(date1, date2),
        ),
      )
      .orderBy(postsTable.timestamp, lf.Order.DESC)
      .exec()
      .catch((e: Error) => console.log('Post getAll error occurred: ', e));

    if (result.length > 0) {
      return result.map(post => ({
        ...post.posts_reach,
        ...post.posts,
      }));
    }

    return [];
  }

  static async getAllPostsIdsWithDateRange(
    ids: number | number[],
    d1: number,
    d2: number,
  ) {
    const date1 = new Date(d1);
    const date2 = new Date(d2);
    const databaseClient: DatabaseClient = await DatabaseClient.getInstance();
    const table: lf.schema.Table = databaseClient.tables.posts;

    return databaseClient.db
      .select(table.postId)
      .from(table)
      .where(
        lf.op.and(
          table.ownerId.in(Array.isArray(ids) ? ids : [ids]),
          table.timestamp.between(date1, date2),
        ),
      )
      .orderBy(table.timestamp, lf.Order.DESC)
      .exec()
      .catch((e: Error) => console.log('Post getAll error occurred: ', e));
  }

  static async getAllPostsIds(ids: number | number[]) {
    const databaseClient: DatabaseClient = await DatabaseClient.getInstance();
    const table: lf.schema.Table = databaseClient.tables.posts;

    return databaseClient.db
      .select(table.postId)
      .from(table)
      .where(table.ownerId.in(Array.isArray(ids) ? ids : [ids]))
      .orderBy(table.timestamp, lf.Order.DESC)
      .exec()
      .catch((e: Error) => console.log('Post getAll error occurred: ', e));
  }

  static async clearTable() {
    const databaseClient: DatabaseClient = await DatabaseClient.getInstance();
    const postsTable: lf.schema.Table = databaseClient.tables.posts;
    const postsReachTable: lf.schema.Table = databaseClient.tables.postsReach;

    const postsResult = await databaseClient.db
      .delete()
      .from(postsTable)
      .exec()
      .catch((e: Error) => console.log('Post clearTable error occurred: ', e));

    const postsReachResult = await databaseClient.db
      .delete()
      .from(postsReachTable)
      .exec()
      .catch((e: Error) => console.log('Post clearTable error occurred: ', e));

    return [postsResult, postsReachResult];
  }

  static async fetchPosts(params: IFetchParams, withReach: boolean) {
    const { sortField, sortOrder, id, current, pageSize } = params;
    const orderByKey = sortOrder === 'ascend' ? 'ASC' : 'DESC';
    const databaseClient: DatabaseClient = await DatabaseClient.getInstance();
    const postsReachTable: lf.schema.Table = databaseClient.tables.postsReach;
    const table: lf.schema.Table = databaseClient.tables.posts;
    const count = id && (await Post.getCountById(id));

    if (withReach) {
      const result = await databaseClient.db
        .select()
        .from(table)
        .leftOuterJoin(postsReachTable, postsReachTable.id.eq(table.id))
        .where(table.ownerId.eq(id))
        .orderBy(
          withReach
            ? postsReachTable[sortField || 'timestamp']
            : table[sortField || 'timestamp'],
          lf.Order[orderByKey],
        )
        .skip(current === 1 ? 0 : pageSize * current)
        .limit(pageSize)
        .exec()
        .catch((e: Error) => console.log('Post getAll error occurred: ', e));

      if (result.length > 0) {
        const reachResult = result.map(post => ({
          ...post.posts_reach,
          ...post.posts,
        }));

        return {
          result: reachResult,
          total: reachResult.length,
        };
      }
    }

    const result = await databaseClient.db
      .select()
      .from(table)
      .where(table.ownerId.eq(id))
      .orderBy(table[sortField || 'timestamp'], lf.Order[orderByKey])
      .skip(current === 1 ? 0 : pageSize * current)
      .limit(pageSize)
      .exec()
      .catch((e: Error) => console.log('Post getAll error occurred: ', e));

    return {
      result,
      total: count,
    };
  }

  static async fetchPostsWithDateRange(
    params: IFetchParams,
    dateFrom: number,
    dateTo: number,
    withReach: boolean,
  ) {
    const { sortField, sortOrder, id, current, pageSize } = params;
    const orderByKey = sortOrder === 'ascend' ? 'ASC' : 'DESC';
    const databaseClient: DatabaseClient = await DatabaseClient.getInstance();
    const table: lf.schema.Table = databaseClient.tables.posts;
    const postsReachTable: lf.schema.Table = databaseClient.tables.postsReach;
    const count =
      id && (await Post.getCountByIdWithDateRange(id, dateFrom, dateTo));

    const checkTable = postReachTypes.includes(sortField);

    if (withReach) {
      const result = await databaseClient.db
        .select()
        .from(table)
        .leftOuterJoin(postsReachTable, postsReachTable.id.eq(table.id))
        .where(
          lf.op.and(
            table.ownerId.eq(id),
            table.timestamp.between(new Date(dateFrom), new Date(dateTo)),
          ),
        )
        .orderBy(
          checkTable
            ? postsReachTable[sortField || 'timestamp']
            : table[sortField || 'timestamp'],
          lf.Order[orderByKey],
        )
        .skip(current === 1 ? 0 : pageSize * current)
        .limit(pageSize)
        .exec()
        .catch((e: Error) => console.log('Post getAll error occurred: ', e));

      if (result.length > 0) {
        const reachResult = result.map(post => ({
          ...post.posts_reach,
          ...post.posts,
        }));

        return {
          result: reachResult,
          total: count,
        };
      }
    }

    const result = await databaseClient.db
      .select()
      .from(table)
      .where(
        lf.op.and(
          table.ownerId.eq(id),
          table.timestamp.between(new Date(dateFrom), new Date(dateTo)),
        ),
      )
      .orderBy(table[sortField || 'timestamp'], lf.Order[orderByKey])
      .skip(current === 1 ? 0 : pageSize * current)
      .limit(pageSize)
      .exec()
      .catch((e: Error) => console.log('Post getAll error occurred: ', e));

    return {
      result,
      total: count,
    };
  }

  static async getCountById(id: number) {
    const databaseClient: DatabaseClient = await DatabaseClient.getInstance();
    const table: lf.schema.Table = databaseClient.tables.posts;

    const result: any = await databaseClient.db
      .select(lf.fn.count(table.postId).as('count'))
      .from(table)
      .where(table.ownerId.eq(id))
      .exec()
      .catch((e: Error) => console.log('Post getAll error occurred: ', e));

    return result[0].count;
  }

  static async getCountByIdWithDateRange(
    id: number,
    dateFrom: number,
    dateTo: number,
  ) {
    const databaseClient: DatabaseClient = await DatabaseClient.getInstance();
    const table: lf.schema.Table = databaseClient.tables.posts;

    const result: any = await databaseClient.db
      .select(lf.fn.count(table.postId).as('count'))
      .from(table)
      .where(
        lf.op.and(
          table.ownerId.eq(id),
          table.timestamp.between(new Date(dateFrom), new Date(dateTo)),
        ),
      )
      .exec()
      .catch((e: Error) => console.log('Post getAll error occurred: ', e));

    return result[0].count;
  }

  static async getPostsWithDateRange(
    ids: number | number[],
    d1: number,
    d2: number,
  ) {
    const databaseClient: DatabaseClient = await DatabaseClient.getInstance();
    const table: lf.schema.Table = databaseClient.tables.posts;
    const date1 = new Date(new Date(d1).setHours(0, 0, 1, 0));
    const date2 = new Date(new Date(d2).setHours(23, 59, 59, 0));

    return databaseClient.db
      .select()
      .from(table)
      .where(
        lf.op.and(
          table.ownerId.in(Array.isArray(ids) ? ids : [ids]),
          table.timestamp.between(date1, date2),
        ),
      )
      .orderBy(table.timestamp, lf.Order.DESC)
      .exec()
      .catch((e: Error) =>
        console.log('Post getPostsWithDateRange error occurred: ', e),
      );
  }

  static async getAllUp(ids: number | number[], date: number) {
    const databaseClient: DatabaseClient = await DatabaseClient.getInstance();
    const table: lf.schema.Table = databaseClient.tables.posts;
    return databaseClient.db
      .select()
      .from(table)
      .where(
        lf.op.and(
          table.ownerId.in(Array.isArray(ids) ? ids : [ids]),
          table.timestamp.lt(new Date(date)),
        ),
      )
      .orderBy(table.timestamp, lf.Order.DESC)
      .exec()
      .catch((e: Error) =>
        console.log('Post getPostsWithDateRange error occurred: ', e),
      );
  }

  static async getAllUpWithReach(ids: number[], date: number) {
    const databaseClient: DatabaseClient = await DatabaseClient.getInstance();
    const postsReachTable: lf.schema.Table = databaseClient.tables.postsReach;
    const postsTable: lf.schema.Table = databaseClient.tables.posts;

    const result: any = await databaseClient.db
      .select()
      .from(postsTable)
      .leftOuterJoin(postsReachTable, postsReachTable.id.eq(postsTable.id))
      .where(postsTable.ownerId.in(ids))
      .orderBy(postsTable.timestamp, lf.Order.DESC)
      .exec()
      .catch((e: Error) =>
        console.log('Post getPostsWithDateRange error occurred: ', e),
      );

    if (result.length > 0) {
      return result.map(post => ({
        ...post.posts_reach,
        ...post.posts,
      }));
    }

    return [];
  }

  static async getAllPostsWithReach() {
    const databaseClient: DatabaseClient = await DatabaseClient.getInstance();
    const postsReachTable: lf.schema.Table = databaseClient.tables.postsReach;
    const postsTable: lf.schema.Table = databaseClient.tables.posts;

    const result: any = await databaseClient.db
      .select()
      .from(postsTable)
      .leftOuterJoin(postsReachTable, postsReachTable.id.eq(postsTable.id))
      .exec()
      .catch((e: Error) =>
        console.log('Post getPostsWithDateRange error occurred: ', e),
      );

    if (result.length > 0) {
      return result.map(post => ({
        ...post.posts_reach,
        ...post.posts,
      }));
    }

    return [];
  }

  static async insertOrReplaceReach(
    postsReach: Array<any>,
    privilegedEntityId,
  ) {
    const databaseClient: DatabaseClient = await DatabaseClient.getInstance();
    const table: lf.schema.Table = databaseClient.tables.postsReach;
    const rows: lf.Row[] = [];

    const handleError = (postReach, key) => {
      if (process.env.REACT_APP_DEV === 'true') {
        console.log(`ошибка записи данных в бд, с ${key}, пост:`, postReach);
      }
    };

    if (postsReach && Array.isArray(postsReach)) {
      for (const postReach of postsReach) {
        if (!postReach) {
          debugger;
          handleError(postReach, '');
          continue;
        }

        rows.push(
          table.createRow({
            postId: postReach.post_id,
            ownerId: privilegedEntityId,
            reachSubscribers: postReach.reach_subscribers,
            reachTotal: postReach.reach_total,
            reachAds: postReach.reach_ads,
            reachViral: postReach.reach_viral,
            toGroup: postReach.to_group,
            joinGroup: postReach.join_group,
            unsubscribe: postReach.unsubscribe,
            links: postReach.links,
            report: postReach.report,
            hide: postReach.hide,
            adViews: postReach.ad_views,
            adSubscribers: postReach.ad_subscribers,
            adHide: postReach.ad_hide,
            adUnsubscribe: postReach.ad_unsubscribe,
            adLinks: postReach.ad_links,
            adToGroup: postReach.ad_to_group,
            adJoinGroup: postReach.ad_join_group,
            adCoverage: postReach.ad_coverage,
            adReport: postReach.ad_report,
            id: `${privilegedEntityId}_${postReach.post_id}`,
          }),
        );
      }
    } else {
      console.log(`отсутствуют посты или это не массив`);
    }

    if (rows.length > 0) {
      return databaseClient.db
        .insertOrReplace()
        .into(table)
        .values(rows)
        .exec()
        .catch((e: Error) =>
          console.log('Post insertOrReplaceReach error occurred: ', e),
        );
    }
    return [];
  }

  static async insertOrReplaceReachOk(postsReach: Array<any>, ownerId: number) {
    const databaseClient: DatabaseClient = await DatabaseClient.getInstance();
    const table: lf.schema.Table = databaseClient.tables.postsReach;
    const rows: lf.Row[] = [];

    // eslint-disable-next-line array-callback-return
    postsReach.map(postReach => {
      rows.push(
        table.createRow({
          ownerId,
          postId: postReach.post_id,
          reachTotal: postReach.reach_total,
          id: `${ownerId}_${postReach.post_id}`,
        }),
      );
    });

    if (rows.length > 0) {
      return databaseClient.db
        .insertOrReplace()
        .into(table)
        .values(rows)
        .exec()
        .catch((e: Error) =>
          console.log('Post insertOrReplaceReach error occurred: ', e),
        );
    }
    return [];
  }

  static async getSummaryIndicators(ids: number[]) {
    const databaseClient: DatabaseClient = await DatabaseClient.getInstance();
    const posts: lf.schema.Table = databaseClient.tables.posts;
    const postsReach: lf.schema.Table = databaseClient.tables.postsReach;

    const result = await databaseClient.db
      .select(lf.fn.sum(postsReach.reach_total))
      .from(posts)
      .leftOuterJoin(postsReach, postsReach.id.eq(postsReach.id))
      .exec()
      .catch((e: Error) => console.log('Post getAll error occurred: ', e));

    return {
      totalReach: result[0][`SUM(reach_total)`],
    };
  }
}
