import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './index';
import { RootState } from '../../../types';

const selectSlice = (state: RootState) => state.groupCoversData || initialState;

export const getGroupCovers = createSelector(
  [selectSlice],
  state => state.groupCovers,
);

export const getSelectedListsId = createSelector(
  [selectSlice],
  state => state.selectedListsId,
);

export const getGroupCoversLoadStatus = createSelector(
  [selectSlice],
  state => state.isLoad,
);

export const getGroupCoversTitle = createSelector(
  [selectSlice],
  state => state.selectedListTitle,
);

export const getSelectedList = createSelector(
  [selectSlice],
  state => state.selectedList,
);
