import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { IRequestPayload, listStatisticsSliceActions } from './index';
import { getGroupStatisticsHandler } from '../../services/client/vk/handlers';
import { IListStatistics, IStatObject } from './types';

interface IResponse {
  data: IListStatistics[];
  group_id: string;
}

export function* fetchListStatisticsWorker(action) {
  const {
    selectedList,
    interval,
    intervalsCount,
  }: IRequestPayload = action.payload;
  const listsOfGroupsIds: any = selectedList.entities.map(item => item.id);

  yield put(listStatisticsSliceActions.clearListStatisticsState());

  try {
    const getVkGroupsIds = (): number[] => {
      return [].concat
        .apply([], listsOfGroupsIds)
        .filter(el => typeof el === 'number');
    };

    let array = getVkGroupsIds(); //массив, можно использовать массив объектов
    let size = 5; //размер подмассива
    let arrayOfIdsPack: number[][] = []; //массив в который будет выведен результат.
    for (let i = 0; i < Math.ceil(array.length / size); i++) {
      arrayOfIdsPack[i] = array.slice(i * size, i * size + size);
    }

    const arrayParams: any[] = [];

    arrayOfIdsPack.forEach(idsPack => {
      let paramsObj = {};

      idsPack.forEach((id, index) => {
        paramsObj[`group_id_${index + 1}`] = id;
      });

      arrayParams.push(paramsObj);
    });

    for (const params of arrayParams) {
      const response: IResponse[] = yield call(
        getGroupStatisticsHandler,
        params,
        interval,
        intervalsCount,
      );

      const formattedResponse: IListStatistics[][] = response
        .map(item => {
          return !item.data
            ? []
            : item.data.map(i => ({ ...i, groupId: item.group_id }));
        })
        .filter(item => item.length > 0);

      const formattedData = formattedResponse.map(listStat => {
        const renameProperties = listStat.map(stat => ({
          groupId: stat.groupId,
          period_from: stat.period_from,
          period_to: stat.period_to,
          [`охват`]: {
            [`полный`]: stat.reach?.reach || 0,
            [`подписчиков`]: stat.reach?.reach_subscribers || 0,
          },
          [`подписки`]: {
            [`подписалось`]: stat.activity?.subscribed || 0,
            [`отписалось`]: stat.activity?.unsubscribed || 0,
          },
          [`активность`]: {
            [`лайки`]: stat.activity?.likes || 0,
            [`репосты`]: stat.activity?.copies || 0,
            [`коммент.`]: stat.activity?.comments || 0,
            [`скрытия`]: stat.activity?.hidden || 0,
          },
        }));

        let statObj: IStatObject = {
          id: '',
          reachTotal: 0,
          reachSubscribers: 0,
          subscribedTotal: 0,
          unsubscribedTotal: 0,
          reachLikes: 0,
          reachCopies: 0,
          reachComments: 0,
          reachHidden: 0,
          subscribedDifference: 0,
          [`охват`]: [],
          [`подписки`]: [],
          [`активность`]: [],
        };

        const calculateTotal = (key: string, subKey: string): number => {
          const statLists = listStat
            .map(list => list[key])
            .filter(list => list !== undefined);

          const total = statLists.reduce(
            (accum, item) => (item[subKey] ? accum + item[subKey] : accum),
            0,
          );

          return total;
        };

        renameProperties.forEach(stat => {
          statObj.id = stat.groupId;
          statObj.reachTotal = calculateTotal('reach', 'reach');
          statObj.reachSubscribers = calculateTotal(
            'reach',
            'reach_subscribers',
          );
          statObj.subscribedTotal = calculateTotal('activity', 'subscribed');
          statObj.unsubscribedTotal = calculateTotal(
            'activity',
            'unsubscribed',
          );
          statObj.reachLikes = calculateTotal('activity', 'likes');
          statObj.reachCopies = calculateTotal('activity', 'copies');
          statObj.reachComments = calculateTotal('activity', 'comments');
          statObj.reachHidden = calculateTotal('activity', 'hidden');
          statObj.subscribedDifference =
            calculateTotal('activity', 'subscribed') -
            calculateTotal('activity', 'unsubscribed');
          statObj[`охват`].push({
            ...stat[`охват`],
            date: stat.period_to * 1000,
          });
          statObj[`активность`].push({
            ...stat[`активность`],
            date: stat.period_to * 1000,
          });
          statObj[`подписки`].push({
            ...stat[`подписки`],
            date: stat.period_to * 1000,
          });
        });

        return statObj;
      });

      yield put(
        listStatisticsSliceActions.setListStatisticsMiddleResult(formattedData),
      );

      yield delay(340);
    }

    yield put(listStatisticsSliceActions.setListStatisticsSuccess());
  } catch (ERR) {
    yield put(listStatisticsSliceActions.setListStatisticsFailure(ERR.message));
  }
}

export function* listStatisticsSliceSaga() {
  yield takeLatest(
    listStatisticsSliceActions.setListStatisticsRequest,
    fetchListStatisticsWorker,
  );
}
