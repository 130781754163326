import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { InitModuleState, IError } from './types';

export const initialState: InitModuleState = {
  isLoggedIn: false,
  isLoading: true,
  error: {} as IError,
};

const slice = createSlice({
  name: 'initModule',
  initialState,
  reducers: {
    success(state, action: PayloadAction<any>) {
      state.isLoggedIn = true;
      state.isLoading = false;
      return state;
    },
    fail(state, action: PayloadAction<any>) {
      state.isLoggedIn = false;
      state.isLoading = false;
      state.error = action.payload;
      return state;
    },
    login(state, action: PayloadAction<any>) {
      state.isLoading = true;
      return state;
    },
    authStateChanged(state, action: PayloadAction<any>) {
      state.isLoading = true;
      return state;
    },
    setLoading(state, action: PayloadAction<any>) {
      state.isLoading = action.payload;
      return state;
    },
    init(state, action: PayloadAction<any>) {
      state.isLoading = true;
      return state;
    },
    initialize(state, action: PayloadAction<any>) {
      state.isLoading = true;
      return state;
    },
    signOut(state) {
      state.isLoggedIn = false;
      return state;
    },
  },
});

export const { actions: initModuleActions, reducer: initModuleReducer } = slice;

// export const useInitModuleSlice = () => {
//   useInjectReducer({ key: slice.name, reducer: slice.reducer });
//   useInjectSaga({ key: slice.name, saga: initModuleSaga });
//   return { actions: slice.actions };
// };

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useInitModuleSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
