import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './index';
import { RootState } from '../../../types';

const selectSlice = (state: RootState) => state.graphicData || initialState;

export const getGraphicData = createSelector(
  [selectSlice],
  state => state.graphicData,
);

export const getAdditionalGraphicTypes = createSelector(
  [selectSlice],
  state => state.additionalGraphicTypes,
);

export const getGraphicDataCompare = createSelector(
  [selectSlice],
  state => state.graphicDataCompare,
);

export const getPostReachReceived = createSelector(
  [selectSlice],
  state => !!state.additionalGraphicTypes.length,
);

export const getGraphicDataLoad = createSelector(
  [selectSlice],
  state => state.load,
);

export const getGraphicsRender = createSelector(
  [selectSlice],
  state => state.renderGraphics,
);
