import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { UserState } from './types';

export const initialState: UserState = {
  isLoggedIn: false,
  userId: null,
  displayName: '',
  email: '',
  emailVerified: false,
  lastSignInTime: null,
  isAnonymous: false,
  tariffPlan: '',
  role: '',
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    commonAuth(state, action: PayloadAction<any>) {
      return state;
    },
    success(state) {
      state.isLoggedIn = true;
      return state;
    },
    signOut() {
      return initialState;
    },
    fail(state) {
      state.isLoggedIn = false;
      return state;
    },
    login(state) {
      return state;
    },
    setUser(state, action: PayloadAction<any>) {
      return { ...state, ...action.payload.user };
    },
  },
});

export const { actions: authModuleActions, reducer: authModuleReducer } = slice;
