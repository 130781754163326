import moment from 'moment';

export default function formatDate(date: number) {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join('-');
}

export function getEndOfWeek(date: Date) {
  const newDate = new Date(date.getTime());
  const lastDay = newDate.getDate() - (newDate.getDay() - 1) + 6;
  return new Date(newDate.setDate(lastDay));
}

export function getEndOfMonth(date: Date) {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}
export function getStartOfDay(date: Date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}
export function getEndOfDay(date: Date) {
  const d = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  d.setHours(23, 59, 59, 999);
  return d;
}

export function getPostGroupByTimestamp(post: any, groupByValue: string) {
  // eslint-disable-next-line no-nested-ternary
  return groupByValue === 'none' || groupByValue === 'byDay'
    ? post.timestampStartOfDay.getTime()
    : groupByValue === 'byWeek'
    ? post.timestampWeek.getTime()
    : post.timestampMonth.getTime();
}

export function isToday(someDate) {
  if (!someDate) {
    return false;
  }

  const today = new Date();
  return (
    someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
  );
}

export function getPreviousWeekStart() {
  return moment().subtract(1, 'weeks').startOf('isoWeek').valueOf();
}

export function getPreviousWeekEnd() {
  return moment().subtract(1, 'weeks').endOf('isoWeek').valueOf();
}

export function getPreviousMonthStart() {
  return moment().subtract(1, 'months').startOf('month').valueOf();
}

export function getPreviousMonthEnd() {
  return moment().subtract(1, 'months').endOf('month').valueOf();
}

export function previousWeekSelected(dateFrom, dateTo) {
  return dateFrom === getPreviousWeekStart() && dateTo === getPreviousWeekEnd();
}

export function previousMonthSelected(dateFrom, dateTo) {
  return (
    dateFrom === getPreviousMonthStart() && dateTo === getPreviousMonthEnd()
  );
}

export function getWeekBeforeLastStart() {
  return moment().subtract(2, 'weeks').startOf('isoWeek').valueOf();
}

export function getWeekBeforeLastEnd() {
  return moment().subtract(2, 'weeks').endOf('isoWeek').valueOf();
}

export function getMonthBeforeLastStart() {
  return moment().subtract(2, 'months').startOf('month').valueOf();
}

export function getMonthBeforeLastEnd() {
  return moment().subtract(2, 'months').endOf('month').valueOf();
}

export function findValuePlaceInArray({ value, array, directionIsUp }): number {
  let index = directionIsUp ? array.length - 1 : 0;
  if (directionIsUp) {
    while (array[index] < value) {
      index -= 1;
    }
  } else {
    while (array[index] > value) {
      index += 1;
    }
  }

  return index;
}
