export const HIDDEN_INTERFACE_RATES = [
  // 'erp',
  // 'postsCount',
  'reachSubscribers',
  // 'reachTotal',
  'reachAds',
  'reachViral',
  'toGroup',
  'joinGroup',
  'unsubscribe',
  'links',
  'report',
  'hide',
  'adViews',
  'adSubscribers',
  'adHide',
  'adUnsubscribe',
  'adLinks',
  'adToGroup',
  'adJoinGroup',
  'adCoverage',
  'adReport',
];

export const COMPARING_INTERFACE_RATES = [
  'viewsCount',
  'likesCount',
  'commentsCount',
  'repostsCount',
];

export const TYPES_MAP = {
  membersCount: 'Подписчики',
  postsCount: 'Посты',
  reachTotal: 'Охват',
  viewsCount: 'Просмотры',
  likesCount: 'Лайки',
  repostsCount: 'Репосты',
  commentsCount: 'Комментарии',
  erv: 'ER.v, %',
  erp: 'ER.p',
  err: 'ER.r, %',
};

export const TYPES_ARRAY = [
  'Подписчики',
  'Посты',
  'Охват',
  'Просмотры',
  'Лайки',
  'Репосты',
  'Комментарии',
  'ER.v, %',
  'ER.p',
  'ER.r, %',
];
