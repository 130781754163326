import {
  takeLatest,
  select,
  call,
  all,
  put,
  putResolve,
} from 'redux-saga/effects';
import { graphicDataModuleActions } from './index';
import { rateDataModuleActions } from '../ratesModule';
import { graphicCompareDefaultData } from '../../helpers/graphic.helper';
import {
  getEntityApp,
  getEntityId,
  getMembersCountFromEntity,
  getNameFromEntity,
  getOwnerIdFromEntity,
  numberWithSpaces,
} from '../../helpers/auth.helper';
import { getEntityInfo } from '../entityFetchModule/selector';
import {
  getDateRange,
  getDefaultDateRange,
  getGroupBy,
} from '../dateModule/selectors';
import {
  getMonthBeforeLastEnd,
  getMonthBeforeLastStart,
  getPostGroupByTimestamp,
  getWeekBeforeLastEnd,
  getWeekBeforeLastStart,
  previousMonthSelected,
  previousWeekSelected,
} from '../../helpers/date.helper';
import { getAdditionalGraphicTypes, getPostReachReceived } from './selector';
import Post from '../../services/client/database/classes/post';
import { getSelectedList } from '../listsModule/selectors';
import { IGraphicData } from './types';
import { getTotalRates } from '../ratesModule/selectors';
import { customLogger } from '../../helpers/log.helper';
import { IList } from '../listsModule/types';
import { IEntityInfo } from '../entityFetchModule/types';
import { groupDataModuleActions } from '../groupDataModule';

function* fetchGraphicDataCompare({ payload: { graphicType } }: any) {
  // only triggers when selected previous month
  // fill graphicDataMap as 31 days
  const graphicDataMap = graphicCompareDefaultData();
  const selectedEntityInfo = yield select(getEntityInfo);
  const { from: dateFrom, to: dateTo } = yield select(getDateRange);
  // check post reach received to show the corresponding value
  const postsReachReceived = yield select(getPostReachReceived);

  const ownerId = getOwnerIdFromEntity(
    selectedEntityInfo,
    selectedEntityInfo.app,
  );

  const id = `${graphicType}-${ownerId}`;
  const [previousWeekIsSelected, previousMonthIsSelected] = [
    previousWeekSelected(dateFrom, dateTo),
    previousMonthSelected(dateFrom, dateTo),
  ];

  if (!previousWeekIsSelected && !previousMonthIsSelected) {
    customLogger('выбран другой период или не выбран вообще');
  } else {
    // Getting date before last for week or month based on date which is selected
    const dateFromBeforeLast = previousMonthIsSelected
      ? getMonthBeforeLastStart()
      : getWeekBeforeLastStart();

    const dateToBeforeLast = previousMonthIsSelected
      ? getMonthBeforeLastEnd()
      : getWeekBeforeLastEnd();

    const [getPostsEffect, getPostsBeforeLast] = [
      postsReachReceived
        ? call(Post.getAllByIdWithReachDateRange, ownerId, dateFrom, dateTo)
        : call(Post.getPostsWithDateRange, ownerId, dateFrom, dateTo),
      postsReachReceived
        ? call(
            Post.getAllByIdWithReachDateRange,
            ownerId,
            dateFromBeforeLast,
            dateToBeforeLast,
          )
        : call(
            Post.getPostsWithDateRange,
            ownerId,
            dateFromBeforeLast,
            dateToBeforeLast,
          ),
    ];

    // getting regular posts for current dateRange and month before last posts to compare and show line on graphic of previous values
    const { posts, postsBeforeLast } = yield all({
      posts: getPostsEffect,
      postsBeforeLast: getPostsBeforeLast,
    });

    // merging in one Map, ay is regular month value, by - is month before last value
    // all should be in one object, values of previous month and month before last
    const isPostsCountType = graphicType === 'postsCount';
    [...posts, ...postsBeforeLast].map((post, index) => {
      const xKey = index > posts.length - 1 ? 'bx' : 'ax';
      const yKey = index > posts.length - 1 ? 'by' : 'ay';
      const dayNumber = post.timestamp.getDate();
      graphicDataMap[dayNumber] = {
        ...graphicDataMap[dayNumber],
        [xKey]: dayNumber,
        [yKey]:
          graphicDataMap[dayNumber][yKey] +
          (isPostsCountType ? 1 : post[graphicType] || 0),
      };
      return post;
    });
    yield put(
      graphicDataModuleActions.setGraphicDataCompare({
        id,
        data: Object.values(graphicDataMap),
      }),
    );
  }
}

export function* fetchGraphicData() {
  customLogger(`Графики. Начинаем операции...`);
  const before = Date.now();

  const selectedEntityInfo = yield select(getEntityInfo);
  const dateRange = yield select(getDateRange);
  const groupBy = yield select(getGroupBy);
  const selectedList: IList = yield select(getSelectedList);
  const defaultDateRange = yield select(getDefaultDateRange);
  const additionalGraphicTypes = yield select(getAdditionalGraphicTypes);
  const additionalGraphicTypesExists = additionalGraphicTypes.length > 0;
  yield put(graphicDataModuleActions.setGraphicDataLoad(true));

  const groupByValue = groupBy;
  const [dateFrom, dateTo] = [
    dateRange.from || defaultDateRange.from,
    dateRange.to || defaultDateRange.to,
  ];
  const listSelected = Object.keys(selectedList).length > 0;
  const entitiesArr: IEntityInfo[] = listSelected
    ? selectedList.entities
    : [selectedEntityInfo];
  const graphicId = `id${dateFrom}${dateTo}${defaultDateRange.from}${
    defaultDateRange.to
  }${groupByValue}${
    listSelected
      ? selectedList.id
      : getOwnerIdFromEntity(selectedEntityInfo, selectedEntityInfo.app)
  }${additionalGraphicTypes.join()}`;
  const graphicData: IGraphicData = {
    viewsCount: {},
    likesCount: {},
    repostsCount: {},
    commentsCount: {},
    id: graphicId,
    postsCount: {},
  };

  if (additionalGraphicTypesExists) {
    additionalGraphicTypes.map(
      // eslint-disable-next-line no-return-assign
      additionalGraphicType => (graphicData[additionalGraphicType] = {}),
    );
  }

  const graphicTypes = [
    'viewsCount',
    'likesCount',
    'repostsCount',
    'commentsCount',
    'postsCount',
    ...additionalGraphicTypes,
  ];

  const viewsCountMinDate = new Date(2017, 0, 1);
  const reachTotalMinDate = new Date(2016, 0, 1);

  // eslint-disable-next-line no-restricted-syntax
  for (const entityInfo of entitiesArr) {
    const { first_name: firstName, last_name: lastName, name } = entityInfo;
    const ownerId = getOwnerIdFromEntity(entityInfo, entityInfo.app);
    const effect =
      dateTo && dateFrom
        ? call(
            additionalGraphicTypesExists
              ? Post.getAllByIdWithReachDateRange
              : Post.getPostsWithDateRange,
            ownerId,
            dateFrom,
            dateTo,
            selectedList.indexes,
          )
        : call(
            additionalGraphicTypesExists
              ? Post.getAllByIdWithReach
              : Post.getAllById,
            ownerId,
          );

    const posts = yield effect;

    posts.forEach((post: any) => {
      const timestamp: number = getPostGroupByTimestamp(post, groupByValue);

      graphicTypes.forEach(graphicType => {
        const isPostsCountType = graphicType === 'postsCount';
        const isViewsCountType = graphicType === 'viewsCount';
        const isReachTotalType = graphicType === 'reachTotal';

        if (isViewsCountType && post.timestamp < viewsCountMinDate) {
          return null;
        }

        if (isReachTotalType && post.timestamp < reachTotalMinDate) {
          return null;
        }

        const graphicDataByType = graphicData[graphicType];
        const valueKey = name || `${firstName} ${lastName}`;
        graphicDataByType[timestamp] = graphicDataByType[timestamp]
          ? {
              ...graphicDataByType[timestamp],
              date: new Date(timestamp),

              [valueKey]:
                (graphicDataByType[timestamp][valueKey] || 0) +
                (isPostsCountType ? 1 : post[graphicType]),
            }
          : {
              date: new Date(timestamp),
              [valueKey]: isPostsCountType ? 1 : post[graphicType] || 0,
            };
      });
    });
  }

  yield put(
    graphicDataModuleActions.setGraphicData({ graphicData: graphicData }),
  );

  yield put(graphicDataModuleActions.setGraphicDataLoad(false));

  const after = Date.now();
  customLogger(`Графики. Все операции завершены.`, (after - before) / 1000);
}

export function* graphicDataModuleSaga() {
  // yield takeLatest(
  //   graphicDataModuleActions.fetchGraphicDataCompare,
  //   fetchGraphicDataCompare,
  // );
  yield takeLatest(graphicDataModuleActions.fetchGraphicData, fetchGraphicData);
}
