import React from 'react';

import { Button } from 'antd';
import CollectPostsBlock from '../CollectPostsBlock';
import { INFO_BLOCK_VARIANT } from '../../../constants/collect.constants';
import { logEvent } from '../../../libs/lib.amplitude';
import { LOG_EVENTS } from '../../../constants/lib.constants';
import {
  getNameFromEntity,
  getOwnerIdFromEntity,
  getUserRoleOfEntity,
} from '../../../helpers/auth.helper';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedApplication } from '../../../state/socialAccountsModule/selector';
import { entityModuleActions } from '../../../state/entityFetchModule';
import { IEntityInfo } from '../../../state/entityFetchModule/types';
import { CheckCircleOutlined } from '@ant-design/icons';
import { green } from '@ant-design/colors';
import { ListsLogs } from '../../../services/amplitude/amplitude';

type Props = {
  variant: string | null;
  setSelectedCountExecute;
  downloadInProgress;
  handleAddToList: (entityInfo: IEntityInfo) => void;
  entityInfo: IEntityInfo;
  selectedListExists: boolean;
  isBlockedUser?: boolean;
  entitiesArr?: IEntityInfo[];
  setInputValue: (input: string) => void;
};
export default function ActionBlock({
  variant,
  setSelectedCountExecute,
  downloadInProgress,
  handleAddToList,
  entityInfo,
  selectedListExists,
  isBlockedUser,
  entitiesArr,
  setInputValue,
}: Props) {
  const {
    COLLECTING,
    COLLECTING_LIST,
    ADD_TO_LIST,
    SEARCH_QUERY,
    SHOW_ROLE,
  } = INFO_BLOCK_VARIANT;

  const dispatch = useDispatch();

  const handleOnAddToList = () => {
    handleAddToList({
      app: entityInfo.app || 0,
      id: entityInfo.id || '',
      name: entityInfo.name || '',
      photo_100: entityInfo.photo_100 || '',
      type: entityInfo.type || '',
      channelId: entityInfo.channelId || '',
      followers_count: entityInfo.followers_count || 0,
      tags: [],
      screen_name: entityInfo.screen_name || '',
      members_count: entityInfo.members_count,
      admin_level: entityInfo.admin_level || '',
      first_name: entityInfo.first_name || '',
      postsCount: entityInfo.postsCount || 0,
      last_name: entityInfo.last_name || '',
      picAvatar: entityInfo.picAvatar || '',
    });

    dispatch(entityModuleActions.setEntityInfo({}));

    setInputValue('');

    const type = variant === 'ADD_TO_LIST' ? 'form' : 'search';

    ListsLogs.addGroup('click list add group', { type });
  };

  const addedEntitiesIds = entitiesArr?.map(entity => entity.id);

  return (
    <>
      {variant && [COLLECTING, COLLECTING_LIST].includes(variant) ? (
        <CollectPostsBlock
          setSelectedCountExecute={setSelectedCountExecute}
          downloadInProgress={downloadInProgress}
          selectedListExists={selectedListExists}
          entityInfo={entityInfo}
          isBlockedUser={isBlockedUser}
        />
      ) : variant && [ADD_TO_LIST, SEARCH_QUERY].includes(variant) ? (
        <div
          style={{
            display: 'flex',
            alignSelf: 'flex-end',
            position: 'relative',
          }}
        >
          <Button
            id="add-entity-to-list"
            size="middle"
            className="add-to-list"
            onClick={handleOnAddToList}
            type={
              addedEntitiesIds?.includes(entityInfo.id) ? 'primary' : 'default'
            }
            icon={
              addedEntitiesIds?.includes(entityInfo.id) ? (
                <CheckCircleOutlined />
              ) : (
                <></>
              )
            }
            style={{
              backgroundColor: addedEntitiesIds?.includes(entityInfo.id)
                ? green.primary
                : '',
              borderColor: addedEntitiesIds?.includes(entityInfo.id)
                ? green.primary
                : '',
            }}
          >
            {addedEntitiesIds?.includes(entityInfo.id)
              ? 'Добавлено'
              : 'Добавить в список'}
          </Button>
        </div>
      ) : variant === SHOW_ROLE ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            position: 'relative',
            height: '90%',
          }}
        >
          <span>{getUserRoleOfEntity(entityInfo)}</span>
        </div>
      ) : null}
    </>
  );
}
