import React from 'react';
import { Modal } from 'antd';
import routes from '../../../../configs/app.routes';
import { NavigateFunction } from 'react-router-dom';

const AnonWarningModal = (navigate: NavigateFunction) => {
  return Modal.confirm({
    title: 'Приветствуем на hitalama.com',
    content: (
      <div>
        <p>
          Для того, чтобы воспользоваться функцией необходима регистрация в
          Хиталаме.
        </p>
      </div>
    ),
    onOk() {
      navigate(routes.signUp);
    },
    cancelText: 'Понятно',
    okText: 'Регистрация',
  });
};

export default AnonWarningModal;
