import React from 'react';

import { numberWithSpaces } from '../../../helpers/auth.helper';
import styled from 'styled-components';
import { TYPES_MAP } from '../../../constants/rates.constants';

type Props = {
  type: string;
  rate: string;
  showTitle: boolean;
  comparingValue?: number;
};

function Rate({ showTitle, type, rate, comparingValue }: Props) {
  const calculatedValue = comparingValue
    ? +rate.replace(/\s/g, '') - comparingValue
    : null;
  const isCalculatedValueNegative = calculatedValue
    ? Math.sign(calculatedValue) === -1
    : false;
  const color = isCalculatedValueNegative ? 'red' : 'green';
  return (
    <Container>
      {showTitle && <Title>{TYPES_MAP[type]}</Title>}
      <StyledRate>{rate}</StyledRate>
      {comparingValue && (
        <span
          style={{
            color,
          }}
        >
          {!isCalculatedValueNegative && '+'}
          {calculatedValue && numberWithSpaces(calculatedValue)}
        </span>
      )}
    </Container>
  );
}

Rate.defaultProps = {
  comparingValue: null,
};

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: end;
  width: 110px;
  height: 70px;
  border-radius: 2px;
  background: rgb(240, 240, 240);
  padding: 7px;
`;

const Title = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000;
  flex: 1;
`;

const StyledRate = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000;
`;

export default Rate;
