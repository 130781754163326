import { put, takeLatest } from 'redux-saga/effects';
import { dateModuleActions } from './index';
import { graphicDataModuleActions } from '../graphicDataModule';
import { rateDataModuleActions } from '../ratesModule';
import { collectFlowSaga } from '../collectFlow/collectFlow.saga';

export function* setGroupBySaga() {
  yield collectFlowSaga();
}

function* setDateRange({ payload: { needGraphicFetch } }) {
  yield collectFlowSaga();
}

export function* dateModuleSaga() {
  yield takeLatest(dateModuleActions.setGroupBy, setGroupBySaga);
  yield takeLatest(dateModuleActions.setDateRange, setDateRange);
}
