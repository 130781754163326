import React, { FC, memo } from 'react';
import {
  Card,
  Col,
  Divider,
  PageHeader,
  Row,
  Space,
  Statistic,
  Typography,
} from 'antd';
import Graphic from '../../../components/app/Graphic';
import styled from 'styled-components';
import { IEntityInfo } from '../../../state/entityFetchModule/types';
import { IStatObject } from '../../../state/listStatistics/types';
import { useSelector } from 'react-redux';
import { getSelectedStatisticsList } from '../../../state/listStatistics/selectors';
import { totalSeriesNames } from '../namesMap';
import { seriesNames, totalSeries } from '../serieses';

interface StatCardProps {
  stat: IStatObject;
}

const StatCard: FC<StatCardProps> = ({ stat }) => {
  const selectedListStatistics = useSelector(getSelectedStatisticsList);

  const getEntityInfoFromId = (id: string): IEntityInfo => {
    const searchedEntity = selectedListStatistics.entities.find(
      entity => `${entity.id}` === id,
    );

    return searchedEntity || ({} as IEntityInfo);
  };

  const types = ['охват', 'подписки', 'активность'];

  return (
    <Card>
      <PageHeader
        style={{ padding: 0, marginBottom: '24px' }}
        title={
          <Typography.Link
            href={`https://vk.com/public${stat.id}`}
            children={getEntityInfoFromId(stat.id).name}
            target="_blank"
          />
        }
      />
      <Row gutter={[16, 0]}>
        {types.map((type, index) => (
          <Col span={8}>
            <GraphicStatTitle>
              <Typography.Title type={'secondary'} level={4}>
                {type[0].toUpperCase() + type.slice(1)}
              </Typography.Title>
              <Space split={<Divider type="vertical" />}>
                {totalSeries[index].map(ser => {
                  switch (ser) {
                    case 'subscribedTotal':
                      return (
                        <Statistic
                          title={totalSeriesNames[ser]}
                          value={stat[ser]}
                          valueStyle={{ fontSize: '18px', textAlign: 'end' }}
                        />
                      );
                    case 'unsubscribedTotal':
                      return (
                        <Statistic
                          title={totalSeriesNames[ser]}
                          value={stat[ser]}
                          valueStyle={{ fontSize: '18px', textAlign: 'end' }}
                        />
                      );
                    case 'subscribedDifference':
                      return (
                        <Statistic
                          title={totalSeriesNames[ser]}
                          value={stat[ser].toString().replace('-', '')}
                          valueStyle={{
                            color:
                              Math.sign(stat[ser]) === -1
                                ? '#cf1322'
                                : '#3f8600',
                            fontSize: '18px',
                            textAlign: 'end',
                          }}
                          prefix={Math.sign(stat[ser]) === -1 ? '-' : '+'}
                        />
                      );
                    default:
                      return (
                        <Statistic
                          title={totalSeriesNames[ser]}
                          value={stat[ser]}
                          valueStyle={{ fontSize: '18px', textAlign: 'end' }}
                        />
                      );
                  }
                })}
              </Space>
            </GraphicStatTitle>
            <Graphic
              height={'230px'}
              seriesNames={seriesNames[index]}
              id={'idListStatistics'}
              key={type}
              type={type}
              graphicData={[...stat[type]]}
            />
          </Col>
        ))}
      </Row>
    </Card>
  );
};

const GraphicStatTitle = styled.div`
  margin-top: 5px;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export default memo(StatCard);
