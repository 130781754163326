import React from 'react';
import { message, Modal } from 'antd';
import { AUTH_TYPE_VK } from '../../../constants/auth.constants';
import routes from '../../../../configs/app.routes';
import FirestoreListService from '../../../firebase/firestore.list.service';
import { Dispatch } from '@reduxjs/toolkit';
import { listsModuleActions } from '../../../state/listsModule';
import { IList } from '../../../state/listsModule/types';
import FirebaseAccountService from '../../../firebase/firebase.user.service';

const ListStatisticsModal = (list: IList, dispatch: Dispatch) => {
  const getStatsToken = async () => {
    const permissions: string[] = ['stats', 'groups', 'wall', 'offline'];

    const url = `https://oauth.vk.com/authorize?client_id=7803698&display=popup&redirect_uri=${
      window.location.origin
    }${
      routes.app._groupLists
    }?permission_request=1&scope=${permissions.join()}&response_type=token&v=5.131`;

    window.open(url, 'popup');

    localStorage.setItem('application', `${AUTH_TYPE_VK}`);

    const updatedLists = await FirestoreListService.enableExtendedStatistics(
      list.id,
    );
    await FirebaseAccountService.updateAccountScope(
      list.linkedAccount.userId,
      permissions,
    );

    dispatch(listsModuleActions.setUserListsOfEntities(updatedLists));

    message.success(
      'Расширенная статистика для списка Название Списка успешно подключена.',
    );
  };

  return Modal.confirm({
    title: 'Подключение расширенной статистики ',
    content: (
      <div>
        <p>
          Для подключения расширенной статистики из управляемых сообществ
          необходимо предоставить дополнительный доступ приложению Хиталама.
        </p>
        <p>
          Расширенная статистика <b>работает только для сообществ</b>, которые
          находятся у вас под управлением
        </p>
      </div>
    ),
    async onOk() {
      await getStatsToken();
    },
    cancelText: 'Закрыть',
    okText: 'Предоставить права',
  });
};

export default ListStatisticsModal;
