import React, { FC } from 'react';
import { Button, Select, Space, Typography } from 'antd';
import { IIntervalKeys } from './types';
import { listStatisticsSliceActions } from '../../../state/listStatistics';
import FirestoreListService from '../../../firebase/firestore.list.service';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getListStatLoad } from '../../../state/listStatistics/selectors';
import { IList } from '../../../state/listsModule/types';

interface StatCardActionsProps {
  interval: IIntervalKeys;
  intervalsCount: number;
  setInterval: (interval: IIntervalKeys) => void;
  setIntervalsCount: (intervalCount: number) => void;
  list: IList;
}

const StatCardActions: FC<StatCardActionsProps> = ({
  interval,
  intervalsCount,
  setInterval,
  setIntervalsCount,
  list,
}) => {
  const counts = [7, 30, 31, 60, 90, 100];
  const daysKeys = {
    day: 'день',
    week: 'неделя',
    month: 'месяц',
    year: 'год',
    all: 'все',
  };

  const statLoad = useSelector(getListStatLoad);

  const dispatch = useDispatch();

  const handleUpdateStatistics = async () => {
    dispatch(
      listStatisticsSliceActions.setListStatisticsRequest({
        selectedList: list,
        interval,
        intervalsCount,
      }),
    );
  };

  return (
    <Space size={'large'}>
      <Space>
        <Typography.Text strong children="Количество дней:" />
        <Select
          onChange={value => setIntervalsCount(value)}
          defaultValue={intervalsCount}
          style={{ width: 120 }}
        >
          {counts.map(count => (
            <Select.Option key={count} value={count}>
              {count}
            </Select.Option>
          ))}
        </Select>
      </Space>
      <Space>
        <Typography.Text strong children="Интервал:" />
        <Select
          onChange={value => setInterval(value)}
          defaultValue={interval}
          style={{ width: 120 }}
        >
          {Object.keys(daysKeys).map((key, index) => (
            <Select.Option key={index} value={key}>
              {daysKeys[key]}
            </Select.Option>
          ))}
        </Select>
      </Space>
      <Button
        onClick={handleUpdateStatistics}
        loading={statLoad}
        type={'primary'}
        children={'Обновить'}
      />
    </Space>
  );
};

export default StatCardActions;
