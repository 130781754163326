/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from '@reduxjs/toolkit';

import { InjectedReducersType } from 'utils/types/injector-typings';

import { authModuleReducer } from '../app/state/userAuthModule';
import { initModuleReducer } from '../app/state/initModule';
import { socialAccountsModuleReducers } from '../app/state/socialAccountsModule';
import { entityModuleReducers } from '../app/state/entityFetchModule';
import { listsModuleReducers } from '../app/state/listsModule';
import { graphicDataModuleReducers } from '../app/state/graphicDataModule';
import { dateModuleReducers } from '../app/state/dateModule';
import { searchDataModuleReducers } from '../app/state/searchModule';
import { rateDataModuleReducers } from '../app/state/ratesModule';
import { groupDataModuleReducers } from '../app/state/groupDataModule';
import { compareDataModuleReducers } from '../app/state/compareDataModule';
import { loadingStepsModuleReducers } from '../app/state/loadingStepsModule';
import { groupCoversDataModuleReducers } from '../app/state/groupCoversDataModule';
import { siteStatisticsReducers } from '../app/state/siteStatisticsModule';
import { listStatisticsReducers } from '../app/state/listStatistics';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export function createReducer(injectedReducers: InjectedReducersType = {}) {
  // Initially we don't have any injectedReducers, so returning identity function to avoid the error
  return combineReducers({
    ...injectedReducers,
    user: authModuleReducer,
    initModule: initModuleReducer,
    socialAccounts: socialAccountsModuleReducers,
    entities: entityModuleReducers,
    lists: listsModuleReducers,
    graphicData: graphicDataModuleReducers,
    dates: dateModuleReducers,
    searchData: searchDataModuleReducers,
    ratesData: rateDataModuleReducers,
    groupData: groupDataModuleReducers,
    groupCoversData: groupCoversDataModuleReducers,
    compareData: compareDataModuleReducers,
    loadingSteps: loadingStepsModuleReducers,
    siteStatistics: siteStatisticsReducers,
    listStatistics: listStatisticsReducers,
  });
}
