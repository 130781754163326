import React, { useEffect, memo } from 'react';

import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4themesAnimated from '@amcharts/amcharts4/themes/animated';

import {
  GRAPHIC_TITLE,
  LIST_STATISTICS_GRAPHIC_TITLE,
  SITE_GRAPHIC_TITLE,
} from '../../../constants/graphic.constants';
import styled from 'styled-components';
import { nanoid } from 'nanoid';

am4core.useTheme(am4themesAnimated);

type Props = {
  seriesNames: string[];
  type: string;
  graphicData;
  id: string;
  height?: string;
};

function Graphic({ id, type, graphicData, seriesNames, height }: Props) {
  const chartId = `chart-${type}-${nanoid()}`;

  const titleMap = (type, id) => {
    switch (id) {
      case 'idHitalamaSiteStatistics':
        return SITE_GRAPHIC_TITLE[type];
      case 'idListStatistics':
        return LIST_STATISTICS_GRAPHIC_TITLE[type];
      default:
        return GRAPHIC_TITLE[type];
    }
  };

  useEffect(() => {
    const chart = am4core.create(chartId, am4charts.XYChart);

    if (process.env.REACT_APP_DEV === 'true') {
      console.log('creating chart with type: ', type);
    }
    chart.colors.list = [
      am4core.color('#007ab1'),
      am4core.color('#587bc6'),
      am4core.color('#9876cc'),
      am4core.color('#d16cbe'),
      am4core.color('#fc64a0'),
      am4core.color('#ff6b76'),
      am4core.color('#ff8448'),
      am4core.color('#ffa600'),
    ];
    chart.data = graphicData.sort((a, b) => a.date - b.date);
    chart.dateFormatter.inputDateFormat = 'yyyy-MM-dd';
    chart.seriesContainer.draggable = false;
    chart.seriesContainer.resizable = false;
    // Create axes
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.baseInterval = {
      timeUnit: 'day',
      count: 1,
    };
    dateAxis.start = 0.79;
    dateAxis.keepSelection = false;

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;

    // Create series
    function createSeries(field, name) {
      const series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = field;
      series.dataFields.dateX = 'date';
      series.name = name;
      series.tooltipText = '{name} {dateX}: [b]{valueY}[/]';
      series.strokeWidth = 2;
      series.minBulletDistance = 15;
      const bullet = series.bullets.push(new am4charts.CircleBullet());
      bullet.circle.stroke = am4core.color('#fff');
      bullet.circle.strokeWidth = 2;
      const bullethover = bullet.states.create('hover');
      bullethover.properties.scale = 1.3;
      return series;
    }

    const seriesArr = seriesNames.map(seriesName =>
      createSeries(seriesName, seriesName),
    );

    chart.legend = new am4charts.Legend();
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = 'none';
    chart.cursor.xAxis = dateAxis;
    chart.cursor.snapToSeries = seriesArr;

    return () => {
      chart.dispose();
    };
  }, [id, graphicData, seriesNames, type]);

  return (
    <Container>
      {id !== 'idListStatistics' && (
        <GraphicTitle>{titleMap(type, id)}</GraphicTitle>
      )}
      <div
        style={{
          width: '100%',
          height: height || '500px',
        }}
        id={chartId}
      />
    </Container>
  );
}

const Container = styled.div`
  margin-top: 5px;
`;

const GraphicTitle = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #262626;
`;

export default memo(Graphic);
