import { Avatar, message, Space, Typography } from 'antd';

import anonIcon from '../../../../styles/assets/svg/anonymousIcon.svg';
import { CopyOutlined, DownOutlined, UserOutlined } from '@ant-design/icons';
import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { UserState } from '../../../state/userAuthModule/types';

interface IProfile {
  expand: boolean;
  user: UserState;
}

const Profile: FC<IProfile> = ({ expand, user }) => {
  const handleCopyToClipboard = (textToCopy: string) => {
    navigator.clipboard.writeText(textToCopy);
    message.info('ID пользователя cкопирован');
  };

  return (
    <StyledCompactProfile>
      {expand ? (
        <div className="compact-profile-expand">
          {user?.isAnonymous ? (
            <img
              style={{ height: 40, marginRight: 10 }}
              src={anonIcon}
              alt="anon-user"
            />
          ) : (
            <ProfileAvatar
              expand={expand}
              className="profile-avatar"
              size="large"
              icon={<UserOutlined />}
              alt={user?.displayName}
              style={{ marginRight: 10 }}
            />
          )}

          <div className="compact-profile-expand-actions">
            <Typography.Text
              style={{ color: '#1890ff' }}
              children={
                user?.isAnonymous
                  ? 'Анонимный пользователь'
                  : user?.displayName || user?.email
              }
            />
            <Space>
              <Typography.Text type="secondary" children={user.userId} />
              <CopyOutlined
                onClick={() => handleCopyToClipboard(`${user.userId}`)}
                style={{ cursor: 'pointer' }}
              />
            </Space>
          </div>
        </div>
      ) : (
        <>
          <Typography.Text
            style={{ marginRight: '10px' }}
            strong
            children={
              user?.isAnonymous
                ? 'Анонимный пользователь'
                : user?.displayName || user?.email
            }
          />
          {user?.isAnonymous ? (
            <img
              style={{ height: 40, marginRight: 10 }}
              src={anonIcon}
              alt="anon-user"
            />
          ) : (
            <ProfileAvatar
              expand={expand}
              className="profile-avatar"
              icon={<UserOutlined />}
            />
          )}
          <DownOutlined style={{ marginLeft: '10px' }} />
        </>
      )}
    </StyledCompactProfile>
  );
};

export default Profile;

const StyledCompactProfile = styled.div`
  display: flex;
  align-items: center;

  .compact-profile-expand {
    display: flex;
    align-items: center;

    .compact-profile-expand-actions {
      display: flex;
      flex-direction: column;
    }
  }
`;

const ProfileAvatar = styled(Avatar)<{ expand: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
`;
