import React, { FC } from 'react';
import { Button, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

interface Props {
  value: string;
}

const CopyToClipboard: FC<Props> = ({ value }) => {
  const copy = async () => {
    await navigator.clipboard.writeText(`${value}`);
    message.info('ID списка скопировано в буффер обмена');
  };

  return (
    <Button
      onClick={copy}
      size="small"
      type="text"
      children={`id: ${value}`}
      icon={<CopyOutlined />}
    />
  );
};

export default CopyToClipboard;
