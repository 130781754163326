import { put, call, takeLatest } from 'redux-saga/effects';
import { groupCoversSliceActions } from './index';
import { getGroupCoversHandler } from '../../services/client/vk/handlers';

export function* fetchGroupCoversWorker(action) {
  const { selectedList } = action.payload;

  try {
    const getVkGroupsIds = () => {
      const listsOfGroupsIds: any = selectedList.entities.map(item => item.id);
      return [].concat
        .apply([], listsOfGroupsIds)
        .filter(el => typeof el === 'number');
    };

    const response = yield call(getGroupCoversHandler, getVkGroupsIds());

    yield put(
      groupCoversSliceActions.setGroupCoversSuccess({
        data: Object.values(response),
        title: selectedList.name,
      }),
    );
  } catch (ERR) {
    yield put(groupCoversSliceActions.setGroupCoversFailure(ERR));
  }
}

export function* groupCoversSliceSaga() {
  yield takeLatest(
    groupCoversSliceActions.setGroupCoversRequest,
    fetchGroupCoversWorker,
  );
}
