import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './index';
import { RootState } from '../../../types';

const selectSlice = (state: RootState) => state.ratesData || initialState;

export const getTotalRates = createSelector(
  [selectSlice],
  state => state.totalRates,
);

export const getRatesCalculateProgress = createSelector(
  [selectSlice],
  state => state.progress,
);

export const getAdditionalTotalRatesTypes = createSelector(
  [selectSlice],
  state => state.additionalTotalRatesTypes,
);
