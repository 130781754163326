import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './index';
import { RootState } from '../../../types';
import { IList } from './types';

const selectSlice = (state: RootState) => state.lists || initialState;

export const getLists = createSelector([selectSlice], state => state.lists);

export const getSelectedList = createSelector([selectSlice], state => {
  return state.selectedList;
});

export const getListsIsLoad = createSelector(
  [selectSlice],
  state => state.isLoad,
);
