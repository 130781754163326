import styled from 'styled-components/macro';

import { Button, Divider, Space, Typography, Row, Col } from 'antd';
import { AUTH_TYPE_OK, AUTH_TYPE_VK } from '../../../constants/auth.constants';
import { getAuthUrl } from '../../../helpers/auth.helper';
import { useDispatch, useSelector } from 'react-redux';
import { initModuleActions } from '../../../state/initModule';
import { getUser } from '../../../state/userAuthModule/selectors';
import AnonWarningModal from '../../common/Modals/AnonWarningModal';
import * as services from '../../../services/client';
import { getUserAccounts } from '../../../state/socialAccountsModule/selector';
import { useNavigate } from 'react-router-dom';
import { SourceLogs } from 'app/services/amplitude/amplitude';

export function AppAuth() {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const accounts = useSelector(getUserAccounts);

  const navigate = useNavigate();

  const ifBeta = user?.tariffPlan === 'beta';

  return (
    <Content>
      <ContentHeader>
        <Typography.Title level={2}>
          Добавить аккаунт или источник
        </Typography.Title>
      </ContentHeader>
      <Row>
        <Col span={12}>
          <Space direction="vertical">
            <Typography.Text>
              Для сбора постов из социальных медиа вам нужно:
            </Typography.Text>
            <ul>
              <li>
                авторизоваться в социальной сети ВКонтакте{' '}
                {ifBeta && 'или Одноклассники'} и предоставить нашему приложению
                доступ.
              </li>
              {ifBeta && (
                <li>
                  подключить Анализ-сайтов как источник данных (авторизация не
                  требуется).
                </li>
              )}
            </ul>
            <Divider />
            <Space direction={'vertical'} size={'large'}>
              <Space direction="vertical">
                <span>Авторизоваться в: </span>
                <Space>
                  <a
                    rel="noreferrer"
                    target="_self"
                    href={getAuthUrl(`${AUTH_TYPE_VK}`, [])}
                  >
                    <Button
                      id="auth-button-vk"
                      type="primary"
                      children="Вконтакте"
                      style={{ width: '170px' }}
                      onClick={() =>
                        SourceLogs.sourceEvent('click add source', {
                          type: 'vk_profile',
                        })
                      }
                    />
                  </a>
                  {ifBeta && (
                    <a
                      rel="noreferrer"
                      target="_self"
                      href={getAuthUrl(`${AUTH_TYPE_OK}`, [])}
                    >
                      <Button
                        id="auth-button-ok"
                        type="primary"
                        style={{ width: '170px' }}
                        onClick={() =>
                          SourceLogs.sourceEvent('click add source', {
                            type: 'ok_profile',
                          })
                        }
                      >
                        Одноклассники
                      </Button>
                    </a>
                  )}
                </Space>
              </Space>
              {ifBeta && (
                <Space direction="vertical">
                  <span>Добавить:</span>
                  <Space>
                    <Button
                      onClick={() => {
                        user.isAnonymous
                          ? AnonWarningModal(navigate)
                          : services
                              .addTelegramService(2, 'telegramAnalytics')
                              .then(() => {
                                dispatch(initModuleActions.init({}));
                              });
                      }}
                      children="Telegram-аналитика"
                      type="primary"
                      style={{ width: '170px' }}
                      disabled={
                        !!Object.keys(accounts).find(
                          key => accounts[key].social === 2,
                        )
                      }
                    />
                    <Button
                      onClick={() => {
                        user?.isAnonymous
                          ? AnonWarningModal(navigate)
                          : services
                              .addSiteStatisticsService(
                                3,
                                'hitalamaSiteStatistics',
                              )
                              .then(() => {
                                dispatch(initModuleActions.init({}));
                              });
                      }}
                      children="Анализ сайтов"
                      type="primary"
                      style={{ width: '170px' }}
                      disabled={
                        !!Object.keys(accounts).find(
                          key => accounts[key].social === 3,
                        )
                      }
                    />
                  </Space>
                </Space>
              )}
            </Space>
          </Space>
        </Col>
        <Col span={12}></Col>
      </Row>
    </Content>
  );
}

const Content = styled.div`
  padding: 35px;
`;

const ContentHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 25px;
`;

const ContentBody = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentDesc = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #595959;
  margin-top: 25px;
`;

const SocialStyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 162px;
  height: 32px;
  cursor: pointer;
`;
