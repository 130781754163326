import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Alert, Button, Checkbox, Form, Input, PageHeader, Space } from 'antd';
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  LockOutlined,
  MailOutlined,
} from '@ant-design/icons';
import routes from '../../../configs/app.routes';
import { useDispatch, useSelector } from 'react-redux';
import { initModuleActions } from '../../state/initModule';
import { UserTypeEnum } from '../../constants/enums';
import { getUser } from '../../state/userAuthModule/selectors';
import { getLoginError } from '../../state/initModule/selectors';

const { Item } = Form;

export function Login() {
  const dispatch = useDispatch();
  const [type, setType] = useState<UserTypeEnum>(0);
  const error = useSelector(getLoginError);
  const { isLoggedIn } = useSelector(getUser);
  let navigate = useNavigate();
  let location = useLocation();
  let from = location.state?.from?.pathname || '/';

  const onFinish = (values: any) => {
    dispatch(initModuleActions.login({ ...values, loginType: type }));
  };

  useEffect(() => {
    return isLoggedIn ? navigate(from) : navigate(routes.login);
  }, [from, isLoggedIn, navigate, error]);

  return (
    <>
      <PageHeader
        style={{ padding: 0, marginBottom: '15px' }}
        title="Вход в Хиталаму"
      />
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Space direction="vertical">
          <Item
            name="username"
            rules={[
              {
                required: type === UserTypeEnum.Login,
                message: 'Не введён адрес электронной почты.',
              },
            ]}
          >
            <Input
              prefix={<MailOutlined />}
              placeholder="Введите адрес электронной почты."
            />
          </Item>
          <Item
            name="password"
            rules={[
              {
                required: type === UserTypeEnum.Login,
                message: 'Введите свой пароль.',
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              type="password"
              placeholder="Пароль"
              iconRender={visible =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Item>
          {error.isError && (
            <Alert
              closable
              type="error"
              message={
                error.error.code === 'auth/too-many-requests'
                  ? 'Слишком много попыток, попробуйте позже'
                  : 'Неверный E-mail или пароль'
              }
            />
          )}
          <Item>
            <Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Запомнить меня</Checkbox>
            </Item>
            <Link to={routes.recoverPassword}>Забыли пароль?</Link>
          </Item>
        </Space>
        <Item>
          <Space>
            <Button
              style={{ marginRight: '10px' }}
              type="primary"
              htmlType="submit"
              className="login-form-button"
              onClick={() => setType(UserTypeEnum.Login)}
            >
              Войти
            </Button>
            <span>или</span>
            <Link to={routes.signUp}>Зарегистрироваться</Link>
          </Space>
        </Item>
        <Item style={{ marginBottom: 0 }}>
          <Button
            htmlType="submit"
            style={{ width: '100%' }}
            onClick={() => setType(UserTypeEnum.Anonymous)}
          >
            Войти без регистрации
          </Button>
        </Item>
      </Form>
    </>
  );
}

export default Login;
