import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import {
  getImageFromEntity,
  numberWithSpaces,
} from '../../../helpers/auth.helper';
import Rate from '../Rate';
import {
  HIDDEN_INTERFACE_RATES,
  TYPES_ARRAY,
} from '../../../constants/rates.constants';
import {
  getRatesCalculateProgress,
  getTotalRates,
} from '../../../state/ratesModule/selectors';
import { sortedTableColumns } from '../../../helpers/data.helpers';
import { generateSocialLink } from '../../../helpers/link.helpers';
import { Button, Space, Spin, Typography } from 'antd';
import ExportTotalRatesExcel from '../Statistics/ExportTotalRatesExcel';
import TableRates from '../Statistics/TableRates';
import { TotalRatesLogs } from '../../../services/amplitude/amplitude';

type Props = {
  isActive?: boolean;
  entitiesMap;
};

function Rates({ entitiesMap, isActive }: Props) {
  const totalRates = useSelector(getTotalRates);
  const [changeView, setChangeView] = useState(true);

  const totalRateKeys = useMemo(() => totalRates && Object.keys(totalRates), [
    totalRates,
  ]);

  const ratesCalculateStatus = useSelector(getRatesCalculateProgress);

  const handleChangeView = () => {
    setChangeView(!changeView);
    TotalRatesLogs.changeView('click change view', {
      type: changeView ? 'grid' : 'table',
    });
  };

  return (
    <>
      {isActive && (
        <Container style={{ marginBottom: '30px' }}>
          <Spin
            spinning={ratesCalculateStatus}
            tip={'Идёт расчет сводных показателей...'}
          >
            <Space align={'center'}>
              <Typography.Title style={{ marginBottom: 0 }} level={5}>
                Сводные показатели
              </Typography.Title>
              <ExportTotalRatesExcel />
              <Button type="link" onClick={handleChangeView}>
                <span style={{ fontWeight: changeView ? 'bold' : 'unset' }}>
                  Сетка
                </span>
                <span>|</span>
                <span style={{ fontWeight: !changeView ? 'bold' : 'unset' }}>
                  Таблица
                </span>
              </Button>
            </Space>
            {changeView ? (
              <>
                <Row isFirst>
                  {TYPES_ARRAY.map(type => (
                    <Title key={type}>{type}</Title>
                  ))}
                </Row>
                {totalRateKeys &&
                  totalRateKeys.map((ownerId, index) => {
                    if (process.env.REACT_APP_DEV === 'true') {
                      console.log(totalRateKeys, 'tot');
                    }
                    const showTitle = index === 0 && false;

                    const entity = entitiesMap[ownerId];
                    if (!entity) return null;
                    const {
                      members_count: membersCount,
                      followers_count: followersCount,
                    } = entity;
                    const entityImg = getImageFromEntity(entity);
                    const entityMembersCount =
                      membersCount || followersCount
                        ? numberWithSpaces(membersCount || followersCount)
                        : '------';
                    const columns = sortedTableColumns(
                      Object.keys(totalRates[ownerId]),
                    );

                    return (
                      <Row key={ownerId}>
                        <a
                          rel="noreferrer"
                          href={generateSocialLink(entity)[entity.app || 0]}
                          target="_blank"
                        >
                          <Image
                            src={entityImg}
                            alt={entity.name}
                            title={entity.name}
                          />
                        </a>
                        <Rate
                          showTitle={showTitle}
                          key={`${ownerId}-membersCount`}
                          type="membersCount"
                          rate={entityMembersCount}
                        />
                        {columns.map(type => {
                          return HIDDEN_INTERFACE_RATES.includes(
                            type,
                          ) ? null : (
                            <>
                              <Rate
                                showTitle={showTitle}
                                key={`${ownerId}-${type}`}
                                type={type}
                                rate={totalRates[ownerId][type]}
                              />
                            </>
                          );
                        })}
                      </Row>
                    );
                  })}
              </>
            ) : (
              <TableRates totalRates={totalRates} />
            )}
          </Spin>
        </Container>
      )}
    </>
  );
}

const Title: any = styled.span`
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  line-height: 24px;
  color: #000;
  flex: 1;
  width: 243px;
  text-align: right;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
`;

const Row: any = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  ${(props: any) =>
    props.isFirst &&
    `
      padding-left: 70px;
      position: sticky;
      width: 100%;
      top: 0;
    `}
`;

const Image = styled.img`
  width: 70px;
  height: 70px;
`;

export default Rates;
