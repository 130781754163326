import React from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getSiteStatisticsFilteredGraphicData } from '../../../state/siteStatisticsModule/selectors';

const ExportCSV = ({ csvData, selectedList }) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const dateToExport: any = [];
  const filterGraphicsData = useSelector(getSiteStatisticsFilteredGraphicData);

  filterGraphicsData.length > 0
    ? filterGraphicsData.forEach(item => {
        dateToExport.push({
          [`Список`]: selectedList.name,
          [`Сайт`]: Object.keys(item)[0],
          [`Дата`]: new Date(item.date).toLocaleDateString('ru-RU'),
          [`Визиты`]: item[Object.keys(item)[0]],
        });
      })
    : csvData.forEach(item => {
        dateToExport.push({
          [`Список`]: selectedList.name,
          [`Сайт`]: Object.keys(item)[0] || '',
          [`Дата`]: new Date(item.date).toLocaleDateString('ru-RU'),
          [`Визиты`]: item[Object.keys(item)[0]],
        });
      });

  const exportToCSV = (dateToExport, fileName) => {
    const ws = XLSX.utils.json_to_sheet(dateToExport);
    const wb = {
      Sheets: { data: ws },
      SheetNames: ['data'],
    };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  return (
    <Button
      type="primary"
      onClick={() =>
        exportToCSV(
          dateToExport,
          `${selectedList.name}_${moment().format('D-MM-yyyy_HH:mm:ss')}`,
        )
      }
      icon={<DownloadOutlined />}
    >
      Скачать Excel
    </Button>
  );
};
export default ExportCSV;
