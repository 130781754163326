export const ERRORS = {
  NO_USER_ID: { code: 'No user id!', message: 'No user id!' },
  NO_GROUP_ID: { code: 'No group id!', message: 'No group id!' },
  NO_USER: { code: 'No user!', message: 'No user!' },
  PERMISSION_DENIED: {
    code: 'PERMISSION_DENIED!',
    message: 'Недостаточно прав для выполнения данной операции.',
  },
};

export interface Group {
  id: string;
  name: string;
}

export enum ListRoles {
  OWNER = 'owner', // read, edit, delete
  EDITOR = 'editor', // read, edit
  READER = 'reader', // read
}

export interface List {
  name: string;
  created: number;
  updated: number;
  application: string;
  roles: {
    [key: string]: ListRoles;
  };
  groups: Group[];
  owner?: string;
}
