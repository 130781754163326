import React, { FC, useEffect, useState } from 'react';
import { Form, Input, Modal } from 'antd';
import firebase from 'firebase/app';
import { useDispatch, useSelector } from 'react-redux';
import FirebaseAccountService from '../../../firebase/firebase.user.service';
import { getUser } from '../../../state/userAuthModule/selectors';
import { initModuleActions as actions } from '../../../state/initModule';

interface IProfileEditModalProps {
  isModalVisible: boolean;
  handleCancel: () => void;
  setEditProfileVisible: any;
}

const ProfileEditModal: FC<IProfileEditModalProps> = ({
  isModalVisible,
  handleCancel,
  setEditProfileVisible,
}) => {
  const user = useSelector(getUser);
  const [loading, setLoading] = useState(false);
  const [displayName, setDisplayName] = useState('');

  const dispatch = useDispatch();

  const handleUpdate = () => {
    setLoading(true);

    FirebaseAccountService.updateUserProfile(displayName, '').then(async () => {
      const firebaseUser: any = firebase.auth().currentUser;
      const additionalUserInfo = await FirebaseAccountService.getUserAdditionalInfo();

      dispatch(
        actions.initialize({
          user: {
            email: firebaseUser.email,
            emailVerified: firebaseUser.emailVerified,
            displayName: firebaseUser.displayName,
            lastSignInTime: firebaseUser.metadata.lastSignInTime,
            userId: firebaseUser.uid,
            isLoggedIn: true,
            isAnon: firebaseUser.isAnonymous,
            photoURL: firebaseUser.photoURL,
            ...additionalUserInfo,
          },
        }),
      );

      setEditProfileVisible(false);
      setLoading(false);
    });
  };

  useEffect(() => {
    setDisplayName(user.displayName);
  }, [user.displayName]);

  return (
    <Modal
      title="Редактирование профиля"
      visible={isModalVisible}
      onOk={handleUpdate}
      onCancel={handleCancel}
      confirmLoading={loading}
      okText="Обновить"
      cancelText="Отмена"
    >
      <Form layout="vertical">
        <Form.Item name="displayName" label="Отображаемое имя">
          <Input
            value={displayName}
            onChange={e => setDisplayName(e.target.value)}
            defaultValue={displayName}
            placeholder="Введите отображаемое имя"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ProfileEditModal;
