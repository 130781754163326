import FirebaseUtils from './firebase.utils';
import { ERRORS } from './firebase.types';
import { message } from 'antd';
import app from '../../fbConfig';
import { IUser } from '../state/socialAccountsModule/types';
import { IAdditionalUserInfo } from '../state/userAuthModule/types';

export default class FirebaseAccountService {
  public static login(email: string, password: string): Promise<any> {
    return app
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => app.auth().signInWithEmailAndPassword(email, password))
      .then(userCredential => {
        return app
          .firestore()
          .collection('users')
          .doc(userCredential.user?.uid)
          .get()
          .then(doc => {
            if (doc.exists) {
              return { ...userCredential.user, ...doc.data() };
            }
          });
      })
      .catch(error => FirebaseUtils.handleError(error));
  }

  public static async anonymousLogin(): Promise<any> {
    try {
      const userCred = await app.auth().signInAnonymously();

      if (!userCred.user) {
        return Promise.reject('no_user');
      }

      const { isAnonymous, uid } = userCred.user;

      const userAdditionalInfo = await FirebaseAccountService.updateUserAdditionalInfo(
        {
          storeTokens: false,
          isAnonymous,
          uid,
        },
      );

      return { ...userCred.user, ...userAdditionalInfo };
    } catch (e) {
      console.log(e);
    }
  }

  public static async register(email: string, password: string): Promise<any> {
    return app
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(async cred => {
        if (!cred.user) {
          return Promise.reject('no user');
        }

        const { isAnonymous, uid } = cred.user;

        await this.updateUserAdditionalInfo({
          storeTokens: false,
          isAnonymous,
          uid,
        });
        await FirebaseAccountService.sendVerificationEmail();

        return { ...cred.user, ...cred.additionalUserInfo };
      })
      .catch(error => FirebaseUtils.handleError(error));
  }

  public static anonymousLink(credential) {
    // [START auth_anonymous_link]
    return app
      .auth()
      .currentUser?.linkWithCredential(credential)
      .then(async usercred => {
        const user = usercred.user;

        if (!user) {
          return Promise.reject('no_user');
        }

        const { isAnonymous, uid } = user;
        await this.updateUserAdditionalInfo({
          storeTokens: false,
          isAnonymous,
          uid,
        });
        await this.sendVerificationEmail();
        return user;
      })
      .catch(error => {
        return error;
      });
    // [END auth_anonymous_link]
  }

  public static getUserAdditionalInfo(): Promise<any> {
    const user = FirebaseUtils.getCurrentUser();

    if (!user) {
      return Promise.reject(ERRORS.NO_USER);
    }

    return app
      .firestore()
      .collection('users')
      .doc(user.uid)
      .get()
      .then(doc => {
        if (doc.exists) {
          return doc.data();
        }
      });
  }

  public static async getUserAdditionalInfoByUid(
    uid: string,
  ): Promise<IAdditionalUserInfo> {
    return app
      .firestore()
      .collection('users')
      .doc(uid)
      .get()
      .then(doc => {
        if (doc.exists) {
          return doc.data() as IAdditionalUserInfo;
        } else {
          return {} as IAdditionalUserInfo;
        }
      });
  }

  public static async updateUserTariff(
    uid: string | number,
    tariff: string,
  ): Promise<IAdditionalUserInfo> {
    try {
      const usersRef = app.firestore().collection('users').doc(`${uid}`);
      await usersRef.update({ tariffPlan: tariff });
      return await this.getUserAdditionalInfoByUid(`${uid}`);
    } catch (e) {
      throw new Error('Ошибка при обновление роли');
    }
  }

  public static updateUserAdditionalInfo({
    storeTokens,
    isAnonymous,
    uid,
  }: {
    storeTokens: boolean;
    isAnonymous: boolean;
    uid: string;
  }): Promise<any> {
    return app
      .firestore()
      .collection('users')
      .doc(uid)
      .set({
        tariffPlan: isAnonymous ? 'anonim' : 'test',
        isAnonymous: isAnonymous,
        storeTokens: storeTokens,
      })
      .then(() => {
        return {
          tariffPlan: isAnonymous ? 'anonim' : 'test',
          isAnonymous: isAnonymous,
          storeTokens: storeTokens,
        };
      })
      .catch(() => {
        return Promise.reject('error');
      });
  }

  public static async linkUserSocialAccount(
    accounts,
  ): Promise<{ status: string }> {
    const user = await FirebaseUtils.getCurrentUser();

    if (!user) {
      return Promise.reject(ERRORS.NO_USER);
    }

    const db = app.firestore();
    const batch = db.batch();

    accounts.forEach(doc => {
      const docRef = db.collection('accounts').doc();

      batch.set(docRef, {
        ...doc,
        ownerId: user.uid,
      });
    });

    await db.collection('users').doc(user.uid).update({ storeTokens: true });

    return batch
      .commit()
      .then(() => ({ status: 'linked' }))
      .catch(() => ({ status: 'linkError' }));
  }

  public static async unlinkUserSocialAccount(): Promise<{ status: string }> {
    const user = await FirebaseUtils.getCurrentUser();

    if (!user) {
      return Promise.reject(ERRORS.NO_USER);
    }

    const db = app.firestore();
    const batch = db.batch();

    await db.collection('users').doc(user.uid).update({ storeTokens: false });

    return db
      .collection('accounts')
      .where('ownerId', '==', user.uid)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          batch.delete(doc.ref);
        });
        return batch.commit().then(() => {
          return {
            status: 'unlinked',
          };
        });
      });
  }

  public static async getLinkedUserAccount(): Promise<any> {
    const user = await FirebaseUtils.getCurrentUser();

    if (!user) {
      return Promise.reject(ERRORS.NO_USER);
    }

    return app
      .firestore()
      .collection('accounts')
      .where('ownerId', '==', user.uid)
      .get()
      .then(querySnapshot => {
        if (querySnapshot.empty) {
          return [];
        } else {
          const accounts: any = [];
          querySnapshot.forEach(doc => {
            accounts.push(doc.data());
          });

          return accounts;
        }
      });
  }

  public static async updateUserProfile(displayName, photoURL): Promise<any> {
    return app
      .auth()
      .currentUser?.updateProfile({
        displayName: displayName,
        photoURL: photoURL,
      })
      .then(async () => {
        const user = await app.auth().currentUser;
        return { displayName: user?.displayName, photoURL: user?.photoURL };
      })
      .catch(error => {
        message.error(error);
      });
  }

  public static async sendVerificationEmail(): Promise<any> {
    const user = await FirebaseUtils.getCurrentUser();

    if (!user) {
      return Promise.reject(ERRORS.NO_USER);
    }

    return user
      .sendEmailVerification()
      .catch(error => FirebaseUtils.handleError(error));
  }

  public static logout(): Promise<any> {
    return app
      .auth()
      .signOut()
      .catch(error => FirebaseUtils.handleError(error));
  }

  public static forgot(email: string): Promise<any> {
    return app
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => ({ success: true }))
      .catch(error => FirebaseUtils.handleError(error));
  }

  public static delete(): Promise<any> {
    const user = FirebaseUtils.getCurrentUser();

    if (!user) {
      return Promise.reject(ERRORS.NO_USER);
    }

    return user.delete().catch(error => FirebaseUtils.handleError(error));
  }

  public static async updateAccountScope(
    userId: string | number | null,
    permissions: string[],
  ) {
    const accountsRef = app.firestore().collection('accounts');

    accountsRef
      .where('userId', '==', userId)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          accountsRef.doc(doc.id).update({
            permissions: permissions,
          });
        });
      })
      .catch(error => {
        console.log('Error getting documents: ', error);
      });
  }

  public static async checkAccountPermissions(
    userId: string | number | null,
    permission: 'stats' | 'wall' | 'groups' | 'offline',
  ): Promise<IUser> {
    const accountsRef = app.firestore().collection('accounts');

    return accountsRef
      .where('userId', '==', `${userId}`)
      .where('permissions', 'array-contains', permission)
      .get()
      .then(querySnapshot => {
        return querySnapshot.empty;
      })
      .catch(reason => {
        return reason;
      });
  }
}
