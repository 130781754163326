import React, { FC } from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { getAppIcon } from '../../../helpers/getAppIcon.helper';
import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import { IList } from '../../../state/listsModule/types';
import { useDispatch, useSelector } from 'react-redux';
import { getLists } from '../../../state/listsModule/selectors';
import { getSelectedApplication } from '../../../state/socialAccountsModule/selector';
import { useNavigate } from 'react-router-dom';
import { siteStatisticsSliceActions } from '../../../state/siteStatisticsModule';

interface StatisticsProps {
  setInputValue: (inputValue: string) => void;
}

const SiteStatisticsMenu: FC<StatisticsProps> = ({ setInputValue }) => {
  const listsOfEntities: IList[] = useSelector(getLists);
  const application = useSelector(getSelectedApplication);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const menuItemClick = entity => {
    dispatch(
      siteStatisticsSliceActions.setSiteStatisticsGraphicsList({
        ...entity,
        type: 'list',
      }),
    );
    setInputValue('');
  };

  const sitesLists = listsOfEntities.map(listOfEntities => {
    if (listOfEntities.application === application) {
      return (
        <Menu.Item
          key={listOfEntities.id}
          onClick={() => menuItemClick(listOfEntities)}
        >
          <span>
            <img
              style={{ height: '20px', marginRight: '10px' }}
              src={getAppIcon()[listOfEntities.application]}
              alt={listOfEntities.name}
            />
            {listOfEntities.name}
          </span>
        </Menu.Item>
      );
    } else {
      return <></>;
    }
  });

  const menu = (
    <Menu>
      {<span>Нет листов</span> && sitesLists}
      <Menu.Item key={0}>
        <Button
          onClick={() => navigate('/app/group-lists/create')}
          type="primary"
          icon={<PlusOutlined />}
        >
          Создать список
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      {menu && (
        <Dropdown overlay={menu} trigger={['click']}>
          <button
            id="select-list"
            style={{
              marginLeft: '10px',
              color: '#1890ff',
              border: 'none',
              background: 'none',
              outline: 'none',
            }}
            onClick={e => e.preventDefault()}
          >
            выберите список <DownOutlined />
          </button>
        </Dropdown>
      )}
    </>
  );
};

export default SiteStatisticsMenu;
