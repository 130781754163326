import axios, { AxiosInstance } from 'axios';

export const instance = (url: string): AxiosInstance => {
  return axios.create({
    baseURL: url,
  });
};

export { default as addTelegramService } from './tg/initial.module';
export { default as addSiteStatisticsService } from './site-analysis/initial.module';
