import * as React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import routes from '../../../configs/app.routes';
import { ISocialAccountStorage } from '../../localStorage/types';

const HomePage = () => {
  const socialAccountsMap: ISocialAccountStorage = JSON.parse(
    localStorage.getItem('usersMap') || '{}',
  );

  const socialAccountsExists = Object.keys(socialAccountsMap).length > 0;

  return socialAccountsExists ? (
    <Navigate to={routes.app.collectAndAnalyze} />
  ) : (
    <Navigate to={routes.app.auth} />
  );
};

export default HomePage;
