import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './index';
import { RootState } from '../../../types';

const selectSlice = (state: RootState) =>
  (state && state.socialAccounts) || initialState;

export const getUserAccounts = createSelector(
  [selectSlice],
  state => state.accounts,
);

export const getUserAccountsArray = createSelector([selectSlice], state =>
  Object.values(state.accounts),
);

export const getSelectedAccount = createSelector(
  [selectSlice],
  state => state.selectedAccount,
);

export const getSelectedApplication = createSelector(
  [selectSlice],
  state => state.application,
);

export const getDisabledAccount = createSelector(
  [selectSlice],
  state => state.disabledAccounts,
);
