import {
  Form,
  Input,
  Checkbox,
  Button,
  message,
  PageHeader,
  Space,
} from 'antd';
import styled from 'styled-components/macro';
import { useEffect, useState } from 'react';
import FirebaseAccountService from '../../firebase/firebase.user.service';
import routes from '../../../configs/app.routes';
import firebase from 'firebase';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../../state/userAuthModule/selectors';
import { UserTypeEnum } from '../../constants/enums';
import { Link, useNavigate } from 'react-router-dom';
import { initModuleActions } from '../../state/initModule';
import { AuthLogs } from 'app/services/amplitude/amplitude';

export function SignUp() {
  const [load, setLoad] = useState<boolean>(false);
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  let navigate = useNavigate();

  const onFinish = async (values: any) => {
    setLoad(true);

    const anonymousLinkHandler = async () => {
      const credential = await firebase.auth.EmailAuthProvider.credential(
        values.email,
        values.password,
      );
      return FirebaseAccountService.anonymousLink(credential);
    };

    const result = user.isAnonymous
      ? await anonymousLinkHandler()
      : await FirebaseAccountService.register(values.email, values.password);

    if (result && !result.isError) {
      AuthLogs.registerEvent('click register', {
        id: result.uid,
        isAnonymous: user.isAnonymous,
      });

      message.info(
        'Письмо для подтверждение адреса электронной успешно отправлено',
      );

      // @ts-ignore
      window.dataLayer = window.dataLayer || [];
      // @ts-ignore
      window.dataLayer.push({
        userId: user.userId,
        authorizationType: 'authorization',
        tariff: user.tariffPlan,
      });

      // @ts-ignore
      window.dataLayer.push({
        event: 'autoEvent',
        eventCategory: 'profile',
        eventAction: 'registration',
        eventLabel: 'full',
        eventValue: undefined,
      });

      dispatch(
        initModuleActions.login({
          remember: true,
          password: values.password,
          username: values.email,
          loginType: UserTypeEnum.Login,
          navigate,
        }),
      );
    } else {
      message.error(result?.error);
    }
    setLoad(false);
  };

  const { isLoggedIn, isAnonymous } = useSelector(getUser);

  useEffect(() => {
    isLoggedIn
      ? isAnonymous
        ? navigate(routes.signUp)
        : navigate(routes._app)
      : navigate(routes.signUp);
  }, [isAnonymous, isLoggedIn, navigate]);

  return (
    <>
      <PageHeader
        style={{ padding: 0, marginBottom: '15px' }}
        title="Регистрация в Хиталаме"
      />
      <StyledForm
        name="register"
        onFinish={onFinish}
        scrollToFirstError
        layout="vertical"
      >
        <Form.Item
          name="email"
          label="Адрес эл. почты"
          rules={[
            {
              type: 'email',
              message: 'Неверный адрес эл-почты!',
            },
            {
              required: true,
              message: 'Пожалуйста, введите ваш E-mail!',
            },
          ]}
        >
          <Input placeholder="Введите адрес эл. почты" />
        </Form.Item>
        <Form.Item
          name="password"
          label="Пароль"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, введите свой пароль!',
            },
            () => ({
              validator(_, value) {
                if (value.length >= 6) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Не менее чем из 6 символов.'));
              },
            }),
          ]}
          hasFeedback
        >
          <Input.Password placeholder="Введите пароль" />
        </Form.Item>
        <Form.Item
          name="confirm"
          label="Подтвердите пароль"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Пожалуйста, подтвердите свой пароль!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Пароли не совпадают.'));
              },
            }),
          ]}
        >
          <Input.Password placeholder="Подтвердите пароль" />
        </Form.Item>
        <Form.Item
          name="agreement"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(new Error('Следует принять соглашение')),
            },
          ]}
        >
          <Checkbox>Я прочитал соглашение.</Checkbox>
        </Form.Item>
        <Form.Item>
          <Space>
            <Button loading={load} htmlType="submit" type="primary">
              Зарегистрироваться
            </Button>
            <span>или</span>
            <Link to={routes.login}>войти</Link>
          </Space>
        </Form.Item>
      </StyledForm>
    </>
  );
}

const StyledForm = styled(Form)`
  min-width: 250px;
`;
