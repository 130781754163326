import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { IRatesState, ITotalRates, TotalRatesPayload } from './types';

export const initialState: IRatesState = {
  totalRates: {} as ITotalRates,
  progress: false,
  additionalTotalRatesTypes: [],
};

const slice = createSlice({
  name: 'ratesData',
  initialState,
  reducers: {
    setTotalRates(state, action: PayloadAction<any>) {
      state.totalRates = action.payload.totalRates;
      return state;
    },
    reset(state, action: PayloadAction<any>) {
      state = initialState;
      return state;
    },
    setCalculateTotalRatesStatus(state, action: PayloadAction<boolean>) {
      state.progress = action.payload;
    },
    calculateTotalRates(state) {
      return state;
    },
    addToAdditionalTotalRatesTypes(
      state,
      action: PayloadAction<{ totalRatesType: string[] }>,
    ) {
      action.payload.totalRatesType.forEach(type => {
        if (!state.additionalTotalRatesTypes.includes(type)) {
          state.additionalTotalRatesTypes.push(type);
        }
      });

      return state;
    },
  },
});

export const {
  actions: rateDataModuleActions,
  reducer: rateDataModuleReducers,
} = slice;
