import * as lf from 'lovefield';

import { DatabaseConnectionState } from '../../../constants/enums';
import { IDatabaseTable } from './dbInterface';
import { DATABASE } from '../../../constants/app.constants';

export default class DatabaseClient {
  static instance: DatabaseClient;

  name: string;

  version: number;

  schema: lf.schema.Builder;

  db!: lf.Database;

  state: DatabaseConnectionState = DatabaseConnectionState.NOT_CONNECTED;

  connectionError: Error | undefined;

  tables: IDatabaseTable = {} as IDatabaseTable;

  private constructor(name: string, version: number) {
    this.name = name;
    this.version = version;
    this.schema = lf.schema.create(this.name, this.version);
    this.initSchema();
  }

  public static async getInstance(): Promise<DatabaseClient> {
    if (!DatabaseClient.instance) {
      DatabaseClient.instance = new DatabaseClient(
        `${DATABASE.NAME}-${DATABASE.VERSION}`,
        DATABASE.VERSION,
      );
      await DatabaseClient.instance.connect();
    }
    return DatabaseClient.instance;
  }

  async connect() {
    try {
      this.state = DatabaseConnectionState.CONNECTING;
      this.db = await this.schema.connect({
        storeType: lf.schema.DataStoreType.INDEXED_DB,
      });
      this.state = DatabaseConnectionState.CONNECTED;
      this.tables.posts = this.db.getSchema().table('posts');
      this.tables.postsReach = this.db.getSchema().table('posts_reach');
    } catch (e) {
      if (process.env.REACT_APP_DEV === 'true') {
        console.log('connection error, error is: ', e);
      }
      this.state = DatabaseConnectionState.FAILED;
      // @ts-ignore
      this.connectionError = e;
    }
  }

  initSchema() {
    this.schema
      .createTable('posts')
      .addColumn('id', lf.Type.STRING)
      .addColumn('link', lf.Type.STRING)
      .addColumn('postId', lf.Type.NUMBER)
      .addColumn('ownerId', lf.Type.NUMBER)
      .addColumn('likesCount', lf.Type.NUMBER)
      .addColumn('repostsCount', lf.Type.NUMBER)
      .addColumn('commentsCount', lf.Type.NUMBER)
      .addColumn('erp', lf.Type.STRING)
      .addColumn('erv', lf.Type.STRING)
      .addColumn('err', lf.Type.STRING)
      .addColumn('viewsCount', lf.Type.NUMBER)
      .addColumn('timestamp', lf.Type.DATE_TIME)
      .addColumn('timestampWeek', lf.Type.DATE_TIME)
      .addColumn('timestampMonth', lf.Type.DATE_TIME)
      .addColumn('timestampStartOfDay', lf.Type.DATE_TIME)
      .addColumn('timestampEndOfDay', lf.Type.DATE_TIME)
      .addIndex('ownerIdIndex', ['ownerId'], false)
      .addIndex('timestampIndex', ['timestamp'], false, lf.Order.DESC)
      .addPrimaryKey(['id'], false);

    this.schema
      .createTable('posts_reach')
      .addColumn('id', lf.Type.STRING)
      .addColumn('postId', lf.Type.STRING)
      .addColumn('ownerId', lf.Type.NUMBER)
      .addColumn('reachSubscribers', lf.Type.NUMBER)
      .addColumn('reachTotal', lf.Type.NUMBER)
      .addColumn('reachAds', lf.Type.NUMBER)
      .addColumn('reachViral', lf.Type.NUMBER)
      .addColumn('toGroup', lf.Type.NUMBER)
      .addColumn('joinGroup', lf.Type.NUMBER)
      .addColumn('unsubscribe', lf.Type.NUMBER)
      .addColumn('links', lf.Type.NUMBER)
      .addColumn('report', lf.Type.NUMBER)
      .addColumn('hide', lf.Type.NUMBER)
      .addColumn('adViews', lf.Type.NUMBER)
      .addColumn('adSubscribers', lf.Type.NUMBER)
      .addColumn('adHide', lf.Type.NUMBER)
      .addColumn('adUnsubscribe', lf.Type.NUMBER)
      .addColumn('adLinks', lf.Type.NUMBER)
      .addColumn('adToGroup', lf.Type.NUMBER)
      .addColumn('adJoinGroup', lf.Type.NUMBER)
      .addColumn('adCoverage', lf.Type.NUMBER)
      .addColumn('adReport', lf.Type.NUMBER)
      .addNullable([
        'reachSubscribers',
        'reachTotal',
        'reachAds',
        'reachViral',
        'toGroup',
        'joinGroup',
        'unsubscribe',
        'links',
        'report',
        'hide',
        'adViews',
        'adSubscribers',
        'adHide',
        'adUnsubscribe',
        'adLinks',
        'adToGroup',
        'adJoinGroup',
        'adCoverage',
        'adReport',
      ])
      .addPrimaryKey(['id'], false);
  }
}
