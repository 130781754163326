import {
  all,
  call,
  cancel,
  delay,
  fork,
  put,
  putResolve,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import XLSX from 'xlsx';
import moment from 'moment';
import { CANCEL_COLLECT_POSTS, entityModuleActions } from './index';
import { searchDataModuleActions } from '../searchModule';
import { groupDataModuleActions } from '../groupDataModule';
import { graphicDataModuleActions } from '../graphicDataModule';
import { listsModuleActions } from '../listsModule';
import { loadingStepsModuleActions } from '../loadingStepsModule';
import { dateModuleActions } from '../dateModule';
import {
  getSelectedAccount,
  getSelectedApplication,
} from '../socialAccountsModule/selector';
import routes from '../../../configs/app.routes';
import {
  App,
  DownloadStatus,
  SearchStatus,
  UserRole,
} from '../../constants/enums';
import { sanitizeEntityStr } from '../../helpers/link.helpers';
import {
  getEntityIdOk,
  getEntityInfoOk,
  getStatTopicsOk,
  getUserGroupsByIdsOk,
  streamGetOk,
} from '../../helpers/ok.requests';
import {
  buildEntityObject,
  getDataFromVkResponse,
} from '../../helpers/data.helpers';
import {
  generateUID,
  getMembersCountFromEntity,
  getNameFromEntity,
  getOwnerIdFromEntity,
  getPathName,
  getSocialName,
  isCommunity,
  msToTime,
} from '../../helpers/auth.helper';
import { logEvent } from '../../libs/lib.amplitude';
import { LOG_EVENTS } from '../../constants/lib.constants';
import { resetAll } from '../resetModule';
import { getDateRange, getDefaultDateRange } from '../dateModule/selectors';
import { getLists, getSelectedList } from '../listsModule/selectors';
import Post from '../../services/client/database/classes/post';
import { postReachTypes } from '../../constants/app.constants';
import { IList } from '../listsModule/types';
import { getEntityInfo } from './selector';
import { getEntityInfoHandler } from '../../services/client/vk/handlers';
import { message } from 'antd';
import { getWallPosts } from '../../services/client/vk/procedures';
import { VK_ERROR_CODES } from '../../constants/vk.constants';
import {
  COUNT_EXECUTE_MAP,
  ITERATION_LIMIT,
} from '../../constants/collect.constants';
import { IDateRange } from '../dateModule/types';
import { IEntityInfo, ITag } from './types';
import { getLastFetchDetails } from '../searchModule/selectors';
import {
  changeColumnTypeToPercent,
  createExcelFileName,
} from '../../helpers/xlsx.helper';
import { formatMembersCount, formatPosts } from '../../helpers/json.helper';

import { checkOffset } from '../../helpers/offset.helper';
import TgApiServices from '../../services/client/tg/api';
import SitesApiServices from '../../services/client/site-analysis/api';
import { customLogger } from '../../helpers/log.helper';
import { logTypeNames } from '../../constants/log.constants';
import { collectFlowSaga } from '../collectFlow/collectFlow.saga';
import { rateDataModuleActions } from '../ratesModule';

export function* entityInfoRequest(application: App, str: string) {
  switch (application) {
    case App.vk: {
      return yield call(getEntityInfoHandler, str);
    }

    case App.ok: {
      const selectedAccount = yield select(getSelectedAccount);
      const userId = selectedAccount.userId;
      const entityIdResponse = yield call(getEntityIdOk, str);
      if (entityIdResponse.error_code || entityIdResponse.type === 'PROFILE') {
        return {
          error: true,
        };
      }

      if (entityIdResponse.type === 'UNKNOWN') {
        const splitStr = str.split('/');
        const groupStr = `https://ok.ru/group/${splitStr[splitStr.length - 1]}`;
        const entityIdResponse = yield call(getEntityIdOk, groupStr);
        const { objectId, type } = entityIdResponse;
        const { entityInfoResponse, userGroupsResponse } = yield all({
          entityInfoResponse: call(getEntityInfoOk, objectId),
          userGroupsResponse: call(
            getUserGroupsByIdsOk,
            objectId,
            [userId],
            '',
          ),
        });
        if (entityInfoResponse.error_code || userGroupsResponse.error_code) {
          return {
            error: true,
          };
        }

        return buildEntityObject({
          ...entityInfoResponse[0],
          type,
          status: userGroupsResponse[0] && userGroupsResponse[0].status,
        });
      }

      const { objectId, type } = entityIdResponse;
      const { entityInfoResponse, userGroupsResponse } = yield all({
        entityInfoResponse: call(getEntityInfoOk, objectId),
        userGroupsResponse: call(getUserGroupsByIdsOk, objectId, [userId], ''),
      });

      if (entityInfoResponse.error_code || userGroupsResponse.error_code) {
        return {
          error: true,
        };
      }

      return buildEntityObject({
        ...entityInfoResponse[0],
        type,
        status: userGroupsResponse[0] && userGroupsResponse[0].status,
      });
    }

    case App.tg:
      return yield call(TgApiServices.getChannelInfo, str);
    case App.sites:
      return yield call(SitesApiServices.getSiteInfo, str);
    default:
      return {};
  }
}

function* getEntityInfoSaga({
  payload: { str, buttonName = '', isEnter = false },
}: any) {
  try {
    const application = yield select(getSelectedApplication);

    const isListsPage = window.location.hash.includes(routes.app._groupLists);
    yield put(
      entityModuleActions.setDownloadingStatus({
        downloadProgress: 0,
        downloadState: DownloadStatus.NOT_STARTED,
      }),
    );
    yield put(groupDataModuleActions.setEntityPosts({}));
    const effects: any = [];
    const sanitizedEntityStr = sanitizeEntityStr(application, str);

    yield put(entityModuleActions.setLoad(true));
    const result = yield call(
      entityInfoRequest,
      application,
      sanitizedEntityStr,
    );

    if (result.error) {
      const isBlockedUserCase =
        result.error.error_code === VK_ERROR_CODES.BLOCK_USER;
      effects.push(
        put(
          searchDataModuleActions.setSearchStatus({
            state: isBlockedUserCase
              ? SearchStatus.BLOCKED
              : SearchStatus.ERROR,
          }),
        ),
        put(entityModuleActions.setLoad(false)),
      );
      if (isBlockedUserCase) {
        effects.push(
          put(
            entityModuleActions.setEntityInfo({
              entity: { ...result, app: application },
            }),
          ),
        );
      }
    } else if (isListsPage && !isCommunity(result.type)) {
      effects.push(
        put(
          searchDataModuleActions.setSearchStatus({
            state: SearchStatus.ERROR,
          }),
        ),
        put(entityModuleActions.setLoad(false)),
      );
    } else {
      effects.push(
        put(
          searchDataModuleActions.setSearchStatus({
            state: SearchStatus.SUCCESS,
          }),
        ),
        put(
          entityModuleActions.setEntityInfo({
            entity: { ...result, app: application },
          }),
        ),
        put(entityModuleActions.setLoad(false)),
      );
    }

    if (effects.length > 0) {
      yield all(effects);
    }

    logEvent(LOG_EVENTS.SEARCH_OBJECT, {
      members_count: getMembersCountFromEntity(result),
      start_search: isEnter ? 'ENTER' : 'button',
      name_object: getNameFromEntity(result),
      type: isListsPage ? 'groups' : 'user',
      social: getSocialName(application),
      wall_post: result.postsCount,
      page: getPathName(),
      button: buttonName,
      objectId: !result.error ? getOwnerIdFromEntity(result, application) : '',
      query: str,
    });
  } catch (e: any) {
    customLogger(e, 'error in saga getEntityInfo');
    return;
  }
}

function* collectWallPostsOk(
  isList: boolean,
  collectRange,
  selectOffset: number,
) {
  yield put(entityModuleActions.setCollectLoad(true));
  try {
    const dateRange = yield select(getDateRange);
    const selectedEntityInfo = yield select(getEntityInfo);
    let selectedList: IList = yield select(getSelectedList);
    if (isList) {
      const entitiesList: IList[] = yield select(getLists);
      const selectedListInGlobal: IList | undefined = entitiesList.find(
        list => `${list.id}` === `${selectedList.id}`,
      );

      const hasDiffSelectedList = selectedListInGlobal
        ? selectedListInGlobal.entities.length !== selectedList.entities.length
        : false;
      if (hasDiffSelectedList) {
        yield put(
          listsModuleActions.updateSelectedList({ list: selectedListInGlobal }),
        );
      }

      selectedList =
        hasDiffSelectedList && selectedListInGlobal
          ? selectedListInGlobal
          : selectedList;
    }

    const copyRange: any = [
      moment(collectRange[0].format()),
      moment(collectRange[1].format()),
    ];

    const collectStartTime = checkOffset(selectOffset, copyRange[0]) * 1000;
    const collectEndTime = checkOffset(selectOffset, copyRange[1]) * 1000;

    const entitiesArray = isList ? selectedList.entities : [selectedEntityInfo];
    const ownerIds = entitiesArray.map(entity =>
      getOwnerIdFromEntity(entity, App.ok),
    );
    const totalRatesWithReach = {};
    yield call(Post.clearTable);
    yield put(graphicDataModuleActions.resetAdditionalGraphicTypes({}));
    yield put(
      entityModuleActions.setDownloadingStatus({
        downloadProgress: 0,
        downloadState: DownloadStatus.PROGRESS,
      }),
    );
    const collectDoneOwnerIds: any = [];

    const collectProcess = yield fork(function* () {
      // eslint-disable-next-line no-restricted-syntax
      // @ts-ignore
      for (const [index, entityInfo] of entitiesArray.entries()) {
        yield put(
          entityModuleActions.setDownloadingStatus({
            downloadProgress: 0,
            downloadState: DownloadStatus.PROGRESS,
            currentIndex: index + 1,
            total: entitiesArray.length,
            name: !!entityInfo.name
              ? entityInfo.name
              : `${entityInfo.first_name} ${entityInfo.last_name}`,
          }),
        );
        const membersCount = getMembersCountFromEntity(entityInfo);
        const ownerId = getOwnerIdFromEntity(entityInfo, App.ok);
        const postReachType = 'reachTotal';
        const startTimeOfStep = Date.now();
        const posts: any = [];
        const { admin_level: adminLevel } = entityInfo;
        const hasPermissions =
          adminLevel === UserRole.Admin || adminLevel === UserRole.Moderator;

        if (!hasPermissions) {
          let fetchCompleted = false;
          let anchor = '';
          let pinnedPost;

          while (!fetchCompleted) {
            const result: any = yield call(streamGetOk, entityInfo.id, anchor);

            customLogger('streamGetOk:', result);
            const { entities } = result;
            if (
              !entities ||
              (entities &&
                entities.media_topics &&
                entities.media_topics.length === 0)
            ) {
              fetchCompleted = true;
              break;
            }
            const { media_topics: mediaTopics } = entities;
            const isPinned =
              mediaTopics[0].created_ms &&
              mediaTopics[1] &&
              mediaTopics[1].created_ms &&
              mediaTopics[0].created_ms < mediaTopics[1].created_ms;

            const newestPostTimestamp = isPinned
              ? mediaTopics[1] && mediaTopics[1].created_ms
              : mediaTopics[0].created_ms;

            if (isPinned) {
              pinnedPost = {
                ...mediaTopics[0],
              };
            }

            anchor = result.anchor;
            let isInRange: boolean = false;
            let mediaTopicsForIndex: number = mediaTopics.length - 1;
            while (!isInRange) {
              if (
                mediaTopics[mediaTopicsForIndex].created_ms < collectStartTime
              ) {
                mediaTopics.pop();
              } else {
                isInRange = true;
                break;
              }
              if (mediaTopicsForIndex === 0) {
                isInRange = true;
                break;
              }
              mediaTopicsForIndex -= 1;
            }
            posts.push(...mediaTopics);

            if (newestPostTimestamp < collectStartTime || !result.anchor) {
              if (
                pinnedPost &&
                pinnedPost.created_ms < collectStartTime &&
                pinnedPost.created_ms < posts[posts.length - 1].created_ms
              ) {
                posts.splice(0, 1);
              }
              fetchCompleted = true;
            }
          }
        } else {
          let hasMore = true;
          let anchor = '';

          while (hasMore) {
            const result = yield call(
              getStatTopicsOk,
              entityInfo.id,
              collectStartTime,
              collectEndTime,
              anchor,
            );
            const { topics } = result;

            if (topics && topics.length === 0) {
              break;
            }
            posts.push(...topics);
            hasMore = result.has_more;
            anchor = result.anchor;
          }
          const postsWithReach = posts.map(p => ({
            post_id: p.id,
            reach_total: p.reach,
          }));
          const postsWithReachRes = yield call(
            Post.insertOrReplaceReachOk,
            postsWithReach,
            ownerId,
          );

          if (postsWithReachRes.length > 0) {
            totalRatesWithReach[ownerId] = {};
            postsWithReachRes.forEach(postReach => {
              if (
                typeof totalRatesWithReach[ownerId][postReachType] === 'number'
              ) {
                totalRatesWithReach[ownerId][postReachType] +=
                  postReach[postReachType] || 0;
              } else {
                totalRatesWithReach[ownerId][postReachType] =
                  postReach[postReachType];
              }
            });
          }
        }
        collectDoneOwnerIds.push(ownerId);
        yield put(
          loadingStepsModuleActions.setLoadingSteps({
            current: index + 1,
            index,
            spentTime: msToTime(Date.now() - startTimeOfStep),
            totalPosts: posts.length,
          }),
        );
        customLogger('Дата для indexedb:', posts);
        yield call(
          Post.insertOrReplaceOk,
          posts,
          ownerId,
          membersCount,
          selectedList.indexes,
        );
      }

      const allPosts = yield call(Post.getAllById, ownerIds);

      const [newestPost, oldestPost] = [
        allPosts[0],
        allPosts[allPosts.length - 1],
      ];

      if (newestPost && oldestPost) {
        if (dateRange.from && dateRange.to) {
          yield put(
            dateModuleActions.setDateRange({
              from: null,
              to: null,
            }),
          );
        }

        yield put(
          dateModuleActions.setDefaultDateRange({
            from: oldestPost.timestampStartOfDay.getTime(),
            to: newestPost.timestampEndOfDay.getTime(),
          }),
        );
      }
      if (Object.keys(totalRatesWithReach).length > 0) {
        yield put(
          rateDataModuleActions.addToAdditionalTotalRatesTypes({
            totalRatesType: postReachTypes,
          }),
        );
        yield collectFlowSaga();
      }

      yield put(
        entityModuleActions.setDownloadingStatus({
          downloadProgress: 100,
          downloadState: DownloadStatus.SUCCESS,
        }),
      );
      yield put(entityModuleActions.setCollectLoad(false));
      yield delay(200);
      yield put(loadingStepsModuleActions.reset({}));
      yield collectFlowSaga();
    });

    if (isList) {
      yield take(CANCEL_COLLECT_POSTS);
      yield cancel(collectProcess);
      const newSelectedList: any = {
        name: selectedList.name,
        id: selectedList.id,
        lastUpdate: selectedList.lastUpdate,
        entities: [],
        application: App.ok,
      };
      for (let i = 0; i < selectedList.entities.length; i += 1) {
        if (collectDoneOwnerIds.includes(`${selectedList.entities[i].id}`)) {
          newSelectedList.entities.push(selectedList.entities[i]);
        }

        if (newSelectedList.entities.length === collectDoneOwnerIds.length) {
          i = selectedList.entities.length;
        }
      }
      yield put(
        listsModuleActions.updateSelectedList({ list: newSelectedList }),
      );
      const allPosts = yield call(Post.getAllById, ownerIds);

      const [newestPost, oldestPost] = [
        allPosts[0],
        allPosts[allPosts.length - 1],
      ];

      if (newestPost && oldestPost) {
        if (dateRange.from && dateRange.to) {
          yield put(
            dateModuleActions.setDateRange({
              from: null,
              to: null,
            }),
          );
        }

        yield put(
          dateModuleActions.setDefaultDateRange({
            from: oldestPost.timestampStartOfDay.getTime(),
            to: newestPost.timestampEndOfDay.getTime(),
          }),
        );
      }
      if (Object.keys(totalRatesWithReach).length > 0) {
        yield put(
          rateDataModuleActions.addToAdditionalTotalRatesTypes({
            totalRatesType: postReachTypes,
          }),
        );
        yield collectFlowSaga();
      }

      yield put(
        entityModuleActions.setDownloadingStatus({
          downloadProgress: 100,
          downloadState: DownloadStatus.SUCCESS,
        }),
      );

      yield put(entityModuleActions.setCollectLoad(false));
      yield delay(200);
      yield put(loadingStepsModuleActions.reset({}));
    }
  } catch (e) {
    yield put(
      entityModuleActions.setDownloadingStatus({
        downloadProgress: 0,
        downloadState: DownloadStatus.ERROR,
      }),
    );
    yield delay(3000);
    yield put(
      entityModuleActions.setDownloadingStatus({
        downloadProgress: 0,
        downloadState: DownloadStatus.NOT_STARTED,
      }),
    );
    customLogger('Произошла ошибка в сборе постов', e);
  }
}

function* collectProcessSuccess(ownerIds, dateRange, entitiesForComparing) {
  try {
    yield put(loadingStepsModuleActions.reset({}));
    const posts = yield call(Post.getAllById, ownerIds);
    const [newestPost, oldestPost] = [posts[0], posts[posts.length - 1]];

    if (newestPost && oldestPost) {
      if (dateRange.from && dateRange.to) {
        yield put(
          dateModuleActions.setDateRange({
            from: null,
            to: null,
          }),
        );
      }

      yield put(
        dateModuleActions.setDefaultDateRange({
          from: oldestPost.timestampStartOfDay.getTime(),
          to: newestPost.timestampEndOfDay.getTime(),
        }),
      );
    }
    yield put(
      entityModuleActions.setDownloadingStatus({
        downloadProgress: 0,
        downloadState: DownloadStatus.SUCCESS,
      }),
    );
    // сбор время
    if (process.env.REACT_APP_DEV === 'true') {
      console.timeEnd('Сбор. Затраченное время');
    }
    yield put(entityModuleActions.setCollectLoad(false));

    yield collectFlowSaga();
  } catch (e) {
    throw e;
  }
}

function* collectGetWallPostsEffects(
  realCountExecute,
  ownerId,
  collectId,
  iterationLimit = ITERATION_LIMIT,
) {
  const effects: any = [];
  let offset = 0;
  for (let i = iterationLimit - 3; i < iterationLimit; i += 1) {
    offset = (100 + realCountExecute) * i;
    const params = {
      ownerId,
      countExecute: realCountExecute,
      offset,
    };
    customLogger(`Параметры № ${i}`, params);
    effects.push(call(getWallPosts, { ...params, collectId }));
  }

  yield put(
    searchDataModuleActions.setLastFetchDetails({
      iterationLimit: iterationLimit,
      countExecute: realCountExecute,
    }),
  );

  return effects;
}

function* collectResultInRange({
  collectRange,
  totalResult,
  formattedResponse,
  startTimeOfStep,
  entityInfo,
  ownerId,
  collectId,
  selectOffset,
  index,
  isList,
}) {
  const newestPostDate: number =
    formattedResponse[0][0] > formattedResponse[0][1]
      ? checkOffset(selectOffset, moment(formattedResponse[0][0] * 1000)) * 1000
      : checkOffset(selectOffset, moment(formattedResponse[0][1] * 1000)) *
        1000;

  const oldestPostDate: number =
    checkOffset(
      selectOffset,
      moment(formattedResponse[0][formattedResponse[0].length - 1] * 1000),
    ) * 1000;

  const copyRange: any = [
    moment(collectRange[0].format()),
    moment(collectRange[1].format()),
  ];

  const selectedEntityInfo = yield select(getEntityInfo);
  let selectedList: IList = yield select(getSelectedList);
  const name = getNameFromEntity(entityInfo);
  const entitiesArray = isList ? selectedList.entities : [selectedEntityInfo];

  yield put(
    entityModuleActions.setDownloadingStatus({
      downloadProgress: 0,
      downloadState: DownloadStatus.PROGRESS,
      currentIndex: index + 1,
      total: entitiesArray.length,
      name: `${name}. Собраны посты до ${moment(oldestPostDate).format(
        'DD.MM.YYYY',
      )}`,
    }),
  );

  const collectFrom: number = checkOffset(selectOffset, copyRange[0]) * 1000;
  const collectTo: number = copyRange[1].valueOf();

  if (oldestPostDate === collectFrom && newestPostDate === collectTo) {
    return formattedResponse;
  }

  for (
    let indexOfTimestamp = 0;
    indexOfTimestamp < formattedResponse[0].length;
    indexOfTimestamp += 1
  ) {
    const postTimeStamp = formattedResponse[0][indexOfTimestamp] * 1000;
    if (postTimeStamp <= collectTo && postTimeStamp >= collectFrom) {
      for (let index = 0; index < formattedResponse.length; index += 1) {
        totalResult[index].push(formattedResponse[index][indexOfTimestamp]);
      }
    }
  }
  if (collectFrom < oldestPostDate) {
    const lastFetchDetails = yield select(getLastFetchDetails);
    const effects: any = yield call(
      collectGetWallPostsEffects,
      lastFetchDetails.countExecute,
      ownerId,
      collectId,
      lastFetchDetails.iterationLimit + 3,
    );
    yield delay(1000);
    return yield call(handleDataFromVk, {
      effects,
      startTimeOfStep,
      entityInfo,
      collectRange,
      totalResult,
      ownerId,
      collectId,
    });
  } else {
    customLogger('Затраченное время на лог постов');
    customLogger('Получен ответ: ', totalResult);
    customLogger('Длина ответа: ', totalResult[1].length);
    customLogger('Затраченное время на лог постов');

    return totalResult;
  }
}

function* handleDataFromVk({
  effects,
  index,
  startTimeOfStep,
  entityInfo,
  collectRange,
  totalResult,
  ownerId,
  collectId,
  selectOffset,
  isList,
}) {
  const results: any = yield all(effects);
  const [result1, result2, result3] = results;
  const isExecuteErrorExists: boolean =
    !!result1?.execute_errors?.[0] ||
    !!result1?.execute_errors?.[0] ||
    !!result1?.execute_errors?.[0];
  const { execute_errors } = result1;
  customLogger('Затраченное время на паралелльные запросы');

  const finishParallelTime = new Date();
  customLogger(
    `${finishParallelTime.toLocaleTimeString()} ms: ${finishParallelTime.getMilliseconds()} | Завершился параллельный запрос на сбор постов`,
  );
  if (
    (result1.execute_errors && result1.execute_errors[0]) ||
    (result2.execute_errors && result2.execute_errors[0]) ||
    (result3.execute_errors && result3.execute_errors[0])
  ) {
    message.warning(`Сбор постов для ${entityInfo.name} недоступен`);
  }

  const formattedResponse: number[][] = getDataFromVkResponse(
    isExecuteErrorExists,
    results,
  );

  return yield call(collectResultInRange, {
    collectRange,
    totalResult,
    formattedResponse,
    startTimeOfStep,
    entityInfo,
    ownerId,
    collectId,
    index,
    execute_errors,
    result1,
    selectOffset,
    isList,
  });
}

function* collectProcessSaga(
  entitiesArray,
  realCountExecute,
  countExecute,
  entitiesForComparing,
  collectDoneOwnerIds,
  ownerIds,
  dateRange,
  collectRange,
  selectOffset,
  isList,
) {
  try {
    const ownerIdsForComparing: any = [];
    const compareDate = moment()
      .subtract(2, 'months')
      .startOf('month')
      .valueOf();
    let index = 0;
    for (const entityInfo of entitiesArray) {
      const name = getNameFromEntity(entityInfo);
      yield put(
        entityModuleActions.setDownloadingStatus({
          downloadProgress: 0,
          downloadState: DownloadStatus.PROGRESS,
          currentIndex: index + 1,
          total: entitiesArray.length,
          name,
        }),
      );
      const startTimeOfStep = Date.now();
      const ownerId = getOwnerIdFromEntity(entityInfo, entityInfo.app);
      const membersCount = getMembersCountFromEntity(entityInfo);
      const collectId = generateUID();
      yield put(
        groupDataModuleActions.setOwnerIdCollectIdGroup({
          collectId,
          ownerId,
        }),
      );
      const effects: any = yield call(
        collectGetWallPostsEffects,
        realCountExecute,
        ownerId,
        collectId,
        ITERATION_LIMIT,
      );
      customLogger('Общее время на сбор постов с добавлением в базу данных');

      const startParallelTime = new Date();

      customLogger(
        `${startParallelTime.toLocaleTimeString()} ms: ${startParallelTime.getMilliseconds()} | Начался параллельный запрос на сбор постов`,
      );

      customLogger('Затраченное время на паралелльные запросы', '', 'TIME');
      const totalResult = [[], [], [], [], [], []];
      const resultsInRange = yield call(handleDataFromVk, {
        effects,
        index,
        startTimeOfStep,
        entityInfo,
        collectRange,
        totalResult,
        ownerId,
        collectId,
        selectOffset,
        isList,
      });

      customLogger('Дата загружаемая в indexedb:', resultsInRange);

      yield put(
        loadingStepsModuleActions.setLoadingSteps({
          current: index + 1,
          index,
          spentTime: msToTime(Date.now() - startTimeOfStep),
          totalPosts: totalResult[1].length,
        }),
      );

      customLogger('Затраченное время на добавление в базу данных', '', 'TIME');

      const insertResult = yield call(
        Post.insertOrReplace,
        resultsInRange,
        ownerId,
        membersCount,
      );
      customLogger(
        'Затраченное время на добавление в базу данных',
        '',
        'TIME_END',
      );

      const entityOldestPost = insertResult[insertResult.length - 1];

      if (
        entityOldestPost &&
        entityOldestPost.timestampStartOfDay.getTime() > compareDate &&
        countExecute <= insertResult.length
      ) {
        ownerIdsForComparing.push(getOwnerIdFromEntity(entityInfo));
        entitiesForComparing.push({ entityInfo, indexInList: index + 1 });
      }

      customLogger(
        'Общее время на сбор постов с добавлением в базу данных',
        '',
        'TIME_END',
      );
      collectDoneOwnerIds.push(ownerId);
      index += 1;
      yield delay(1000);
    }
    yield call(
      collectProcessSuccess,
      ownerIds,
      dateRange,
      entitiesForComparing,
    );
  } catch (e) {
    throw e;
  }
}

function* collectWallPostsVk(countExecute, isList, collectRange, selectOffset) {
  yield put(entityModuleActions.setCollectLoad(true));
  // сбор время
  try {
    if (process.env.REACT_APP_DEV === 'true') {
      console.time('Сбор. Затраченное время');
    }
    let collectProcess;
    const entitiesForComparing: any = [];
    const realCountExecute = COUNT_EXECUTE_MAP[countExecute];
    const dateRange: IDateRange = yield select(getDateRange);
    const selectedEntityInfo: IEntityInfo = yield select(getEntityInfo);

    yield call(Post.clearTable);
    yield put(graphicDataModuleActions.resetAdditionalGraphicTypes({}));
    yield put(
      searchDataModuleActions.setCurrentExecuteCount({
        countExecute: realCountExecute,
      }),
    );

    const startTime = new Date();
    customLogger(
      `${startTime.toLocaleTimeString()} ms: ${startTime.getMilliseconds()} | Начался сбор постов с параметром countExecute: ${realCountExecute}`,
    );

    let selectedList: IList = yield select(getSelectedList);
    if (isList) {
      const entitiesList: IList[] = yield select(getLists);
      const selectedListInGlobal: IList | undefined = entitiesList.find(
        list => `${list.id}` === `${selectedList.id}`,
      );

      const hasDiffSelectedList =
        selectedListInGlobal?.entities.length !== selectedList.entities.length;
      if (hasDiffSelectedList && selectedListInGlobal) {
        yield put(
          listsModuleActions.updateSelectedList({
            list: selectedListInGlobal,
          }),
        );
      }

      selectedList =
        hasDiffSelectedList && selectedListInGlobal
          ? selectedListInGlobal
          : selectedList;
    }

    const entitiesArray = isList ? selectedList.entities : [selectedEntityInfo];

    const ownerIds = entitiesArray.map(entity =>
      getOwnerIdFromEntity(entity, entity.app),
    );

    const collectDoneOwnerIds: any = [];

    collectProcess = yield fork(
      collectProcessSaga,
      entitiesArray,
      realCountExecute,
      countExecute,
      entitiesForComparing,
      collectDoneOwnerIds,
      ownerIds,
      dateRange,
      collectRange,
      selectOffset,
      isList,
    );

    if (isList) {
      yield take(CANCEL_COLLECT_POSTS);
      yield cancel(collectProcess);
      const newSelectedList: any = {
        name: selectedList.name,
        id: selectedList.id,
        lastUpdate: selectedList.lastUpdate,
        entities: [],
        application: App.vk,
      };
      for (let i = 0; i < selectedList.entities.length; i += 1) {
        if (collectDoneOwnerIds.includes(-selectedList.entities[i].id)) {
          // added - because id's of vk posts is kept with -
          newSelectedList.entities.push(selectedList.entities[i]);
        }

        if (newSelectedList.entities.length === collectDoneOwnerIds.length) {
          i = selectedList.entities.length;
        }
      }
      yield put(
        listsModuleActions.updateSelectedList({ list: newSelectedList }),
      );
      yield call(
        collectProcessSuccess,
        ownerIds,
        dateRange,
        entitiesForComparing,
      );
    }
  } catch (e) {
    yield put(
      entityModuleActions.setDownloadingStatus({
        downloadProgress: 0,
        downloadState: DownloadStatus.ERROR,
      }),
    );
    yield delay(3000);
    yield put(
      entityModuleActions.setDownloadingStatus({
        downloadProgress: 0,
        downloadState: DownloadStatus.NOT_STARTED,
      }),
    );
    customLogger('Произошла ошибка в сборе постов', e);
  }
}

/** Telegram section */

function* tgProcess(
  entity: IEntityInfo,
  collectRange,
  selectOffset,
  index,
  total,
) {
  const tempArr: any = [];
  const copyRange: any = [
    moment(collectRange[0].format()),
    moment(collectRange[1].format()),
  ];
  let selected = checkOffset(selectOffset, copyRange[0]);

  yield put(
    entityModuleActions.setDownloadingStatus({
      downloadProgress: 0,
      downloadState: DownloadStatus.PROGRESS,
      currentIndex: index + 1,
      total: total,
      name: `${entity.name}`,
    }),
  );

  try {
    for (let limit = 0; ; limit += 50) {
      const data = yield call(
        TgApiServices.getChannelPublications,
        entity.channelId,
        limit,
      );

      let last = checkOffset(
        selectOffset,
        moment(data.response.items[data.response.items.length - 1].date * 1000),
      );

      yield put(
        entityModuleActions.setDownloadingStatus({
          downloadProgress: 0,
          downloadState: DownloadStatus.PROGRESS,
          currentIndex: index + 1,
          total: total,
          name: `${entity.name}. Собраны посты до ${moment(last * 1000).format(
            'DD.MM.YYYY',
          )}.`,
        }),
      );

      if (last >= selected) {
        tempArr.push(...data.response.items);
      } else {
        tempArr.push(...data.response.items);
        break;
      }
    }
  } catch (err: any) {
    message
      .error(`Ошибка в сборе: ${err.message}`)
      .then(() => customLogger(err.message));
  }

  function filterByDate(arr) {
    return arr.filter(item => item.date >= selected);
  }

  return filterByDate(tempArr);
}

function* collectChannelsPostsTg(isList, collectRange, selectOffset) {
  function* collectEntity(entity: IEntityInfo, selectOffset, index, total) {
    yield put(
      entityModuleActions.setDownloadingStatus({
        downloadProgress: 0,
        downloadState: DownloadStatus.PROGRESS,
      }),
    );
    return yield call(
      tgProcess,
      entity,
      collectRange,
      selectOffset,
      index,
      total,
    );
  }

  function* setSortDate() {
    const entity: IEntityInfo = yield select(getEntityInfo);
    const dateRange: IDateRange = yield select(getDateRange);
    const posts = yield call(Post.getAllById, +entity.id);
    const [newestPost, oldestPost] = [posts[0], posts[posts.length - 1]];

    if (newestPost && oldestPost) {
      if (dateRange.from && dateRange.to) {
        yield put(
          dateModuleActions.setDateRange({
            from: null,
            to: null,
          }),
        );
      } else {
        yield put(
          dateModuleActions.setDefaultDateRange({
            from: oldestPost.timestampStartOfDay.getTime(),
            to: newestPost.timestampEndOfDay.getTime(),
          }),
        );
      }
    }
  }

  try {
    switch (isList) {
      case false:
        yield put(loadingStepsModuleActions.setLoadingSteps({ current: 0 }));
        const entity: IEntityInfo = yield select(getEntityInfo);
        yield call(Post.clearTable);
        yield put(graphicDataModuleActions.resetAdditionalGraphicTypes({}));

        const collectEntityData = yield call(
          collectEntity,
          entity,
          selectOffset,
          0,
          1,
        );

        yield call(
          Post.insertOrReplaceTg,
          collectEntityData,
          +entity.id,
          entity.followers_count,
        );

        yield call(setSortDate);

        yield put(loadingStepsModuleActions.setLoadingSteps({ current: 1 }));
        yield delay(200);

        yield put(
          entityModuleActions.setDownloadingStatus({
            downloadProgress: 100,
            downloadState: DownloadStatus.SUCCESS,
          }),
        );

        yield put(loadingStepsModuleActions.reset({}));

        break;

      case true:
        // сбор списка
        yield call(Post.clearTable);
        yield put(graphicDataModuleActions.resetAdditionalGraphicTypes({}));

        const selectedList: IList = yield select(getSelectedList);
        let index = 0;

        for (let entity of selectedList.entities) {
          const startTime = Date.now();

          try {
            const resData = yield call(
              collectEntity,
              entity,
              selectOffset,
              index,
              selectedList.entities.length,
            );

            yield call(
              Post.insertOrReplaceTg,
              resData,
              +entity.id,
              entity.members_count,
            );

            yield put(
              loadingStepsModuleActions.setLoadingSteps({
                current: index + 1,
                index,
                spentTime: msToTime(Date.now() - startTime),
              }),
            );

            index += 1;
          } catch (e) {
            message.error(`Ошибка в сборе списка ${selectedList.name}`);
          }
        }

        yield call(setSortDate);

        yield put(
          loadingStepsModuleActions.setLoadingSteps({
            current: index,
            index,
          }),
        );
        yield delay(200);

        yield put(
          entityModuleActions.setDownloadingStatus({
            downloadProgress: 100,
            downloadState: DownloadStatus.SUCCESS,
          }),
        );

        yield put(loadingStepsModuleActions.reset({}));

        break;

      default:
        customLogger('Проблемы с параметром isList');
        break;
    }
  } catch (e: any) {
    customLogger(e);
    yield put(
      entityModuleActions.setDownloadingStatus({
        downloadProgress: 0,
        downloadState: DownloadStatus.ERROR,
      }),
    );
  }
}

function* collectWallPosts({
  payload: { countExecute, isList, collectRange, selectOffset },
}: any) {
  const application = yield select(getSelectedApplication);
  switch (application) {
    case App.ok:
      yield call(collectWallPostsOk, isList, collectRange, selectOffset);
      break;
    case App.vk:
      yield call(
        collectWallPostsVk,
        countExecute,
        isList,
        collectRange,
        selectOffset,
      );
      break;
    case App.tg:
      yield call(collectChannelsPostsTg, isList, collectRange, selectOffset);
      break;
  }
}

function* exportGroupPosts(
  formattedFollowersCount: any[],
  fileName: string,
  postsToExport: any[],
  // entitiesTotalRates,
  // weeklyTotalRates,
  // monthlyTotalRates,
  callback?: any,
) {
  // converting json to xml and show the dialog for save
  try {
    const postsWS = XLSX.utils.json_to_sheet(postsToExport);
    const membersCountWS = XLSX.utils.json_to_sheet(formattedFollowersCount);
    // const totalRatesWS = XLSX.utils.json_to_sheet(entitiesTotalRates);
    // const weeklyTotalRatesWS = XLSX.utils.json_to_sheet(weeklyTotalRates);
    // const monthlyTotalRatesWS = XLSX.utils.json_to_sheet(monthlyTotalRates);
    const wb = XLSX.utils.book_new();

    changeColumnTypeToPercent(postsWS, 'J');
    changeColumnTypeToPercent(postsWS, 'K');
    changeColumnTypeToPercent(postsWS, 'L');
    changeColumnTypeToPercent(membersCountWS, 'K');
    changeColumnTypeToPercent(membersCountWS, 'L');
    // changeColumnTypeToPercent(totalRatesWS, 'K');
    // changeColumnTypeToPercent(totalRatesWS, 'L');
    // changeColumnTypeToPercent(weeklyTotalRatesWS, 'K');
    // changeColumnTypeToPercent(weeklyTotalRatesWS, 'L');
    // changeColumnTypeToPercent(monthlyTotalRatesWS, 'K');
    // changeColumnTypeToPercent(monthlyTotalRatesWS, 'L');

    XLSX.utils.book_append_sheet(wb, postsWS, 'Посты');
    XLSX.utils.book_append_sheet(wb, membersCountWS, 'Подписчики');
    // XLSX.utils.book_append_sheet(wb, totalRatesWS, 'Сводные Показатели');
    // XLSX.utils.book_append_sheet(wb, weeklyTotalRatesWS, 'Сводные Недельные');
    // XLSX.utils.book_append_sheet(wb, monthlyTotalRatesWS, 'Сводные Месячные');
    XLSX.writeFile(wb, fileName);
    if (callback) {
      yield call(callback);
    }
  } catch (e: any) {
    customLogger(e, 'error in exportGroupPosts');
  }
}

function* exportEntityPosts() {
  customLogger('Нажали на кнопку "Скачать эксель"');
  customLogger('exportClick', '', logTypeNames.TIME);

  const application = yield select(getSelectedApplication);
  const entityInfo = yield select(getEntityInfo);
  const dateRange = yield select(getDateRange);
  const defaultDateRange = yield select(getDefaultDateRange);
  const selectedList: IList = yield select(getSelectedList);
  const listSelected = Object.keys(selectedList).length > 0;

  logEvent(LOG_EVENTS.CLICK_BUTTON, {
    page: getPathName(),
    button: 'Скачать Excel',
    type: listSelected ? 'list' : 'object',
    list_name: listSelected ? selectedList.name : 'none',
    list_count: listSelected ? selectedList.entities.length : 'none',
    objectId: listSelected
      ? 'none'
      : getOwnerIdFromEntity(entityInfo, entityInfo.app),
    social: getSocialName(application),
  });

  // лоадер кнопки
  yield put(entityModuleActions.excelGenerateRequest(true));

  const namesMap: {
    name: string;
    app: App;
    followersCount: number;
    tags: ITag[];
  } = {} as {
    name: string;
    app: App;
    followersCount: number;
    tags: ITag[];
  };

  if (!listSelected) {
    customLogger('Формируем карту имён');
    customLogger('notList', '', logTypeNames.TIME);

    namesMap[getOwnerIdFromEntity(entityInfo, entityInfo.app)] = {
      name: getNameFromEntity(entityInfo),
      app: entityInfo.app,
      followersCount:
        entityInfo.app === App.tg
          ? entityInfo.followers_count
          : entityInfo.members_count,
      tags: [],
    };

    customLogger('Карта имён сформирована', namesMap);
    customLogger('notList', '', logTypeNames.TIME_END);
  }

  customLogger('Создаём массив ID');
  customLogger('idsArr', '', logTypeNames.TIME);

  const idsArr = listSelected
    ? selectedList.entities.map((entity: IEntityInfo) => {
        const ownerId = getOwnerIdFromEntity(entity, selectedList.application);
        namesMap[ownerId] = {
          name: getNameFromEntity(entity),
          app: selectedList.application,
          followersCount: entity.members_count,
          tags: entity.tags.map(tag => tag.text),
        };
        return ownerId;
      })
    : [getOwnerIdFromEntity(entityInfo, entityInfo.app)];

  customLogger('Массив ID создан', idsArr);
  customLogger('idsArr', '', logTypeNames.TIME_END);

  const [dateFrom, dateTo] = [
    dateRange.from || defaultDateRange.from,
    moment(dateRange.to)
      .set({ hours: 23, minutes: 59, seconds: 59 })
      .valueOf() || defaultDateRange.to,
  ];

  customLogger('Получаем посты из IndexedDB', idsArr);
  customLogger('getAllByIdWithReach', '', logTypeNames.TIME);

  const effect =
    dateFrom && dateTo
      ? call(
          Post.getAllByIdWithReachDateRange,
          idsArr,
          dateFrom,
          dateTo,
          selectedList.indexes,
        )
      : call(Post.getAllByIdWithReach, idsArr);

  const posts = yield effect;

  customLogger('Получение постов окончено', idsArr);
  customLogger('getAllByIdWithReach', '', logTypeNames.TIME_END);

  customLogger('Форматируем посты для Excel');
  customLogger('formattedPosts', '', logTypeNames.TIME);

  const formattedPosts = yield call(formatPosts, posts, namesMap, entityInfo);

  customLogger('Форматирование постов для Excel завершено');
  customLogger('formattedPosts', '', logTypeNames.TIME_END);

  customLogger('Форматируем посты для отображения кол-ва подписчикв');
  customLogger('formattedFollowersCount', '', logTypeNames.TIME);

  const formattedFollowersCount = yield call(
    formatMembersCount,
    posts,
    namesMap,
  );

  customLogger('Форматирование кол-ва подписчиков завершено');
  customLogger('formattedFollowersCount', '', logTypeNames.TIME_END);

  customLogger('Создаём имя файла');
  customLogger('fileName', '', logTypeNames.TIME);

  const fileName = createExcelFileName(
    application,
    listSelected,
    selectedList,
    dateFrom,
    dateTo,
    entityInfo,
  );

  customLogger('Имя файла создана');
  customLogger('fileName', '', logTypeNames.TIME_END);

  customLogger('Выгружаем файл Excel');
  customLogger('exportExcel', '', logTypeNames.TIME);

  yield fork(
    exportGroupPosts,
    formattedFollowersCount,
    fileName,
    formattedPosts,
  );

  customLogger('Файл Excel выгружен');
  customLogger('exportExcel', '', logTypeNames.TIME_END);

  yield put(entityModuleActions.excelGenerateRequest(false));
  yield put(entityModuleActions.setExportPercent({ max: 0, value: 0 }));

  customLogger('Все операции завершены');
  customLogger('exportClick', '', logTypeNames.TIME_END);
}

function* setEntityInfo(action) {
  yield put(resetAll(action));
}

export function* entityModuleSaga() {
  yield takeLatest(entityModuleActions.getEntityInfo, getEntityInfoSaga);
  yield takeLatest(entityModuleActions.setEntityInfo, setEntityInfo);
  yield takeLatest(entityModuleActions.collectWallPosts, collectWallPosts);
  yield takeLatest(entityModuleActions.exportEntityPosts, exportEntityPosts);
}
