export enum App {
  vk,
  ok,
  tg,
  sites,
  fb,
  inst,
  tt,
  tw,
}

export const AppMaps = {
  [App.vk]: 'vk',
  [App.ok]: 'ok',
  [App.tg]: 'tg',
  [App.sites]: 'sites',
};

export enum socialAccountRole {
  Moderator = 1,
  Editor,
  Admin,
}

export enum DownloadStatus {
  ERROR,
  SUCCESS,
  PROGRESS,
  EXPORTING,
  FETCHING,
  NOT_STARTED,
}

export enum SearchStatus {
  ERROR,
  SUCCESS,
  INITIAL,
  BLOCKED = 15,
}

export enum DatabaseConnectionState {
  NOT_CONNECTED,
  CONNECTING,
  CONNECTED,
  FAILED,
}

export enum UserRole {
  Moderator = 1,
  Editor,
  Admin,
}

export enum UserTypeEnum {
  Login,
  Anonymous,
}
