import React, { useEffect, useState } from 'react';

import { Button, DatePicker, Space } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import { DownloadStatus } from '../../../constants/enums';
import { LOG_EVENTS } from '../../../constants/lib.constants';
import {
  getOwnerIdFromEntity,
  getPathName,
  getSocialName,
} from '../../../helpers/auth.helper';
import {
  previousMonthSelected,
  previousWeekSelected,
} from '../../../helpers/date.helper';
import { logEvent } from '../../../libs/lib.amplitude';
import { metaData } from '../../../meta-data/date.data';
import { getSelectedApplication } from '../../../state/socialAccountsModule/selector';
import styled from 'styled-components';
import {
  getDateRange,
  getDefaultDateRange,
} from '../../../state/dateModule/selectors';
import {
  getDownloadingStatus,
  getEntityInfo,
} from '../../../state/entityFetchModule/selector';
import { getSelectedList } from '../../../state/listsModule/selectors';
import { dateModuleActions } from '../../../state/dateModule';
import { getGraphicDataLoad } from '../../../state/graphicDataModule/selector';
import { PostsAnalyzeLogs } from '../../../services/amplitude/amplitude';

const { RangePicker } = DatePicker;

const { text } = metaData;

type Props = {
  triggerWarning: (title) => void;
};

export default function DateRange({ triggerWarning }: Props) {
  const dispatch = useDispatch();
  const selectedDateRange = useSelector(getDateRange);
  const defaultDateRange = useSelector(getDefaultDateRange);
  const postsExists = defaultDateRange.from && defaultDateRange.to;

  const [dateRange, setDateRange] = useState<any>(
    postsExists
      ? [
          moment(selectedDateRange.from || defaultDateRange.from),
          moment(selectedDateRange.to || defaultDateRange.to),
        ]
      : [],
  );

  const comparePeriods = false;

  const downloadingStatus = useSelector(getDownloadingStatus);
  const graphicDataLoad = useSelector(getGraphicDataLoad);
  const downloadInProgress =
    downloadingStatus.state === DownloadStatus.PROGRESS;

  const handleDateRange = () => {
    if (!postsExists) {
      return triggerWarning(text.noPosts);
    }

    if (Array.isArray(dateRange) && dateRange[0] && dateRange[1]) {
      const d1 = dateRange[0].valueOf();
      const d2 = dateRange[1].valueOf();

      const from = new Date(d1).toISOString();
      const to = new Date(d2).toISOString();

      PostsAnalyzeLogs.dataFiltering('click posts collect data filtering', {
        period: { from, to },
      });

      return dispatch(
        dateModuleActions.setDateRange({
          from: d1,
          to: d2,
          needGraphicFetch: true,
        }),
      );
    }
    return dispatch(
      dateModuleActions.setDateRange({
        from: null,
        to: null,
        needGraphicFetch: true,
      }),
    );
  };

  const resetDateRange = () => {
    const { from, to } = defaultDateRange;
    dispatch(
      dateModuleActions.setDateRange({ from, to, needGraphicFetch: true }),
    );
    setDateRange([moment(from), moment(to)]);
  };

  useEffect(() => {
    if (
      Array.isArray(dateRange) &&
      dateRange[0] &&
      dateRange[1] &&
      comparePeriods
    ) {
      const d1 = dateRange[0].valueOf();
      const d2 = dateRange[1].valueOf();

      if (previousWeekSelected(d1, d2) || previousMonthSelected(d1, d2)) {
        handleDateRange();
      }
    }
  }, [dateRange]);

  return (
    <DatePickerWrapper>
      <StyledDatePicker>
        <span>Изменить отчётный период</span>
        <RangePicker
          disabledDate={() => comparePeriods}
          value={dateRange}
          ranges={{
            ...(!comparePeriods && {
              [text.currentWeek]: [
                moment().startOf('isoWeek'),
                moment().endOf('isoWeek'),
              ],
              [text.currentMonth]: [
                moment().startOf('month'),
                moment().endOf('month'),
              ],
            }),
            [text.previousWeek]: [
              moment().subtract(1, 'weeks').startOf('isoWeek'),
              moment().subtract(1, 'weeks').endOf('isoWeek'),
            ],
            [text.previousMonth]: [
              moment().subtract(1, 'months').startOf('month'),
              moment().subtract(1, 'months').endOf('month'),
            ],
          }}
          onChange={v => !!v && setDateRange(v)}
        />
      </StyledDatePicker>
      <Space>
        <Button
          type="primary"
          disabled={downloadInProgress}
          onClick={handleDateRange}
          loading={graphicDataLoad}
        >
          Обновить
        </Button>
        <Button
          onClick={resetDateRange}
          loading={graphicDataLoad}
          children="Сбросить"
        />
      </Space>
    </DatePickerWrapper>
  );
}

const DatePickerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 35px;
`;

const StyledDatePicker = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-right: 5px;

  & > span {
    position: absolute;
    bottom: 100%;
    left: 25%;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #262626;
  }
`;
