import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { IList, ILists } from './types';
import { getOwnerIdFromEntity } from '../../helpers/auth.helper';

export const initialState: ILists = {
  selectedList: {} as IList,
  lists: [],
  isLoad: false,
};

const slice = createSlice({
  name: 'lists',
  initialState,
  reducers: {
    setSelectedList(state, action: PayloadAction<any>) {
      state.selectedList = action.payload.list;
      return state;
    },
    updateSelectedList(state, action: PayloadAction<any>) {
      state.selectedList = action.payload.list;
      return state;
    },
    setSelectedListWithCheck(state, action: PayloadAction<any>) {
      return state;
    },
    updateList(
      state,
      action: PayloadAction<{ id: number; updatedList: IList }>,
    ) {
      const { id, updatedList } = action.payload;
      const listToUpdate = state.lists.find(list => `${list.id}` === `${id}`);
      if (listToUpdate) {
        listToUpdate.name = updatedList.name;
        listToUpdate.entities = updatedList.entities;
        listToUpdate.lastUpdate = updatedList.lastUpdate;
        listToUpdate.linkedAccount = updatedList.linkedAccount;
        listToUpdate.indexes = updatedList.indexes;
      }

      return state;
    },
    createListOfEntities(state, action: PayloadAction<any>) {
      return state;
    },
    removeEntityFromUserList(state, action: PayloadAction<any>) {
      return state;
    },
    removeUserList(state, action: PayloadAction<any>) {
      return state;
    },
    updateUserList(state, action: PayloadAction<IList>) {
      return state;
    },
    addListOfEntities(state, action: PayloadAction<any>) {
      const {
        id,
        name,
        entities,
        lastUpdate,
        application,
        linkedAccount,
        extended_statistics,
        indexes,
      } = action.payload;
      state.lists = [
        ...state.lists,
        {
          name: name,
          entities,
          id: id,
          lastUpdate,
          application,
          linkedAccount: linkedAccount,
          extended_statistics: extended_statistics,
          indexes,
        },
      ];
      return state;
    },
    requestUserListsOfEntities(state, action: PayloadAction<any>) {
      state.isLoad = action.payload;
      return state;
    },
    setUserListsOfEntities(state, action: PayloadAction<any>) {
      state.lists = [...state.lists, ...action.payload.userListsOfEntities];
      state.isLoad = false;
      return state;
    },
    removeEntityFromList(state, action: PayloadAction<any>) {
      const { id, ownerId } = action.payload;
      const listOfEntities = state.lists.find(list => list.id === id);
      const index =
        listOfEntities &&
        listOfEntities.entities.findIndex(
          entity => getOwnerIdFromEntity(entity, entity.app) === ownerId,
        );
      if (listOfEntities && index) {
        listOfEntities.entities.splice(index, 1);
      }
      return state;
    },
    removeList(state, action: PayloadAction<any>) {
      const { id } = action.payload;
      const index = state.lists.findIndex(list => `${list.id}` === `${id}`);
      if (index >= 0) {
        state.lists.splice(index, 1);
      }
      return state;
    },
    reset(state, action: PayloadAction<any>) {
      state = {
        selectedList: {} as IList,
        lists: [],
        isLoad: false,
      };
      return state;
    },
  },
});

export const {
  actions: listsModuleActions,
  reducer: listsModuleReducers,
} = slice;
