import app from '../../fbConfig';
import moment from 'moment';
import { message } from 'antd';
import { ERRORS } from './firebase.types';

export default class FirebaseUtils {
  public static handleError(error): { error; isError: boolean } {
    return { error, isError: true };
  }

  public static getCurrentUser() {
    return app.auth().currentUser;
  }

  public static getCurrentUserUid(): string | null {
    return app.auth().currentUser?.uid || null;
  }

  public static getCurrentUserToken(): Promise<string> | undefined {
    return app
      .auth()
      .currentUser?.getIdToken(true)
      .then(res => {
        return res;
      });
  }

  public static getIsNewUserStatus(): boolean {
    const creationTime = moment(
      app.auth().currentUser?.metadata.creationTime,
    ).format();
    const lastSignInTime = moment().format();
    return creationTime === lastSignInTime;
  }

  public static async getUserAdditionalInfo(): Promise<any> {
    const user = await this.getCurrentUser();

    if (!user) return Promise.reject(ERRORS.NO_USER);

    return app
      .firestore()
      .collection('users')
      .doc(user.uid)
      .get()
      .then(doc => {
        if (doc.exists) {
          return doc.data();
        } else {
          message.info('Ошибка загрузки профиля');
        }
      });
  }
}
