import {
  getAccess,
  getGroupCovers,
  getGroupInfo,
  getGroupStatistics,
  getPosts25Reach,
  getUserInfo,
  getWallPosts,
  searchEntity,
} from './procedures';
import { message } from 'antd';

export async function getGroupCoversHandler(vkGroupsIds: number[]) {
  try {
    const groupCovers: any = await getGroupCovers(vkGroupsIds);
    return {
      ...groupCovers.response,
    };
  } catch (e) {
    return {
      error: e,
    };
  }
}

export async function getEntityInfoHandler(str: string) {
  try {
    const groupResult: any = await getGroupInfo(str);
    if (
      groupResult.error ||
      (groupResult.errors && groupResult.errors.length > 0)
    ) {
      const userResult: any = await getUserInfo(str);

      if (
        userResult.error ||
        (userResult.errors && userResult.errors.length > 0)
      ) {
        return {
          error: true,
        };
      }
      const userInfo = userResult.response[0];
      return {
        ...userInfo,
      };
    }
    return {
      ...groupResult.response[0],
    };
  } catch (e) {
    return {
      error: e,
    };
  }
}

export async function getUserInfoHandler(str: string) {
  try {
    const userResult: any = await getUserInfo(str);
    const accessResult: any = await getAccess();

    if (
      userResult.error ||
      userResult?.errors?.length > 0 ||
      accessResult.error ||
      accessResult?.errors?.length > 0
    ) {
      return {
        error: true,
      };
    }
    const userInfo = userResult.response[0];
    return {
      ...userInfo,
      // accessRestricted: accessResult.response === 'access denied',
      accessRestricted: false,
    };
  } catch (e) {
    console.log(e, 'eeeeeeeee');
    return {
      error: e,
    };
  }
}

export async function collectWallPostsHandler(params) {
  const response: any = await getWallPosts(params);
  const result =
    (response.errors && response.errors.length > 0) || response.error
      ? { error: true }
      : response;
  return result;
}

export async function getGroups(ids, key) {
  const response: any = await getGroupInfo(ids);

  if ((response.errors && response.errors.length > 0) || response.error) {
    message.error(
      `Произошла ошибка в обновление данных :( Ошибка: ${response.error}`,
    );
  } else {
    message.success({
      content: `Данные успешно обновлены`,
      key: key,
      duration: 2,
    });
  }

  return (response.errors && response.errors.length > 0) || response.error
    ? { error: true }
    : response;
}

export async function searchEntityHandler(q) {
  return searchEntity(q);
}

export async function getPosts25ReachHandler(params, collectId) {
  try {
    const result: any = await getPosts25Reach(params, collectId);
    return result;
  } catch (e) {
    console.log(e, 'e in get post reach');
    return {
      errors: [e],
    };
  }
}

export async function getGroupStatisticsHandler(
  params,
  interval: string,
  intervalCount: number,
) {
  try {
    const groupStatistics: any = await getGroupStatistics(
      params,
      interval,
      intervalCount,
    );
    return groupStatistics.response;
  } catch (e) {
    return {
      error: e,
    };
  }
}
