import React from 'react';
import styled from 'styled-components';

const SitePreview = ({ inputValue }) => {
  return <StyledSpan inputValue={inputValue} />;
};

const StyledSpan = styled.div<{ inputValue: string }>`
  height: 20px;
  width: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('${p =>
    'https://www.google.com/s2/favicons?sz=32&domain_url=' + p.inputValue}');
`;

export default SitePreview;
