import { pjVersion } from '../../../constants/app.constants';
import { constructVkUrl, createRequestBody } from './apiEndpoints';
import { customLogsForRequests } from '../../../helpers/log.helper';
import { IResult } from 'app/state/groupDataModule/types';

(window as any).CallbackRegistry = {};

export function getGroupStatistics(
  params,
  interval: string,
  intervalCount: number,
) {
  return new Promise((resolve, reject) => {
    const url =
      constructVkUrl(
        createRequestBody({
          interval: interval,
          interval_count: intervalCount,
          ...params,
          v: '5.131',
        }),
        'execute.stats25_reach',
      ) || '';
    scriptRequest(url, resolve, reject);
  });
}

export function getGroupCovers(vkGroupsIds: number[]) {
  return new Promise((resolve, reject) => {
    const url =
      constructVkUrl(
        createRequestBody({
          group_ids: vkGroupsIds,
          fields: ['members_count,cover'],
          v: '5.130',
        }),
        'groups.getById',
      ) || '';
    scriptRequest(url, resolve, reject);
  });
}

export function getGroupInfo(str: string) {
  return new Promise((resolve, reject) => {
    const url =
      constructVkUrl(
        createRequestBody({
          groups_id: str,
          v: '5.130',
        }),
        'execute.groups_getById',
      ) || '';
    scriptRequest(url, resolve, reject);
  });
}

export async function getWallPosts({
  ownerId,
  offset,
  countExecute,
  collectId,
}: any) {
  return new Promise((resolve, reject) => {
    // @ts-ignore
    const paramsStr = createRequestBody({
      owner_id: ownerId,
      offset,
      count_execute: countExecute,
      hm_version: pjVersion.replace(/[^0-9]/g, ''),
      v: '5.130',
    });
    const url = constructVkUrl(paramsStr, 'execute.newFuncWall') || '';
    scriptRequest(url, resolve, reject, paramsStr, collectId, ownerId);
  });
}

export async function searchEntity(q: any) {
  return new Promise((resolve, reject) => {
    const url =
      constructVkUrl(
        createRequestBody({
          q,
          v: '5.122',
        }),
        'execute.groups_search',
      ) || '';
    scriptRequest(url, resolve, reject);
  });
}

export async function getUserInfo(str: string) {
  return new Promise((resolve, reject) => {
    const url =
      constructVkUrl(
        createRequestBody({
          v: '5.130',
          users: str,
        }),
        'execute.get_user_id',
      ) || '';
    scriptRequest(url, resolve, reject);
  });
}

export async function getAccess() {
  return new Promise((resolve, reject) => {
    const url =
      constructVkUrl(
        createRequestBody({
          v: '5.130',
        }),
        'execute.get_access',
      ) || '';
    scriptRequest(url, resolve, reject);
  });
}

export async function getPosts25Reach(params, collectId): Promise<IResult> {
  return new Promise((resolve, reject) => {
    const paramsStr = createRequestBody({
      ...params,
      v: '5.130',
      func_v: 2,
    });
    const url = constructVkUrl(paramsStr, 'execute.get_posts25_reach') || '';
    scriptRequest(url, resolve, reject, paramsStr, collectId, params.owner_id);
  });
}

export async function getPostReach(ownerId, postId) {
  return new Promise((resolve, reject) => {
    const url =
      constructVkUrl(
        createRequestBody({
          owner_id: ownerId,
          post_id: postId,
          v: '5.130',
        }),
        'execute.get_post_reach',
      ) || '';
    scriptRequest(url, resolve, reject);
  });
}

export function scriptRequest(
  url,
  onSuccess,
  onError,
  paramsStr = '',
  collectId = '',
  ownerId = '',
) {
  const startTime = new Date().getTime();
  const script: any = document.createElement('script');
  let scriptOk = false; // флаг, что вызов прошел успешно

  // сгенерировать имя JSONP-функции для запроса
  const callbackName = 'cb' + String(Math.random()).slice(-6);

  // укажем это имя в URL запроса
  url += ~url.indexOf('?') ? '&' : '?';
  url += 'callback=CallbackRegistry.' + callbackName;

  // ..и создадим саму функцию в реестре
  (window as any).CallbackRegistry[callbackName] = function (data) {
    scriptOk = true; // обработчик вызвался, указать что всё ок
    delete (window as any).CallbackRegistry[callbackName]; // можно очистить реестр
    script.parentNode.removeChild(script);
    const endTime = new Date().getTime();
    const spentTime = endTime - startTime;
    customLogsForRequests({
      ownerId,
      collectId,
      url,
      paramsStr,
      status: 200,
      spentTime,
      logType: 'INFO',
    });
    onSuccess(data); // и вызвать onSuccess
    script.remove();
  };

  // эта функция сработает при любом результате запроса
  // важно: при успешном результате - всегда после JSONP-обработчика
  function checkCallback() {
    if (scriptOk) return; // сработал обработчик?
    delete (window as any).CallbackRegistry[callbackName];
    script.parentNode.removeChild(script);
    const endTime = new Date().getTime();
    const spentTime = endTime - startTime;
    customLogsForRequests({
      ownerId,
      collectId,
      url,
      paramsStr,
      status: 500,
      spentTime,
      logType: 'ERROR',
    });
    onError({ message: url }); // нет - вызвать onError
    script.remove();
  }

  // в старых IE поддерживается только событие, а не onload/onerror
  // в теории 'readyState=loaded' означает "скрипт загрузился",
  // а 'readyState=complete' -- "скрипт выполнился", но иногда
  // почему-то случается только одно из них, поэтому проверяем оба
  script.onreadystatechange = function () {
    if (this.readyState === 'complete' || this.readyState === 'loaded') {
      this.onreadystatechange = null;
      setTimeout(checkCallback, 0); // Вызвать checkCallback - после скрипта
    }
  };

  // события script.onload/onerror срабатывают всегда после выполнения скрипта
  script.onload = script.onerror = checkCallback;
  script.src = url;

  document.body.appendChild(script);
}
