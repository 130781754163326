import XLSX from 'xlsx';
import { App } from '../constants/enums';
import { generateUID, getOwnerIdFromEntity } from './auth.helper';
import moment from 'moment';
import { nanoid } from 'nanoid';

export const changeColumnTypeToPercent = (ws, column) => {
  const colNum = XLSX.utils.decode_col(column); // decode_col converts Excel col name to an integer for col #
  const fmt = '0.00##\\%'; // or '"$"#,##0.00_);[Red]\\("$"#,##0.00\\)' or any Excel number format
  const range = XLSX.utils.decode_range(ws['!ref']);
  // eslint-disable-next-line no-plusplus
  for (let i = range.s.r + 1; i <= range.e.r; ++i) {
    /* find the data cell (range.s.r + 1 skips the header row of the worksheet) */
    const ref = XLSX.utils.encode_cell({ r: i, c: colNum });
    /* if the particular row did not contain data for the column, the cell will not be generated */
    // eslint-disable-next-line no-continue
    if (!ws[ref]) continue;
    /* `.t == "n"` for number cells */
    // eslint-disable-next-line no-continue
    if (ws[ref].t !== 'n') continue;
    /* assign the `.z` number format */
    ws[ref].z = fmt;
  }
};

export const createExcelFileName = (
  application,
  listSelected,
  selectedList,
  dateFromSeconds,
  dateToSeconds,
  entityInfo,
) => {
  const hash = generateUID();
  const appMap = {
    0: 'vk',
    1: 'ok',
    2: 'tg',
  };

  const createUnloadingPeriodString = () => {
    const from = moment(dateFromSeconds).format('DD-MM-YY');
    const to = moment(dateToSeconds).format('DD-MM-YY');

    return `${from}_${to}`;
  };

  const createNameString = () => selectedList.name || entityInfo.name;

  const appName = appMap[application];
  const unloadingPeriod = createUnloadingPeriodString();
  const name = createNameString();

  return `${appName}_${name}_${unloadingPeriod}_${hash}.xlsx`;
};
