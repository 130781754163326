// eslint-disable-next-line import/prefer-default-export
export const graphicCompareDefaultData = () => {
  const graphicCompareMap = {};
  // fill 31 days with 0 values
  for (let i = 1; i < 32; i += 1) {
    graphicCompareMap[i] = {
      ax: i,
      bx: i,
      ay: 0,
      by: 0,
    };
  }
  return graphicCompareMap;
};
