import { ITotalRates } from '../../../state/ratesModule/types';

export interface TableRatesProps {
  totalRates: ITotalRates;
}

export interface IRate {
  app: number;
  commentsCount: number;
  repostsCount: number;
  likesCount: number;
  postsCount: number;
  viewsCount: number;
  erv: number;
  erp: number;
  membersCount: number;
  reachTotal: number;
  err: number;
  name: string;
  id: string;
  reachSubscribers: number;
  reachAds: number;
  reachViral: number;
  toGroup: number;
  joinGroup: number;
  unsubscribe: number;
  links: number;
  report: number;
  hide: number;
  // adViews: number;
  // adSubscribers: number;
  // adHide: number;
  // adUnsubscribe: number;
  // adLinks: number;
  // adToGroup: number;
  // adJoinGroup: number;
  // adCoverage: number;
  // adReport: number;
}

export const COLUMN_NAMES = [
  {
    key: 'name',
    render: 'Название',
  },
  {
    key: 'membersCount',
    render: 'Подписчики',
  },
  {
    key: 'postsCount',
    render: 'Посты',
  },
  {
    key: 'reachTotal',
    render: 'Охват',
  },
  {
    key: 'viewsCount',
    render: 'Просмотры',
  },
  {
    key: 'likesCount',
    render: 'Лайки',
  },
  {
    key: 'repostsCount',
    render: 'Репосты',
  },
  {
    key: 'commentsCount',
    render: 'Комментарии',
  },
  {
    key: 'erv',
    render: 'ER.v, %',
  },
  {
    key: 'erp',
    render: 'ER.p',
  },
  {
    key: 'err',
    render: 'ER.r',
  },
  {
    key: 'joinGroup',
    render: 'Вступления в сообщество',
  },
  {
    key: 'unsubscribe',
    render: 'Отписавшихся участников',
  },
  {
    key: 'reachSubscribers',
    render: 'Охват подписчиков',
  },
  {
    key: 'reachAds',
    render: 'Рекламный охват',
  },
  {
    key: 'reachViral',
    render: 'Виральный охват',
  },
  {
    key: 'toGroup',
    render: 'Переходы в сообщество',
  },
  {
    key: 'links',
    render: 'Переходы по ссылке',
  },
  {
    key: 'report',
    render: 'Жалоб на запись',
  },
  {
    key: 'hide',
    render: 'Скрывших запись',
  },
];

export const RATE_TOTAL_KEYS: string[] = [
  'membersCount',
  'postsCount',
  'reachTotal',
  'viewsCount',
  'likesCount',
  'repostsCount',
  'commentsCount',
  'erv',
  'erp',
  'err',
  'joinGroup',
  'unsubscribe',
  'reachSubscribers',
  'reachAds',
  'reachViral',
  'toGroup',
  'links',
  'report',
  'hide',
];
