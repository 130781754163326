import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './index';
import { RootState } from '../../../types';

const selectSlice = (state: RootState) => state.siteStatistics || initialState;

export const getSiteStatisticsGraphicData = createSelector(
  [selectSlice],
  state => state.graphicsData,
);

export const getSiteStatisticsFilteredGraphicData = createSelector(
  [selectSlice],
  state => state.filteredGraphicsData,
);

export const getSiteStatisticsData = createSelector(
  [selectSlice],
  state => state.siteStatistics,
);

export const getSiteStatisticsLoad = createSelector(
  [selectSlice],
  state => state.isLoad,
);

export const getSiteStatisticsSelectedList = createSelector(
  [selectSlice],
  state => state.selectedList,
);

export const getSiteStatisticsSteps = createSelector(
  [selectSlice],
  state => state.steps,
);

export const getSiteStatisticsMessage = createSelector(
  [selectSlice],
  state => state.message,
);
