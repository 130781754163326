import { APP_CREDENTIALS, AUTH_TYPE_OK } from '../constants/auth.constants';
import { getCredentialFromCache } from '../localStorage';
import { generateSIG } from './auth.helper';
import { message } from 'antd';

export const constructOKUrl = (
  userId = null,
  method: string,
  queryParams: string[] = [],
) => {
  const accessToken = getCredentialFromCache(
    AUTH_TYPE_OK,
    'accessToken',
    userId,
  );
  const sessionSecretKey = getCredentialFromCache(
    AUTH_TYPE_OK,
    'sessionSecretKey',
    userId,
  );
  const sig = generateSIG(method, sessionSecretKey, queryParams);
  const { url, key } = APP_CREDENTIALS[AUTH_TYPE_OK];
  return `${url}?application_key=${key}&format=json&method=${method}&sig=${sig}&access_token=${accessToken}&${queryParams.join(
    '&',
  )}`;
};

export const getUserInfoOk = async (userId = null) => {
  const method = 'users.getCurrentUser';
  const fetchUrl = constructOKUrl(userId, method);
  const response = await fetch(fetchUrl);
  return response.json();
};

export const getEntityIdOk = async (link: string) => {
  const method = 'url.getInfo';
  const queryParams = [`url=${link}`];
  const fetchUrl = constructOKUrl(null, method, queryParams);
  const response = await fetch(fetchUrl);
  return response.json();
};

export const getEntityInfoOk = async (uid: string) => {
  const method = 'group.getInfo';
  const queryParams = [
    `uids=${uid}`,
    `fields=UID,NAME,PIC_AVATAR,MEMBERS_COUNT,ROLE`,
  ];
  const fetchUrl = constructOKUrl(null, method, queryParams);
  const response = await fetch(fetchUrl);
  return response.json();
};

export const getEntityInfoOkUrl = async (uid: string) => {
  const method = 'group.getInfo';
  const queryParams = [
    `uids=${uid}`,
    `fields=UID,NAME,PIC_AVATAR,MEMBERS_COUNT,ROLE`,
  ];
  const fetchUrl = constructOKUrl(null, method, queryParams);
  const response = await fetch(fetchUrl);
  return response.json();
};

export const getUserGroupsByIdsOk = async (
  groupId: number | string,
  uids: string[],
  key,
) => {
  const method = 'group.getUserGroupsByIds';
  const queryParams = [`uids=${uids.join(',')}`, `group_id=${groupId}`];
  const fetchUrl = constructOKUrl(null, method, queryParams);
  const response = await fetch(fetchUrl);

  if (!response) {
    message.error(`Произошла ошибка в обновление данных :(`);
  } else {
    message.success({
      content: `Данные успешно обновлены`,
      key: key,
      duration: 2,
    });
  }
  return response.json();
};

export const streamGetOk = async (groupId: number | string, anchor: string) => {
  const method = 'stream.get';
  const queryParams = [
    `gid=${groupId}`,
    'fields=feed.*,media_topic.*,group_photo.*,video.*',
    'patterns=POST,CONTENT,PHOTO',
    'count=100',
  ];

  if (anchor) {
    queryParams.push(`anchor=${anchor}`);
  }

  const fetchUrl = constructOKUrl(null, method, queryParams);
  const response = await fetch(fetchUrl);
  return response.json();
};

export const getStatTopicsOk = async (
  groupId: number | string,
  startTime: number,
  endTime: number,
  anchor: string,
) => {
  const method = 'group.getStatTopics';
  const queryParams = [
    'fields=CREATED_MS,ID,REACH,LIKES,RESHARES,COMMENTS,VIEWS',
    `gid=${groupId}`,
    `start_time=${startTime}`,
    `end_time=${endTime}`,
    `anchor=${anchor}`,
    'count=50',
  ];
  const fetchUrl = constructOKUrl(null, method, queryParams);
  const response = await fetch(fetchUrl);
  return response.json();
};
