import { takeEvery, call, put, select, all } from 'redux-saga/effects';
import { authModuleActions as actions } from './index';
import { socialAccountsModuleActions } from '../socialAccountsModule';
import { initModuleActions } from '../initModule';
import { AUTH_TYPE_OK, AUTH_TYPE_VK } from '../../constants/auth.constants';
import { IUser } from '../socialAccountsModule/types';
import { getUserInfoOk } from '../../helpers/ok.requests';
import {
  getCredentialFromCache,
  setUserCredentials,
  setUserInfo,
} from '../../localStorage';
import { getUserInfoHandler } from '../../services/client/vk/handlers';
import Post from '../../services/client/database/classes/post';
import { resetAll } from '../resetModule';
import FirebaseAccountService from '../../firebase/firebase.user.service';

function* getUserInfo(_userId, _application) {
  const application =
    typeof _application === 'number'
      ? _application
      : localStorage.getItem('application');
  const applicationNum = application && +application;
  const userInfo: IUser = {} as IUser;
  if (applicationNum || applicationNum === 0) {
    userInfo.social = applicationNum;
  }

  switch (applicationNum) {
    case AUTH_TYPE_VK: {
      const userId = _userId || localStorage.getItem('userId');
      const user = yield call(getUserInfoHandler, `id${userId}`);
      userInfo.accessRestricted = user.accessRestricted;
      userInfo.firstName = user.first_name;
      userInfo.lastName = user.last_name;
      userInfo.avatar = user.photo_100;
      userInfo.userId = userId;
      yield call(setUserInfo, userInfo);
      return userInfo;
    }
    case AUTH_TYPE_OK: {
      const user = yield call(getUserInfoOk, _userId);
      yield call(
        setUserCredentials,
        applicationNum,
        user.uid,
        getCredentialFromCache(applicationNum, 'sessionSecretKey', user.uid),
        getCredentialFromCache(applicationNum, 'accessToken', user.uid),
      );
      userInfo.firstName = user.first_name;
      userInfo.lastName = user.last_name;
      userInfo.accessRestricted = false;
      userInfo.avatar = user.pic_1;
      userInfo.userId = user.uid;
      yield call(setUserInfo, userInfo);
      if (!localStorage.getItem(`${userInfo.userId}_allowedRequestsCount`)) {
        localStorage.setItem(`${userInfo.userId}_allowedRequestsCount`, '25');
      }
      return userInfo;
    }
    default:
      return userInfo;
  }
}

function* commonAuthSaga({
  payload: { _userId, _application = null, _userInfo },
}) {
  const userId = _userId || localStorage.getItem('userId');
  const allowedRequestsCount = localStorage.getItem(
    `${userId}_allowedRequestsCount`,
  );
  const allowedRequestsCountInNum =
    allowedRequestsCount && +allowedRequestsCount;
  let userInfo = _userInfo
    ? { ..._userInfo, accessRestricted: false }
    : _userInfo;

  if (!userInfo || Object.keys(userInfo).length <= 3) {
    userInfo = yield call(getUserInfo, userId, _application);
  }

  const user = {
    ...userInfo,
    allowedRequestsCountInNum,
  };

  // TODO to try catch the case when userId is null and typeof string
  if (user && user.userId) {
    yield put(socialAccountsModuleActions.addAccountSuccess({ user: user }));
  }
}

function* signOutSaga() {
  yield put(resetAll({ type: 'SIGN_OUT' }));
  yield call(FirebaseAccountService.logout);
  yield call(Post.clearTable);
  localStorage.clear();
}

export function* userAuthModuleSaga() {
  yield takeEvery(actions.commonAuth, commonAuthSaga);
  yield takeEvery(actions.signOut, signOutSaga);
}
