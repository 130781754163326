import FirebaseUtils from '../../../firebase/firebase.utils';
import { customAlphabet } from 'nanoid';
import axios from 'axios';

const nanoid = customAlphabet('1234567890', 10);

const url =
  process.env.REACT_APP_DEV === 'true'
    ? 'https://us-central1-hitalama-aea54.cloudfunctions.net/sitesApiDev/'
    : 'https://europe-west3-hitalama-prod.cloudfunctions.net/sitesApiProd/';

export default class SitesApiServices {
  public static async getSiteInfo(str: string) {
    return {
      id: parseInt(nanoid()),
      photo_100: `https://www.google.com/s2/favicons?sz=64&domain_url=${str}`,
      name: str,
      type: 'site',
    };
  }

  public static async getVisits(domainName: string, monthLimit: number) {
    const token = await FirebaseUtils.getCurrentUserToken();
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const response = await axios.get(
      `${url}getVisits?domainName=${domainName}&monthLimit=${monthLimit}`,
      config,
    );

    return response.data;
  }
}
