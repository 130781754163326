import React, { useState } from 'react';

import { DownloadOutlined } from '@ant-design/icons';
import { Button, DatePicker, Modal, Select, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { getSelectedApplication } from '../../../state/socialAccountsModule/selector';
import {
  cancelCollectPosts,
  entityModuleActions,
} from '../../../state/entityFetchModule';
import { App, AppMaps } from '../../../constants/enums';
import styled from 'styled-components';
import { IList } from '../../../state/listsModule/types';
import { IEntityInfo } from '../../../state/entityFetchModule/types';
import { getSelectedList } from '../../../state/listsModule/selectors';
import moment from 'moment';

import { getUser } from '../../../state/userAuthModule/selectors';
import { getCollectLoad } from '../../../state/entityFetchModule/selector';
import { PostsAnalyzeLogs } from '../../../services/amplitude/amplitude';
import { Moment } from 'moment-timezone';

const { Option } = Select;
const { RangePicker } = DatePicker;

type Props = {
  downloadInProgress;
  setSelectedCountExecute: (selectValue: number) => void;
  selectedListExists: boolean;
  isBlockedUser?: boolean;
  entityInfo: IEntityInfo;
};

export default function CollectPostsBlock({
  downloadInProgress,
  setSelectedCountExecute,
  selectedListExists,
  entityInfo,
  isBlockedUser,
}: Props) {
  const dispatch = useDispatch();
  const [selectValue, setSelectValue] = useState(300);
  const [selectOffset, setSelectOffset] = useState(
    moment(new Date()).utcOffset() / 60,
  );

  const date = new Date();
  const [dateRange, setDateRange] = useState<[Moment, Moment]>([
    moment(date.setHours(0, 0, 1, 0)).subtract(1, 'months').date(1),
    moment(date.setHours(23, 59, 59, 0)),
  ]);

  const application = useSelector(getSelectedApplication);
  const selectedList: IList = useSelector(getSelectedList);
  const user = useSelector(getUser);

  const handleCollect = () => {
    if (selectValue) {
      if (
        selectedListExists &&
        application &&
        selectedList &&
        typeof selectedList.application === 'number' &&
        application !== selectedList.application
      ) {
        Modal.warning({
          title:
            'Выберете соответствующую социальную платформу, чтобы запустить сбор для данного листа',
        });
      } else {
        dispatch(
          entityModuleActions.collectWallPosts({
            countExecute: selectValue,
            isList: selectedListExists,
            collectRange: dateRange,
            selectOffset: selectOffset,
          }),
        );
        setSelectedCountExecute(selectValue);

        const collectData = {
          period: {
            from: dateRange[0].toISOString(),
            to: dateRange[1].toISOString(),
          },
          packSize: selectValue,
          collectType: 'default',
          social: AppMaps[selectedList?.application || entityInfo.app],
          numberOfObjects: selectedList?.entities?.length || null,
        };

        PostsAnalyzeLogs.postsCollect('click posts collect', collectData);
      }
    }
  };

  function disabledTgDate(current: moment.MomentInput) {
    const startDate = moment().subtract(180, 'day');
    const endDate = moment();
    return !(startDate.isBefore(current) && endDate.isAfter(current));
  }

  const collectLoad = useSelector(getCollectLoad);

  return (
    <Column>
      <CollectWallPosts>
        {application === App.vk && user.tariffPlan === 'beta' && (
          <Select
            onChange={(value: number) => setSelectValue(value)}
            style={{
              position: 'absolute',
              right: '100%',
              marginRight: '10px',
              width: '75px',
            }}
            optionFilterProp="children"
            value={selectValue}
          >
            <Option value={300}>300</Option>
            <Option value={900}>900</Option>
            <Option value={1500}>1500</Option>
            <Option value={3000}>3000</Option>
            <Option value={7500}>7500</Option>
          </Select>
        )}
        <Button
          id="collect-posts-button"
          type="primary"
          loading={collectLoad}
          disabled={isBlockedUser}
          onClick={handleCollect}
          icon={<DownloadOutlined />}
        >
          {application !== 3 ? 'Собрать посты' : 'Собрать визиты'}
        </Button>
        {selectedListExists && downloadInProgress && (
          <Button
            id="collect-posts-button"
            style={{
              width: '123px',
              marginRight: '10px',
            }}
            type="primary"
            disabled={!downloadInProgress}
            onClick={() => dispatch(cancelCollectPosts())}
          >
            Отменить
          </Button>
        )}
      </CollectWallPosts>
      <StyledRangePicker
        disabledDate={
          application === 2
            ? disabledTgDate
            : () => downloadInProgress || isBlockedUser
        }
        value={dateRange}
        onCalendarChange={(v: any) => {
          if (!!v && v[0] && v[1]) {
            setDateRange(v);
          } else {
            setDateRange([
              moment(date.setHours(0, 0, 1, 0)).subtract(1, 'months').date(1),
              moment(date.setHours(23, 59, 59, 0)),
            ]);
          }
        }}
        disabled={[false, true]}
      />
      <Typography.Text>Часовой пояс</Typography.Text>
      <Select
        onChange={(value: number) => setSelectOffset(value)}
        optionFilterProp="children"
        value={selectOffset}
      >
        <Option value={-1}>-01:00</Option>
        <Option value={-2}>-02:00</Option>
        <Option value={-3}>-03:00</Option>
        <Option value={-4}>-04:00</Option>
        <Option value={-5}>-05:00</Option>
        <Option value={-6}>-06:00</Option>
        <Option value={-7}>-07:00</Option>
        <Option value={-8}>-08:00</Option>
        <Option value={-9}>-09:00</Option>
        <Option value={-10}>-10:00</Option>
        <Option value={-11}>-11:00</Option>
        <Option value={-12}>-12:00</Option>
        <Option value={0}>00:00</Option>
        <Option value={1}>+01:00</Option>
        <Option value={2}>+02:00</Option>
        <Option value={3}>+03:00</Option>
        <Option value={4}>+04:00</Option>
        <Option value={5}>+05:00</Option>
        <Option value={6}>+06:00</Option>
        <Option value={7}>+07:00</Option>
        <Option value={8}>+08:00</Option>
        <Option value={9}>+09:00</Option>
        <Option value={10}>+10:00</Option>
        <Option value={11}>+11:00</Option>
        <Option value={12}>+12:00</Option>
      </Select>
    </Column>
  );
}

const StyledRangePicker = styled(RangePicker)`
  margin-top: 10px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const CollectWallPosts = styled.div`
  display: flex;
  align-self: flex-end;
  position: relative;
`;
