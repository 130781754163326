import { getCredentialFromCache } from '../../../localStorage';
import { AUTH_TYPE_OK } from '../../../constants/auth.constants';

export const VK_CORE_API = 'https://api.vk.com/method/';

export const constructVkUrl = (params, method) => {
  const userId: any = localStorage.getItem('userId');
  if (userId) {
    const accessToken = getCredentialFromCache(
      AUTH_TYPE_OK,
      'accessToken',
      userId,
    );

    return `${VK_CORE_API}${method}?${params}timout=60e3&access_token=${accessToken}`;
  }

  return null;
};

export const createRequestBody = params => {
  let paramsStr = '';
  let index = 0;
  const keys = Object.keys(params);
  for (let key of keys) {
    paramsStr += `${key}=${params[key]}`;
    if (index < keys.length - 1) {
      paramsStr += '&';
    }
    index += 1;
  }
  return paramsStr.endsWith('&') ? paramsStr : `${paramsStr}&`;
};
