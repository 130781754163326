import styled from 'styled-components';
import { Empty } from 'antd';

export const StyledContainer = styled.div`
  padding: 2%;

  > div {
    border-bottom: 1px solid #e8e8e8;
  }

  > div:first-child {
    border-bottom: none;
  }

  > div:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;

export const StyledCoverCard = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  padding-bottom: 15px;
`;

export const StyledCoverImage = styled.img`
  object-fit: cover;
  border-radius: 5px;
  width: ${props => props.width};
  height: ${props => props.height};
`;

export const StyledCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  max-width: 1080px;
`;

export const SpinWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 160px);
  width: 100%;
`;

export const ListHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  height: 64px;
  min-height: 64px;
  max-height: 64px;
  border-bottom: 1px solid #d9d9d9;
  padding: 25px;
`;

export const PageName = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #262626;
`;

export const StyledEmpty = styled(Empty)`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 1080px;
  height: 360px;
  background-color: #f4f4f4;
  border-radius: 5px;
`;
