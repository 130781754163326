import React, { memo, useEffect, useMemo } from 'react';

import { useSelector } from 'react-redux';

import { previousMonthSelected } from '../../../helpers/date.helper';
import Graphic from '../Graphic';
import GraphicCompare from '../GraphicCompare';
import styled from 'styled-components';
import { getSelectedList } from '../../../state/listsModule/selectors';
import {
  getGraphicData,
  getGraphicDataCompare,
  getGraphicsRender,
} from '../../../state/graphicDataModule/selector';
import { getDateRange } from '../../../state/dateModule/selectors';

type Props = {
  isActive: boolean;
  seriesNames;
  additionalGraphicTypes;
};

function Graphics({ isActive, seriesNames, additionalGraphicTypes }: Props) {
  const graphicData = useSelector(getGraphicData);
  const selectedList = useSelector(getSelectedList);
  const comparePeriods = false;
  const graphicDataCompare = useSelector(getGraphicDataCompare);
  const { from: dateFrom, to: dateTo } = useSelector(getDateRange);
  const selectedListExists = useMemo(
    () => Object.keys(selectedList).length > 0,
    [selectedList],
  );
  const previousMonthIsSelected = useMemo(
    () => dateFrom && dateTo && previousMonthSelected(dateFrom, dateTo),
    [dateFrom, dateTo],
  );
  const showGraphicCompare = useMemo(
    () => comparePeriods && previousMonthIsSelected && !selectedListExists,
    [comparePeriods, previousMonthIsSelected, selectedListExists],
  );

  if (process.env.REACT_APP_DEV === 'true') {
    console.log('graphicData: ', graphicData, 'in Graphics');
  }

  const types = useMemo(
    () => [
      'viewsCount',
      'postsCount',
      'likesCount',
      'repostsCount',
      'commentsCount',
      ...additionalGraphicTypes,
    ],
    [additionalGraphicTypes],
  );

  const formattedTypes = {
    idHitalamaSiteStatistics: types,
    idListStatistics: types,
    [`${graphicData.id}`]:
      additionalGraphicTypes.length > 0
        ? [
            'reachTotal',
            ...types.filter(type => type !== 'reachTotal').slice(0, 14),
          ]
        : types,
  };

  return (
    <>
      {isActive && (
        <Container>
          {showGraphicCompare && false && (
            <GraphicCompare
              id={graphicDataCompare.id}
              data={graphicDataCompare.data}
            />
          )}
          {formattedTypes[graphicData.id].map(type => (
            <Graphic
              seriesNames={seriesNames}
              id={graphicData.id}
              key={type}
              type={type}
              graphicData={
                graphicData[type] ? Object.values(graphicData[type]) : []
              }
            />
          ))}
        </Container>
      )}
    </>
  );
}

const Container = styled.div`
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 15px;
`;

export default memo(Graphics);
