import React, { useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, Navigate } from 'react-router-dom';
import { ExclamationCircleOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Layout, Radio, Button, Modal, Dropdown, Space } from 'antd';

import routes from '../../../../configs/app.routes';

import './AppHeader.css';
import {
  getDisabledAccount,
  getSelectedAccount,
  getUserAccounts,
} from '../../../state/socialAccountsModule/selector';

import { socialAccountsModuleActions } from '../../../state/socialAccountsModule';
import { authModuleActions } from '../../../state/userAuthModule';
import FirestoreListService from '../../../firebase/firestore.list.service';
import { getUser } from '../../../state/userAuthModule/selectors';
import ProfileEditModal from '../UserProfile/ProfileEditModal';
import { changeSocialSvg } from '../../../helpers/getAppIcon.helper';
import Profile from '../UserProfile/Profile';
import { ProfileMenu } from '../UserProfile/ProfileMenu';
import { listsModuleActions } from '../../../state/listsModule';

const { Header } = Layout;

export function AppHeader() {
  const dispatch = useDispatch();
  const accountsMap = useSelector(getUserAccounts);
  const accounts = useMemo(() => Object.values(accountsMap), [accountsMap]);
  const selectedAccount = useSelector(getSelectedAccount);
  const disabledAccounts = useSelector(getDisabledAccount);
  const user = useSelector(getUser);
  const navigate = useNavigate();

  const handleOnChange = e => {
    if (e.target.value === 'hitalamaSiteStatistics') {
      navigate(routes.app.siteStatistics.path, { state: e.target.value });
    } else {
      navigate(routes.app.collectAndAnalyze, { state: e.target.value });
    }

    dispatch(
      socialAccountsModuleActions.changeUser({
        user: accountsMap[e.target.value],
      }),
    );
  };

  const [loading, setLoading] = useState<boolean>(false);
  const [menuVisible, setMenuVisible] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [editProfileVisible, setEditProfileVisible] = useState<boolean>(false);

  function onSignOut() {
    if (user.isAnonymous) {
      setVisible(true);
    } else {
      dispatch(authModuleActions.signOut());
    }
  }

  function handleExit() {
    setLoading(true);
    FirestoreListService.removeAllUserDocument().then(() => {
      dispatch(authModuleActions.signOut());
      setVisible(false);
      setLoading(false);
    });
  }

  function handleMenuClick(e) {
    if (e.key === '2') {
      setMenuVisible(true);
    }
  }

  return (
    <Container
      className={`site-layout-background`}
      style={{
        justifyContent:
          accounts.length > 0 || selectedAccount.accessRestricted
            ? 'space-between'
            : 'flex-end',
      }}
    >
      <AccountsContainer>
        {accounts.length > 0 && (
          <>
            <CollectTitle>Сбор осуществляется от имени</CollectTitle>
            <div>
              <Radio.Group
                onChange={handleOnChange}
                style={{
                  display: 'flex',
                }}
                value={selectedAccount.userId}
              >
                {accounts.map(account => (
                  <Account key={account.userId}>
                    <Radio
                      style={{
                        position: 'absolute',
                        zIndex: 1,
                        top: '15px',
                        left: '20px',
                      }}
                      value={account.userId}
                      disabled={disabledAccounts[account.userId || '']}
                    />
                    <StyledAvatar
                      src={account.avatar}
                      size={32}
                      icon={<UserOutlined />}
                    />
                    <StyledSvgIcon
                      src={`${changeSocialSvg(account.social)}`}
                      alt="social svg"
                    />
                  </Account>
                ))}
              </Radio.Group>
            </div>
            <Link
              to={routes.app.auth}
              style={{
                marginLeft: '10px',
              }}
            >
              добавить
            </Link>
          </>
        )}
      </AccountsContainer>
      <Space>
        {user.isAnonymous && (
          <Button
            type="primary"
            children="Зарегистрироваться"
            onClick={() => navigate(routes.signUp)}
          />
        )}
        <StyledDropdown
          overlay={ProfileMenu({
            setEditProfileVisible,
            onSignOut,
            handleMenuClick,
            setVisible,
          })}
          trigger={['click']}
          onVisibleChange={setMenuVisible}
          visible={menuVisible}
          children={
            <div>
              <Profile user={user} expand={false} />
            </div>
          }
        />
      </Space>
      <Modal
        visible={visible}
        title={
          <StyledModalTitle>
            <ExclamationCircleOutlined />
            <span>Внимание!</span>
          </StyledModalTitle>
        }
        onCancel={() => setVisible(false)}
        footer={[
          <Button danger key="submit" loading={loading} onClick={handleExit}>
            Выйти
          </Button>,
          <Button
            key="link"
            type="primary"
            onClick={() => navigate(routes.signUp)}
          >
            Зарегистрироваться
          </Button>,
        ]}
      >
        <p>
          Все списки пользователя будут удалены после выхода из приложения. Если
          вы хотите сохранить созданные списки авторизуйтесь через email-адрес
        </p>
      </Modal>
      <ProfileEditModal
        isModalVisible={editProfileVisible}
        handleCancel={() => setEditProfileVisible(false)}
        setEditProfileVisible={setEditProfileVisible}
      />
    </Container>
  );
}

const AvatarImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  -webkit-mask-image: url(#mask);
  mask-image: url(#mask);
`;

const Container = styled(Header)`
  padding: 0 15px 0 0;
  height: 64px;
  display: flex;
  align-items: center;
`;

const AccountsContainer = styled.div`
  padding: 0;
  height: 64px;
  display: flex;
  align-items: center;
`;

const CollectTitle = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.48);
  margin-left: 30px;
`;

const Account = styled.div`
  position: relative;
  margin-left: 15px;
`;

const StyledSvgIcon = styled.img`
  position: absolute;
  bottom: 20px;
  left: -5px;
  height: 17px;
  width: 17px;
`;

const StyledDropdown = styled(Dropdown)`
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 64px;
  justify-content: space-between;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const StyledModalTitle = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
  }
`;

const StyledAvatar = styled(Avatar)`
  img {
    object-fit: cover;
  }
`;
