import {
  APP_CREDENTIALS,
  AUTH_TYPE_OK,
  AUTH_TYPE_TG,
  AUTH_TYPE_VK,
  SOCIAL_NAMES,
} from '../constants/auth.constants';
import md5 from 'crypto-js/md5';
import { logEvent } from '../libs/lib.amplitude';
import { socialAccountsModuleActions } from '../state/socialAccountsModule';
import routes from '../../configs/app.routes';

import { INFO_BLOCK_VARIANT } from '../constants/collect.constants';
import { App, socialAccountRole } from '../constants/enums';
import { IEntityInfo } from '../state/entityFetchModule/types';
import { EntityType } from '../constants/app.constants';
import { initModuleActions } from '../state/initModule';
import { IIndexes } from '../state/listsModule/types';

export const getAuthUrl = (authType: string, permissions: any[]): string => {
  const requestedPermissionsExists = permissions.length > 0;

  const appAuthUrlMap = {
    [AUTH_TYPE_VK]: `https://oauth.vk.com/authorize?client_id=7803698&display=popup&redirect_uri=${
      window.location.origin
    }${routes.app.collectAndAnalyze}${
      requestedPermissionsExists ? '?permission_request=1' : ''
    }&scope=${
      requestedPermissionsExists ? `${permissions.join()},` : ''
    }groups,wall,offline&response_type=token&v=5.100`,
    [AUTH_TYPE_OK]: `https://connect.ok.ru/oauth/authorize?client_id=1267244800&scope=VALUABLE_ACCESS;LONG_ACCESS_TOKEN;PHOTO_CONTENT;GROUP_CONTENT;VIDEO_CONTENT;APP_INVITE;GET_EMAIL&response_type=token&redirect_uri=${window.location.origin}${routes.app.collectAndAnalyze}`,
  };

  localStorage.setItem('application', authType);

  return appAuthUrlMap[authType];
};

export const generateSIG = (
  method: string,
  sessionSecretKey: string,
  queryParams: string[] = [],
) => {
  const params: string[] = [];
  params.push(`application_key=${APP_CREDENTIALS[App.ok].key}`);
  params.push(`format=json`);
  params.push(`method=${method}`);
  params.push(...queryParams);
  return md5(`${params.sort().join('')}${sessionSecretKey}`).toString();
};

export const getCredentialsFromAuthUrl = (url, dispatch) => {
  dispatch(initModuleActions.setLoading(true));

  if (url) {
    const rawCode = /access_token=([^&]*)/.exec(url) || null;
    const accessToken = rawCode && rawCode.length > 1 ? rawCode[1] : null;
    const error = /\?error=(.+)$/.exec(url);
    const userIdArr = /user_id=([^&]*)/.exec(url);
    const errorReasonArr = /error_reason=([^&]*)/.exec(url);
    const userId = userIdArr && userIdArr.length > 1 ? userIdArr[1] : null;
    const errorReason =
      errorReasonArr && errorReasonArr.length > 1 ? errorReasonArr[1] : null;
    const isPermArr = /permission_request=([^&]*)/.exec(url);
    const isPermissionRequest =
      isPermArr && isPermArr.length > 1
        ? !!+isPermArr[1].replace(/\D/g, '')
        : null;

    // OK
    const sessionSecretKeyArr = /session_secret_key=([^&]*)/.exec(url);
    const sessionSecretKey =
      sessionSecretKeyArr && sessionSecretKeyArr.length > 1
        ? sessionSecretKeyArr[1]
        : null;
    const isOK = !!sessionSecretKey;
    // OK

    logEvent(`recv. accessToken: ${accessToken}`);
    logEvent(`recv. error: ${error}`);
    logEvent(`recv. userId: ${userId}`);
    logEvent(`recv. errorReason: ${errorReason}`);
    logEvent(`recv. isPermissionRequest: ${isPermissionRequest}`);
    if (accessToken) {
      const application = isOK ? App.ok : App.vk;
      logEvent('accessToken exists setting....');
      dispatch(
        socialAccountsModuleActions.setApplication({
          application,
        }),
      );

      // @ts-ignore
      localStorage.setItem('application', application);
      dispatch(
        socialAccountsModuleActions.addAccount({
          accessToken,
          error,
          userId,
          errorReason,
          isPermissionRequest,
          sessionSecretKey,
        }),
      );
    }
  }
};

export function getCredentialsFromAuth(authCred, dispatch) {
  const accessToken = authCred.accessToken,
    userId = authCred.userId,
    isPermissionRequest = null,
    sessionSecretKey = authCred.sessionSecretKey;

  logEvent(`recv. accessToken: ${accessToken}`);
  logEvent(`recv. userId: ${userId}`);
  logEvent(`recv. isPermissionRequest: ${isPermissionRequest}`);

  if (accessToken) {
    const application = AUTH_TYPE_TG;
    logEvent('accessToken exists setting....');
    dispatch(
      socialAccountsModuleActions.setApplication({
        application,
      }),
    );
    localStorage.setItem('application', String(application));
    dispatch(
      socialAccountsModuleActions.addAccount({
        accessToken,
        userId,
        isPermissionRequest,
        sessionSecretKey,
      }),
    );
    // history.push(routes.app.auth);
  }
}

export function numberWithSpaces(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

export function isCommunity(type: EntityType) {
  return type === 'group' || type === 'page' || type === 'event' || 'site';
}

export function getOwnerIdFromEntity(entity: any, application = App.vk) {
  // TODO ownerId should be string in all places
  switch (application) {
    case App.vk: {
      return isCommunity(entity.type) ? -entity.id : entity.id;
    }
    case App.ok: {
      return entity.id;
    }
    case App.tg: {
      return entity.id;
    }
    case App.sites: {
      return entity.id;
    }
    default:
      return '';
  }
}

export function getImageFromEntity(entity) {
  return (
    entity.photo_100 ||
    entity.picAvatar ||
    'https://vk.com/images/community_100.png'
  );
}

export function getNameFromEntity(entity) {
  if (entity && Object.keys(entity).length > 0) {
    return isCommunity(entity.type)
      ? entity.name
      : `${entity.first_name} ${entity.last_name}`;
  }
  return null;
}

export function getMembersCountFromEntity(entity: IEntityInfo) {
  return entity.followers_count || entity.members_count;
}

export function getEntityId(entity: IEntityInfo) {
  return entity.id || entity.channelId;
}

export function getEntityApp(entity: IEntityInfo) {
  return entity.app;
}

export function getUserRoleOfEntity(entity: IEntityInfo) {
  const { admin_level: adminLevel } = entity;
  // eslint-disable-next-line no-nested-ternary
  return adminLevel === socialAccountRole.Moderator
    ? 'модератор'
    : // eslint-disable-next-line no-nested-ternary
    adminLevel === socialAccountRole.Editor
    ? 'редактор'
    : adminLevel === socialAccountRole.Admin
    ? 'администратор'
    : '';
}

export function hasPrivileges(entity: IEntityInfo) {
  const { admin_level: adminLevel } = entity;
  return adminLevel > 1;
}

export function calculatePostErp(post, membersCount, indexes?: IIndexes) {
  const { likesCount, repostsCount, commentsCount } = post;

  if (indexes) {
    return membersCount > 0
      ? `${(
          ((likesCount +
            repostsCount * indexes.repostsIndex +
            commentsCount * indexes.commentsIndex) /
            membersCount) *
          100
        ).toFixed(2)}%`
      : '';
  }

  return membersCount > 0
    ? `${(
        ((likesCount + repostsCount + commentsCount) / membersCount) *
        100
      ).toFixed(2)}%`
    : '';
}

export function calculatePostErr(
  post,
  postReach: { reachTotal: any },
  indexes?: IIndexes,
): string {
  const { likesCount, repostsCount, commentsCount } = post;
  const { reachTotal } = postReach;

  if (!reachTotal) {
    return '0';
  }

  if (indexes) {
    return `${(
      ((likesCount +
        repostsCount * indexes.repostsIndex +
        commentsCount * indexes.commentsIndex) *
        100) /
      reachTotal
    ).toFixed(2)}%`;
  }

  return `${(
    ((likesCount + repostsCount + commentsCount) * 100) /
    reachTotal
  ).toFixed(2)}%`;
}

export function calculatePostErv(post, indexes?: IIndexes) {
  const { likesCount, repostsCount, commentsCount, viewsCount } = post;

  if (indexes) {
    return viewsCount > 0
      ? `${(
          ((likesCount +
            repostsCount * indexes.repostsIndex +
            commentsCount * indexes.commentsIndex) /
            viewsCount) *
          100
        ).toFixed(2)}%`
      : '';
  }

  return viewsCount > 0
    ? `${(
        ((likesCount + repostsCount + commentsCount) / viewsCount) *
        100
      ).toFixed(2)}%`
    : '';
}

export function calculateTgPostErv(post, membersCount) {
  const { viewsCount } = post;

  return membersCount > 0
    ? `${((viewsCount / membersCount) * 100).toFixed(2)}%`
    : '';
}

export function msToTime(duration, onlySecondsWithMs = true) {
  // eslint-disable-next-line radix
  const milliseconds = parseInt(String((duration % 1000) / 100));
  const seconds: string | number = Math.floor((duration / 1000) % 60);
  let minutes: string | number = Math.floor((duration / (1000 * 60)) % 60);
  let hours: string | number = Math.floor((duration / (1000 * 60 * 60)) % 24);

  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;

  return onlySecondsWithMs
    ? `${seconds}.${milliseconds}`
    : `${hours}:${minutes}:${seconds}.${milliseconds}`;
}

export function generateUID() {
  // eslint-disable-next-line no-bitwise
  let firstPart: number | string = (Math.random() * 46656) | 0;
  // eslint-disable-next-line no-bitwise
  let secondPart: number | string = (Math.random() * 46656) | 0;
  firstPart = `000${firstPart.toString(36)}`.slice(-3);
  secondPart = `000${secondPart.toString(36)}`.slice(-3);
  return firstPart + secondPart;
}

export function calculateInfoData(selectedList, entityInfo, variant) {
  const label =
    // eslint-disable-next-line no-nested-ternary
    variant === INFO_BLOCK_VARIANT.COLLECTING_LIST
      ? selectedList.name
      : [
          INFO_BLOCK_VARIANT.COLLECTING,
          INFO_BLOCK_VARIANT.SHOW_ROLE,
          INFO_BLOCK_VARIANT.ADD_TO_LIST,
          INFO_BLOCK_VARIANT.SEARCH_QUERY,
        ].includes(variant)
      ? getNameFromEntity(entityInfo)
      : null;
  const avatar = [
    INFO_BLOCK_VARIANT.COLLECTING,
    INFO_BLOCK_VARIANT.SHOW_ROLE,
    INFO_BLOCK_VARIANT.ADD_TO_LIST,
    INFO_BLOCK_VARIANT.SEARCH_QUERY,
  ].includes(variant)
    ? getImageFromEntity(entityInfo)
    : null;
  const membersCount = [
    INFO_BLOCK_VARIANT.ADD_TO_LIST,
    INFO_BLOCK_VARIANT.COLLECTING,
    INFO_BLOCK_VARIANT.SHOW_ROLE,
  ].includes(variant)
    ? getMembersCountFromEntity(entityInfo)
    : null;

  return {
    label,
    avatar,
    membersCount,
  };
}

export function getPathName() {
  return window.location.href.substr(
    window.location.href.lastIndexOf('#'),
    window.location.href.length + 1,
  );
}

export function chunk(arr, size) {
  let i;
  let j;
  let tempArr;
  const chunks: any = [];
  for (i = 0, j = arr.length; i < j; i += size) {
    tempArr = arr.slice(i, i + size);
    chunks.push(tempArr);
  }
  return chunks;
}

export const filterOption = (input, option) =>
  option?.children.toLowerCase().includes(input.toLowerCase());

export const constructPostUrl = (
  groupId: string,
  postId: string,
  app: App | null = null,
  link,
) => {
  let application: any =
    app === null ? localStorage.getItem('application') : app;
  const applicationInNum = application || application === 0 ? +application : -1;

  const urlsMap = {
    0: `https://vk.com/wall${groupId}_${postId}`,
    1: `https://ok.ru/group/${groupId}/topic/${postId}`,
    2: `${link}`,
  };

  return urlsMap[applicationInNum];
};

export const constructEntityUrl = (groupId: string, app: App | null = null) => {
  let application = app === null ? localStorage.getItem('application') : app;
  const applicationInNum = application || application === 0 ? +application : -1;

  switch (applicationInNum) {
    case App.vk: {
      return `https://vk.com/${
        Math.sign(+groupId) === -1 ? `public${Math.abs(+groupId)}` : groupId
      }`;
    }
    case App.ok: {
      return `https://ok.ru/group/${groupId}`;
    }
    default:
      return '';
  }
};

export const getSocialName = (app: App) => SOCIAL_NAMES[app];
