import React, { FC, useEffect } from 'react';
import { Select } from 'antd';
import { getAppIcon } from '../../../helpers/getAppIcon.helper';
import styled from 'styled-components';
import { IUser } from '../../../state/socialAccountsModule/types';
import { IList } from '../../../state/listsModule/types';
import { useDispatch } from 'react-redux';
import { socialAccountsModuleActions } from '../../../state/socialAccountsModule';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import routes from '../../../../configs/app.routes';

interface PropsAccLink {
  linkedAccount?: IUser;
  setLinkedAccount: (account: IUser) => void;
  list: IList;
  entitiesExist: boolean;
  setLinkErr: (err: boolean) => void;
  linkErr: boolean;
}

const AccountLink: FC<PropsAccLink> = ({
  linkedAccount,
  setLinkedAccount,
  list,
  entitiesExist,
  setLinkErr,
}) => {
  const location = useLocation();
  const accounts = JSON.parse(localStorage.getItem('usersMap') || '');
  const dispatch = useDispatch();
  const { id }: any = useParams();

  const normalizeAccounts = Object.keys(accounts).map(item => {
    return {
      social: accounts[item].application,
      accessRestricted: accounts[item].userInfo.accessRestricted,
      userId: accounts[item].userId,
      avatar: accounts[item].avatar || null,
      firstName: accounts[item].userInfo.firstName,
      lastName: accounts[item].userInfo.lastName,
      allowedRequestsCountInNum: null,
      allowedRequestsCount: accounts[item].allowedRequestsCount || null,
      loggedIn: accounts[item].loggedIn || null,
      disabled: accounts[item].disabled || null,
    };
  });

  const handleChange = value => {
    const parsedLinkedAccount = JSON.parse(value);
    dispatch(
      socialAccountsModuleActions.changeUser({ user: parsedLinkedAccount }),
    );
    setLinkedAccount(parsedLinkedAccount);
    setLinkErr(false);
  };

  useEffect(() => {
    const checkIfAccountAdd = accounts.hasOwnProperty(
      `${list?.linkedAccount?.userId}`,
    );

    if (
      list?.linkedAccount &&
      location.pathname !== routes.app.groupLists.create &&
      checkIfAccountAdd
    ) {
      dispatch(
        socialAccountsModuleActions.changeUser({ user: list?.linkedAccount }),
      );
      setLinkErr(false);
    } else {
      location.pathname === routes.app.groupLists.create
        ? setLinkErr(false)
        : location.pathname.includes('/group-lists/edit/') &&
          !list?.linkedAccount &&
          setLinkErr(false);
    }

    if (
      normalizeAccounts.length === 1 &&
      location.pathname === routes.app.groupLists.create
    ) {
      setLinkedAccount({ ...normalizeAccounts[0] });
      setLinkErr(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entitiesExist, id, list?.linkedAccount]);

  return (
    <Select
      style={{ width: '100%', marginBottom: '30px' }}
      value={`${linkedAccount?.firstName || 'Выберите аккаунт'} ${
        linkedAccount?.lastName || ''
      }`}
      onChange={handleChange}
    >
      {list.linkedAccount
        ? normalizeAccounts
            .filter(acc => acc.social === list.linkedAccount?.social)
            .map(account => (
              <Select.Option
                key={account.userId}
                value={JSON.stringify(account)}
              >
                <StyledOptionImage src={getAppIcon()[account.social]} />{' '}
                {account.firstName} {account.lastName}
              </Select.Option>
            ))
        : normalizeAccounts.map(account => (
            <Select.Option key={account.userId} value={JSON.stringify(account)}>
              <StyledOptionImage src={getAppIcon()[account.social]} />{' '}
              {account.firstName} {account.lastName}
            </Select.Option>
          ))}
    </Select>
  );
};
const StyledOptionImage = styled.img`
  height: 15px;
`;

export default AccountLink;
