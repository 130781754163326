import { getUserId } from '../state/userAuthModule/selectors';
import { store } from '../../index';
import { logTypeNames } from '../constants/log.constants';
import { pjVersion } from '../constants/app.constants';
import moment from 'moment';

export const customLogsForRequests = ({
  ownerId,
  collectId,
  url,
  paramsStr,
  status,
  spentTime,
  logType,
}) => {
  if (process.env.REACT_APP_DEV === 'true') {
    const userId = getUserId(store.getState());
    let date = new Date().toString();
    const sliceIndex = date.indexOf('GMT');
    date = date.slice(0, sliceIndex - 1);
    const dateForm = `${moment(date).format('L LTS')}`;
    const log = `${dateForm};${userId};${collectId};${ownerId};${url};${paramsStr};${status};${spentTime};${pjVersion}`;

    switch (logType) {
      case logTypeNames.ERROR:
        console.error(log);
        break;
      case logTypeNames.INFO:
        console.info(log);
        break;
      case logTypeNames.WARNING:
        console.warn(log);
        break;
    }
  }
};

export const dbActionTime = (collectId, spentTime) => {
  if (process.env.REACT_APP_DEV === 'true') {
    console.info(`Db insert time collectId-${collectId}; ${spentTime}`);
  }
};

export const customLogger = (
  message: string,
  messageValue: any = '',
  type?: any,
) => {
  if (process.env.REACT_APP_DEV === 'true') {
    switch (type) {
      case logTypeNames.ERROR:
        console.error(message, messageValue);
        break;
      case logTypeNames.INFO:
        console.info(message, messageValue);
        break;
      case logTypeNames.WARNING:
        console.warn(message, messageValue);
        break;
      case logTypeNames.TIME:
        console.time(message);
        break;
      case logTypeNames.TIME_END:
        console.timeEnd(message);
        break;
      default:
        console.log(message, messageValue);
    }
  }
};
