import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumbs from '../../components/common/Breadcrumbs/Breadcrumbs';
import { useNavigate, useParams } from 'react-router-dom';

// ant components
import { Typography, Spin, message } from 'antd';

// styles
import {
  StyledCardHeader,
  StyledCoverCard,
  SpinWrapper,
  StyledCoverImage,
  ListHeader,
  PageName,
  StyledEmpty,
} from './GroupCoversStyles';

// selectors
import {
  getGroupCovers,
  getGroupCoversLoadStatus,
  getGroupCoversTitle,
  getSelectedList,
  getSelectedListsId,
} from '../../state/groupCoversDataModule/selectors';
import { groupCoversSliceActions } from '../../state/groupCoversDataModule';
import { getLists } from '../../state/listsModule/selectors';
import ContentContainer from '../../components/common/ContentContainer/StyledContent';
import { getUserAccounts } from '../../state/socialAccountsModule/selector';
import routes from '../../../configs/app.routes';
import { IList } from '../../state/listsModule/types';
import FirestoreListService from '../../firebase/firestore.list.service';

const GroupCovers = () => {
  const groupCoversData = useSelector(getGroupCovers);
  const groupCoversTitle = useSelector(getGroupCoversTitle);
  const groupCoversId = useSelector(getSelectedListsId);
  const load = useSelector(getGroupCoversLoadStatus);
  const dispatch = useDispatch();
  const { id } = useParams<'id'>();

  const usersMap = useSelector(getUserAccounts);
  const selectedList = useSelector(getSelectedList);
  const navigate = useNavigate();

  useEffect(() => {
    if (Object.keys(selectedList).length === 0) {
      FirestoreListService.getList(id || '').then(list => {
        if (!usersMap.hasOwnProperty(`${list.linkedAccount.userId}`)) {
          message.warn(
            'Добавьте привязанный к листу аккаунт, либо перепривяжите аккаунт в листе',
          );
          navigate(routes.app._groupLists);
          return;
        } else {
          dispatch(groupCoversSliceActions.setSelectedList(list));
          dispatch(
            groupCoversSliceActions.setGroupCoversRequest({
              id: id || '',
              selectedList: list,
            }),
          );
        }
      });
    } else {
      dispatch(
        groupCoversSliceActions.setGroupCoversRequest({
          id: id || '',
          selectedList: selectedList,
        }),
      );
    }
  }, [id, selectedList]);

  return (
    <>
      {load ? (
        <SpinWrapper>
          <Spin tip={'Загрузка обложек группы...'} />
        </SpinWrapper>
      ) : (
        <>
          <ListHeader>
            <PageName children={groupCoversTitle} />
            <span>{groupCoversId}</span>
          </ListHeader>
          <ContentContainer>
            <Breadcrumbs />
            {groupCoversData &&
              groupCoversData.map(item => {
                return (
                  <StyledCoverCard key={item.id}>
                    <StyledCardHeader>
                      <Typography.Link
                        href={`https://vk.com/public${item.id}`}
                        target="_blank"
                        children={item.name}
                      />
                      <Typography.Text>
                        Кол-во подписчиков: <b>{item.members_count}</b>
                      </Typography.Text>
                    </StyledCardHeader>
                    {item.cover.enabled === 1 ? (
                      <StyledCoverImage
                        width={item.cover.images[3].width}
                        height={item.cover.images[3].height}
                        src={item.cover.images[3].url}
                        alt={item.name}
                      />
                    ) : (
                      <StyledEmpty description={'Изображение отсутствует'} />
                    )}
                  </StyledCoverCard>
                );
              })}
          </ContentContainer>
        </>
      )}
    </>
  );
};

export default GroupCovers;
