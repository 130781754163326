export const totalSeries = [
  ['reachTotal', 'reachSubscribers'],
  ['subscribedTotal', 'unsubscribedTotal', 'subscribedDifference'],
  ['reachLikes', 'reachCopies', 'reachComments', 'reachHidden'],
];

export const seriesNames = [
  ['полный', 'подписчиков'],
  ['подписалось', 'отписалось', 'прирост'],
  ['лайки', 'репосты', 'коммент.', 'скрытия'],
];
