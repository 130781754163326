import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './index';
import { RootState } from '../../../types';

const selectSlice = (state: RootState) => state.searchData || initialState;

export const getSearchStatus = createSelector(
  [selectSlice],
  state => state.search,
);

export const getCurrentCountExecute = createSelector(
  [selectSlice],
  state => state.currentCountExecute,
);

export const getLastFetchDetails = createSelector(
  [selectSlice],
  state => state.lastFetchDetails,
);
