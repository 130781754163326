import React, { useState } from 'react';

import { Button, Modal, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { LOG_EVENTS } from '../../../constants/lib.constants';
import {
  getOwnerIdFromEntity,
  getPathName,
  hasPrivileges,
} from '../../../helpers/auth.helper';
import { logEvent } from '../../../libs/lib.amplitude';
import { IEntityInfo } from '../../../state/entityFetchModule/types';
import { getSelectedList } from '../../../state/listsModule/selectors';
import { getEntityInfo } from '../../../state/entityFetchModule/selector';
import { groupDataModuleActions } from '../../../state/groupDataModule';
import { IList } from '../../../state/listsModule/types';
import { getUser } from '../../../state/userAuthModule/selectors';
import { getSelectedApplication } from '../../../state/socialAccountsModule/selector';
import { App } from '../../../constants/enums';
import AnonWarningModal from '../../common/Modals/AnonWarningModal';
import { useNavigate } from 'react-router-dom';

type Props = {
  selectedCountExecute: number;
  disabled: boolean;
};

export default function GetPostsReach({
  selectedCountExecute,
  disabled,
}: Props) {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [boxSize, setBoxSize] = useState<number>(1);

  const selectedList: IList = useSelector(getSelectedList);
  const entityInfo: IEntityInfo = useSelector(getEntityInfo);
  const user = useSelector(getUser);
  const application = useSelector(getSelectedApplication);

  const selectedListExists = Object.keys(selectedList).length > 0;
  const handleGetPostsReach = () => {
    if (user.isAnonymous) {
      return AnonWarningModal(navigate);
    }

    logEvent(LOG_EVENTS.CLICK_BUTTON, {
      page: getPathName(),
      button: 'Собрать расширенную статистику постов',
      type: selectedListExists ? 'list' : 'object',
      list_name: selectedListExists ? selectedList.name : 'none',
      list_count: selectedListExists ? selectedList.entities.length : 'none',
      objectId: selectedListExists
        ? 'none'
        : getOwnerIdFromEntity(entityInfo, entityInfo.app),
      count_execute: selectedCountExecute,
    });

    const privilegedEntitiesIds: number[] = [];
    if (selectedListExists) {
      selectedList.entities.forEach((entity: any) => {
        if (hasPrivileges(entity)) {
          privilegedEntitiesIds.push(getOwnerIdFromEntity(entity, entity.app));
        }
      });
    } else if (hasPrivileges(entityInfo)) {
      privilegedEntitiesIds.push(
        getOwnerIdFromEntity(entityInfo, entityInfo.app),
      );
    }

    if (privilegedEntitiesIds.length === 0) {
      Modal.warning({
        title:
          'У вас недостаточно прав для сбора расширенной статистики ВКонтакте',
      });
      debugger;
    } else {
      dispatch(
        groupDataModuleActions.getPostsReach({
          privilegedEntitiesIds,
          boxSize,
          entityInfo,
          selectedList,
        }),
      );
    }
  };

  return (
    <div>
      {application === App.vk && user.tariffPlan === 'beta' && (
        <Input
          type="number"
          onChange={e => setBoxSize(+e.currentTarget.value)}
          value={boxSize}
          min={1}
          max={25}
        />
      )}
      <Button
        disabled={disabled}
        style={{ marginTop: '10px' }}
        onClick={handleGetPostsReach}
      >
        Собрать расширенную статистику постов
      </Button>
    </div>
  );
}
