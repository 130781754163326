import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { PlusOutlined } from '@ant-design/icons';
import { Button, message, Spin, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import Statistics from '../../components/app/Statistics';
import { getPathName } from '../../helpers/auth.helper';
import { logEvent } from '../../libs/lib.amplitude';
import LoadingSteps from '../../components/app/LoadingSteps';
import PostsActions from '../../components/app/PostActions';
import Search from '../../components/app/Search';
import { INFO_BLOCK_VARIANT } from '../../constants/collect.constants';
import { LOG_EVENTS } from '../../constants/lib.constants';
import { DownloadStatus } from '../../constants/enums';
import routes from '../../../configs/app.routes';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDownloadingStatus,
  getEntityInfo,
} from '../../state/entityFetchModule/selector';
import { getLists, getSelectedList } from '../../state/listsModule/selectors';
import { listsModuleActions } from '../../state/listsModule';
import { IList } from '../../state/listsModule/types';
import { useLocation } from 'react-router-dom';
import { names } from '../../helpers/names';
import PageHeader from '../../components/common/PageHeader/PageHeader';
import ContentContainer from '../../components/common/ContentContainer/StyledContent';
import { getUserAccounts } from '../../state/socialAccountsModule/selector';
import { getAppIcon } from '../../helpers/getAppIcon.helper';
import { socialAccountsModuleActions } from '../../state/socialAccountsModule';
import linkNotification from '../../components/common/LinkNotification';

export default function CollectAndAnalyze() {
  const dispatch = useDispatch();
  const [selectedCountExecute, setSelectedCountExecute] = useState<number>(0);
  const navigate = useNavigate();
  const location = useLocation();
  const downloadingStatus = useSelector(getDownloadingStatus);
  const downloadInProgress = useMemo(
    () => downloadingStatus.state === DownloadStatus.PROGRESS,
    [downloadingStatus.state],
  );
  const readyToExport = useMemo(
    () => downloadingStatus.state === DownloadStatus.SUCCESS,
    [downloadingStatus.state],
  );

  const selectedList = useSelector(getSelectedList);
  const entityInfo = useSelector(getEntityInfo);
  const listsOfEntities: IList[] = useSelector(getLists);
  const accounts = useSelector(getUserAccounts);

  const handleOnMenuItemClick = async (id: number) => {
    dispatch(listsModuleActions.setSelectedList({}));

    const list = listsOfEntities.find(
      listOfEntities => listOfEntities.id === id,
    );

    if (!accounts.hasOwnProperty(`${list?.linkedAccount?.userId}`)) {
      linkNotification(list, navigate);
      return;
    }

    if (!list?.linkedAccount) {
      message.warning('Пожалуйста привяжите аккаунт');
      return;
    }

    logEvent(LOG_EVENTS.LIST_PICKER, {
      page: getPathName(),
      list_name: list.name,
      list_count: list.entities.length,
    });
    dispatch(
      socialAccountsModuleActions.changeUser({ user: list.linkedAccount }),
    );
    dispatch(listsModuleActions.setSelectedListWithCheck({ list }));
  };

  const handleListCreate = () => navigate(routes.app.groupLists.create);

  const filterByUrl = (lists: IList[]) =>
    location.pathname === routes.app.siteStatistics.path
      ? lists.filter(list => list.application === 3)
      : lists.filter(list => list.application !== 3);

  const menu = (
    <div
      style={{
        boxShadow:
          'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
      }}
    >
      <StyledMenu>
        {filterByUrl(listsOfEntities).length === 0 ? (
          <Typography.Text>У вас еще нет списков.</Typography.Text>
        ) : (
          filterByUrl(listsOfEntities).map(listOfEntities => (
            <li
              onClick={() => handleOnMenuItemClick(listOfEntities.id)}
              key={listOfEntities.id}
            >
              <img
                style={{ height: '20px', marginRight: '10px' }}
                src={getAppIcon()[listOfEntities.application]}
                alt={listOfEntities.name}
              />
              <span>{listOfEntities.name}</span>
            </li>
          ))
        )}
      </StyledMenu>
      <div style={{ padding: '15px', backgroundColor: 'white' }}>
        <Button
          style={{ width: '100%' }}
          type="primary"
          icon={<PlusOutlined />}
          onClick={handleListCreate}
          children={'Создать список'}
        />
      </div>
    </div>
  );

  const [selectedListExists, entityInfoExists] = [
    selectedList && Object.keys(selectedList).length > 0, // TODO same as the next line
    entityInfo && Object.keys(entityInfo).length > 0, // TODO correct this why to check object length
  ];

  const calculatedVariant = selectedListExists
    ? INFO_BLOCK_VARIANT.COLLECTING_LIST
    : entityInfoExists
    ? INFO_BLOCK_VARIANT.COLLECTING
    : null;

  return (
    <>
      <PageHeader title={names[location.pathname]} />
      <ContentContainer>
        <StyledContent>
          <ContentBody>
            <SearchWithActionsBlock>
              <Search
                setSelectedCountExecute={setSelectedCountExecute}
                menu={menu}
                variant={calculatedVariant}
              />
              {readyToExport && (
                <PostsActions selectedCountExecute={selectedCountExecute} />
              )}
            </SearchWithActionsBlock>
            {readyToExport && <Statistics />}
            {downloadInProgress && downloadingStatus.currentIndex && (
              <div className="spin-message" style={{ zIndex: 2 }}>
                <div className="ant-message-notice">
                  <div className="ant-message-notice-content">
                    <div className="ant-message-custom-content ant-message-loading">
                      <Spin />
                      <span className="m-l-10">
                        {downloadingStatus.currentIndex}/
                        {downloadingStatus.total} Идет сбор постов из{' '}
                        {downloadingStatus.name}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {downloadInProgress && (
              <LoadingSteps
                entityInfo={entityInfo}
                selectedList={selectedList}
                selectedCountExecute={selectedCountExecute}
              />
            )}
          </ContentBody>
        </StyledContent>
      </ContentContainer>
    </>
  );
}

const StyledContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentBody = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
const SearchWithActionsBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledMenu = styled.ul`
  background-color: white;
  max-height: 300px;
  max-width: 500px;
  overflow: auto;
  padding: 10px;
  list-style: none;
  margin: 0;

  li {
    padding: 5px;

    &:hover {
      background-color: #f5f5f5;
      border-radius: 2px;
      cursor: pointer;
    }
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background-color: #ededed;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(110, 110, 110, 0.3);
  }
`;
