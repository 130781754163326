import React, { memo, useState } from 'react';

import { LinkOutlined } from '@ant-design/icons';
import { ConfigProvider, Table, Pagination } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { constructPostUrl } from '../../../helpers/auth.helper';
import { DownloadStatus } from '../../../constants/enums';
import { useSelector } from 'react-redux';
import { getDownloadingStatus } from '../../../state/entityFetchModule/selector';
import { getPosts } from '../../../state/groupDataModule/selectors';
import { getSelectedApplication } from '../../../state/socialAccountsModule/selector';
import ruRU from 'antd/lib/locale/ru_RU';

type Props = {
  isActive: boolean;
  ownerId: number;
};

function TableBlock({ isActive, ownerId }: Props) {
  const [page, setPage] = useState<{ page: number; pageSize: number }>({
    page: 1,
    pageSize: 50,
  });

  const downloadingStatus = useSelector(getDownloadingStatus);
  const posts = useSelector(getPosts);
  const loading = downloadingStatus.state === DownloadStatus.PROGRESS;

  const app = useSelector(getSelectedApplication);

  const columns: ColumnsType<any> = [
    {
      title: 'Дата и Время',
      dataIndex: 'timestamp',
      render: (timestamp: Date) =>
        `${new Date(timestamp).toLocaleDateString('ru-RU', {
          month: 'short',
          day: '2-digit',
          year: 'numeric',
        })} ${new Date(timestamp).toLocaleTimeString('ru-RU', {
          hour: 'numeric',
          minute: 'numeric',
        })}`,
      width: 200,
      fixed: 'left',
    },
    {
      title: 'Ссылка',
      dataIndex: 'link',
      render: (text: string, post) => {
        return (
          <a
            href={
              app === 2
                ? `https://${post.link}`
                : constructPostUrl(post.ownerId, post.postId, null, '')
            }
            target="_blank"
            rel="noreferrer"
          >
            <LinkOutlined
              style={{
                color: '#2F80ED',
                cursor: 'pointer',
              }}
            />
          </a>
        );
      },
      width: 100,
      fixed: 'left',
    },
    {
      title: 'Просмотры',
      dataIndex: 'viewsCount',
      width: 150,
      align: 'right',
      sorter: (a, b) => a.viewsCount - b.viewsCount,
    },
    {
      title: 'Лайки',
      dataIndex: 'likesCount',
      sorter: (a, b) => a.likesCount - b.likesCount,
      width: 150,
      align: 'right',
    },
    {
      title: 'Репосты',
      dataIndex: 'repostsCount',
      sorter: (a, b) => a.repostsCount - b.repostsCount,
      width: 150,
      align: 'right',
    },
    {
      title: 'Комментарии',
      dataIndex: 'commentsCount',
      sorter: (a, b) => a.commentsCount - b.commentsCount,
      width: 150,
      align: 'right',
    },
    {
      title: 'ER.v, %',
      dataIndex: 'erv',
      width: 150,
      align: 'right',
      sorter: (a, b) => parseFloat(a.erv) - parseFloat(b.erv),
    },
    {
      title: 'ER.p, %',
      dataIndex: 'erp',
      width: 150,
      align: 'right',
      sorter: (a, b) => parseFloat(a.erp) - parseFloat(b.erp),
    },
    {
      title: 'ER.r, %',
      dataIndex: 'err',
      width: 150,
      align: 'right',
      sorter: (a, b) => parseFloat(a.err) - parseFloat(b.err),
    },
    {
      title: 'Вступления в сообщество',
      dataIndex: 'joinGroup',
      sorter: (a, b) => a.joinGroup - b.joinGroup,
      width: 150,
      align: 'right',
    },
    {
      title: 'Отписавшихся участников',
      dataIndex: 'unsubscribe',
      sorter: (a, b) => a.unsubscribe - b.unsubscribe,
      width: 150,
      align: 'right',
    },
    {
      title: 'Охват подписчиков',
      dataIndex: 'reachSubscribers',
      sorter: (a, b) => a.reachSubscribers - b.reachSubscribers,
      width: 150,
      align: 'right',
    },
    {
      title: 'Рекламный охват',
      dataIndex: 'reachAds',
      sorter: (a, b) => a.reachAds - b.reachAds,
      width: 150,
      align: 'right',
    },
    {
      title: 'Виральный охват',
      dataIndex: 'reachViral',
      sorter: (a, b) => a.reachViral - b.reachViral,
      width: 150,
      align: 'right',
    },
    {
      title: 'Переходы в сообщество',
      dataIndex: 'toGroup',
      sorter: (a, b) => a.toGroup - b.toGroup,
      width: 150,
      align: 'right',
    },
    {
      title: 'Переходы по ссылке',
      dataIndex: 'links',
      sorter: (a, b) => a.links - b.links,
      width: 150,
      align: 'right',
    },
    {
      title: 'Жалоб на запись',
      dataIndex: 'report',
      sorter: (a, b) => a.report - b.report,
      width: 150,
      align: 'right',
    },
    {
      title: 'Скрывших запись',
      dataIndex: 'hide',
      sorter: (a, b) => a.hide - b.hide,
      width: 150,
      align: 'right',
    },
  ];

  return (
    <>
      {isActive && (
        <ConfigProvider locale={ruRU}>
          <Table
            scroll={{ x: 1500, y: 500 }}
            loading={loading}
            columns={columns}
            pagination={{
              current: page.page,
              pageSize: page.pageSize,
              position: ['topRight'],
              onChange: (page, pageSize) => setPage({ page, pageSize }),
            }}
            dataSource={posts.result}
          />
        </ConfigProvider>
      )}
    </>
  );
}

export default memo(TableBlock);
