import React, { useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  getOwnerIdFromEntity,
  getPathName,
  getSocialName,
} from '../../../helpers/auth.helper';
import { logEvent } from '../../../libs/lib.amplitude';
import Rates from '../Rates';
import Table from '../Table';
import { getSelectedApplication } from '../../../state/socialAccountsModule/selector';
import { LOG_EVENTS } from '../../../constants/lib.constants';
import { DATA_TAB } from '../../../constants/collect.constants';
import styled from 'styled-components';
import { getEntityInfo } from '../../../state/entityFetchModule/selector';
import { getSelectedList } from '../../../state/listsModule/selectors';
import { graphicDataModuleActions } from '../../../state/graphicDataModule';
import {
  getAdditionalGraphicTypes,
  getGraphicDataLoad,
} from '../../../state/graphicDataModule/selector';
import Graphics from '../Graphics';
import { Empty, Spin } from 'antd';
import { rateDataModuleActions } from '../../../state/ratesModule';
import { GroupBy } from '../../../constants/app.constants';

export default function Statistics() {
  const dispatch = useDispatch();
  const application = useSelector(getSelectedApplication);
  const entityInfo = useSelector(getEntityInfo);
  const selectedList = useSelector(getSelectedList);
  const additionalGraphicTypes = useSelector(getAdditionalGraphicTypes);
  const graphicsDataLoad = useSelector(getGraphicDataLoad);
  const selectedListExists = Object.keys(selectedList).length > 0;
  const ownerId =
    entityInfo && getOwnerIdFromEntity(entityInfo, entityInfo.app);
  const [activeTab, setActiveTab] = useState(DATA_TAB.GRAPHIC);
  const isGraphicTab = useMemo(() => activeTab === DATA_TAB.GRAPHIC, [
    activeTab,
  ]);
  const isTableTab = useMemo(() => activeTab === DATA_TAB.TABLE, [activeTab]);
  const entitiesMap = {};
  const series = selectedList.entities || [entityInfo];
  const seriesNames = series.map(entity => {
    entitiesMap[getOwnerIdFromEntity(entity, entity.app)] = entity;
    const { name, first_name: firstName, last_name: lastName } = entity;
    return name || `${firstName} ${lastName}`;
  });

  const handleOnTabClick = (tab: number) => {
    setActiveTab(tab);
  };

  return (
    <Container>
      <Rates entitiesMap={entitiesMap} isActive={true} />
      <DataType>
        <Title
          id="graphic-title"
          onClick={() => handleOnTabClick(DATA_TAB.GRAPHIC)}
          isActive={isGraphicTab}
        >
          Графики
        </Title>
        {Object.keys(selectedList).length === 0 && (
          <>
            <span> | </span>
            <Title
              id="table-title"
              onClick={() => handleOnTabClick(DATA_TAB.TABLE)}
              isActive={isTableTab}
            >
              Таблица постов
            </Title>
          </>
        )}
      </DataType>
      {seriesNames && seriesNames.length <= 10 ? (
        <GraphicsContainer>
          <Spin spinning={graphicsDataLoad} tip={'Идёт расчет графиков...'}>
            <Graphics
              seriesNames={seriesNames}
              isActive={activeTab === DATA_TAB.GRAPHIC}
              additionalGraphicTypes={additionalGraphicTypes}
            />
          </Spin>
        </GraphicsContainer>
      ) : (
        <Empty
          description={
            <span>
              Графики для Списков, в которых больше 10 сообществ в процессе
              разработки. Рекомендуем воспользоваться выгрузкой в Excel и
              загрузку данных в{' '}
              <a href="https://public.tableau.com/" target={'_blank'}>
                Tableau Public
              </a>
            </span>
          }
        />
      )}
      <Table ownerId={ownerId} isActive={activeTab === DATA_TAB.TABLE} />
    </Container>
  );
}

const GraphicsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
`;

const DataType = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #262626;
  left: 0;
  top: 20px;
  z-index: 1;
  width: 100%;
`;

const Title: any = styled.span`
  cursor: pointer;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  font-weight: ${(props: any) => (props.isActive ? 'bold' : 'normal')};
  color: ${(props: any) => (props.isActive ? '#262626' : '#40a9ff')};
`;
