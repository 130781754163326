import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './index';
import { RootState } from '../../../types';

const selectSlice = (state: RootState) =>
  (state && state.initModule) || initialState;

export const getIsLoggedIn = createSelector(
  [selectSlice],
  state => state.isLoggedIn,
);

export const getIsLoading = createSelector(
  [selectSlice],
  state => state.isLoading,
);

export const getLoginError = createSelector(
  [selectSlice],
  state => state.error,
);
