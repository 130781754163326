export const GRAPHIC_TITLE: any = {
  viewsCount: 'Количество просмотров',
  likesCount: 'Количество лайков',
  repostsCount: 'Количество репостов',
  commentsCount: 'Количество комментариев',
  postsCount: 'Количество постов',
  reachTotal: 'Суммарный охват',
  graphicCompare: 'График сравнения',
  joinGroup: 'Вступления в сообщество',
  reachSubscribers: 'Охват подписчиков',
  reachAds: 'Рекламный охват',
  reachViral: 'Виральный охват',
  links: 'Переходы по ссылке',
  toGroup: 'Переходы в сообщество',
  report: 'Жалоб на запись',
  hide: 'Скрывших запись',
  unsubscribe: 'Отписавшихся участников',
};

export const SITE_GRAPHIC_TITLE: any = {
  viewsCount: 'Количество визитов',
};

export const LIST_STATISTICS_GRAPHIC_TITLE: any = {
  reach: 'Охват',
  visitors: 'Активность',
  activity: 'Подписки',
};
