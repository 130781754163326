import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { IEntityInfo, IEntityModuleState } from './types';
import { DownloadStatus } from '../../constants/enums';

export const CANCEL_COLLECT_POSTS = 'CANCEL_COLLECT_POSTS';

export const initialState: IEntityModuleState = {
  downloadStatus: { state: DownloadStatus.NOT_STARTED, progress: 0 },
  entityInfo: {} as IEntityInfo,
  load: false,
  excelGenerateStatus: false,
  exportPercent: {
    max: 100,
    value: 0,
  },
  collectLoad: false,
};

const slice = createSlice({
  name: 'entities',
  initialState,
  reducers: {
    exportEntityPosts(state, action: PayloadAction<any>) {
      return state;
    },
    setDownloadingStatus(state, action: PayloadAction<any>) {
      state.downloadStatus.state = action.payload.downloadState;
      state.downloadStatus.progress = action.payload.downloadProgress;
      if (action.payload.currentIndex) {
        state.downloadStatus.currentIndex = action.payload.currentIndex;
      }
      if (action.payload.total) {
        state.downloadStatus.total = action.payload.total;
      }
      if (action.payload.name) {
        state.downloadStatus.name = action.payload.name;
      }
      return state;
    },
    collectWallPosts(state, action: PayloadAction<any>) {
      return state;
    },
    collectPostsForPeriod(state, action: PayloadAction<any>) {
      return state;
    },
    getEntityInfo(state, action: PayloadAction<any>) {
      return state;
    },
    setEntityInfo(state, action: PayloadAction<any>) {
      state.entityInfo = action.payload.entity;
      return state;
    },
    reset(state, action: PayloadAction<any>) {
      state = initialState;
      return state;
    },
    cancelCollectPosts(state, action: PayloadAction<any>) {
      return state;
    },
    excelGenerateRequest(state, action: PayloadAction<boolean>) {
      state.excelGenerateStatus = action.payload;
    },
    setLoad(state, action: PayloadAction<boolean>) {
      state.load = action.payload;
    },
    setExportPercent(
      state,
      action: PayloadAction<{ max: number; value: number }>,
    ) {
      state.exportPercent = action.payload;
    },
    setCollectLoad(state, action: PayloadAction<boolean>) {
      state.collectLoad = action.payload;
    },
  },
});

export function cancelCollectPosts() {
  return {
    type: CANCEL_COLLECT_POSTS,
    payload: {},
    load: false,
  };
}

export const {
  actions: entityModuleActions,
  reducer: entityModuleReducers,
} = slice;
