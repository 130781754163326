import React, { useMemo, useRef, useState } from 'react';

import {
  CheckCircleTwoTone,
  ClearOutlined,
  CloseCircleTwoTone,
  DownOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Button, Card, Dropdown, Input, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import InfoBlock from '../InfoBlock';
import styled from 'styled-components';
import { App, DownloadStatus, SearchStatus } from '../../../constants/enums';
import { INFO_BLOCK_VARIANT } from '../../../constants/collect.constants';
import { getOwnerIdFromEntity } from '../../../helpers/auth.helper';
import { getSelectedApplication } from '../../../state/socialAccountsModule/selector';
import { IList } from '../../../state/listsModule/types';
import { IEntityInfo } from '../../../state/entityFetchModule/types';
import { getSearchStatus } from '../../../state/searchModule/selectors';
import {
  getDownloadingStatus,
  getEntityInfo,
  getEntityLoad,
} from '../../../state/entityFetchModule/selector';
import { getSelectedList } from '../../../state/listsModule/selectors';
import { entityModuleActions } from '../../../state/entityFetchModule';
import { searchDataModuleActions } from '../../../state/searchModule';
import { searchEntityHandler } from '../../../services/client/vk/handlers';
import { searchTitles, searchPlaceholders } from '../../../helpers/names';
import SitePreview from '../../common/SitePreview';
import { IUser } from '../../../state/socialAccountsModule/types';
import { ListsLogs, PostsAnalyzeLogs } from 'app/services/amplitude/amplitude';
import { useLocation } from 'react-router-dom';
import routes from '../../../../configs/app.routes';

type Props = {
  setSelectedCountExecute?: (countExecute: number) => void;
  variant: string | null;
  handleAddToList?: (entityInfo: IEntityInfo) => void;
  menu?: any;
  title?: string;
  enableSearchByQuery?: boolean;
  type?: string;
  linkedAccount?: IUser;
  list?: IList;
  entitiesArr?: IEntityInfo[];
};

export default function Search({
  handleAddToList,
  menu,
  enableSearchByQuery = false,
  variant,
  setSelectedCountExecute,
  linkedAccount,
  entitiesArr,
}: Props) {
  const dispatch = useDispatch();
  const [searchedEntities, setSearchedEntities] = useState<any>([]);
  const inputEl: React.Ref<Input> = useRef(null);
  const [inputValue, setInputValue] = useState('');
  const searchStatus = useSelector(getSearchStatus);
  const application = useSelector(getSelectedApplication);
  const entityInfo: IEntityInfo = useSelector(getEntityInfo);
  const selectedList: IList = useSelector(getSelectedList);
  const downloadingStatus = useSelector(getDownloadingStatus);
  const entityLoad = useSelector(getEntityLoad);
  const location = useLocation();

  const downloadInProgress = useMemo(
    () => downloadingStatus.state === DownloadStatus.PROGRESS,
    [downloadingStatus.state],
  );
  const { state: searchState } = searchStatus;
  const searchSuccess = useMemo(() => searchState === SearchStatus.SUCCESS, [
    searchState,
  ]);
  const searchError = useMemo(() => searchState === SearchStatus.ERROR, [
    searchState,
  ]);
  const isBlockedUser = useMemo(() => searchState === SearchStatus.BLOCKED, [
    searchState,
  ]);
  const { postsCount } = entityInfo || {};

  const errorText = `Такого сообщества ${inputValue} ${
    variant === INFO_BLOCK_VARIANT.ADD_TO_LIST || application === App.ok
      ? ''
      : 'или пользователя'
  } не существует. Проверьте ссылку или
          обратитесь в поддержку сервиса.`;

  const searchSuffix = searchSuccess ? (
    <CheckCircleTwoTone twoToneColor="#52c41a" />
  ) : searchError ? (
    <CloseCircleTwoTone twoToneColor="#F5222D" />
  ) : null;

  const handleClick = (isEnter = false) => {
    const buttonName = 'Поиск';
    const state: any = inputEl?.current?.state;
    const { value } = state;

    if (typeof value !== 'string' || value.trim().length === 0) {
      return;
    }

    const query = value.trim();

    if (!enableSearchByQuery) {
      dispatch(
        entityModuleActions.getEntityInfo({
          str: query,
          buttonName,
          isEnter,
          linkedAccount,
        }),
      );

      location.pathname === routes.app.collectAndAnalyze
        ? PostsAnalyzeLogs.searchGroup('click search group', { query })
        : ListsLogs.searchGroup('click list search group');
    } else {
      (async () => {
        try {
          const { response }: any = await searchEntityHandler(query);
          const entities = response.items;
          setSearchedEntities(entities);
          ListsLogs.searchGroupBySearch('click list search group by search', {
            query,
          });
        } catch (e) {
          console.log(e);
        }
      })();
    }
  };

  const handleChange = e => {
    setInputValue(e.target.value);
    if ((searchError || searchSuccess) && !enableSearchByQuery) {
      dispatch(
        searchDataModuleActions.setSearchStatus({
          state: SearchStatus.INITIAL,
        }),
      );
    }
  };

  return (
    <SearchBlock enableSearchByQuery={enableSearchByQuery}>
      <div>
        <span>{searchTitles[application || 0]}</span>
        {menu && (
          <Dropdown overlay={menu} trigger={['click']}>
            <button
              id="select-list"
              style={{
                marginLeft: '10px',
                color: '#1890ff',
                border: 'none',
                background: 'none',
                outline: 'none',
              }}
              onClick={e => e.preventDefault()}
            >
              выберите список <DownOutlined />
            </button>
          </Dropdown>
        )}
      </div>
      <SearchWrapper>
        <StyledInput
          id={enableSearchByQuery ? 'search-group' : 'search-entity'}
          style={{
            width: '417px',
          }}
          ref={inputEl}
          onPressEnter={() => handleClick(true)}
          placeholder={
            searchPlaceholders[
              linkedAccount ? linkedAccount?.social : application || 0
            ]
          }
          size="middle"
          prefix={<SearchOutlined />}
          onChange={handleChange}
          suffix={enableSearchByQuery ? null : searchSuffix}
          value={inputValue}
        />
        {inputValue.length > 0 && (
          <Button
            id="clear-entity-search"
            icon={<ClearOutlined />}
            onClick={() => setInputValue('')}
          />
        )}
        <Button
          id={
            enableSearchByQuery ? 'search-group-button' : 'search-entity-button'
          }
          style={{
            marginLeft: '10px',
          }}
          type="primary"
          onClick={() => handleClick(false)}
          loading={entityLoad}
          disabled={inputValue.length === 0}
        >
          Поиск
        </Button>
      </SearchWrapper>
      {application === 3 && inputValue.length > 0 && (
        <StyledCard onClick={() => handleClick(false)}>
          <Space>
            <SitePreview inputValue={inputValue} />
            <span>{inputValue}</span>
          </Space>
        </StyledCard>
      )}
      {!searchError && !enableSearchByQuery ? (
        <InfoBlock
          selectedList={selectedList}
          setSelectedCountExecute={setSelectedCountExecute}
          downloadInProgress={downloadInProgress}
          postsCount={postsCount}
          variant={variant}
          handleAddToList={handleAddToList}
          entityInfo={entityInfo}
          isBlockedUser={isBlockedUser}
          setInputValue={setInputValue}
        />
      ) : searchedEntities.length > 0 ? (
        <div style={{ height: '600px', overflow: 'auto', padding: '10px' }}>
          {searchedEntities.map((searchedEntity: any) => (
            <InfoBlock
              key={`searched_${getOwnerIdFromEntity(
                searchedEntity,
                searchedEntity.app,
              )}`}
              selectedList={selectedList}
              setSelectedCountExecute={setSelectedCountExecute}
              downloadInProgress={downloadInProgress}
              handleAddToList={handleAddToList}
              entityInfo={searchedEntity}
              variant={variant}
              isBlockedUser={isBlockedUser}
              entitiesArr={entitiesArr}
              setInputValue={setInputValue}
            />
          ))}
        </div>
      ) : searchError && !enableSearchByQuery ? (
        <ErrorText>{errorText}</ErrorText>
      ) : null}
      {isBlockedUser && (
        <ErrorText>Сбор постов для данного сообщества недоступен</ErrorText>
      )}
    </SearchBlock>
  );
}

const SearchBlock: any = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 210px;
  max-width: 500px;
  ${(props: any) => props.enableSearchByQuery && 'height: auto;'}
`;

const SearchWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const StyledInput: any = styled(Input)`
  ${(props: any) => {
    if (props.searchError && !props.enableSearchByQuery) {
      return `
        border: 1px solid #f5222d !important;
        box-sizing: border-box !important;
        box-shadow: 0 0 4px rgba(245, 34, 45, 0.5) !important;
        border-radius: 2px !important;
        outline: 0 !important;
        &:hover {
          border: 1px solid #f5222d !important;
          box-sizing: border-box !important;
          box-shadow: 0 0 4px rgba(245, 34, 45, 0.5) !important;
          border-radius: 2px !important;
          outline: 0 !important;
        }
        &:focus {
          border: 1px solid #f5222d !important;
          box-sizing: border-box !important;
          box-shadow: 0 0 4px rgba(245, 34, 45, 0.5) !important;
          border-radius: 2px !important;
          outline: 0 !important;
        }
      `;
    }
  }}
`;

const ErrorText = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #f5222d;
  max-width: 400px;
  margin-top: 5px;
`;

const StyledCard = styled(Card)`
  .ant-card-body {
    display: flex;
    align-items: center;
    padding: 5px 10px 5px 10px;
  }
  margin-top: 10px;
  cursor: pointer;
`;

Search.defaultProps = {
  handleAddToList: null,
  menu: null,
  title: 'Введите ссылку на сообщество или профиль',
  enableSearchByQuery: false,
};
