import routes from '../../configs/app.routes';

export const names = {
  [`${routes.app.siteStatistics.path}`]: 'Анализ сайтов',
  [`${routes.app._groupLists}`]: 'Списки сообществ',
  [`${routes.app.collectAndAnalyze}`]: 'Сбор и анализ постов',
};
//

export const searchTitles = {
  0: 'Введите ссылку на сообщество',
  1: 'Введите ссылку на сообщество',
  2: 'Введите ссылку на канал',
  3: 'Введите адрес сайта',
};

export const searchPlaceholders = {
  0: 'https://vk.com/smmblog',
  1: 'https://ok.ru/ok',
  2: '@smmblog',
  3: 'hitalama.com',
};

export const appNames = {
  0: 'VK',
  1: 'OK',
  2: 'TG',
  3: 'Сайты',
};
