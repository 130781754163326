import { IPostReachParams } from '../../types/RequestTypes';
import { chunk } from './auth.helper';

export const createPostReachRequestParams = (
  ownerId: number,
  posts,
): IPostReachParams => {
  const params: IPostReachParams = {} as IPostReachParams;
  params.owner_id = ownerId;
  chunk(posts, 30).forEach(
    (postChunk, i) =>
      (params[`post_ids_${i + 1}`] = postChunk
        .map(postIdObj => postIdObj.postId)
        .join()),
  );

  return params;
};
