import React, { useState } from 'react';
import styled from 'styled-components';

import {
  CloseCircleTwoTone,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import {
  Modal,
  Button,
  Space,
  message,
  Tabs,
  InputNumber,
  Typography,
  Row,
  Col,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { IEntityInfo } from '../../../state/entityFetchModule/types';
import { App } from '../../../constants/enums';
import { logEvent } from '../../../libs/lib.amplitude';
import { LOG_EVENTS } from '../../../constants/lib.constants';
import {
  getImageFromEntity,
  getNameFromEntity,
  getOwnerIdFromEntity,
  getPathName,
} from '../../../helpers/auth.helper';
import routes from '../../../../configs/app.routes';
import { getAppIcon } from '../../../helpers/getAppIcon.helper';
import { useLocation, useNavigate } from 'react-router-dom';
import Tags from '../../common/Tags';
import { generateSocialLink } from '../../../helpers/link.helpers';
import { socialAccountsModuleActions } from '../../../state/socialAccountsModule';
import { IUser } from '../../../state/socialAccountsModule/types';
import CopyToClipboard from '../../common/CopyToClipboard';
import {
  getSelectedAccount,
  getUserAccounts,
} from '../../../state/socialAccountsModule/selector';
import { groupCoversSliceActions } from '../../../state/groupCoversDataModule';
import { IList } from '../../../state/listsModule/types';
import linkNotification from '../../common/LinkNotification';
import ListStatisticsModal from '../../common/Modals/ListStatisticsModal';
import { listStatisticsSliceActions } from '../../../state/listStatistics';
import { getUser } from '../../../state/userAuthModule/selectors';
import FirebaseAccountService from '../../../firebase/firebase.user.service';
import AnonWarningModal from '../../common/Modals/AnonWarningModal';
import { ListsLogs } from '../../../services/amplitude/amplitude';

type Props = {
  handleOnEntityRemove: (ownerId: number, id?: number) => void;
  handleOnListRemove?: (id: number, title: string) => void | null;
  entities: IEntityInfo[];
  showActions?: boolean;
  application: App;
  id?: number;
  title: string;
  roles?: any;
  setEntitiesArr?: any;
  onSearch?: any;
  linkedAccount?: IUser;
  list: IList;
  indexes: {
    repostsIndex: number;
    commentsIndex: number;
  };
  setIndexes?: any;
};

const { confirm } = Modal;

export default function EntitiesList({
  entities,
  handleOnEntityRemove,
  title,
  showActions,
  handleOnListRemove,
  id,
  application,
  setEntitiesArr,
  linkedAccount,
  list,
  indexes,
  setIndexes,
}: Props) {
  const showEntityRemoveIcon = !showActions;
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const usersMap = useSelector(getUserAccounts);
  const user = useSelector(getUser);

  const appPropPassed =
    (application || application === 0) &&
    Object.values(App).includes(application);

  const handleOnListEdit = () => {
    if (user.isAnonymous) {
      return AnonWarningModal(navigate);
    }

    logEvent(LOG_EVENTS.CLICK_BUTTON, {
      page: getPathName(),
      button: 'Редактировать',
      list_name: title,
    });
    navigate(`${routes.app._groupLists}/edit/${id}`);
  };

  const showDeleteModal = () => {
    logEvent(LOG_EVENTS.CLICK_BUTTON, {
      page: getPathName(),
      button: 'Удалить',
      list_name: title,
    });
    confirm({
      title: `Вы точно хотите удалить "${title}" ?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Удалить',
      okType: 'danger',
      cancelText: 'Нет',
      onOk() {
        handleOnListRemove && id && handleOnListRemove(id, title);
      },
    });
  };

  const handleOnRemoveEntity = entity => {
    const ownerId = getOwnerIdFromEntity(entity, entity.app);

    logEvent(LOG_EVENTS.DELETE_GROUP_LIST, {
      name_object: getNameFromEntity(entity),
      objectId: ownerId,
      type: 'groups',
    });
    handleOnEntityRemove(ownerId, id);
  };

  const onShowCover = () => {
    if (!linkedAccount) {
      message.warn('Привяжите аккаунт!');
    } else {
      logEvent(LOG_EVENTS.CLICK_BUTTON, {
        page: getPathName(),
        button: 'Обложки сообществ',
        list_name: title,
      });

      if (!usersMap.hasOwnProperty(`${linkedAccount.userId}`)) {
        linkNotification(list, navigate);
        return;
      }

      dispatch(groupCoversSliceActions.setSelectedList(list));
      dispatch(socialAccountsModuleActions.changeUser({ user: linkedAccount }));
      navigate(`${routes.app._groupLists}/${id}/covers`);

      ListsLogs.showCover('click show cover');
    }
  };

  const onShowListStatistics = async () => {
    if (!list.extended_statistics) {
      ListStatisticsModal(list, dispatch);
      return;
    }

    dispatch(listStatisticsSliceActions.setSelectedList(list));
    navigate(routes.app.groupLists.stats.replace(':id', `${id}`));
  };

  return (
    <Container>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '8px',
        }}
      >
        <Space
          size={'large'}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <Space
            direction={
              location.pathname.includes(
                routes.app.groupLists.edit.split(':')[0],
              )
                ? 'vertical'
                : 'horizontal'
            }
          >
            {appPropPassed && (
              <StyledSvgIcon
                src={`${getAppIcon()[application]}`}
                alt="social svg"
              />
            )}
            <Title className={`list-title`}>
              {location.pathname.includes(routes.app.groupLists.edit)
                ? `Список ${title}`
                : title}
            </Title>
            {location.pathname === routes.app._groupLists && (
              <CopyToClipboard value={`${id}`} />
            )}
          </Space>
          {showActions && (
            <>
              <Edit onClick={handleOnListEdit}>Редактировать</Edit>
              <Remove onClick={showDeleteModal}>Удалить</Remove>
            </>
          )}
          {application === 0 && user.tariffPlan === 'beta' && (
            <Button
              onClick={onShowListStatistics}
              size={'small'}
              type="link"
              children={
                list.extended_statistics
                  ? 'Расширенная статистика'
                  : 'Подключить расширенную статистику'
              }
            />
          )}
        </Space>
        <div>
          {`${application}` === '0' ? (
            <>
              <Button onClick={onShowCover} type="link">
                Обложки сообществ
              </Button>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <EntityContainer location={location.pathname}>
        {location.pathname.includes(
          routes.app.groupLists.edit.split(':')[0],
        ) ? (
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="Сообщества" key="1">
              <Space direction={'vertical'}>
                {entities &&
                  entities.map(entity => (
                    <Entity key={entity.id}>
                      {showEntityRemoveIcon && (
                        <CloseCircleTwoTone
                          style={{
                            color: '#E6F7FF',
                            position: 'absolute',
                            cursor: 'pointer',
                            fontSize: '18px',
                            left: '58px',
                            top: '-6px',
                          }}
                          onClick={() => handleOnRemoveEntity(entity)}
                          twoToneColor="#FF1818"
                        />
                      )}
                      <a
                        href={generateSocialLink(entity)[entity.app || 0]}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          width="70"
                          height="70"
                          src={getImageFromEntity(entity)}
                          alt={entity.name}
                          title={entity.name}
                        />
                      </a>
                      {location.pathname.includes(
                        routes.app.groupLists.edit.split(':')[0],
                      ) && (
                        <StyledEntityInfo>
                          <span>{entity.name}</span>
                          <Tags
                            setEntitiesArr={setEntitiesArr}
                            entity={entity}
                            entities={entities}
                          />
                        </StyledEntityInfo>
                      )}
                    </Entity>
                  ))}
              </Space>
            </Tabs.TabPane>
            {user.tariffPlan === 'beta' && list.application === 1 && (
              <Tabs.TabPane tab="ER" key="2">
                <Space direction={'vertical'}>
                  <Space
                    style={{ width: '200px', justifyContent: 'space-between' }}
                  >
                    <Typography.Text children={'Комментарии:'} />
                    <InputNumber
                      min={1}
                      max={99}
                      defaultValue={indexes?.commentsIndex}
                      onChange={value =>
                        setIndexes({ ...indexes, commentsIndex: value })
                      }
                    />
                  </Space>
                  <Space
                    style={{ width: '200px', justifyContent: 'space-between' }}
                  >
                    <Typography.Text children={'Репосты:'} />
                    <InputNumber
                      min={1}
                      max={99}
                      defaultValue={indexes?.repostsIndex}
                      onChange={value =>
                        setIndexes({ ...indexes, repostsIndex: value })
                      }
                    />
                  </Space>
                </Space>
              </Tabs.TabPane>
            )}
          </Tabs>
        ) : (
          entities &&
          entities.map(entity => (
            <Entity key={entity.id}>
              {showEntityRemoveIcon && (
                <CloseCircleTwoTone
                  style={{
                    color: '#E6F7FF',
                    position: 'absolute',
                    cursor: 'pointer',
                    fontSize: '18px',
                    left: '58px',
                    top: '-6px',
                  }}
                  onClick={() => handleOnRemoveEntity(entity)}
                  twoToneColor="#FF1818"
                />
              )}
              <a
                href={generateSocialLink(entity)[entity.app || 0]}
                target="_blank"
              >
                <img
                  width="70"
                  height="70"
                  src={getImageFromEntity(entity)}
                  alt={entity.name}
                  title={entity.name}
                />
              </a>
              {location.pathname.includes(
                routes.app.groupLists.edit.split(':')[0],
              ) && (
                <StyledEntityInfo>
                  <span>{entity.name}</span>
                  <Tags
                    setEntitiesArr={setEntitiesArr}
                    entity={entity}
                    entities={entities}
                  />
                </StyledEntityInfo>
              )}
            </Entity>
          ))
        )}
      </EntityContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`;

const StyledSvgIcon = styled.img`
  height: 17px;
  width: 17px;
`;

const Title = styled.span`
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  display: block;
  font-weight: 600;
`;

const Edit = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-decoration-line: underline;
  color: #000;
  cursor: pointer;
`;

const Remove = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-decoration-line: underline;
  color: #f00;
  cursor: pointer;
`;

const EntityContainer = styled.div<{ location: string }>`
  display: flex;
  flex-direction: ${p =>
    p.location.includes(routes.app.groupLists.edit.split(':')[0])
      ? 'column'
      : 'row'};
  flex-wrap: wrap;
  grid-gap: 8px;
`;

const Entity = styled.div`
  position: relative;
  display: flex;
`;

const StyledEntityInfo = styled.div`
  margin-left: 15px;

  > span {
    display: block;
    margin-bottom: 5px;
  }
`;

EntitiesList.defaultProps = {
  handleOnListRemove: null,
  showActions: false,
  application: null,
  id: null,
};
