import appInfo from '../../../package.json';

export const pjVersion = appInfo.version;

export type GroupBy = 'none' | 'page' | 'byDay' | 'byWeek' | 'byMonth';
export type PeriodCalculateType = 'isoWeek' | 'month';
export type EntityType = 'group' | 'page';

export const postReachTypes = [
  'reachSubscribers',
  'reachTotal',
  'reachAds',
  'reachViral',
  'toGroup',
  'joinGroup',
  'unsubscribe',
  'links',
  'report',
  'hide',
  'adViews',
  'adSubscribers',
  'adHide',
  'adUnsubscribe',
  'adLinks',
  'adToGroup',
  'adJoinGroup',
  'adCoverage',
  'adReport',
];

export const DATABASE = {
  VERSION: 4,
  NAME: 'hitalamaMultitool-web',
};

export const PRODUCTION_HOST = 'hitalama.com';
export const DEV_HOST = 'hitalama-aea54.web.app';
