import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ILoadingSteps } from './types';

export const initialState: ILoadingSteps = {
  spentTime: {},
  current: 0,
  totalPosts: {},
};

const slice = createSlice({
  name: 'loadingSteps',
  initialState,
  reducers: {
    setLoadingSteps(state, action: PayloadAction<any>) {
      state.current = action.payload.current;
      state.spentTime[action.payload.index] = action.payload.spentTime;
      state.totalPosts[action.payload.index] = action.payload.totalPosts;
      return state;
    },
    reset(state, action: PayloadAction<any>) {
      state = initialState;
      return state;
    },
  },
});

export const {
  actions: loadingStepsModuleActions,
  reducer: loadingStepsModuleReducers,
} = slice;
