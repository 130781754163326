import React, { useState } from 'react';
import { Alert, Layout, Menu, Skeleton, Spin } from 'antd';
import { Outlet } from 'react-router-dom';
import { Sidebar } from '../../components/app/Sidebar';
import { AppHeader } from '../../components/app/AppHeader';

const { Content, Sider } = Layout;

const AppLayout = () => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider>
        <Sidebar />
      </Sider>
      <Layout>
        <AppHeader />
        <Content className="site-layout-background" style={{ margin: '16px' }}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
