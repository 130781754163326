import React from 'react';

import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getListsIsLoad } from '../../state/listsModule/selectors';
import { Spin } from 'antd';
import styled from 'styled-components';

const ListPageContainer = () => {
  const listsLoad = useSelector(getListsIsLoad);

  return listsLoad ? (
    <SpinPage children={<Spin tip={'Загружаем списки...'} />} />
  ) : (
    <Outlet />
  );
};

export default ListPageContainer;

export const SpinPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 154px);
  width: 100%;
`;
