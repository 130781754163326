import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IListStatisticsState, IStatObject } from './types';
import { IList } from '../listsModule/types';

export const initialState: IListStatisticsState = {
  isLoad: false,
  selectedList: {} as IList,
  listStatistics: [],
  failureMessage: '',
};

export type IntervalKeys = 'day' | 'week' | 'month' | 'year' | 'all';

export interface IRequestPayload {
  selectedList: IList;
  interval: IntervalKeys;
  intervalsCount: number;
}

const listStatisticsSlice = createSlice({
  name: 'listStatistics',
  initialState,
  reducers: {
    setListStatisticsRequest(state, action: PayloadAction<IRequestPayload>) {
      state.isLoad = true;
      state.selectedList = action.payload.selectedList;
    },
    setListStatisticsFailure(state, action: PayloadAction<string>) {
      state.isLoad = false;
      state.failureMessage = action.payload;
    },
    setSelectedList(state, action: PayloadAction<IList>) {
      state.selectedList = action.payload;
    },
    setListStatisticsSuccess(state) {
      state.isLoad = false;
    },
    setListStatisticsMiddleResult(state, action: PayloadAction<IStatObject[]>) {
      state.listStatistics = [...state.listStatistics, ...action.payload];
    },
    clearListStatisticsState(state) {
      state.listStatistics = [];
    },
  },
});

export const {
  actions: listStatisticsSliceActions,
  reducer: listStatisticsReducers,
} = listStatisticsSlice;
