import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import {
  Alert,
  Button,
  Form,
  Input,
  Layout,
  message,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getSelectedApplication } from '../../state/socialAccountsModule/selector';
import {
  getOwnerIdFromEntity,
  getPathName,
  getSocialName,
} from '../../helpers/auth.helper';
import { INFO_BLOCK_VARIANT } from '../../constants/collect.constants';
import { logEvent } from '../../libs/lib.amplitude';
import { LOG_EVENTS } from '../../constants/lib.constants';
import { App, AppMaps } from '../../constants/enums';
import Search from '../../components/app/Search';
import EntitiesList from '../../components/app/EntitiesList';
import { getEntityInfo } from '../../state/entityFetchModule/selector';
import { entityModuleActions } from '../../state/entityFetchModule';
import { listsModuleActions } from '../../state/listsModule';
import { getLists } from '../../state/listsModule/selectors';
import { IList } from '../../state/listsModule/types';
import { useLocation } from 'react-router-dom';
import FirestoreListService from '../../firebase/firestore.list.service';
import AccountLink from './components/AccountLink';
import { IUser } from '../../state/socialAccountsModule/types';
import routes from '../../../configs/app.routes';
import AnonWarningModal from '../../components/common/Modals/AnonWarningModal';
import { getUser } from '../../state/userAuthModule/selectors';
import { ClearOutlined } from '@ant-design/icons';
import { ListsLogs } from '../../services/amplitude/amplitude';

const { Content } = Layout;

export default function ListPage() {
  const dispatch = useDispatch();
  const application = useSelector(getSelectedApplication);
  const entityInfo = useSelector(getEntityInfo);
  const listsOfEntities: IList[] = useSelector(getLists);
  const user = useSelector(getUser);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const isEditingMode = !!id;
  const list: IList =
    listsOfEntities.find(
      listOfEntities => `${listOfEntities.id}` === `${id}`,
    ) || ({} as IList);
  const listEntitiesIds = {};
  // @ts-ignore
  list?.entities?.map(
    // eslint-disable-next-line no-return-assign
    entity =>
      (listEntitiesIds[getOwnerIdFromEntity(entity, entity.app)] = true),
  );
  // @ts-ignore
  const [entitiesArr, setEntitiesArr] = useState<any>(list?.entities || []);
  const [addedEntitiesIds, setAddedEntitiesIds] = useState<any>(
    listEntitiesIds,
  );
  const entityInfoExists = entityInfo && Object.keys(entityInfo).length > 0;
  // @ts-ignore
  const [listName, setListTitle] = useState(list?.name || '');
  const [titleName, setTitleName] = useState('');
  const [linkedAccount, setLinkedAccount] = useState<IUser>(
    list?.linkedAccount || ({} as IUser),
  );
  const [linkErr, setLinkErr] = useState(true);

  const entitiesExists = entitiesArr.length > 0;
  const buttonDisabled = !entitiesArr || listName.trim().length === 0;
  const calculatedVariant = entityInfoExists
    ? INFO_BLOCK_VARIANT.ADD_TO_LIST
    : null;

  const handleAddToList = async entityInf1o => {
    let newEntityInfo = { ...entityInf1o };
    const ownerId = getOwnerIdFromEntity(newEntityInfo, newEntityInfo.app);

    if (!addedEntitiesIds[ownerId]) {
      setEntitiesArr([...entitiesArr, newEntityInfo]);
      setAddedEntitiesIds({
        ...addedEntitiesIds,
        [ownerId]: true,
      });
    }
  };

  const handleOnEntityRemove = (ownerId: number) => {
    if (addedEntitiesIds[ownerId]) {
      setEntitiesArr(
        entitiesArr.filter((entity: any) => {
          return getOwnerIdFromEntity(entity, entity.app) !== ownerId;
        }),
      );
      const objClone = {
        ...addedEntitiesIds,
      };
      delete objClone[ownerId];
      setAddedEntitiesIds(objClone);
    }
  };

  const [indexes, setIndexes] = useState(
    list.indexes || {
      repostsIndex: 1,
      commentsIndex: 1,
    },
  );

  const handleListSave = () => {
    if (user.isAnonymous && listsOfEntities.length === 1 && !isEditingMode) {
      AnonWarningModal(navigate);
      return;
    }
    // @ts-ignore
    const listId = +id;
    if (isEditingMode) {
      dispatch(
        listsModuleActions.updateUserList({
          ...list,
          id: listId,
          name: listName,
          entities: entitiesArr,
          linkedAccount: linkedAccount,
          indexes,
        }),
      );
      navigate(routes.app._groupLists);
    } else {
      dispatch(
        listsModuleActions.createListOfEntities({
          name: listName,
          entities: entitiesArr,
          linkedAccount: linkedAccount,
          navigate,
        }),
      );
    }
    dispatch(entityModuleActions.setEntityInfo({}));
  };

  useEffect(() => {
    logEvent(LOG_EVENTS.VIEW_PAGE, {
      page: getPathName(),
      social: application && getSocialName(application),
    });

    pathname === routes.app.groupLists.create
      ? setTitleName('Создание')
      : setTitleName('Редактирование');

    dispatch(entityModuleActions.setEntityInfo({}));

    if (
      pathname === `${routes.app._groupLists}/edit/${id}` &&
      entitiesArr.length === 0
    ) {
      FirestoreListService.getUserDocumentById(id).then(res => {
        setEntitiesArr(res.entities);
        setListTitle(res.name);
        setLinkedAccount(res.linkedAccount);
      });
    }

    setListTitle('Список 1');

    return () => {
      dispatch(entityModuleActions.setEntityInfo({}));
    };
  }, [pathname]);

  return (
    <>
      <ListHeader>
        <PageName>{titleName} списка</PageName>
      </ListHeader>
      <Content
        className="site-layout-background"
        style={{
          margin: '24px 16px',
          overflow: 'initial',
        }}
      >
        <Container>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <StyledContent>
              <Space direction={'vertical'} style={{ width: '500px' }}>
                <Typography.Text children={'Введите название списка'} />
                <Input.Group compact style={{ width: '100%' }}>
                  <Input
                    value={listName}
                    style={{ width: 'calc(100% - 32px)' }}
                    onChange={e => setListTitle(e.target.value)}
                    placeholder={'Введите название списка'}
                  />
                  <Tooltip title="Очистить название списка">
                    <Button
                      id="clear-list-title"
                      onClick={() => setListTitle('')}
                      icon={<ClearOutlined />}
                    />
                  </Tooltip>
                </Input.Group>
                {listName.length === 0 && (
                  <Alert
                    showIcon
                    message="Введите название списка"
                    type="warning"
                  />
                )}
                <Typography.Text children={'Привязанный аккаунт'} />
                <AccountLink
                  list={list}
                  linkedAccount={linkedAccount}
                  setLinkedAccount={setLinkedAccount}
                  entitiesExist={entitiesArr.length > 0}
                  setLinkErr={setLinkErr}
                  linkErr={linkErr}
                />
                {linkedAccount && !linkErr && (
                  <Search
                    title="Введите ссылку на сообщество"
                    handleAddToList={handleAddToList}
                    variant={calculatedVariant}
                    linkedAccount={linkedAccount}
                    list={list}
                  />
                )}
              </Space>
              {entitiesExists && (
                <EntitiesList
                  indexes={indexes}
                  setIndexes={setIndexes}
                  title={listName}
                  entities={entitiesArr}
                  setEntitiesArr={setEntitiesArr}
                  handleOnEntityRemove={handleOnEntityRemove}
                  list={list}
                />
              )}
              {entitiesExists && (
                <Button
                  id="save-list"
                  onClick={handleListSave}
                  disabled={buttonDisabled}
                  type={'primary'}
                  style={{
                    width: '146px',
                    marginTop: '40px',
                  }}
                >
                  Сохранить
                </Button>
              )}
            </StyledContent>
            {linkedAccount?.social === App.vk && (
              <SearchEntityContent>
                <SearchEntityLabel>Поиск по названию группы</SearchEntityLabel>
                <Search
                  enableSearchByQuery
                  title="Укажите поисковую фразу"
                  handleAddToList={handleAddToList}
                  variant={INFO_BLOCK_VARIANT.SEARCH_QUERY}
                  linkedAccount={linkedAccount}
                  entitiesArr={entitiesArr}
                />
              </SearchEntityContent>
            )}
          </div>
        </Container>
      </Content>
    </>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const StyledContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  height: 100%;
`;

const SearchEntityContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 472px;
  padding: 20px;
`;

const SearchEntityLabel = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: #000;
  margin-bottom: 7px;
`;

const ListHeader = styled.div`
  display: flex;
  flex-direction: row;
  height: 64px;
  min-height: 64px;
  max-height: 64px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d9d9d9;
`;

const PageName = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #262626;
  margin-left: 2%;
`;
