export const INFO_BLOCK_VARIANT = {
  COLLECTING: 'COLLECTING',
  COLLECTING_LIST: 'COLLECTING_LIST',
  ADD_TO_LIST: 'ADD_TO_LIST',
  SEARCH_QUERY: 'SEARCH_QUERY',
  SHOW_ROLE: 'SHOW_ROLE',
};

export const DATA_TAB = {
  NONE: 0,
  GRAPHIC: 1,
  TABLE: 2,
};

export const COUNT_EXECUTE_MAP: { [key: number]: number } = {
  300: 0,
  900: 200,
  1500: 400,
  3000: 900,
  7500: 2400,
};

export const ITERATION_LIMIT = 3;
