import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { IGraphicData, IGraphicDataState } from './types';

export const initialState: IGraphicDataState = {
  graphicDataCompare: {
    id: null,
    data: [],
  },
  additionalGraphicTypes: [],
  graphicData: {} as IGraphicData,
  load: false,
  renderGraphics: false,
};

const slice = createSlice({
  name: 'graphicData',
  initialState,
  reducers: {
    setGraphicDataCompare(state, action: PayloadAction<any>) {
      state.graphicDataCompare = {
        id: action.payload.id,
        data: action.payload.data,
      };

      return state;
    },
    setGraphicData(state, action: PayloadAction<any>) {
      state.graphicData = action.payload.graphicData;
      return state;
    },
    setGraphicDataLoad(state, action: PayloadAction<boolean>) {
      state.load = action.payload;
    },
    addToAdditionalGraphicTypes(state, action: PayloadAction<any>) {
      action.payload.graphicType.forEach(type => {
        if (
          !state.additionalGraphicTypes.includes(action.payload.graphicType)
        ) {
          state.additionalGraphicTypes.push(type);
        }
      });

      return state;
    },
    resetAdditionalGraphicTypes(state, action: PayloadAction<any>) {
      state.additionalGraphicTypes = [];
      return state;
    },
    resetGraphicData(state, action: PayloadAction<any>) {
      state.graphicDataCompare = {
        id: null,
        data: [],
      };
      state.additionalGraphicTypes = [];
      state.graphicData = {} as IGraphicData;

      return state;
    },
    fetchGraphicDataCompare(state, action: PayloadAction<any>) {
      return state;
    },
    fetchGraphicData(state, action: PayloadAction<any>) {
      return state;
    },
    reset(state, action: PayloadAction<any>) {
      state = initialState;
      return state;
    },
    renderGraphics(state, action: PayloadAction<boolean>) {
      state.renderGraphics = action.payload;
    },
  },
});

export const {
  actions: graphicDataModuleActions,
  reducer: graphicDataModuleReducers,
} = slice;
