import React, { FC } from 'react';
import styled from 'styled-components';

const ContentContainer: FC = ({ children }) => {
  return <StyledContent children={children} />;
};

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px;
  width: 100%;
  overflow: initial;
`;

export default ContentContainer;
