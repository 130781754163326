import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { IAccountsState } from './types';
import { socialAccountsModuleSaga } from './saga';

export const initialState: IAccountsState = {
  accounts: {},
  application: null,
  selectedAccount: {
    userId: '',
    accessRestricted: false,
  },
  disabledAccounts: {},
};

const slice = createSlice({
  name: 'socialAccounts',
  initialState,
  reducers: {
    addAccount(state, action: PayloadAction<any>) {
      return state;
    },
    addAccountSuccess(state, action: PayloadAction<any>) {
      state.accounts = {
        ...state.accounts,
        [action.payload.user.userId]: action.payload.user,
      };
      state.selectedAccount = {
        userId: action.payload.user.userId,
        accessRestricted: action.payload.user.accessRestricted,
      };
      state.application = action.payload.user.social;
      return state;
    },
    setApplication(state, action: PayloadAction<any>) {
      state.application = action.payload.application;
      return state;
    },
    changeUser(state, action: PayloadAction<any>) {
      return state;
    },
    setSelectedAccount(state, action: PayloadAction<any>) {
      state.selectedAccount.userId = action.payload.userId;
      state.selectedAccount.accessRestricted = false;
      return state;
    },
    signOut(state) {
      state.accounts = {};
      state.application = null;
      state.selectedAccount = {
        userId: '',
        accessRestricted: false,
      };
      return state;
    },
    setDisabledAccounts(state, action: PayloadAction<any>) {
      state.disabledAccounts = action.payload;
    },
  },
});

export const {
  actions: socialAccountsModuleActions,
  reducer: socialAccountsModuleReducers,
} = slice;

export const useSocialAccountsModuleSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: socialAccountsModuleSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useInitModuleSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
