import { customLogger } from '../../helpers/log.helper';
import { IEntityInfo } from '../entityFetchModule/types';
import {
  call,
  delay,
  fork,
  put,
  select,
  takeLatest,
  join,
  retry,
  spawn,
} from 'redux-saga/effects';
import { findEntity } from '../../helpers/data.helpers';
import {
  calculatePostErr,
  chunk,
  getNameFromEntity,
  getOwnerIdFromEntity,
} from '../../helpers/auth.helper';
import { message } from 'antd';
import Post from '../../services/client/database/classes/post';
import { getCollectOwnerIdGroup } from './selectors';
import { IPostReachParams } from '../../../types/RequestTypes';
import { createPostReachRequestParams } from '../../helpers/data-create.helpers';
import { logTypeNames } from '../../constants/log.constants';
import { graphicDataModuleActions } from '../graphicDataModule';
import { postReachTypes } from '../../constants/app.constants';
import { groupDataModuleActions } from './index';
import {
  getDownloadingStatus,
  getEntityInfo,
} from '../entityFetchModule/selector';
import { getDateRange, getDefaultDateRange } from '../dateModule/selectors';
import { DownloadStatus } from '../../constants/enums';
import {
  getPostsReachPayload,
  IErrorRequest,
  IResponse,
  IResult,
} from './types';
import { getAdditionalGraphicTypes } from '../graphicDataModule/selector';
import { IFetchParams } from '../../services/client/database/dbInterface';
import { rateDataModuleActions } from '../ratesModule';
import { Task } from 'redux-saga';
import { collectFlowSaga } from '../collectFlow/collectFlow.saga';
import { getPosts25Reach } from 'app/services/client/vk/procedures';
import { nanoid } from 'nanoid';

let errorRequests: IErrorRequest[] = [];

function* retryProcess(boxSize) {
  const retryTasks: Task[] = [];

  const allPosts = errorRequests.map(error => error.posts).flat();

  const ownerIds = new Set(allPosts.map(post => post.privilegedEntityId));
  const privilegedEntitiesIds = Array.from(ownerIds);

  errorRequests = [];

  for (const privilegedEntityId of privilegedEntitiesIds) {
    const postsById = allPosts.filter(
      post => post.privilegedEntityId === privilegedEntityId,
    );

    const postsChunks = chunk(postsById, 30 * boxSize);

    const collectIdGroup = yield select(getCollectOwnerIdGroup);

    for (const [index, postsChunk] of postsChunks.entries()) {
      const params: IPostReachParams = createPostReachRequestParams(
        privilegedEntityId,
        postsChunk,
      );

      const collectId = collectIdGroup[params.owner_id];

      yield delay(400);

      const task: Task = yield fork(
        postReachRequest,
        params,
        '',
        collectId,
        postsChunk,
        privilegedEntityId,
      );

      retryTasks.push(task);
    }
  }

  return retryTasks;
}

function* postReachRequest(
  params,
  name,
  collectId,
  postsChunk,
  privilegedEntityId,
) {
  const posts = postsChunk.map(post => ({
    ...post,
    privilegedEntityId,
  }));

  try {
    const result: IResult = yield call(getPosts25Reach, params, collectId);

    if (!result.response) {
      console.log('запрос вернул undefined без 500 ошибки');

      errorRequests.push({
        id: nanoid(),
        collectId,
        name,
        posts,
        privilegedEntityId,
      });
    }

    const errorOne = result.errors && result.errors.length > 0;
    const errorTwo =
      (Array.isArray(result.execute_errors) &&
        result.execute_errors.length > 0) ||
      (result.error && result.error.error_msg === 'Access denied');

    if (errorOne) {
      console.log(`error occurred in postReachRequest with params: ${params}`);
      return {
        response: [
          {
            data: [],
            ownerId: name,
          },
        ],
      };
    }

    if (errorTwo) {
      return {
        response: [
          {
            data: [],
            ownerId: name,
          },
        ],
      };
    }

    return result.response;
  } catch (e: any) {
    // массив с ошибочными запросами объявлен в самом верху, не запутайтесь, если есть предложения как сделать лучше я только за
    console.log('запрос вернул 500 ошибку', e);
    errorRequests.push({
      id: nanoid(),
      collectId,
      name,
      posts,
      privilegedEntityId,
    });
  }
}

function* coldStartLoop(ownerIds: number[]) {
  console.time('Расширенный сбор прогрев');
  const coldStartMessage = message.loading(
    'Подготовка сбора расширенной статистики...',
    0,
  );

  for (const ownerId of ownerIds) {
    const collectIdGroup = yield select(getCollectOwnerIdGroup);
    const posts = yield call(Post.getAllPostsIds, ownerId);
    const postsChunks = chunk(posts, 30 * 25);

    for (const [index, postsChunk] of postsChunks.entries()) {
      const params: IPostReachParams = createPostReachRequestParams(
        ownerId,
        postsChunk,
      );

      const collectId = collectIdGroup[params.owner_id];

      yield delay(400);

      yield spawn(getPosts25Reach, params, collectId);
    }
  }

  coldStartMessage();
  console.timeEnd('Расширенный сбор прогрев');
}

function* getPostsReach(action) {
  const {
    privilegedEntitiesIds,
    boxSize,
    entityInfo,
    selectedList,
  }: getPostsReachPayload = action.payload;
  yield coldStartLoop(privilegedEntitiesIds);

  if (process.env.REACT_APP_DEV === 'true') {
    console.time('Расширенный сбор. Затраченное время');
  }

  try {
    const selectedListEntities: false | IEntityInfo[] =
      Object.keys(selectedList).length > 0 && selectedList.entities;

    let index = 1;
    let resultLength: number = 0;
    const tasks: Task[] = [];

    for (const privilegedEntityId of privilegedEntitiesIds) {
      const entity = selectedListEntities
        ? findEntity(selectedListEntities, privilegedEntityId)
        : entityInfo;
      const name = getNameFromEntity(entity);
      const hide = message.loading(
        `${index}/${privilegedEntitiesIds.length} Собираем расширенную статистику постов для ${name}`,
        0,
      );

      const posts = yield call(Post.getAllPostsIds, privilegedEntityId);
      const postsChunks = chunk(posts, 30 * boxSize);
      const collectIdGroup = yield select(getCollectOwnerIdGroup);

      for (const [index, postsChunk] of postsChunks.entries()) {
        const params: IPostReachParams = createPostReachRequestParams(
          privilegedEntityId,
          postsChunk,
        );

        const collectId = collectIdGroup[params.owner_id];

        yield delay(400);

        const task: Task = yield fork(
          postReachRequest,
          params,
          name,
          collectId,
          postsChunk,
          privilegedEntityId,
        );

        tasks.push(task);
      }

      hide();
      index += 1;
    }

    const collectMessage = message.loading(
      'Собираем расширенную статистику',
      0,
    );

    let results: IResponse[] = yield join(tasks);

    let box = boxSize - 5;

    while (errorRequests.length > 0) {
      if (box < 5) {
        box = 1;
      }

      const retryTasks = yield retryProcess(box);

      const retryResults: IResponse[] = yield join(retryTasks);

      results.push(...retryResults);

      box = box - 5;
    }

    const insertMessage = message.loading(
      'Записываем данные в базу данных...',
      0,
    );

    if (process.env.REACT_APP_DEV === 'true') {
      console.time('Записываем данные в indexedDB');
    }

    for (const res of results) {
      if (!res) {
        continue;
      }

      yield call(Post.insertOrReplaceReach, res.data, res.ownerId);

      resultLength += res?.data?.length || 0;
    }

    insertMessage();
    if (process.env.REACT_APP_DEV === 'true') {
      console.timeEnd('Записываем данные в indexedDB');
    }
    message.success('Запись данных в базу данных.', 2);

    if (resultLength) {
      yield put(
        rateDataModuleActions.addToAdditionalTotalRatesTypes({
          totalRatesType: postReachTypes,
        }),
      );
      yield put(
        graphicDataModuleActions.addToAdditionalGraphicTypes({
          graphicType: postReachTypes,
        }),
      );
      message.success('Расширенная статистика постов собрана');
      if (process.env.REACT_APP_DEV === 'true') {
        console.timeEnd('Расширенный сбор. Затраченное время');
      }
      collectMessage();

      yield collectFlowSaga();
    } else {
      message.warning('Нету расширенной статистики постов', 1);
    }
  } catch (e) {
    customLogger('error in getPostReach', e, logTypeNames.ERROR);
  }
}

function* fetchPosts({
  payload: { params },
}: {
  payload: { params: IFetchParams };
}) {
  const entityInfo = yield select(getEntityInfo);
  const downloadingStatus = yield select(getDownloadingStatus);
  const dateRange = yield select(getDateRange);
  const defaultDateRange = yield select(getDefaultDateRange);
  const additionalGraphicTypes = yield select(getAdditionalGraphicTypes);
  const additionalGraphicTypesExists = additionalGraphicTypes.length > 0;

  if (
    entityInfo &&
    Object.keys(entityInfo).length > 0 &&
    downloadingStatus.state === DownloadStatus.SUCCESS
  ) {
    const ownerId = getOwnerIdFromEntity(entityInfo, entityInfo.app);
    const [dateFrom, dateTo] = [
      dateRange.from || defaultDateRange.from,
      dateRange.to || defaultDateRange.to,
    ];

    const effect =
      dateTo && dateFrom
        ? call(
            Post.fetchPostsWithDateRange,
            {
              ...params,
              id: ownerId,
            },
            dateFrom,
            dateTo,
            additionalGraphicTypesExists,
          )
        : call(
            Post.fetchPosts,
            {
              ...params,
              id: ownerId,
            },
            additionalGraphicTypesExists,
          );
    const { total, result } = yield effect;

    const resultWithErr = result.map(post => ({
      ...post,
      err: calculatePostErr(post, { reachTotal: post.reachTotal }),
    }));

    yield put(
      groupDataModuleActions.setEntityPosts({
        total,
        result: resultWithErr,
      }),
    );
  }
}

export function* groupDataModuleSaga() {
  yield takeLatest(groupDataModuleActions.getPostsReach, getPostsReach);
  yield takeLatest(groupDataModuleActions.fetchPosts, fetchPosts);
}
