import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { IDatesState } from './types';

export const initialState: IDatesState = {
  defaultDateRange: {
    from: null,
    to: null,
  },
  dateRange: {
    from: null,
    to: null,
  },
  groupBy: 'none',
  load: false,
};

const slice = createSlice({
  name: 'dates',
  initialState,
  reducers: {
    setDefaultDateRange(state, action: PayloadAction<any>) {
      state.defaultDateRange = {
        from: action.payload.from,
        to: action.payload.to,
      };
      return state;
    },
    setDateRange(state, action: PayloadAction<any>) {
      state.dateRange = {
        from: action.payload.from,
        to: action.payload.to,
      };
      return state;
    },
    setGroupBy(state, action: PayloadAction<any>) {
      state.groupBy = action.payload.groupBy;
      return state;
    },
    reset(state, action: PayloadAction<any>) {
      state = initialState;
      return state;
    },
  },
});

export const {
  actions: dateModuleActions,
  reducer: dateModuleReducers,
} = slice;
