import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ICompareDataState } from './types';
import { DownloadStatus } from '../../constants/enums';

export const initialState: ICompareDataState = {
  comparePeriods: false,
  comparingFetchStatus: DownloadStatus.NOT_STARTED,
};

const slice = createSlice({
  name: 'compareData',
  initialState,
  reducers: {
    setComparePeriods(state, action: PayloadAction<any>) {
      state.comparePeriods = action.payload.comparePeriods;
      return state;
    },
    setComparingFetchStatus(state, action: PayloadAction<any>) {
      state.comparingFetchStatus = action.payload.status;
      return state;
    },
    reset(state, action: PayloadAction<any>) {
      state = initialState;
      return state;
    },
  },
});

export const {
  actions: compareDataModuleActions,
  reducer: compareDataModuleReducers,
} = slice;
