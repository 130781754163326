import { createSlice } from '@reduxjs/toolkit';
import { SiteStatisticsState } from './types';

export const initialState: SiteStatisticsState = {
  isLoad: false,
  siteStatistics: [],
  selectedList: {
    id: 0,
    name: '',
    type: '',
    application: 3,
    entities: [],
  },
  lists: [],
  domainsArray: [],
  message: {
    fail: '',
    success: '',
  },
  graphicsData: [],
  filteredGraphicsData: [],
  steps: {
    all: [],
    current: 0,
    status: 'wait',
  },
};

const siteStatisticsSlice = createSlice({
  name: 'siteStatistics',
  initialState,
  reducers: {
    setSiteStatisticsRequest(state, action) {
      state.isLoad = true;
      state.message.fail = '';
      state.selectedList = action.payload.list;
    },
    setSiteStatisticsSuccess(state, action) {
      state.siteStatistics = action.payload;
      state.isLoad = false;
    },
    setSiteStatisticsFailure(state, action) {
      state.message.fail = action.payload;
      state.isLoad = false;
    },
    setSiteStatisticsGraphicsData(state, action) {
      state.graphicsData = action.payload;
    },
    setSiteStatisticsFilteredGraphicsData(state, action) {
      state.filteredGraphicsData = action.payload;
    },
    setSiteStatisticsGraphicsList(state, action) {
      state.selectedList = action.payload;
    },
    setSiteStatisticsLists(state, action) {
      state.lists = action.payload;
    },
    setSiteStatisticsSteps(state, action) {
      state.steps.all = action.payload;
    },
    setSiteStatisticsStepStatus(state, action) {
      state.steps.current = action.payload.current;
      state.steps.status = action.payload.status;
      state.steps.all[action.payload.current] = {
        ...state.steps.all[action.payload.current],
        description: action.payload.description,
      };
    },
    setSiteStatisticsStepTime(state, action) {
      state.steps.all = [...state.steps.all].map(step => {
        if (step.title === action.payload.domain) {
          return {
            title: step.title,
            description: action.payload.description,
          };
        } else {
          return step;
        }
      });
    },
  },
});

export const {
  actions: siteStatisticsSliceActions,
  reducer: siteStatisticsReducers,
} = siteStatisticsSlice;
