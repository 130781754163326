import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './index';
import { RootState } from '../../../types';
import { IListStatisticsState } from './types';

const selectSlice = (state: RootState) => state.listStatistics || initialState;

const selectState = (key: keyof IListStatisticsState) =>
  createSelector([selectSlice], state => state[key]);

export const getSelectedStatisticsList = createSelector(
  [selectSlice],
  state => state.selectedList,
);

export const getListStatLoad = createSelector(
  [selectSlice],
  state => state.isLoad,
);
export const getListStatistics = createSelector(
  [selectSlice],
  state => state.listStatistics,
);
