import moment from 'moment';
import {
  calculatePostErr,
  constructEntityUrl,
  constructPostUrl,
  getMembersCountFromEntity,
  getNameFromEntity,
  getOwnerIdFromEntity,
} from './auth.helper';
import { IEntityInfo } from '../state/entityFetchModule/types';
import { store } from '../../index';
import { entityModuleActions } from '../state/entityFetchModule';

const socialNameMap = {
  0: 'VK',
  1: 'OK',
  2: 'TG',
};

export const formatPosts = async (
  posts: any[],
  namesMap,
  entityInfo: IEntityInfo,
) => {
  return new Promise(resolve => {
    let value = 0;
    let max = posts.length || 100;

    const formattedPosts: any[] = [];

    (function loop() {
      formattedPosts.push({
        Social: socialNameMap[namesMap[posts[value].ownerId].app],
        Name: namesMap[posts[value].ownerId].name,
        Url: constructPostUrl(
          posts[value].ownerId,
          posts[value].postId,
          namesMap[posts[value].ownerId].app,
          posts[value].link,
        ),
        Likes: posts[value].likesCount,
        Reposts: posts[value].repostsCount,
        Comments: posts[value].commentsCount,
        Views: posts[value].viewsCount,
        Date: new Date(posts[value].timestamp),
        Author:
          Math.sign(posts[value].ownerId) === -1
            ? `public${Math.abs(posts[value].ownerId)}`
            : posts[value].ownerId,
        'ER.v': posts[value].erv && parseFloat(posts[value].erv),
        'ER.p': posts[value].erp && parseFloat(posts[value].erp),
        'ER.r': parseFloat(
          calculatePostErr(posts[value], {
            reachTotal: posts[value].reachTotal,
          }),
        ),
        'Reach Subscribers':
          typeof posts[value].reachSubscribers !== 'number'
            ? ''
            : posts[value].reachSubscribers,
        'Reach Total':
          typeof posts[value].reachTotal !== 'number'
            ? ''
            : posts[value].reachTotal,
        'Reach Ads':
          typeof posts[value].reachAds !== 'number'
            ? ''
            : posts[value].reachAds,
        'Reach Viral':
          typeof posts[value].reachViral !== 'number'
            ? ''
            : posts[value].reachViral,
        'To Group':
          typeof posts[value].toGroup !== 'number' ? '' : posts[value].toGroup,
        'Join Group':
          typeof posts[value].joinGroup !== 'number'
            ? ''
            : posts[value].joinGroup,
        Unsubscribe:
          typeof posts[value].unsubscribe !== 'number'
            ? ''
            : posts[value].unsubscribe,
        Links: typeof posts[value].links !== 'number' ? '' : posts[value].links,
        Report:
          typeof posts[value].report !== 'number' ? '' : posts[value].report,
        Hide: typeof posts[value].hide !== 'number' ? '' : posts[value].hide,
        'Ad Views':
          typeof posts[value].adViews !== 'number' ? '' : posts[value].adViews,
        'Ad Subscribers':
          typeof posts[value].adSubscribers !== 'number'
            ? ''
            : posts[value].adSubscribers,
        'Ad Hide':
          typeof posts[value].adHide !== 'number' ? '' : posts[value].adHide,
        'Ad Unsubscribe':
          typeof posts[value].adUnsubscribe !== 'number'
            ? ''
            : posts[value].adUnsubscribe,
        'Ad Links':
          typeof posts[value].adLinks !== 'number' ? '' : posts[value].adLinks,
        'Ad To Group':
          typeof posts[value].adToGroup !== 'number'
            ? ''
            : posts[value].adToGroup,
        'Ad Join Group':
          typeof posts[value].adJoinGroup !== 'number'
            ? ''
            : posts[value].adJoinGroup,
        'Ad Coverage':
          typeof posts[value].adCoverage !== 'number'
            ? ''
            : posts[value].adCoverage,
      });

      store.dispatch(
        entityModuleActions.setExportPercent({ max: max, value: value }),
      );
      value++;
      if (value < max) {
        setTimeout(loop, 0);
      } else {
        resolve(formattedPosts);
      }
    })();
  });
};

export const formatMembersCount = (posts: any[], namesMap) => {
  // Уникальные значения
  const uniqValue = (arr: any[]) => Array.from(new Set(arr));
  const social = posts.map(post => socialNameMap[namesMap[post.ownerId].app]);

  const uniqPublicName = uniqValue(
    posts.map(post => namesMap[post.ownerId].name),
  );
  const uniqAuthor = uniqValue(
    posts.map(post =>
      Math.sign(post.ownerId) === -1
        ? `public${Math.abs(post.ownerId)}`
        : post.ownerId,
    ),
  );
  const collectionData = moment().format('DD.MM.YYYY');
  const uniqFollowersCount = uniqValue(
    posts.map(post => namesMap[post.ownerId].followersCount),
  );

  const uniqTags = uniqValue(
    posts.map(post =>
      namesMap[post.ownerId].tags.length > 0
        ? namesMap[post.ownerId].tags
        : [''],
    ),
  );

  let uniqArray: any = [];

  for (let key in uniqPublicName) {
    uniqArray.push({
      Social: social[key],
      Name: uniqPublicName[key],
      Author: uniqAuthor[key],
      'Дата сбора': collectionData,
      'Количество подписчиков': uniqFollowersCount[key],
      Теги: uniqTags[key].join('|'),
    });
  }

  return uniqArray;
};

export const formatEntitiesTotalRates = (
  entities,
  totalRates,
  dateFromSeconds,
  dateToSeconds,
  dateRangeExists,
  app,
) => {
  const result: { [key: string]: any }[] = [];
  Object.keys(totalRates).forEach(totalRateKey => {
    const entityInfo = entities.find(
      entity => `${getOwnerIdFromEntity(entity, app)}` === `${totalRateKey}`,
    );
    const ownerId = getOwnerIdFromEntity(entityInfo, app);

    if (process.env.REACT_APP_DEV === 'true') {
      console.log(
        'totalRates[totalRateKey].postsCount.length',
        totalRates[totalRateKey].postsCount,
      );
    }

    if (totalRates[totalRateKey].postsCount > 0) {
      result.push({
        Название: getNameFromEntity(entityInfo),
        Ссылка: constructEntityUrl(ownerId, app),
        Author:
          Math.sign(ownerId) === -1 ? `public${Math.abs(ownerId)}` : ownerId,
        'Период отчёта': dateRangeExists
          ? `${new Date(dateFromSeconds * 1000).toLocaleDateString(
              'ru-RU',
            )} - ${new Date(dateToSeconds * 1000).toLocaleDateString('ru-RU')}`
          : 'Период не указан',
        'Количество подписчиков на момент сбора данных': getMembersCountFromEntity(
          entityInfo,
        ),
        Посты:
          totalRates[totalRateKey].postsCount &&
          +totalRates[totalRateKey].postsCount.toString().replace(/\s/g, ''),
        Лайки:
          totalRates[totalRateKey].likesCount &&
          +totalRates[totalRateKey].likesCount.toString().replace(/\s/g, ''),
        Репосты:
          totalRates[totalRateKey].repostsCount &&
          +totalRates[totalRateKey].repostsCount.toString().replace(/\s/g, ''),
        Комментарии:
          totalRates[totalRateKey].commentsCount &&
          +totalRates[totalRateKey].commentsCount.toString().replace(/\s/g, ''),
        Просмотры:
          totalRates[totalRateKey].viewsCount &&
          +totalRates[totalRateKey].viewsCount.toString().replace(/\s/g, ''),
        'ER.v':
          totalRates[totalRateKey].erv &&
          parseFloat(totalRates[totalRateKey].erv),
        'ER.p':
          totalRates[totalRateKey].erp &&
          parseFloat(totalRates[totalRateKey].erp),
        'Reach Subscribers':
          typeof totalRates[totalRateKey].reachSubscribers !== 'number'
            ? ''
            : totalRates[totalRateKey].reachSubscribers,
        'Reach Total':
          typeof totalRates[totalRateKey].reachTotal !== 'number'
            ? ''
            : totalRates[totalRateKey].reachTotal,
        'Reach Ads':
          typeof totalRates[totalRateKey].reachAds !== 'number'
            ? ''
            : totalRates[totalRateKey].reachAds,
        'Reach Viral':
          typeof totalRates[totalRateKey].reachViral !== 'number'
            ? ''
            : totalRates[totalRateKey].reachViral,
        'To Group':
          typeof totalRates[totalRateKey].toGroup !== 'number'
            ? ''
            : totalRates[totalRateKey].toGroup,
        'Join Group':
          typeof totalRates[totalRateKey].joinGroup !== 'number'
            ? ''
            : totalRates[totalRateKey].joinGroup,
        Unsubscribe:
          typeof totalRates[totalRateKey].unsubscribe !== 'number'
            ? ''
            : totalRates[totalRateKey].unsubscribe,
        Links:
          typeof totalRates[totalRateKey].links !== 'number'
            ? ''
            : totalRates[totalRateKey].links,
        Report:
          typeof totalRates[totalRateKey].report !== 'number'
            ? ''
            : totalRates[totalRateKey].report,
        Hide:
          typeof totalRates[totalRateKey].hide !== 'number'
            ? ''
            : totalRates[totalRateKey].hide,
        'Ad Views':
          typeof totalRates[totalRateKey].adViews !== 'number'
            ? ''
            : totalRates[totalRateKey].adViews,
        'Ad Subscribers':
          typeof totalRates[totalRateKey].adSubscribers !== 'number'
            ? ''
            : totalRates[totalRateKey].adSubscribers,
        'Ad Hide':
          typeof totalRates[totalRateKey].adHide !== 'number'
            ? ''
            : totalRates[totalRateKey].adHide,
        'Ad Unsubscribe':
          typeof totalRates[totalRateKey].adUnsubscribe !== 'number'
            ? ''
            : totalRates[totalRateKey].adUnsubscribe,
        'Ad Links':
          typeof totalRates[totalRateKey].adLinks !== 'number'
            ? ''
            : totalRates[totalRateKey].adLinks,
        'Ad To Group':
          typeof totalRates[totalRateKey].adToGroup !== 'number'
            ? ''
            : totalRates[totalRateKey].adToGroup,
        'Ad Join Group':
          typeof totalRates[totalRateKey].adJoinGroup !== 'number'
            ? ''
            : totalRates[totalRateKey].adJoinGroup,
        'Ad Coverage':
          typeof totalRates[totalRateKey].adCoverage !== 'number'
            ? ''
            : totalRates[totalRateKey].adCoverage,
        'Ad Report':
          typeof totalRates[totalRateKey].adReport !== 'number'
            ? ''
            : totalRates[totalRateKey].adReport,
      });
    }
  });
  return result;
};

export const formatEntitiesTotalRatesForPeriod = (
  entities,
  totalRatesForPeriod,
  app,
  periodType: 'isoWeek' | 'month' = 'isoWeek',
) => {
  const result: any = [];
  Object.keys(totalRatesForPeriod).forEach(totalRateKey => {
    const entityInfo = entities.find(
      entity => `${getOwnerIdFromEntity(entity, app)}` === `${totalRateKey}`,
    );
    const ownerId = getOwnerIdFromEntity(entityInfo, app);
    Object.keys(totalRatesForPeriod[ownerId]).forEach(periodTimestamp => {
      const date = new Date(periodTimestamp);
      const totalRates = totalRatesForPeriod[ownerId][date.toString()];
      if (totalRates.postsCount > 0) {
        result.push({
          Название: getNameFromEntity(entityInfo),
          Ссылка: constructEntityUrl(ownerId, app),
          Author:
            Math.sign(ownerId) === -1 ? `public${Math.abs(ownerId)}` : ownerId,
          'Период отчёта': `${new Date(
            moment(date).startOf(periodType).valueOf(),
          ).toLocaleDateString('ru-RU')} - ${date.toLocaleDateString('ru-RU')}`,
          'Количество подписчиков на момент сбора данных': getMembersCountFromEntity(
            entityInfo,
          ),
          Посты:
            totalRates.postsCount &&
            +totalRates.postsCount.toString().replace(/\s/g, ''),
          Лайки:
            totalRates.likesCount &&
            +totalRates.likesCount.toString().replace(/\s/g, ''),
          Репосты:
            totalRates.repostsCount &&
            +totalRates.repostsCount.toString().replace(/\s/g, ''),
          Комментарии:
            totalRates.commentsCount &&
            +totalRates.commentsCount.toString().replace(/\s/g, ''),
          Просмотры:
            totalRates.viewsCount &&
            +totalRates.viewsCount.toString().replace(/\s/g, ''),
          'ER.v': totalRates.erv && parseFloat(totalRates.erv),
          'ER.p': totalRates.erp && parseFloat(totalRates.erp),
          'Reach Subscribers':
            typeof totalRates.reachSubscribers !== 'number'
              ? ''
              : totalRates.reachSubscribers,
          'Reach Total':
            typeof totalRates.reachTotal !== 'number'
              ? ''
              : totalRates.reachTotal,
          'Reach Ads':
            typeof totalRates.reachAds !== 'number' ? '' : totalRates.reachAds,
          'Reach Viral':
            typeof totalRates.reachViral !== 'number'
              ? ''
              : totalRates.reachViral,
          'To Group':
            typeof totalRates.toGroup !== 'number' ? '' : totalRates.toGroup,
          'Join Group':
            typeof totalRates.joinGroup !== 'number'
              ? ''
              : totalRates.joinGroup,
          Unsubscribe:
            typeof totalRates.unsubscribe !== 'number'
              ? ''
              : totalRates.unsubscribe,
          Links: typeof totalRates.links !== 'number' ? '' : totalRates.links,
          Report:
            typeof totalRates.report !== 'number' ? '' : totalRates.report,
          Hide: typeof totalRates.hide !== 'number' ? '' : totalRates.hide,
          'Ad Views':
            typeof totalRates.adViews !== 'number' ? '' : totalRates.adViews,
          'Ad Subscribers':
            typeof totalRates.adSubscribers !== 'number'
              ? ''
              : totalRates.adSubscribers,
          'Ad Hide':
            typeof totalRates.adHide !== 'number' ? '' : totalRates.adHide,
          'Ad Unsubscribe':
            typeof totalRates.adUnsubscribe !== 'number'
              ? ''
              : totalRates.adUnsubscribe,
          'Ad Links':
            typeof totalRates.adLinks !== 'number' ? '' : totalRates.adLinks,
          'Ad To Group':
            typeof totalRates.adToGroup !== 'number'
              ? ''
              : totalRates.adToGroup,
          'Ad Join Group':
            typeof totalRates.adJoinGroup !== 'number'
              ? ''
              : totalRates.adJoinGroup,
          'Ad Coverage':
            typeof totalRates.adCoverage !== 'number'
              ? ''
              : totalRates.adCoverage,
          'Ad Report':
            typeof totalRates.adReport !== 'number' ? '' : totalRates.adReport,
        });
      }
    });
  });
  return result;
};
