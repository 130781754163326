import { Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import styled from 'styled-components';
import routes from '../../../../configs/app.routes';

const Breadcrumbs = () => {
  const breadcrumbItems = [
    <Breadcrumb.Item key="group-list">
      <Link to={routes.app._groupLists}>Списки сообществ</Link>
    </Breadcrumb.Item>,
    <Breadcrumb.Item key="group-list">Обложки</Breadcrumb.Item>,
  ];

  return (
    <BreadContainer>
      <Breadcrumb>{breadcrumbItems}</Breadcrumb>
    </BreadContainer>
  );
};

const BreadContainer = styled.div`
  margin-bottom: 15px;
  border-bottom: none;

  > div {
    padding-bottom: 15px;
  }
`;

export default Breadcrumbs;
