import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './index';
import { UserState } from './types';
import { RootState } from '../../../types';

const selectSlice = (state: RootState) => state.user || initialState;

export const getUser = createSelector([selectSlice], state => state);

export const getUserMail = createSelector([selectSlice], state => state.email);

export const getUserId = createSelector([selectSlice], state => state.userId);
