import React, { FC } from 'react';
import styled from 'styled-components';

interface IPageHeaderProps {
  title: string;
}

const PageHeader: FC<IPageHeaderProps> = ({ title }) => {
  return (
    <StyledPageHeader>
      <StyledPageName>{title}</StyledPageName>
    </StyledPageHeader>
  );
};

const StyledPageHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding: 25px;
  justify-content: space-between;
  border-bottom: 1px solid #d9d9d9;
`;

const StyledPageName = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #262626;
`;

export default PageHeader;
