import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { IGroupDataState } from './types';

export const initialState: IGroupDataState = {
  managedGroupsStats: {},
  groupPostsCount: 0,
  posts: {
    total: 0,
    result: [],
  },
  collectIdOwnerId: {},
};

const slice = createSlice({
  name: 'groupData',
  initialState,
  reducers: {
    setEntityPosts(state, action: PayloadAction<any>) {
      state.posts = {
        total: action.payload.total || 0,
        result: action.payload.result || [],
      };
      return state;
    },
    fetchPosts(state, action: PayloadAction<any>) {
      return state;
    },
    setOwnerIdCollectIdGroup(state, action: PayloadAction<any>) {
      state.collectIdOwnerId[action.payload.ownerId] = action.payload.collectId;
      return state;
    },
    getPostsReach(state, action: PayloadAction<any>) {
      return state;
    },
    reset(state, action: PayloadAction<any>) {
      state = initialState;
      return state;
    },
  },
});

export const {
  actions: groupDataModuleActions,
  reducer: groupDataModuleReducers,
} = slice;
