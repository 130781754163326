import { customLogger } from '../../helpers/log.helper';
import {
  getEntityApp,
  getEntityId,
  getMembersCountFromEntity,
  getNameFromEntity,
  getOwnerIdFromEntity,
  numberWithSpaces,
} from '../../helpers/auth.helper';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getAdditionalTotalRatesTypes, getTotalRates } from './selectors';
import { rateDataModuleActions } from './index';
import Post from '../../services/client/database/classes/post';
import { getDateRange, getDefaultDateRange } from '../dateModule/selectors';
import { IList } from '../listsModule/types';
import { getSelectedList } from '../listsModule/selectors';
import { getEntityInfo } from '../entityFetchModule/selector';
import { IEntityInfo } from '../entityFetchModule/types';

export function* calculateTotalRatesWorker() {
  customLogger(`Сводные показатели. Начинаем операции...`);
  const before = Date.now();
  yield put(rateDataModuleActions.setCalculateTotalRatesStatus(true));

  const entityInfo = yield select(getEntityInfo);
  const selectedList: IList = yield select(getSelectedList);
  const additionalTotalRatesTypes = yield select(getAdditionalTotalRatesTypes);
  const dateRange = yield select(getDateRange);
  const defaultDateRange = yield select(getDefaultDateRange);

  const listSelected = Object.keys(selectedList).length > 0;
  const entities: IEntityInfo[] = listSelected
    ? selectedList.entities
    : [entityInfo];

  const totalRates: any = {};

  const ratesTypes = [
    'viewsCount',
    'likesCount',
    'repostsCount',
    'commentsCount',
    'postsCount',
    ...additionalTotalRatesTypes,
  ];

  for (const entity of entities) {
    const ownerId = getOwnerIdFromEntity(entity, entity.app);

    totalRates[ownerId] = totalRates[ownerId] || {};

    const [dateFrom, dateTo] = [
      dateRange.from || defaultDateRange.from,
      dateRange.to || defaultDateRange.to,
    ];

    const additionalGraphicTypesExists = additionalTotalRatesTypes.length > 0;

    const effect =
      dateTo && dateFrom
        ? call(
            additionalGraphicTypesExists
              ? Post.getAllByIdWithReachDateRange
              : Post.getPostsWithDateRange,
            ownerId,
            dateFrom,
            dateTo,
            selectedList.indexes,
          )
        : call(
            additionalGraphicTypesExists
              ? Post.getAllByIdWithReach
              : Post.getAllById,
            ownerId,
          );

    const posts = yield effect;

    (() => {
      posts.forEach(post => {
        if (post.reach_total === 0) {
          if (process.env.REACT_APP_DEV === 'true') {
            console.log(
              `ошибка в полученных постах из бд, для формирования сводных показателей, с react_total,в группе ${entity?.name}, пост:`,
              post,
            );
          }
        }
      });
    })();

    posts.forEach((post: any) => {
      ratesTypes.forEach(rateType => {
        const isPostsCountType = rateType === 'postsCount';

        totalRates[ownerId][rateType] = totalRates[ownerId][rateType] || 0;

        totalRates[ownerId][rateType] = totalRates[ownerId][rateType]
          ? totalRates[ownerId][rateType] +
            (isPostsCountType ? 1 : post[rateType])
          : isPostsCountType
          ? 1
          : post[rateType];
      });

      totalRates[ownerId].erv = totalRates[ownerId].erv
        ? parseFloat(totalRates[ownerId].erv || 0) + parseFloat(post.erv || 0)
        : parseFloat(post.erv || 0);

      totalRates[ownerId].err = post.reachTotal
        ? (totalRates[ownerId].err || 0) +
          ((post.likesCount + post.repostsCount + post.commentsCount) * 100) /
            post.reachTotal
        : 0;
    });

    const viewCount: number = totalRates[ownerId].viewsCount || 0;
    const likesCount: number = totalRates[ownerId].likesCount || 0;
    const repostsCount: number = totalRates[ownerId].repostsCount || 0;
    const commentsCount: number = totalRates[ownerId].commentsCount || 0;
    const postsCount: number = totalRates[ownerId].postsCount || 0;
    const reachTotal: number = totalRates[ownerId].reachTotal || 0;

    const postsArrIsEmpty = posts.length === 0;

    totalRates[ownerId].postsCount = !postsArrIsEmpty
      ? numberWithSpaces(postsCount)
      : '------';

    totalRates[ownerId].reachTotal = !postsArrIsEmpty
      ? numberWithSpaces(reachTotal)
      : '------';

    totalRates[ownerId].viewsCount = !postsArrIsEmpty
      ? numberWithSpaces(viewCount)
      : '------';

    totalRates[ownerId].likesCount = !postsArrIsEmpty
      ? numberWithSpaces(likesCount)
      : '------';

    totalRates[ownerId].repostsCount = !postsArrIsEmpty
      ? numberWithSpaces(repostsCount)
      : '------';

    totalRates[ownerId].commentsCount = !postsArrIsEmpty
      ? numberWithSpaces(commentsCount)
      : '------';

    totalRates[ownerId].erv = !postsArrIsEmpty
      ? `${(totalRates[ownerId].erv / posts.length).toFixed(2)}%`
      : '------';

    totalRates[ownerId].err = !postsArrIsEmpty
      ? `${(totalRates[ownerId].err / posts.length).toFixed(2)}%`
      : '------';

    const membersCount = getMembersCountFromEntity(entity);

    totalRates[ownerId].erp = !postsArrIsEmpty
      ? `${(
          ((likesCount + repostsCount + commentsCount) /
            (membersCount * posts.length)) *
          100
        ).toFixed(2)}%`
      : '------';

    totalRates[ownerId].membersCount = membersCount;
    totalRates[ownerId].name = getNameFromEntity(entity);
    totalRates[ownerId].id = getEntityId(entity);
    totalRates[ownerId].app = getEntityApp(entity);

    const currentTotalRates = yield select(getTotalRates);
    if (currentTotalRates[ownerId]) {
      totalRates[ownerId] = {
        ...currentTotalRates[ownerId],
        ...totalRates[ownerId],
      };
    }
  }

  const after = Date.now();
  customLogger(
    `Сводные показатели. Все операции завершены.`,
    (after - before) / 1000,
  );

  yield put(rateDataModuleActions.setTotalRates({ totalRates: totalRates }));
  yield put(rateDataModuleActions.setCalculateTotalRatesStatus(false));
}

export function* ratesModuleSaga() {
  yield takeLatest(
    rateDataModuleActions.calculateTotalRates,
    calculateTotalRatesWorker,
  );
}
