import React from 'react';

import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';

import { LOG_EVENTS } from '../../../constants/lib.constants';
import {
  getOwnerIdFromEntity,
  getPathName,
  getSocialName,
} from '../../../helpers/auth.helper';
import { logEvent } from '../../../libs/lib.amplitude';
import { GroupBy as GroupByType } from '../../../constants/app.constants';
import { getSelectedApplication } from '../../../state/socialAccountsModule/selector';
import { getSelectedList } from '../../../state/listsModule/selectors';
import { getEntityInfo } from '../../../state/entityFetchModule/selector';
import { getGroupBy } from '../../../state/dateModule/selectors';
import { dateModuleActions } from '../../../state/dateModule';
import { PostsAnalyzeLogs } from '../../../services/amplitude/amplitude';

const { Option } = Select;

export default function GroupBy() {
  const dispatch = useDispatch();

  const application = useSelector(getSelectedApplication);
  const selectedList = useSelector(getSelectedList);
  const entityInfo = useSelector(getEntityInfo);
  const groupBy = useSelector(getGroupBy);

  const selectedListExists = Object.keys(selectedList).length > 0;

  const handleSelectChange = (value: GroupByType) => {
    logEvent(LOG_EVENTS.GROUP_BY, {
      page: getPathName(),
      type: selectedListExists ? 'list' : 'object',
      list_name: selectedListExists ? selectedList.name : 'none',
      list_count: selectedListExists ? selectedList.entities.length : 'none',
      objectId: selectedListExists
        ? 'none'
        : getOwnerIdFromEntity(entityInfo, entityInfo.app),
      group_by_value: value,
      social: application && getSocialName(application),
    });
    dispatch(dateModuleActions.setGroupBy({ groupBy: value }));

    PostsAnalyzeLogs.groupData('click posts collect group by', {
      groupBy: value,
    });
  };

  return (
    <SelectWrapper>
      <span>Группировать данные:</span>
      <Select
        style={{ width: 170 }}
        onChange={handleSelectChange}
        defaultValue={groupBy === 'none' ? 'byDay' : groupBy}
      >
        <Option value="byDay">По дням</Option>
        <Option value="byWeek">По неделям</Option>
        <Option value="byMonth">По месяцам</Option>
      </Select>
    </SelectWrapper>
  );
}

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  & > span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #262626;
  }
`;
