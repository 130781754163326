import {
  Button,
  Card,
  Input,
  Menu,
  message,
  Select,
  Space,
  Switch,
  Tooltip,
  Typography,
} from 'antd';
import { IAdditionalUserInfo } from '../../../state/userAuthModule/types';
import Profile from './Profile';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../../../state/userAuthModule/selectors';
import styled from 'styled-components';
import FirebaseAccountService from '../../../firebase/firebase.user.service';
import { initModuleActions } from '../../../state/initModule';
import { getLinkedCred } from '../../../localStorage';
import AnonWarningModal from '../../common/Modals/AnonWarningModal';
import { useNavigate } from 'react-router-dom';
import { QuestionCircleOutlined } from '@ant-design/icons';
import Text from 'antd/es/typography/Text';
import { authModuleActions } from '../../../state/userAuthModule';

interface IProfileMenuProps {
  user?: IAdditionalUserInfo;
  setEditProfileVisible: any;
  onSignOut: any;
  handleMenuClick: any;
  setVisible: (visible: boolean) => void;
}

export const ProfileMenu = ({
  onSignOut,
  handleMenuClick,
  setVisible,
}: IProfileMenuProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector(getUser);
  const [linkLoad, setLinkLoad] = useState(false);
  const [checked, setChecked] = useState<boolean>(user.storeTokens || false);

  const accounts = getLinkedCred();

  const handleStoreTokens = async checked => {
    if (user?.isAnonymous) {
      setChecked(false);
      AnonWarningModal(navigate);
      return;
    }

    setChecked(checked);
    setLinkLoad(true);

    const linkStatus = checked
      ? await FirebaseAccountService.linkUserSocialAccount(accounts)
      : await FirebaseAccountService.unlinkUserSocialAccount();

    if (linkStatus.status === 'linked') {
      dispatch(initModuleActions.init({}));
      message.success('Токены сохранены');
    } else {
      dispatch(initModuleActions.init({}));
      message.success('Токены удалены');
    }
  };

  const [searchedUser, setSearchedUser] = useState<IAdditionalUserInfo | null>(
    null,
  );

  const [changeLoad, setChangeLoad] = useState<boolean>(false);
  const [searchLoad, setSearchLoad] = useState<boolean>(false);

  const handleChangeRole = async (tariff: string) => {
    setChangeLoad(true);
    try {
      const updatedUser = await FirebaseAccountService.updateUserTariff(
        searchedUser?.userId || '',
        tariff,
      );
      setSearchedUser({ ...updatedUser, userId: searchedUser?.userId || '' });
      dispatch(
        authModuleActions.setUser({ user: { ...user, ...updatedUser } }),
      );
      setChangeLoad(false);
    } catch (e) {
      setChangeLoad(false);
    }
  };

  const handleSearchUser = async (uid: string) => {
    setSearchLoad(true);
    const searchedUser = await FirebaseAccountService.getUserAdditionalInfoByUid(
      uid.trim(),
    );
    setSearchedUser({ ...searchedUser, userId: uid });
    setSearchLoad(false);
  };

  return (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="0" style={{ cursor: 'default' }}>
        <Profile user={user} expand />
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="1">
        <span>
          Тарифный план: <b>{user?.tariffPlan}</b>
        </span>
      </Menu.Item>
      {user?.role === 'admin' && (
        <Menu.Item key="2">
          <span>
            Роль: <b>{user?.role}</b>
          </span>
        </Menu.Item>
      )}

      <Menu.Item key="3">
        <StyledMenuItem>
          <Space align={'center'}>
            <span>Хранить токены соц. сетей</span>
            <Tooltip
              placement="left"
              title={
                'Здесь будет информация о том,\n' +
                'зачем мы предлагаем сохранять или не сохранять токены социальных сетей.\n' +
                'Подробнее.'
              }
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </Space>
          <Switch
            defaultChecked={user?.storeTokens}
            onChange={checked => handleStoreTokens(checked)}
            loading={linkLoad}
            disabled={!accounts}
            checked={checked}
          />
        </StyledMenuItem>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="4">
        <Typography.Link
          rel="noreferrer"
          target="_blank"
          href="https://vk.me/shmakov.media"
        >
          Сообщить о проблеме или предложить идею
        </Typography.Link>
      </Menu.Item>
      <Menu.Divider />
      {user?.role === 'admin' && (
        <>
          <Menu.Item style={{ padding: '5px 12px', width: '400px' }} key="5">
            <Space style={{ width: '100%' }} direction={'vertical'}>
              <Text strong children={'Поиск пользователей'} />
              <Input.Search
                placeholder="Введите uid пользователя"
                enterButton="Найти"
                loading={searchLoad}
                onSearch={value => handleSearchUser(value)}
              />
              {searchedUser && (
                <Card size={'small'}>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Text children={searchedUser?.userId} />
                    <Select
                      loading={changeLoad}
                      value={searchedUser.tariffPlan || ''}
                      onChange={value => handleChangeRole(value)}
                    >
                      <Select.Option value={'test'} children={'test'} />
                      <Select.Option value={'beta'} children={'beta'} />
                      <Select.Option value={'anonim'} children={'anonim'} />
                    </Select>
                  </div>
                </Card>
              )}
            </Space>
          </Menu.Item>
          <Menu.Divider />
        </>
      )}
      <Menu.Item key="6">
        <Button
          style={{ width: '100%' }}
          onClick={onSignOut}
          id="signOut-button-vk"
          type="primary"
        >
          Выйти
        </Button>
      </Menu.Item>
    </Menu>
  );
};

const StyledMenuItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
