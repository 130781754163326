import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ISearchState } from './types';
import { SearchStatus } from '../../constants/enums';

export const initialState: ISearchState = {
  search: {
    state: SearchStatus.INITIAL,
  },
  currentCountExecute: 0,
  lastFetchDetails: {
    iterationLimit: 0,
    countExecute: 0,
  },
};

const slice = createSlice({
  name: 'searchData',
  initialState,
  reducers: {
    setSearchStatus(state, action: PayloadAction<any>) {
      state.search = {
        state: action.payload.state,
      };
      return state;
    },
    setLastFetchDetails(state, action: PayloadAction<any>) {
      state.lastFetchDetails = {
        iterationLimit: action.payload.iterationLimit,
        countExecute: action.payload.countExecute,
      };
      return state;
    },
    setCurrentExecuteCount(state, action: PayloadAction<any>) {
      state.currentCountExecute = action.payload.countExecute;
      return state;
    },
    reset(state, action: PayloadAction<any>) {
      state = initialState;
      return state;
    },
  },
});

export const {
  actions: searchDataModuleActions,
  reducer: searchDataModuleReducers,
} = slice;
