import * as React from 'react';
import styled from 'styled-components/macro';
import { Link, useLocation } from 'react-router-dom';
import { DatabaseOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Layout, Space, Tag } from 'antd';
import { Menu } from 'antd';

import footerLogo from '../../../../styles/assets/png/footer-logo.png';
import { pjVersion } from '../../../constants/app.constants';
import routes from '../../../../configs/app.routes';
import { Logo } from '../../../../styles/style-constants';
import { useSelector } from 'react-redux';
import { getSelectedApplication } from '../../../state/socialAccountsModule/selector';
import { useMemo, useState } from 'react';
import { GlobalOutlined } from '@ant-design/icons/lib';
import { names } from '../../../helpers/names';
import { disableAccountByUrl } from '../../../helpers/getAppIcon.helper';
import app from '../../../../fbConfig';
import { getUser } from '../../../state/userAuthModule/selectors';

const { Sider } = Layout;
const { Item } = Menu;

export function Sidebar() {
  const location = useLocation();
  const selectedApplication = useSelector(getSelectedApplication);
  const user = useSelector(getUser);
  const hasSocialAccount = useMemo(
    () => selectedApplication || selectedApplication === 0,
    [selectedApplication],
  );

  app.analytics().logEvent('page_view');

  const [collapsible, setCollapsible] = useState(false);

  return (
    <Sider
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
      }}
      trigger={null}
      collapsible
      collapsed={false}
    >
      <SideChildren>
        <Logo>Хиталама</Logo>
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[
            location.pathname === routes.app.groupLists.create ||
            location.pathname.includes(`${routes.app._groupLists}/edit`)
              ? routes.app._groupLists
              : location.pathname,
          ]}
        >
          <StyledItem
            disabled={!hasSocialAccount}
            key={routes.app.collectAndAnalyze}
            icon={<DatabaseOutlined />}
          >
            <Link
              id={hasSocialAccount ? 'dashboard-page' : ''}
              to={routes.app.collectAndAnalyze}
              onClick={() => disableAccountByUrl(routes.app.collectAndAnalyze)}
            >
              {names[routes.app.collectAndAnalyze]}
            </Link>
          </StyledItem>
          <StyledItem
            disabled={!hasSocialAccount}
            key={routes.app._groupLists}
            icon={<UnorderedListOutlined />}
          >
            <Link
              id={hasSocialAccount ? 'lists-page' : ''}
              to={routes.app._groupLists}
              onClick={() => disableAccountByUrl(routes.app._groupLists)}
            >
              {names[routes.app._groupLists]}
            </Link>
          </StyledItem>
          {user.tariffPlan === 'beta' && (
            <StyledItem
              disabled={!hasSocialAccount}
              key={routes.app.siteStatistics.path}
              icon={<GlobalOutlined />}
            >
              <Link
                id={hasSocialAccount ? 'site-statistics' : ''}
                to={routes.app.siteStatistics.path}
                onClick={() =>
                  disableAccountByUrl(routes.app.siteStatistics.path)
                }
              >
                {names[routes.app.siteStatistics.path]}
              </Link>
            </StyledItem>
          )}
        </Menu>
      </SideChildren>
      <div>
        <Space
          style={{
            width: '100%',
            justifyContent: 'center',
            marginBottom: '15px',
          }}
        >
          <Version>{pjVersion}</Version>
          {process.env.REACT_APP_DEV === 'true' && (
            <Tag children={<b>DEV</b>} />
          )}
        </Space>
        <Brand>
          <LogoHeaderText>разработано в</LogoHeaderText>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.shmakov.media/tools/hitalama-multitool?utm_source=tools&utm_medium=hm&utm_content=sidebar"
          >
            <img width="151" height="46" src={footerLogo} alt="" />
          </a>
        </Brand>
      </div>
    </Sider>
  );
}

const LogoHeaderText = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #d9d9d9;
  margin-bottom: 6px;
`;

const Brand = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 3px solid #1890ff;
  margin-bottom: 15px;
`;

const Version = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  color: #d9d9d9;
`;

const SideChildren = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledItem = styled(Item)`
  display: flex;
  align-items: center;
  font-weight: 100;
  font-size: 13px;
`;
