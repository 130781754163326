import React, { memo, useEffect } from 'react';

import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4themesAnimated from '@amcharts/amcharts4/themes/animated';
import { Select } from 'antd';
import { useDispatch } from 'react-redux';

import { graphicDataModuleActions } from '../../../state/graphicDataModule';
import { GRAPHIC_TITLE } from '../../../constants/graphic.constants';
import styled from 'styled-components';
import { IGraphicDataCompare } from '../../../state/graphicDataModule/types';

am4core.useTheme(am4themesAnimated);

const { Option } = Select;

function GraphicCompare({ id, data }: IGraphicDataCompare) {
  const dispatch = useDispatch();
  const chartId = 'chart-compare';

  useEffect(() => {
    const chart = am4core.create(chartId, am4charts.XYChart);
    chart.colors.list = [am4core.color('#007ab1'), am4core.color('#587bc6')];
    chart.data = data.slice().sort((a, b) => a.ax - b.ax);
    chart.seriesContainer.draggable = false;
    chart.seriesContainer.resizable = false;

    // Create axes
    const valueAxisX = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxisX.renderer.minGridDistance = 40;

    // Create value axis
    chart.yAxes.push(new am4charts.ValueAxis());

    // Create series
    const lineSeries = chart.series.push(new am4charts.LineSeries());
    const bullet = lineSeries.bullets.push(new am4charts.CircleBullet());
    const bulletHover = bullet.states.create('hover');

    bullet.circle.stroke = am4core.color('#fff');
    bullet.circle.strokeWidth = 2;
    bulletHover.properties.scale = 1.3;
    lineSeries.dataFields.valueY = 'ay';
    lineSeries.dataFields.valueX = 'ax';
    lineSeries.tooltipText = 'Месяц 1 | X: {valueX} / Y: {valueY}';
    lineSeries.strokeWidth = 2;
    lineSeries.minBulletDistance = 15;
    lineSeries.name = 'Месяц 1';

    const lineSeries2 = chart.series.push(new am4charts.LineSeries());
    const bullet2 = lineSeries2.bullets.push(new am4charts.CircleBullet());
    const bulletHover2 = bullet.states.create('hover');

    bullet2.circle.stroke = am4core.color('#fff');
    bullet2.circle.strokeWidth = 2;
    bulletHover2.properties.scale = 1.3;
    lineSeries2.dataFields.valueY = 'by';
    lineSeries2.dataFields.valueX = 'bx';
    lineSeries2.tooltipText = 'Месяц 2 | X: {valueX} / Y: {valueY}';
    lineSeries2.strokeWidth = 2;
    lineSeries2.minBulletDistance = 15;
    lineSeries2.name = 'Месяц 2';

    chart.legend = new am4charts.Legend();

    // Add cursor and series tooltip support
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = 'none';

    // @ts-ignore
    valueAxisX.getSeriesDataItem = function (series, position) {
      // eslint-disable-next-line react/no-this-in-sfc
      const key = this.axisFieldName + this.axisLetter;
      // eslint-disable-next-line react/no-this-in-sfc
      const value = this.positionToValue(position);
      return series.dataItems.getIndex(
        series.dataItems.findClosestIndex(
          value,
          function (x) {
            return x[key] ? x[key] : undefined;
          },
          'any',
        ),
      );
    };

    return () => {
      chart.dispose();
    };
  }, [data, id]);

  const handleOnChange = value => {
    dispatch(
      graphicDataModuleActions.fetchGraphicDataCompare({ graphicType: value }),
    );
  };

  return (
    <Container>
      <GraphicTitle>
        <span>{GRAPHIC_TITLE.graphicCompare}</span>
        <Select
          style={{ width: 200, marginLeft: '10px' }}
          placeholder="Выбрать тип графика"
          onChange={handleOnChange}
        >
          <Option value="viewsCount">{GRAPHIC_TITLE.viewsCount}</Option>
          <Option value="likesCount">{GRAPHIC_TITLE.likesCount}</Option>
          <Option value="repostsCount">{GRAPHIC_TITLE.repostsCount}</Option>
          <Option value="commentsCount">{GRAPHIC_TITLE.commentsCount}</Option>
          <Option value="postsCount">{GRAPHIC_TITLE.postsCount}</Option>
        </Select>
      </GraphicTitle>
      <div
        style={{
          width: '100%',
          height: '500px',
        }}
        id={chartId}
      />
    </Container>
  );
}

const Container = styled.div`
  margin-top: 5px;
`;

const GraphicTitle = styled.h1`
  & > span {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #262626;
  }
`;

export default memo(GraphicCompare);
