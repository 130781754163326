import app from '../../fbConfig';
import { ERRORS, List, ListRoles } from './firebase.types';
import FirebaseUtils from './firebase.utils';
import { IUser } from '../state/socialAccountsModule/types';
import { IList } from '../state/listsModule/types';

export default class FirestoreListService {
  public static getAllUserDocuments(): Promise<List[] | any> {
    const userId = FirebaseUtils.getCurrentUserUid();

    if (!userId) {
      return Promise.reject(ERRORS.NO_USER);
    }

    return app
      .firestore()
      .collection('lists')
      .where(`roles.${userId}`, 'in', ['owner', 'reader', 'editor'])
      .get()
      .then(querySnapshot => {
        const userLists: any = [];
        querySnapshot.forEach(doc => {
          userLists.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        return userLists;
      });
  }

  public static getUserDocumentById(
    docId: string | undefined,
  ): Promise<List | any> {
    const userId = FirebaseUtils.getCurrentUserUid();

    if (!userId) {
      return Promise.reject(ERRORS.NO_USER);
    }

    return app
      .firestore()
      .collection('lists')
      .doc(docId)
      .get()
      .then(doc => {
        if (doc.exists) {
          return doc.data();
        } else {
          alert('Запрашиваемый документ не сущенствует!');
        }
      })
      .catch(error => {
        console.log('Error getting document:', error);
        alert('Ошибка получения документа, ошибка в консоли');
      });
  }

  public static setAllDocuments(
    data: {
      name: string;
      entities: string;
      application: string;
      role: ListRoles;
      id: string;
    }[],
  ): Promise<void> {
    const db = app.firestore();
    const batch = db.batch();

    data.forEach(doc => {
      const newDoc = db.collection('lists').doc(doc.id); //automatically generate unique id
      batch.set(newDoc, doc);
    });

    return batch.commit().catch(e => console.log(e, 'error in batch set'));
  }

  public static updateDocument(data: {
    name: string;
    entities: string;
    role: ListRoles;
    id: string;
    linkedAccount: IUser;
  }): Promise<void> {
    const db = app.firestore();
    const docId = `${data.id}`;

    return db
      .collection('lists')
      .doc(docId)
      .update({ ...data })
      .catch(e => {
        return Promise.reject(ERRORS.PERMISSION_DENIED);
      });
  }

  public static async addDocument(data: {
    name: string;
    entities: string;
    application: string;
    role?: ListRoles;
    linkedAccount: IUser;
  }): Promise<any> {
    const userId = FirebaseUtils.getCurrentUserUid();

    if (!userId) {
      return Promise.reject(ERRORS.NO_USER);
    }

    const now = new Date().getTime();

    return app
      .firestore()
      .collection('lists')
      .doc(`${now}`)
      .set({
        name: data.name,
        created: now,
        lastUpdate: now,
        application: data.application,
        roles: {
          [userId]: data.role || ListRoles.OWNER,
        },
        entities: JSON.parse(data.entities),
        linkedAccount: data.linkedAccount,
      })
      .then(docRef => {
        // @ts-ignore
        window.dataLayer.push({
          event: 'autoEvent',
          eventCategory: 'list',
          eventAction: 'create',
          eventLabel: undefined,
          eventValue: undefined,
        });

        return { id: now, lastUpdate: now };
      })
      .catch(error => {
        console.error('Error adding document: ', error);
      });
  }

  public static async removeDocument(id: string): Promise<{ status: string }> {
    const userId = FirebaseUtils.getCurrentUserUid();

    if (!userId) {
      return Promise.reject(ERRORS.NO_USER);
    }

    return await app
      .firestore()
      .collection('lists')
      .doc(id)
      .delete()
      .then(() => {
        return { status: 'success' };
      })
      .catch(error => {
        return Promise.reject(ERRORS.PERMISSION_DENIED);
      });
  }

  public static removeAllUserDocument(): Promise<void> {
    const userId = FirebaseUtils.getCurrentUserUid();

    if (!userId) {
      return Promise.reject(ERRORS.NO_USER);
    }

    const userDocs = app
      .firestore()
      .collection('lists')
      .where(`roles.${userId}`, 'in', ['owner']);

    let batch = app.firestore().batch();

    return userDocs.get().then(snapshot => {
      snapshot.docs.forEach(doc => {
        batch.delete(doc.ref);
      });
      return batch.commit();
    });
  }

  public static shareDocument(
    shareUserId: string,
    docId: string,
  ): Promise<void> {
    const userId = FirebaseUtils.getCurrentUserUid();

    if (!userId) {
      return Promise.reject(ERRORS.NO_USER);
    }

    return app
      .firestore()
      .collection('lists')
      .doc(docId)
      .get()
      .then(doc => {
        const data = doc.data() || { roles: {} };

        return app
          .firestore()
          .collection('lists')
          .doc(docId)
          .set({
            ...data,
            roles: {
              ...data.roles,
              [shareUserId]: ListRoles.READER,
            },
          });
      })
      .catch(error => {
        console.error('Error removing document: ', error);
      });
  }

  public static getList(listId: string): Promise<IList> {
    const userId = FirebaseUtils.getCurrentUserUid();

    if (!userId) {
      return Promise.reject(ERRORS.NO_USER);
    }

    return app
      .firestore()
      .collection('lists')
      .doc(listId)
      .get()
      .then(doc => {
        if (doc.exists) {
          return doc.data() as IList;
        } else {
          return {} as IList;
        }
      });
  }

  public static async enableExtendedStatistics(
    listId: number,
  ): Promise<IList[]> {
    const userId = FirebaseUtils.getCurrentUserUid();

    if (!userId) {
      return Promise.reject(ERRORS.NO_USER);
    }

    const listRef = app.firestore().collection('lists').doc(`${listId}`);

    await listRef.update({
      extended_statistics: true,
    });

    return this.getAllUserDocuments();
  }
}
