import { Col, Layout, PageHeader, Row } from 'antd';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listStatisticsSliceActions } from '../../state/listStatistics';
import FirestoreListService from '../../firebase/firestore.list.service';
import {
  getListStatistics,
  getListStatLoad,
  getSelectedStatisticsList,
} from '../../state/listStatistics/selectors';
import StatSkeleton from './components/StatSkeleton';
import StatCard from './components/StatCard';
import StatCardActions from './components/StatCardActions';
import { IIntervalKeys } from './components/types';
import { getUser } from '../../state/userAuthModule/selectors';

const ListStatistics = () => {
  const [interval, setInterval] = useState<IIntervalKeys>('day');
  const [intervalsCount, setIntervalsCount] = useState<number>(7);

  const { id } = useParams<'id'>();
  const statLoad = useSelector(getListStatLoad);
  const listStatistics = useSelector(getListStatistics);
  const selectedListStatistics = useSelector(getSelectedStatisticsList);
  const user = useSelector(getUser);
  const dispatch = useDispatch();

  const handleGetListStatistics = async () => {
    const list = await FirestoreListService.getList(id || '');

    dispatch(
      listStatisticsSliceActions.setListStatisticsRequest({
        selectedList: list,
        interval,
        intervalsCount,
      }),
    );
  };

  useEffect(() => {
    if (Object.keys(selectedListStatistics).length > 0) {
      dispatch(
        listStatisticsSliceActions.setListStatisticsRequest({
          selectedList: selectedListStatistics,
          interval,
          intervalsCount,
        }),
      );
    } else {
      handleGetListStatistics();
    }

    return () => {
      dispatch(listStatisticsSliceActions.clearListStatisticsState());
    };
  }, []);

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Статистика по списку"
        extra={[
          user.tariffPlan === 'beta' && (
            <StatCardActions
              list={selectedListStatistics}
              interval={interval}
              intervalsCount={intervalsCount}
              setInterval={setInterval}
              setIntervalsCount={setIntervalsCount}
            />
          ),
        ]}
      />
      <Layout.Content style={{ margin: '0 25px' }}>
        {listStatistics.length > 0 && (
          <Row gutter={[0, 16]}>
            {listStatistics.map((stat, index) => (
              <Col key={index} span={24}>
                <StatCard stat={stat} />
              </Col>
            ))}
          </Row>
        )}
        {statLoad && <StatSkeleton statLoad={statLoad} />}
      </Layout.Content>
    </>
  );
};

export default ListStatistics;
