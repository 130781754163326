import styled from 'styled-components';

export const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  color: #1890ff;
  font-size: 22px;
`;
