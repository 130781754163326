import { App } from '../../../constants/enums';
import FirebaseUtils from '../../../firebase/firebase.utils';
import { message } from 'antd';
import { instance } from '../index';

const url =
  process.env.REACT_APP_DEV === 'true'
    ? 'https://us-central1-hitalama-aea54.cloudfunctions.net/tgApi'
    : 'https://us-central1-hitalama-prod.cloudfunctions.net/tgApi';

export default class TgApiServices {
  public static async getChannelInfo(channelID) {
    const token = await FirebaseUtils.getCurrentUserToken();
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    return await instance(url)
      .get(`getChannel?channelId=${channelID}`, config)
      .then(({ data }) => {
        return {
          id: data.response.id,
          name: data.response.title,
          channelId: data.response.username,
          type: 'page',
          followers_count: data.response.participants_count,
          photo_100: data.response.image100,
          app: App.tg,
        };
      })
      .catch(e => console.log(e));
  }

  public static async getChannelsInfo(entitiesChannelIds: string[], key) {
    const promises: Promise<{}>[] = [];

    const token = await FirebaseUtils.getCurrentUserToken();
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    entitiesChannelIds.forEach(item => {
      promises.push(instance(url).get(`getChannel?channelId=${item}`, config));
    });

    return await Promise.all(promises)
      .then((result: any) => {
        const response = result.map(({ data }) => ({
          id: data.response.id,
          name: data.response.title,
          channelId: data.response.username,
          type: 'page',
          members_count: data.response.participants_count,
          photo_100: data.response.image100,
          app: App.tg,
        }));

        message.success({
          content: `Данные успешно обновлены`,
          key: key,
          duration: 2,
        });

        return { response: response };
      })
      .catch(err =>
        message.error(`Произошла ошибка в обновление данных :( Ошибка: ${err}`),
      );
  }

  public static async getChannelPublications(
    channelID: string,
    offset: number,
  ) {
    const token = await FirebaseUtils.getCurrentUserToken();
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    return await instance(url)
      .get(`getPublications?channelId=${channelID}&offset=${offset}`, config)
      .then(({ data }) => {
        return data;
      })
      .catch(e => console.log(e));
  }
}
//
