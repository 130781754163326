import { ErrorMap } from '@firebase/util';

export const AUTH_TYPE_VK = 0;
export const AUTH_TYPE_OK = 1;
export const AUTH_TYPE_TG = 3;

export const APP_CREDENTIALS = {
  [AUTH_TYPE_OK]: {
    id: '1267244800',
    key: 'CBAQJJIMEBABABABA',
    secretKey: '97FBD726C57CDD56EFE1467A',
    url: 'https://api.ok.ru/fb.do',
  },
  [AUTH_TYPE_TG]: {
    userId: '82186',
    accessToken: 'cc6488e26b5b6b2de9614089e6277175',
    isPermissionRequest: true,
    sessionSecretKey: 'ab373074103e4c0d843b30c6e3a82186',
  },
};

export const SOCIAL_NAMES = {
  [AUTH_TYPE_VK]: 'VK',
  [AUTH_TYPE_OK]: 'OK',
  [AUTH_TYPE_TG]: 'TG',
};

export const authErrorsMap = {
  'auth/wrong-password': 'Пароль недействителен или у пользователя нет пароля.',
  'auth/user-not-found':
    'Нет записи пользователя, соответствующей этому идентификатору. Возможно, пользователь был удален.',
  'auth/too-many-requests':
    'Доступ к этой учетной записи был временно отключен из-за множества неудачных попыток входа в систему. Вы можете немедленно восстановить его, сбросив пароль, или можете повторить попытку позже.',
};
